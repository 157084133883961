/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/ban-types */
import React, { RefObject, useState, useCallback, useEffect } from 'react';

import { FiChevronLeft } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import ReactInputMask from 'react-input-mask';
import TegraButton from '../../../../components/TegraButton';
import TegraDivider from '../../../../components/TegraDivider';
import {
  Wrapper,
  CustomSelect,
  WizardNavigationContainer,
  WizardNavigationGoBack,
  Subtitle,
  ExtraActions,
  GoBackContainer,
  Title,
  ErrorsContainer,
  LineDivTitleErrors,
} from './styles';
import TegraRadio from '../../../../components/TegraRadio';
import api from '../../../../services/api';
import ajaxLoading from '../../../../assets/ajax-loader.gif';
import { ImobiliariaFormData } from '..';
import ImobiliariaCorretores from '../../../../components/ImobiliariaCorretores';
// import { StepProps, withWizard } from 'react-albus';

interface StepProps {
  updateForm: (data: ImobiliariaFormData) => void;
  id: string;
  formRef: RefObject<FormHandles>;
  formData: ImobiliariaFormData;
  changeStep: (step: string) => void;
}

interface Gerente {
  id_corretorSigavi: number;
  tx_apelido: string;
  tx_nome: string;
}

const StepTwo: React.FC<StepProps> = ({
  formRef,
  updateForm,
  formData,
  changeStep,
}) => {
  const [defaultValues, setDefaultValues] = useState<ImobiliariaFormData>(
    () => {
      const data = { ...formData };
      return data;
    },
  );

  const [loading, setLoading] = useState<boolean>(true);

  const [errors, setErrors] = useState<string[]>([]);

  const [descErrors, setDescErrors] = useState<string>('');

  const [gerentes, setGerentes] = useState<Gerente[]>([]);

  const [corretores, setCorretores] = useState<any[]>([]);

  const [erroArquivo, setErroArquivo] = useState<boolean>(false);

  const getSelectDefaultValue = useCallback(
    value => {
      const findSelectValue = gerentes.find(
        item => item.id_corretorSigavi === value,
      );
      if (!findSelectValue) {
        return { value: '', label: '' };
      }
      return {
        label: findSelectValue.tx_nome,
        value: findSelectValue.id_corretorSigavi,
      };
    },
    [gerentes],
  );

  useEffect(() => {
    setDescErrors("");
    if (erroArquivo){      
      setErrors(["O arquivo enviado para a associação de corretores a sua imobiliária deve ser um arquivo no formato '.csv'"]);
    }
    else{
      setErrors([]);
    }
  }, [erroArquivo]);


  useEffect(() => {
    const { regional } = defaultValues;
    async function loadGerentes() {
      const res = await api.get<Gerente[]>('Cadastro/gerentes/:id_regional', {
        params: {
          id_regional: Number(regional.value),
        },
      });
      setLoading(false);
      const { data } = res;
      setGerentes(data);
    }
    loadGerentes();
  }, [defaultValues]);

  const handleStepSubmit = useCallback(async () => {
    let data: ImobiliariaFormData = {} as ImobiliariaFormData;

    if (!formRef.current) {
      return;
    }
      
    setDescErrors('');
    setErrors([]);
    
    try {
      data = defaultValues as ImobiliariaFormData;
      updateForm({ ...defaultValues });
      const obj = {
        gerente: Yup.object()
          .nullable()
          .required('Gerente')
          .shape({
            value: Yup.string().required('Selecione o gerente'),
          }),
      };
      const schema = Yup.object().shape({
        possui_gerente: Yup.boolean(),
        ...(data.possui_gerente ? obj : {}),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      setLoading(true);
      const invalidCpf = corretores.filter(c => c.valid !== true).length;
      if (invalidCpf > 0) {
        setDescErrors('Um ou mais campos preenchidos consta alguma informação inválida. Por favor, verifique os campos e tente novamente.');
        setErrors([`Ops, parece que ${invalidCpf} CPF está incorreto`]);
        setLoading(false);
        return;
      }
      const CpfNull = corretores.filter(c => c.tx_cpf === "").length;
      if (CpfNull > 0) {
        setDescErrors('Um ou mais campos preenchidos consta alguma informação inválida. Por favor, verifique os campos e tente novamente.')
        setErrors([`CPF de corretor vazio`]);
        setLoading(false);
        return;
      }

      const dataConstituicaoTratada = data.data_constituicao
      ? `${data.data_constituicao
          .split('/')
          .reverse()
          .join('-')}T00:00:00.000Z`
      : '';
      const requestData = {
        // só utilizado caso esteja editando
        id_parceiro: formData?.id_parceiro,
        id_tipoParceiro: 1,
        tx_cnpj: data.cnpj,
        tx_razaoSocial: data.razao_social,
        tx_responsavel: data.nome,
        tx_responsavelEmail: data.email,
        tx_responsavelTelefone: data.telefone,
        id_zona: data.zona?.value,
        tx_zona: data.zona?.label,
        tx_nome: data.nome,
        tx_email: data.email,
        tx_telefone: data.telefone,
        tx_celular: data.celular,
        tx_inscricaoEstadual: data.inscricao_estadual,
        dt_dataConstituicao: dataConstituicaoTratada,
        tx_logradouro: data.endereco,
        tx_numero: data.numero,
        tx_complemento: data.complemento,
        tx_bairro: data.bairro,
        tx_cep: data.cep,
        id_regional: data.regional?.value,
        tx_regional: data.regional?.label,
        id_cidade: data.cidade?.value,
        tx_cidade: data.cidade?.label,
        tx_creci: data.creci,
        dt_cadastro: new Date().toISOString(),
        tx_uf: data.regional.uf,

        id_gerente: data.gerente?.value || 0,
        tx_gerente: data.gerente?.label,
        hasGerente: data.possui_gerente,

        tx_origemContato: data.contato?.label,
        tx_outraOrigem: data.contato?.label === "Outros" ? data.contato?.outraOrigem : null,
      };
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      // eslint-disable-next-line no-inner-declarations
      // eslint-disable-next-line no-shadow
      const save = async (obj: any): Promise<void> => {
        const req = { ...obj };
        const res = await api.post('Cadastro/part2', req);
        let ok = true;
        const { sucesso } = res.data;
        ok = ok && sucesso;
        if (sucesso) {
          const res2 = await api.post('Cadastro/corretores', {
            id_imobiliaria: req.id_parceiro,
            corretores: corretores.map(c => ({
              ...c,
              id_imobiliaria: req.id_parceiro,
            })),
          });

          const { sucesso: sucesso2 } = res2.data;
          ok = ok && sucesso2;
          if (sucesso2) {
            setLoading(false);
            data.step = 'stepTwo';
            sessionStorage.setItem('_cadastro', JSON.stringify(data));
            changeStep('stepThree');
          }
        }
        if (!ok) {
          setErrors([
            'Ops, não foi possível completar essa etapa, tente novamente',
          ]);
        }
        setLoading(false);
      };
      save(requestData);
    } catch (err : any) {
      const validationErrors: string[] = [];
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        setDescErrors('Um ou mais campos preenchidos consta alguma informação inválida. Por favor, verifique os campos e tente novamente.');
        err.inner.forEach(error => {
          validationErrors.push(error.message);
        });
        setErrors(validationErrors);
      }       
      if (err.response) {
        const { erro } = err.response.data;
        validationErrors.push(erro);
        setErrors(validationErrors);
      }
    }
  }, [formRef, defaultValues, updateForm, formData, changeStep, corretores]);

  const handlePrevious = useCallback(() => {
    const clearObject = {
      possui_gerente: false,
      gerente: {
        label: '',
        value: '',
      },
    };

    updateForm({ ...defaultValues, ...clearObject });

    changeStep('stepOne');
  }, [changeStep, defaultValues, updateForm]);

  const handleSelectChange = useCallback(
    data => {
      updateForm({ ...defaultValues, gerente: data });

      setDefaultValues({ ...defaultValues, gerente: data });
    },
    [defaultValues, updateForm],
  );

  const handleRadioChange = useCallback(
    event => {
      const possui_gerente = event.target.value === '1';
      let { gerente } = defaultValues;

      if (!gerente || !possui_gerente) {
        gerente = { label: '', value: '' };
      }

      if (formRef.current) {
        updateForm({ ...defaultValues, possui_gerente, gerente });
      }
      setDefaultValues({ ...defaultValues, possui_gerente, gerente });
    },
    [defaultValues, formRef, updateForm],
  );

  return (
    <Wrapper>
      <ExtraActions>
        <GoBackContainer>
          <FiChevronLeft size={10} />
          <span onClick={handlePrevious}>Voltar</span>
        </GoBackContainer>
        <Link to="/" className="jaPossuoCadastro">
          Já possuo cadastro
        </Link>
      </ExtraActions>
      <Title>Cadastre-se</Title>
      <Subtitle>Sou uma imobiliária</Subtitle>
      {errors.length >= 1 && (
        <ErrorsContainer>
          {descErrors !== '' &&(
            <div>
              <p>{descErrors} </p>   
              <LineDivTitleErrors/>    
            </div>
          )}
          {errors.map(error => (
            <p dangerouslySetInnerHTML={{ __html: error }} />
          ))}
        </ErrorsContainer>
      )}
      <TegraDivider axis="x" color="#e6e6e6" />
      <p>Possui Gerente?</p>
      <div className="radio-group">
        <TegraRadio
          style={{ marginTop: '20px', marginBottom: '20px' }}
          defaultValue={1}
          name="possui_gerente"
          label="Sim"
          checked={defaultValues.possui_gerente === true}
          onChange={handleRadioChange}
        />

        {defaultValues.possui_gerente && gerentes.length > 0 && (
          <CustomSelect
            onChange={handleSelectChange}
            label="Selecione o gerente"
            placeholder=""
            defaultValue={getSelectDefaultValue(defaultValues.gerente.value)}
            noOptionsMessage={() => <span>Nenhum gerente selecionado</span>}
            name="gerente"
            options={gerentes.map(gerente => ({
              value: gerente.id_corretorSigavi,
              label: `${gerente.tx_apelido} (${gerente.tx_nome})`,
            }))}
          />
        )}

        <TegraRadio
          style={{ marginTop: '20px', marginBottom: '20px' }}
          defaultValue={0}
          name="possui_gerente"
          label="Não"
          checked={defaultValues.possui_gerente === false}
          onChange={handleRadioChange}
        />
      </div>
      <TegraDivider axis="x" color="#e6e6e6" />

      <p>Informe o CPF dos seus corretores</p>
      <ImobiliariaCorretores
        onChange={setCorretores}
        setLoading={setLoading}
        id_imobiliaria={formData?.id_parceiro}
        setErroArquivo={setErroArquivo}
      />
      <WizardNavigationContainer>
        <WizardNavigationGoBack>
          <FiChevronLeft size={10} />
          <span onClick={handlePrevious}>Voltar</span>
        </WizardNavigationGoBack>
        <TegraButton isAction onClick={handleStepSubmit} isDisabled={loading} style={{margin:'0px 0px'}}>
          Próximo
          {loading && <img src={ajaxLoading} alt="Carregando" />}
        </TegraButton>
      </WizardNavigationContainer>
    </Wrapper>
  );
};

export default StepTwo;
