import React from 'react';

import Modal from 'react-modal';
import { Container, ShareButtons, ShareButton, CloseButton } from './styles';
import { ReactComponent as WhatsAppIcon } from '../../assets/tegra-icon-whatsapp.svg';
import { ReactComponent as TelegramIcon } from '../../assets/tegra-icon-telegram.svg';
import { ReactComponent as FacebookIcon } from '../../assets/tegra-icon-facebook.svg';
import { ReactComponent as CloseIcon } from '../../assets/tegra-icon-close.svg';
import zIndex from '../../utils/zIndex';

interface ModalProps extends ReactModal.Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleClose: Function;
  isOpen: boolean;
  urlFacebook?: string;
  urlTelegram?: string;
  urlWhats?: string;
}

const customStyles = {
  content: {
    width: 740,
    padding: 40,
    paddingTop: 80,
    top: '50%',
    left: '50%',
    right: 'auto',
    color: '#272723',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: '#272723',
    zIndex: zIndex.tegraShareModal,
  },
};

Modal.setAppElement('#root');

const TegraModalCompartilhar: React.FC<ModalProps> = ({
  isOpen,
  handleClose,
  urlFacebook = '',
  urlTelegram = '',
  urlWhats = '',
}) => {
  return (
    <Container>
      <Modal isOpen={isOpen} style={customStyles} contentLabel="Example Modal">
        <CloseButton onClick={() => handleClose()}>
          <CloseIcon />
        </CloseButton>

        <h1>Compartilhar</h1>

        <ShareButtons>
          <ShareButton
            onClick={() => {
              urlWhats && window.open(urlWhats, '_blank');
            }}
            bgColor="#25d366"
            txtColor="#60605d"
            type="button"
          >
            <WhatsAppIcon />
            Whatsapp
          </ShareButton>

          <ShareButton
            onClick={() => {
              urlTelegram && window.open(urlTelegram, '_blank');
            }}
            bgColor="#0088cc"
            type="button"
          >
            <TelegramIcon />
            Telegram
          </ShareButton>

          <ShareButton
            onClick={() => {
              urlFacebook && window.open(urlFacebook, '_blank');
            }}
            bgColor="#3b5998"
            type="button"
          >
            <FacebookIcon />
            Facebook
          </ShareButton>
        </ShareButtons>
      </Modal>
    </Container>
  );
};

export default TegraModalCompartilhar;
