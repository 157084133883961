import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isAction: boolean;
  isExtra?: boolean;
  icon?: any;
  isDisabled?: boolean | undefined;
}

const TegraButtonExtra: React.FC<ButtonProps> = ({
  children,
  icon,
  isAction,
  isDisabled = false,
  isExtra = false,
  ...rest
}) => {
  return (
    <Container
      isAction={isAction}
      disabled={isDisabled}
      isExtra={isExtra}
      type="button"
      {...rest}
    >
      {children}
      {icon && <img src={icon} alt="Ícone do botão" />}
    </Container>
  );
};

export default TegraButtonExtra;
