import React, { useState, useEffect } from 'react';
import { ReactComponent as DownloadIcon } from '../../../assets/tegra-icon-download.svg';
import { ReactComponent as ViewIcon } from '../../../assets/tegra-icon-view.svg';
import { ReactComponent as LoginIcon } from '../../../assets/tegra-icon-login.svg';

import {
  Container,
  AtividadesList,
  AtividadeIcon,
  AtividadeInfo,
  AtividadeDateTime,
  AtividadeTitle,
} from './styles';
import getFormattedDate from '../../../utils/date/getFormattedDate';
import getFormattedTime from '../../../utils/date/getFormattedTime';

export interface IAtividade {
  id_atividade: number;
  id_tipoAtividade: number;
  txt_descricao: string;
  txt_responsavel: string;
  dt_cadastro: string;
  is_deleted?: boolean;
}

interface ITipoIcon {
  [key: string]: any;
}

const tiposIcons: ITipoIcon = {
  view: <ViewIcon />,
  download: <DownloadIcon />,
  login: <LoginIcon />,
};

interface ITipoIconById {
  [key: string]: string;
}

const IconTipo: ITipoIconById = {
  '0': 'view',
  '1': 'login',
  '2': 'download',
};

interface AtividadesProps {
  // id: string;
  atividades?: IAtividade[];
}

const Atividades: React.FC<AtividadesProps> = ({ atividades = [] }) => {
  return (
    <Container>
      <AtividadesList>
        {atividades.map(atividade => (
          <li key={atividade.id_atividade}>
            <AtividadeIcon>
              {tiposIcons[IconTipo[`${atividade.id_tipoAtividade}`]]}
            </AtividadeIcon>
            <AtividadeInfo>
              <AtividadeDateTime>
                <time className="data">
                  {getFormattedDate(atividade.dt_cadastro)}
                </time>
                <time className="hora">
                  {getFormattedTime(atividade.dt_cadastro)}
                </time>
                <span className="atividadeUsuario">
                  {atividade.txt_responsavel}
                </span>
              </AtividadeDateTime>
              <AtividadeTitle>{atividade.txt_descricao}</AtividadeTitle>
            </AtividadeInfo>
          </li>
        ))}
      </AtividadesList>
    </Container>
  );
};


export default Atividades;
