import React, { useState, useEffect } from 'react';
import { ReactComponent as PermutaIcon } from '../../../assets/espelhos_tabela/tegra-icon-permuta.svg';
import { ReactComponent as DinheiroIcon } from '../../../assets/espelhos_tabela/tegra-icon-dinheiro.svg';
import { ReactComponent as IdIcon } from '../../../assets/espelhos_tabela/tegra-icon-id.svg';
import { ReactComponent as CalcIcon } from '../../../assets/espelhos_tabela/tegra-icon-calc.svg';
import { Container, Table, TableCell, TableCellIcons } from './styles';
import TegraScrollBar from '../../../components/TegraScrollBar';
import api from '../../../services/api';
import { useLoader } from '../../../hooks/loader';

interface ProdutoItemIconTypes {
  [key: string]: any;
}

const produtoItemIcon: ProdutoItemIconTypes = {
  dinheiro: <DinheiroIcon />,
  permuta: <PermutaIcon />,
  id: <IdIcon />,
  calc: <CalcIcon />,
};

interface IAndar {
  numero_andar: number;
  unidades: {
    nome_unidade: string;
    status: string;
    permuta: boolean;
    utiliza_id_tegra: boolean;
    aprovado_comercial: boolean;
    aprovado_credito: boolean;
    metros_area_total: number;
  }[];
}

interface IEspelho {
  codigo_bloco_sap: string;
  nome_bloco: string;
  andares: IAndar[];
}

interface TegraEspelhoTabelaProps {
  idEmpreendimento: number;
  idBloco: string;
  codigo: string;
}

const TegraEspelhoTabela: React.FC<TegraEspelhoTabelaProps> = ({
  idEmpreendimento,
  idBloco,
  codigo,
}) => {
  const [espelho, setEspelho] = useState<IEspelho>({
    codigo_bloco_sap: '',
    nome_bloco: '',
    andares: [],
  } as IEspelho);

  const { setLoaderActive } = useLoader();

  useEffect(() => {
    async function loadData(): Promise<void> {
      try {
        // setLoaderActive(true);
        const response = await api.get<IEspelho[]>(
          `EspelhosTabelas/espelho/${idEmpreendimento}/${codigo}/${idBloco}`,
        );

        const [espelhoData] = response.data;

        setEspelho(espelhoData);
      } finally {
        // setLoaderActive(false);
      }
    }
    loadData();
  }, [idEmpreendimento, idBloco, codigo, setLoaderActive]);

  return (
    <Container>
      <TegraScrollBar>
        <Table>
          <thead>
            <th>Andar</th>
          </thead>
          <tbody>
            {espelho.andares.map(andar => (
              <tr>
                <th>{andar.numero_andar}</th>
                {andar.unidades.map(unidade => (
                  <td>
                    <TableCell status={unidade.status}>
                      <TableCellIcons>
                        {(unidade.permuta || unidade.status === 'permuta') && produtoItemIcon.permuta}
                        {unidade.utiliza_id_tegra && produtoItemIcon.id}
                        {unidade.aprovado_credito && produtoItemIcon.dinheiro}
                        {unidade.aprovado_comercial && produtoItemIcon.calc}
                      </TableCellIcons>
                      <div className="info">
                        <span>{unidade.nome_unidade}</span>
                        <span>{unidade.metros_area_total}m2</span>
                      </div>
                    </TableCell>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </TegraScrollBar>
    </Container>
  );
};

export default TegraEspelhoTabela;
