import React from 'react';

import Modal from 'react-modal';
import { Container, CloseButton } from './styles';

import { ReactComponent as CloseIcon } from '../../assets/tegra-icon-close.svg';
import zIndex from '../../utils/zIndex';

const customStyles = {
  content: {
    width: 740,
    padding: 40,
    paddingTop: 80,
    top: '50%',
    left: '50%',
    right: 'auto',
    color: '#272723',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: '#272723',
    zIndex: zIndex.tegraShareModal,
  },
};

Modal.setAppElement('#root');

interface ModalProps extends ReactModal.Props {
  handleClose(type?: string): void;
  isOpen: boolean;
  link?: string;
  title?: string;
}

const TegraVideoViewerModal: React.FC<ModalProps> = ({
  isOpen,
  handleClose,
  link = '',
  title = '',
}) => {
  return (
    <Container>
      <Modal isOpen={isOpen} style={customStyles} contentLabel="Example Modal">
        <CloseButton onClick={() => handleClose('video')}>
          <CloseIcon />
        </CloseButton>
        <h1 className="video-title">{title}</h1>
        {link && (
          <iframe
            className="video-frame"
            title={title}
            width="100%"
            height="400"
            src={`https://www.youtube.com/embed/${link}`}
          />
        )}
      </Modal>
    </Container>
  );
};

export default TegraVideoViewerModal;
