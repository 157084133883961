import React from 'react';
import { Link } from 'react-router-dom';
import {
  NavDescription,
  TegraNavIconContainer,
  NavLogo,
  NavTitle,
  NavTitleContainer,
} from './styles';
import UndecoratedLink from '../UndecoratedLink';

interface TegraNavIconProps {
  tegraLogo: string;
  description: string;
  title: string;
  link: string;
}

const TegraNavIcon: React.FC<TegraNavIconProps> = ({
  tegraLogo,
  description,
  title,
  link,
}) => {
  return (
    <TegraNavIconContainer>
      <UndecoratedLink to={link}>
        <NavTitleContainer>
          <NavLogo src={tegraLogo} />
          <NavTitle>{title}</NavTitle>
        </NavTitleContainer>
      </UndecoratedLink>
      {description !== '' && <NavDescription>{description}</NavDescription>}
    </TegraNavIconContainer>
  );
};

export default TegraNavIcon;
