import styled from 'styled-components';
import externalIcon from '../../../assets/open-external-icon.svg';
import externalIconDark from '../../../assets/open-external-dark-icon.svg';

interface NavHypnoboxContainerProps {
  featured?: boolean;
}
export const NavHypnoboxContainer = styled.div<NavHypnoboxContainerProps>`
  padding: ${props => (props.featured ? '0.75rem' : '1.25rem 0')};
  margin-right: ${props => (props.featured ? '1rem' : '0')};
  position: relative;
  padding-right: 1rem;
  text-align: right;
  color: ${props => (props.featured ? 'black' : 'white')};
  &::after {
    content: '';
    top: 40%;
    position: absolute;
    width: 10px;
    height: 10px;
    right: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: ${props =>
      props.featured ? `url(${externalIconDark})` : `url(${externalIcon})`};
  }
  a {
    font-size: 12px;
    font-weight: bold;
    font-family: Montserrat;
    color: #60605d!important;
    text-align: right;
  }
  @media (min-width: 992px) {
    display: ${props => (props.featured ? 'flex' : 'none')};
    height: 100%;
    align-items: center;
  }
`;
