import styled from 'styled-components';

export const CloseButton = styled.div`
  position: absolute;
  right: 40px;
  top: 35px;
  cursor: pointer;

  @media (max-width: 768px) {
    right: 20px;
  }
`;

export const ModalTitle = styled.h2`
  margin-top: -50px;
  margin-bottom: 30px;
  font-weight: bold;
`;

export const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #9c9c9c;
  font-size: 18px;
  line-height: 22px;
`;
