import styled from 'styled-components';

export const Container = styled.div`
  & > input {
    display: none;
  }
`;

export const SliderContainer = styled.div`
  @media (min-width: 600px) {
    display: none;
  }

  .input-range__slider {
    appearance: none;
    background: #ebb92d;
    border-color: #ebb92d;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    outline: none;
    position: absolute;
    top: 50%;

    .input-range--disabled & {
      box-shadow: none;
      transform: none;
    }
  }

  .input-range__label-container {
    left: -50%;
    position: relative;

    .input-range__label--max & {
      left: 50%;
    }
  }

  .input-range__track {
    background: #e6e6e6;
    /* border: solid 1px #adadab; */
    border-radius: 0;
    cursor: pointer;
    display: block;
    height: 5px;
    position: relative;

    .input-range--disabled & {
      background: #e6e6e6;
    }
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.5 * 5px;
    position: absolute;
    right: 0;
    top: 50%;
    background: #e6e6e6;
    border: solid 1px #adadab;
  }

  .input-range__track--active {
    background: #fff;
    border: solid 1px #fff;
  }

  .input-range {
    height: 5px;
    position: relative;
    width: 100%;
  }

  /* LABELS */
  .input-range__label {
    display: none;
  }

  .input-range__label--min,
  .input-range__label--max {
    display: none;
  }

  .input-range__label--min {
    left: 0;
  }

  .input-range__label--max {
    right: 0;
  }

  .input-range__label--value {
    display: none;
  }
`;

export const RangeContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;

  .divider {
    flex-grow: 1;
    flex: 4%;
    margin-top: 30px;
  }

  label {
    display: flex;
    flex-direction: column;
    flex: 46%;
    width: 46%;

    input {
      padding: 10px;
      margin-top: 11px;
      height: 40px;
      border: solid 1px #adadab;
      background-color: #ffffff;

      &::placeholder {
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: left;
        color: #adadab;
      }
    }
  }
`;
