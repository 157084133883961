import {
  PublicClientApplication,
  AuthenticationResult,
  AccountInfo,
  EndSessionRequest,
  RedirectRequest,
  PopupRequest,
} from "@azure/msal-browser";

import { MSAL_CONFIG } from "./azure-authentication-config";

export class AzureAuthenticationContext {
  private myMSALObj: PublicClientApplication = new PublicClientApplication(
    MSAL_CONFIG
  );
  private account?: AccountInfo;
  private loginRedirectRequest: RedirectRequest = { } as RedirectRequest;
  private loginRequest: PopupRequest = {} as PopupRequest;

  public isAuthenticationConfigured = false;

  constructor() {
    // @ts-ignore
    this.account = null;
    this.setRequestObjects();
    if (MSAL_CONFIG?.auth?.clientId) {
      this.isAuthenticationConfigured = true;
    }
  }

  private setRequestObjects(): void {
    // TODO: Obter os scopes permitidos via configuração ou variaveis ambiente
    const azureUserAccessScope: string = process.env.REACT_APP_AZURE_AD_USER_SCOPE || '';

    this.loginRequest = {
      scopes: [azureUserAccessScope],
      prompt: "select_account",
    };
  
    this.loginRedirectRequest = {
      ...this.loginRequest,
      redirectStartPage: window.location.href,
    };
    
  }

  login(signInType: string, setUser: any, setError?: any) {
    if (signInType === "loginPopup") {
      const respostaLogin = this.myMSALObj
        .acquireTokenPopup(this.loginRequest)
        .then(async (resp: AuthenticationResult) => {          
          return await this.handleResponse(resp, setUser);         
        })
        .catch((err) => {
          return  this.handleError(err, setError);
        });
        return respostaLogin;
    } else if (signInType === "loginRedirect") {
      this.myMSALObj.acquireTokenRedirect(this.loginRedirectRequest);      
    }
  }

  logout(account: AccountInfo): void {
    const logOutRequest: EndSessionRequest = {
      account,
    };
    //this.myMSALObj.logout(logOutRequest);
    this.myMSALObj.logoutRedirect(logOutRequest);
  }
  async handleResponse(response: AuthenticationResult, incomingFunction: any) {
    if(response !==null && response.account !==null) {
      this.account = response.account;
    } else {
      this.account = this.getAccount();
    }

    if (this.account) {
      return await incomingFunction(this.account, response.accessToken);
    }
  }

  handleError(error: any, incomingFunction: any) {
    if (incomingFunction && incomingFunction !== undefined) {
      incomingFunction(error);
      return error;
    }
  }


  private getAccount(): AccountInfo | undefined {
    console.log(`loadAuthModule`);
    const currentAccounts = this.myMSALObj.getAllAccounts();
    if (currentAccounts === null) {
      // @ts-ignore
      console.log("No accounts detected");
      return undefined;
    }

    if (currentAccounts.length > 1) {
      // TBD: Add choose account code here
      // @ts-ignore
      console.log(
        "Multiple accounts detected, need to add choose account code."
      );
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
  }
}

export default AzureAuthenticationContext;
