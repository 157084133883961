import React, {
  RefObject,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { FormHandles, Form, SubmitHandler } from '@unform/core';
import { Container, FirstRow, Actions } from './styles';
import TegraOptions from '../../../components/TegraOptions';
import { ReactComponent as EmailIcon } from '../../../assets/espaco_gerente_options/tegra-icon-email.svg';
import { ReactComponent as LocationIcon } from '../../../assets/espaco_gerente_options/tegra-icon-location.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/espaco_gerente_options/tegra-icon-people.svg';
import { ReactComponent as TelephoneIcon } from '../../../assets/espaco_gerente_options/tegra-icon-telephone.svg';
import TegraInput from '../../../components/TegraInput';
import searchIcon from '../../../assets/tegra-icon-search.svg';
import TegraFileInputEspacoGerente from '../../../components/TegraFileInputEspacoGerente';
import TegraTextArea from '../../../components/TegraTextArea';
import TegraButton from '../../../components/TegraButton';
import TegraInputWithMask from '../../../components/TegraInputWithMask';
import getFormattedDate from '../../../utils/date/getFormattedDate';
import { ITarefa } from '../Tarefa';
import TegraSelect from '../../../components/TegraSelect';
import api from '../../../services/api';
import { ICorretor } from '../MeusCorretores/MeusCorretoresLista';
import { INovaTarefaFormData } from '../TarefasAtividadesMain';
import { isTemplateLiteral } from 'typescript';

const IconTipo = ['', 'email', 'visita', 'reuniao', 'ligacao'];

interface TegraNovaTarefaProps {
  onSubmit: SubmitHandler<INovaTarefaFormData>;
  formDefaultValues?: ITarefa;
  className: string;
  setShowEditTarefa: Function;
}

export interface IEmpreendimento {
  id: number;
  nome: string;
  bairro: string;
  logradouro: string;
  cidade: string;
}

const TegraNovaTarefa: React.FC<TegraNovaTarefaProps> = ({
  onSubmit,
  formDefaultValues,
  className,
  setShowEditTarefa,
}) => {
  
  const localVisitaOptions = [
    {
      value: 'empreendimento',
      label: 'Empreendimento',
    },
    {
      value: 'outro',
      label: 'Outro(a)',
    },
    {
      value: 'imobiliaria',
      label: 'Imobiliária',
    }
  ];

  const novaTarefaForm = useRef<FormHandles>(null);

  const [preview, setPreview] = useState('');
  const [tipoPagina, setTipoPagina] = useState('');
  const [enderecoSelecionado, setEnderecoSelecionado] = useState('');
  const [formInitialValues, setFormInitialValues] = useState({});
  const [localVisita, setLocalVisita] = useState("empreendimento");
  const [enderecoEmpreendimentoSelecionado, setEnderecoEmpreendimentoSelecionado] = useState("");
  const [responsaveis, setResponsaveis] = useState<ICorretor[]>([]);
  const [empreendimento, setEmpreendimentos] = useState<IEmpreendimento[]>([]);
  const [tipoOptions, setTipoOptions] = useState([
    {
      icon: <EmailIcon />,
      name: 'email',
      label: 'E-mail',
      selected: false,
    },
    {
      icon: <TelephoneIcon />,
      name: 'ligacao',
      label: 'Ligação',
      selected: false,
    },
    {
      icon: <PeopleIcon />,
      name: 'reuniao',
      label: 'Reunião',
      selected: false,
    },
    {
      icon: <LocationIcon />,
      name: 'visita',
      label: 'Visita',
      selected: false,
    },
  ]);


  useEffect((): void => {
    const values = { tipo: 'email' };
    if (formDefaultValues) {
      novaTarefaForm?.current?.reset();
      const {
        dt_prazo,
        txt_descricao,
        id_tipoTarefa,
        txt_responsavel,
        txt_enderecoVisita,
        txt_empreendimentoRelacionado,
        txt_localVisita,
        id_responsavel,
        id_tipoCorretor,
        arquivos,
        tipo_pagina,
      } = formDefaultValues;
      setTipoPagina(tipo_pagina);

      Object.assign(
        values,
        dt_prazo && {
          prazo: getFormattedDate(dt_prazo),
        },
        txt_descricao && {
          conteudo: txt_descricao,
        },
        id_tipoTarefa && {
          tipo: IconTipo[id_tipoTarefa],
        },
        arquivos && {
          arquivos,
        },
        txt_responsavel && {
          responsavel: {
            label: txt_responsavel,
            value: id_responsavel,
            tipo: id_tipoCorretor,
          },
        },
        txt_localVisita && {
          localVisita: txt_localVisita,
        },
        txt_empreendimentoRelacionado && {
          empreendimentoRelacionado : {
            label: txt_empreendimentoRelacionado,
            value: 30,
            tipo: txt_enderecoVisita
          }
        },
      );

      if(txt_enderecoVisita)
        setEnderecoSelecionado(txt_enderecoVisita);
      if (arquivos && arquivos.length) setPreview(arquivos[0]);
      else setPreview('');
    }
    setTipoOptions(
      tipoOptions.map(t => ({ ...t, selected: t.name === values.tipo })),
    );
    setFormInitialValues(values);
    novaTarefaForm.current?.setData(values);
  }, [formDefaultValues]);

  const handleFormSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      try {
        if (!novaTarefaForm.current) {
          throw new Error('no form');
        }
        novaTarefaForm.current.submitForm();
      } catch (err) {
        console.error(err);
      }
    },
    [novaTarefaForm],
  );

  const handleFormReset = useCallback(
    (e: any) => {
      e.preventDefault();
      try {
        if (!novaTarefaForm.current) {
          throw new Error('no form');
        }
        novaTarefaForm.current.reset();
        setShowEditTarefa(false);
      } catch (err) {
        console.error(err);
      }
    },
    [novaTarefaForm],
  );

  const onChangeEmpreendimento = (item: any) => {
    setEnderecoEmpreendimentoSelecionado(item.tipo);
    if (localVisita === "empreendimento"){
      if (item.label == "Nenhum")
        setEnderecoSelecionado("");
      else
        setEnderecoSelecionado(item.tipo);
    } else {
      setEnderecoSelecionado("");
    }
    
  };

  const onChangeLocalVisita = (item: any) => {
    setLocalVisita(item.value);
    if (item.value !== "empreendimento")
      setEnderecoSelecionado("");
    else
      setEnderecoSelecionado(enderecoEmpreendimentoSelecionado);

  };

  useEffect(() => {
    api.get(`Gerente/todosCorretores`).then(res => {
      const resp = res.data;
      resp.unshift({
        id_parceiro: undefined,
        tx_nome: 'Nenhum',
        id_tipoCorretor: undefined,
      });
      setResponsaveis(resp);
    });
  }, []);

  useEffect(() => {
    api.get(`Empreendimento/list`).then(res => {
      const resp = res.data;
      resp.unshift({
        id: undefined,
        nome: 'Nenhum',
        logradouro: undefined,
      });
      setEmpreendimentos(resp);
    });
  }, []);

  return (
    <Container id="tarefa-container" className={className}>
      <Form
        ref={novaTarefaForm}
        initialData={{ ...formInitialValues }}
        onSubmit={onSubmit}
      >
        <FirstRow>
          <TegraOptions
            noPaddingAll
            name="tipo"
            shouldTriggerParent={false}
            formRef={novaTarefaForm}
            options={tipoOptions}
            setOptions={setTipoOptions}
          />
          <TegraInputWithMask
            name="prazo"
            label="Prazo"
            placeholder="00/00/0000"
            mask="99/99/9999"
          />
          {tipoPagina == 'minha' && (
            <TegraSelect
              placeholder="Nenhum"
              className="tarefaResponsavel"
              label="Referente a"
              noOptionsMessage={() => 'Nenhum selecionado'}
              name="responsavel"
              options={responsaveis.map(c => ({
                value: c.id_parceiro,
                label: c.tx_razaoSocial || c.tx_nome,
                tipo: c.id_tipoCorretor,
              }))}
            />
          )}
        </FirstRow>
        <TegraTextArea name="conteudo" />
         { tipoOptions[3].selected &&  
          <div>
            <br/>
            <TegraSelect
              placeholder="Nenhum"
              className="empreendimentoRelacionado"
              label="Empreendimento relacionado"
              noOptionsMessage={() => 'Nenhum selecionado'}
              name="empreendimentoRelacionado"
              onChange={onChangeEmpreendimento}
              options={empreendimento.map(c => ({
                value: c.id,
                label: c.nome,
                tipo: c.logradouro 
              }))}
            />
            <br/>
            <TegraSelect 
              className="localVisita"
              label="Local da visita"
              name="localVisita" 
              defaultValue={localVisitaOptions[0]}
              onChange={onChangeLocalVisita}
              options={localVisitaOptions}
            />
            <br/>
            <TegraInput 
              className="enderecoVisita"
              label="Endereço da visita"
              value={enderecoSelecionado}
              onChange={({ target }) => setEnderecoSelecionado(target.value)}
              name="enderecoVisita" />
          </div>
         }
        <Actions>
          <TegraFileInputEspacoGerente
            name="arquivos"
            formRef={novaTarefaForm}
            preview={preview}
            setPreview={setPreview}
          />
          <div className="buttons">
            <TegraButton
              className="cancelar"
              type="submit"
              isAction
              onClick={handleFormReset}
            >
              Cancelar
            </TegraButton>
            <TegraButton type="submit" isAction onClick={handleFormSubmit}>
              Salvar
            </TegraButton>
          </div>
        </Actions>
      </Form>
    </Container>
  );
};

export default TegraNovaTarefa;
