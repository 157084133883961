import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding-bottom: 120px;
`;

export const Content = styled.div`
  margin-top: -58px;
  padding: 0 51px;
  width: 100%;

  @media (max-width: 600px) {
    margin-top: -20px;
    padding: 0 36px;
  }
`;

export const Aviso = styled.div`
  min-height: 58px;
  background-color: #ebb92d;
  padding: 20px 12px 20px 21px;
  max-width: 410px;

  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #60605d;
  }
  strong {
    font-weight: bold;
  }
`;

export const FilesContainer = styled.div`
  & > div:not(:first-child) {
    margin-top: 80px;
  }
`;
