import React from 'react';

export interface ISVGImage {
  color: string;
}

const FirstImage: React.FC<ISVGImage> = ({ color }) => {
  const style = {
    fill: 'none',
    stroke: color,
    strokeMiterlimit: 10,
    strokeWidth: '5.13385404029066px',
  };

  const styleLogoTegra = {
    fill: color,
    stroke: 'none',
    strokeMiterlimit: 0,
    strokeWidth: 0,
  };

  return (
    <svg id="Layer_0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
      <polyline
        points="41.8,132.1 41.8,20.8 53.4,20.8 53.4,15.6 88.5,15.6 88.5,20.8 100.5,20.8 100.5,132.1"
        style={style}
      />
      <polyline
        points="25.6,132.1 64.3,132.1 64.3,114.4 78.2,114.4 78.2,132.1 116.6,132.1 	"
        style={style}
      />
      <rect x="50.1" y="48.7" width="9.3" height="9.3" style={style} />
      <rect x="66.3" y="48.7" width="9.3" height="9.3" style={style} />
      <rect x="82.5" y="48.7" width="9.3" height="9.3" style={style} />
      <rect x="50.1" y="65" width="9.3" height="9.3" style={style} />
      <rect x="66.3" y="65" width="9.3" height="9.3" style={style} />
      <rect x="82.5" y="65" width="9.3" height="9.3" style={style} />
      <g>
        <rect x="50.1" y="81.3" width="9.3" height="9.3" style={style} />
        <rect x="66.3" y="81.3" width="9.3" height="9.3" style={style} />
        <rect x="82.5" y="81.3" width="9.3" height="9.3" style={style} />
      </g>
      <g>
        <rect x="50.1" y="97.3" width="9.3" height="9.3" style={style} />
        <rect x="66.3" y="97.3" width="9.3" height="9.3" style={style} />
        <rect x="82.5" y="97.3" width="9.3" height="9.3" style={style} />
      </g>
      <path
        d="M82.5,34.6l0.9-2.2l0.9,2.2H82.5z M77.2,33.2L77.2,33.2l-1.2,0v-2h1.2c0.5,0,1,0.4,1,1S77.7,33.2,77.2,33.2
		 M84.1,29.7h-1.4l-2.9,7.1l-1.3-2.3c0.8-0.4,1.4-1.3,1.4-2.2c0-1.4-1.1-2.5-2.5-2.5h0h-2.9v3.5h-3.2v1.5h1.4
		c-0.4,0.5-1.1,0.9-1.8,0.9c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c0.5,0,0.9,0.1,1.2,0.4l1.2-1.2c-0.7-0.5-1.5-0.8-2.4-0.8
		c-2.1,0-3.9,1.7-3.9,3.9c0,0.8,0.2,1.5,0.6,2.1h-4v-1.3h2.7v-1.6h-2.7v-1.3h3.3v-1.6H54.7v1.6h2.7v5.8h1.7v-5.8H62v5.8h4.5l1.3-1.3
		c0.7,0.9,1.8,1.5,3,1.5c1.6,0,3-1,3.6-2.5v2.3H76v-2.4h0.8l1.4,2.4h3.3l0.4-1H85l0.4,1h1.8L84.1,29.7z"
        style={styleLogoTegra}
      />
    </svg>
  );
};

export default FirstImage;
