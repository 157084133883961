import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding-bottom: 120px;
`;

export const Content = styled.div`
  margin-top: 44px;
  padding: 0 53px;

  @media (max-width: 600px) {
    padding: 0 36px;
  }
`;

export const MostrarTodosContainer = styled.div`
  margin-top: 43px;
  margin-bottom: 38px;
  display: flex;
`;

export const ImoveisContainer = styled.div`
  width: 100%;
  display: grid;
  /* grid-gap: 41px 30px;
  padding: 51px; */
  grid-row-gap: 40px;
  grid-column-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(624px, 1fr));
  justify-content: center;

  @media (min-width: 1280px) and (max-width: 1366px) {
    grid-template-columns: repeat(auto-fit, minmax(50%, 500px));
    justify-content: start;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 302px));
    justify-content: start;
  }
`;

export const PaginaPersonalizadaActions = styled.div`
  display: flex;
  /* flex: 1%; */

  button {
    margin-right: 19px;
  }

  @media (max-width: 600px) {
    width: 100%;

    button {
      flex: 50%;
      margin-right: 11px;
    }

    button:last-child {
      margin-right: 0px;
    }
  }
`;

export const PaginaPersonalizada = styled.div`
  height: 75px;
  border: solid 1px #e6e6e6;
  display: flex;
  padding: 20px;
  margin-bottom: 37px;
  align-items: center;
  width: 100%;

  p {
    margin-right: 36px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #60605d;
  }

  @media (max-width: 600px) {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    border: none;
    padding: 0;
    margin-bottom: 40.5px;

    p {
      margin-bottom: 16.5px;
    }
  }
`;

export const ExternalLink = styled.div`
  a {
    font-size: 12px;
    font-weight: 500;
    color: #ebb92d;
    text-decoration: none;
  }
`;
