import styled, { css } from 'styled-components';

interface ContainerProps {}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    display: block;
    width: 100%;
    margin-bottom: 11px;
    margin-top: 15.5px;
  }

  input {
    padding: 10px;
    height: 40px;
    border: solid 1px #adadab;
    background-color: #ffffff;
    width: 100%;

    &::placeholder {
      color: #adadb0;
      text-transform: capitalize;
    }
  }
`;

export const Icon = styled.img`
  position: absolute;
  bottom: 9px;
  right: 9px;
`;
