import React from 'react';
import ProductMaterials from '../..';

const Ferramentas: React.FC = () => {
  return (
    <ProductMaterials
      title="Treinamentos"
      subtitle="Ferramentas"
      description="Treinamentos de ferramentas utilizados pela Incorporadora."
      showColumnTitle={false}
      actions={['open','download']}
      apiUrl="MateriaisGerais"
      columns={[
        { label: 'Arquivo', field: 'nome' },
        { label: 'Tipo', field: 'extensao' },
      ]}
      anchorName="Ferramentas"
      onClickItem={() => localStorage.setItem('SCROLL', '#Ferramentas')}
    />
  );
};

export default Ferramentas;
