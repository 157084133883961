import React, { useState, useEffect } from 'react';

import { Container, Content, Aviso, FilesContainer } from './styles';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import TegraFileViewer from '../../components/TegraFileViewer';

interface IPDF {
  link: string;
}

const mockPdfs: IPDF[] = [
  {
    link:
      'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
  },
  {
    link:
      'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
  },
];

const TreinamentosDetalhes: React.FC = () => {
  const [files, setFiles] = useState<IPDF[]>(mockPdfs);

  // useEffect(() => {
  //   // chamar api para carregar lista de arquivos

  //   setFiles(mockPdfs);
  // }, []);

  return (
    <Container>
      <TegraOpaqueHero
        title="TEG Corazza"
        description=" "
        category="Treinamentos"
      />
      <Content>
        <Aviso>
          <p>
            <strong>Atenção!</strong> Este material não pode ser compartilhado
          </p>
        </Aviso>
        <FilesContainer>
          {files.map(file => (
            <TegraFileViewer key={file.link} link={file.link} />
          ))}
        </FilesContainer>
      </Content>
    </Container>
  );
};

export default TreinamentosDetalhes;
