import React, {
  useState,
  useCallback,
  RefObject,
  useRef,
  useEffect,
} from 'react';

import { FormHandles, useField } from '@unform/core';
import InputRange, { Range } from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { Container, RangeContainer, SliderContainer } from './styles';
import TegraDivider from '../TegraDivider';

interface ITegraRangeProps {
  formRef: RefObject<FormHandles>;
  name: string;
  alterLeftLabel: string;
  alterRightLabel: string;
  placeholder: string;
  clearRange: boolean;
}

interface IRangeValueProps {
  min: number;
  max: number;
}

const TegraRange: React.FC<ITegraRangeProps> = ({
  formRef,
  name,
  alterRightLabel,
  alterLeftLabel,
  placeholder,
  clearRange,
}) => {
  const inputRef = useRef(null);

  const [rangeValue, setRangeValue] = useState({ min: 0, max: 2000000000 });

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleOnChange = useCallback(
    value => {
      if (!formRef.current) {
        throw new Error('No form ref');
      }
      setRangeValue({ ...value });
      formRef.current.setFieldValue(name, JSON.stringify(value));
      formRef.current.submitForm();
    },
    [formRef, name],
  );

  const handleInputTypeChange = useCallback(
    event => {
      const { name: inputName, value } = event.target;

      const singleName = inputName.replace(`${name}-`, '');

      const newValue = { ...rangeValue, [singleName]: Number(value) };

      if (!formRef.current) {
        throw new Error('No form ref');
      }
      setRangeValue({ ...newValue });
      formRef.current.setFieldValue(name, JSON.stringify(newValue));
      formRef.current.submitForm();
    },
    [formRef, name, rangeValue],
  );

  useEffect(() => {
    setRangeValue({ min: 0, max: 2000000000 });
  }, [clearRange]);

  return (
    <Container>
      <input type="text" ref={inputRef} />
      <RangeContainer>
        <label>
          {`${alterLeftLabel || 'De'}`}
          <input
            type="number"
            placeholder={placeholder || ''}
            name={`${name}-min`}
            value={placeholder && rangeValue.min === 0 ? '' : rangeValue.min}
            onChange={handleInputTypeChange}
          />
        </label>
        <TegraDivider axis="x" height={1} color="#ADADAB" />
        <label>
          {`${alterRightLabel || 'Até'}`}
          <input
            type="number"
            placeholder={placeholder || ''}
            name={`${name}-max`}
            value={
              placeholder && rangeValue.max === 20000 ? '' : rangeValue.max
            }
            onChange={handleInputTypeChange}
          />
        </label>
      </RangeContainer>

      <SliderContainer>
        <InputRange
          draggableTrack
          maxValue={2000000000}
          minValue={0}
          value={rangeValue}
          onChange={handleOnChange}
          onChangeComplete={handleOnChange}
        />
      </SliderContainer>
    </Container>
  );
};

export default TegraRange;
