import React from 'react';

import { Link } from 'react-router-dom';
import { Container, Logo, Description, TegraBlog } from './styles';
import TegraDivider from '../TegraDivider';
import { getLogoByDomain, getTituloByDomain } from '../../utils/logo';

interface ITegraIntroHeaderProps {
  dividerColor: string;
  dividerAxis: 'x' | 'y';
}

const TegraIntroHeader: React.FC<ITegraIntroHeaderProps> = ({
  dividerAxis,
  dividerColor,
}) => (
  <Container>
    <TegraBlog>
      <Link to="/">
        <Logo src={getLogoByDomain(true)} />
      </Link>
      <p dangerouslySetInnerHTML={{ __html: getTituloByDomain(true) }} />
    </TegraBlog>
    <TegraDivider height={82} color={dividerColor} axis={dividerAxis} />
    <Description>
      Encontre todas as informações necessárias para vender um Tegra!
    </Description>
  </Container>
);

export default TegraIntroHeader;
