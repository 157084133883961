import styled, { css } from 'styled-components';

export const Container = styled.div`
  input {
    display: none;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

interface OptionBoxProps {
  noPaddingAll: boolean;
}

export const OptionBox = styled.div<OptionBoxProps>`
  display: flex;
  padding: 8px;
  height: 50px;
  background-color: #e6e6e6;

  ${props =>
    props.noPaddingAll &&
    css`
      padding: 0;
    `}

  &.tipos,
  &.status {
    margin-right: 11px;
  }

  &.status {
    @media (max-width: 1024px) {
      margin-right: none;
    }
  }

  @media (max-width: 600px) {
    background-color: transparent;
    width: 100%;
    min-height: 100px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
`;

interface OptionProps {
  selected?: boolean;
  hasIcon: boolean;
  hasLabel: boolean;
}

export const Option = styled.div<OptionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
  min-width: 50px;
  cursor: pointer;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.12px;
  text-align: right;
  color: #60605d;

  svg path {
    fill: #adadab;
  }

  @media (max-width: 600px) {
    background-color: #e6e6e6;
  }

  @media (min-width: 426) and (max-width: 600px) {
    span {
      display: none;
    }
  }

  ${props =>
    props.selected &&
    css`
      background-color: #ebb92d;
      text-align: center;
      color: #272723;

      svg path {
        fill: #60605d;
      }

      @media (max-width: 600px) {
        background-color: #ebb92d;
      }
    `}

  ${props =>
    props.hasIcon &&
    props.hasLabel &&
    css`
      svg {
        margin-left: 9px;

        @media (width: 768px) {
          margin-left: 0px;
        }

        @media (max-width: 320px) {
          margin-left: 0px;
        }
      }
    `}
`;
