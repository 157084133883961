import styled, { css } from 'styled-components';

export const Container = styled.div`
  input {
    display: none;
  }

  input.search {
    display: block;
  }

  .search-box {
    label {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

interface ICheckboxContentProps {
  isGrid: boolean;
}

export const Content = styled.div<ICheckboxContentProps>`
  & > div {
    margin-top: 18px;
  }
  ${props =>
    props.isGrid &&
    css`
      display: grid;
      grid-gap: 20px 38px;
      padding-right: 10px;
      grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));

      & > div {
        margin-top: 0;
      }
    `}

  ${props =>
    !props.isGrid &&
    css`
      overflow: auto;
    `}


  :-webkit-scrollbar-track {
    /* background: #fff; */
    border: solid 1px #adadab;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    width: 11px;
    height: 40px;
    background-color: #ebb92d;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #ebb92d;
    opacity: 0.7;
  }
`;

interface ICheckboxContainerProps {
  highlightChecked?: boolean;
  checked: boolean;
  underline?: boolean;
  isNotInSearch?: boolean;
}

export const CheckboxContainer = styled.div<ICheckboxContainerProps>`
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;

  /* &:not(:first-child) {
    margin-top: 18px;
  } */

  ${props =>
    props.isNotInSearch &&
    css`
      display: none;
    `}

  label {
    display: flex;
    flex-wrap: nowrap;
  }

  span {
    display: block;
  }

  span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
  }

  ${props =>
    props.checked &&
    props.highlightChecked &&
    css`
      span {
        color: #ebb92d;
      }
    `}
  ${props =>
    props.underline &&
    css`
      span {
        text-decoration: underline;
      }
    `}
`;

export const Icon = styled.img``;

interface StyledCheckboxProps {
  checked: boolean;
}

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  margin-right: 10px;
  padding: 3.4px 5.6px;
  width: 16px;
  height: 16px;
  transition: all 150ms;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: solid 1px #adadab;
  background-color: #ffffff;

  img {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;
