import React, { useCallback, useState } from 'react';

import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Option, Container, Title } from './styles';

import iconProdutos from '../../../assets/options/produtos.svg';
import iconEspelhos from '../../../assets/options/espelhos.svg';
import iconFaq from '../../../assets/options/faq.svg';
import iconVideos from '../../../assets/options/videos.svg';
import iconImagens from '../../../assets/options/imagens.svg';
import iconTour from '../../../assets/options/tour.svg';
import iconTreinamento from '../../../assets/options/treinamento.svg';
import iconApresentacoes from '../../../assets/options/apresentacoes.svg';
import iconIptu from '../../../assets/options/iptu_condominio.svg';
import iconEmailMarketing from '../../../assets/options/email_marketing.svg';
import iconMemorial from '../../../assets/options/memorial.svg';
import iconInformacoes from '../../../assets/options/informacoes.svg';
import iconSimuleFinanciamento from '../../../assets/options/simule_financiamento.svg';
//import iconTimeGigantes from '../../../assets/options/timegigantes.svg';
import iconTimeGigantes from '../../../assets/options/timegigantes.png';
import { useAuth } from '../../../hooks/auth';

interface OptionData {
  icon: any;
  text: string;
  link: string;
  key: string;
}

const secondaryOptions: Array<OptionData> = [
  {
    icon: iconProdutos,
    text: 'Produtos',
    key: 'produtos',
    link: 'produtos',
  },
  {
    icon: iconTreinamento,
    text: 'Books', //Atigo 'Apresentações',
    key: 'produtos6',
    link: 'materiais-de-produtos/books',//'materiais-de-produtos/apresentacoes',
  },
  {
    icon: iconEspelhos,
    text: 'Espelhos e Tabelas',
    key: 'produtos1',
    link: 'materiais-de-produtos/espelhos-e-tabelas',
  },
  {
    icon: iconImagens,
    text: 'Imagens',
    key: 'produtos3',
    link: 'materiais-de-produtos/imagens',
  },  
  {
    icon: iconMemorial,
    text: 'Plantas',
    key: 'produtos14',
    link: 'materiais-de-produtos/plantas',
  },  
  {
    icon: iconVideos,
    text: 'Vídeos',
    key: 'produtos2',
    link: 'materiais-de-produtos/videos',
  },
  {
    icon: iconTour,
    text: 'Tour Virtual',
    key: 'produtos4',
    link: 'materiais-de-produtos/tour-virtual',
  },
  {
    icon: iconEspelhos,
    text: 'Materiais Gerais',
    key: 'produtos13',
    link: 'materiais-de-produtos/email-marketing',
  },
  {
    icon: iconTreinamento,
    text: 'Treinamentos',
    key: 'produtos15',
    link: 'materiais-de-produtos/treinamento-de-produtos',
    /*link: 'materiais-de-produtos/treinamentos',*/
  },  
  {
    icon: iconInformacoes,
    text: 'Informações de IM',
    key: 'produtos10',
    link: 'materiais-de-produtos/informacoes-de-im',
  },
  /*
  {
    icon: iconApresentacoes,
    text: 'Apresentações', //Antigo 'Treinamentos',
    key: 'produtos5',
    link: 'materiais-de-produtos/apresentacoes',//'materiais-de-produtos/treinamentos',
  },
  */
  /*
  {
    icon: iconEmailMarketing,
    text: 'E-mail marketing',
    key: 'produtos8',
    link: 'materiais-de-produtos/email-marketing',
  },
  */
  {
    icon: iconMemorial,
    text: 'Memorial Descritivo',
    key: 'produtos9',
    link: 'materiais-de-produtos/memorial-descritivo',
  },
  /*
  {
    icon: iconIptu,
    text: 'IPTU e Condomínio',
    key: 'produtos12',
    link: 'materiais-de-produtos/iptu-e-condominio',
  },
  */
  {
    icon: iconFaq,
    text: 'FAQ',
    key: 'produtos7',
    link: 'perguntas-frequentes',
  },
  {
    icon: iconFaq,
    text: 'Compliance',
    key: 'produtos15',
    link: 'faq-compliance',
  },
  {
    icon: iconTimeGigantes,
    text: 'Time de Gigantes',
    key: 'produtos16',
    link: 'time-de-gigantes',
  },
];

const OptionsGrid: React.FC = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { user } = useAuth();


  const goToPage = useCallback(link => history.push(`${path}/${link}`), [
    history,
    path,
  ]);

  return (
      <Container>
        {secondaryOptions &&
          secondaryOptions.map(option => {
          if (option.text == 'FAQ' || option.text == 'Compliance') {
              return (
                <Option className="principal" onClick={() => goToPage(option.link)} key={option.key}>
                  <img src={option.icon} alt="Ícone"/>
                  <Title className="optionTitle">{option.text}</Title>
                </Option>
              )  

          }else if (option.text =='Time de Gigantes' && user.idCargoSigavi > 0) {
                return (
                  <Option className="principal" onClick={() => window.open('https://timedegigantes.incentiveplace.com.br/login', '_blank', 'noreferrer')}>
                    <img src={option.icon} alt="Ícone" style={option.text =='Time de Gigantes' ?{height: '60px'}:{}} />
                    <Title className="optionTitle">{option.text}</Title>
                  </Option>
                )                
          } else if (option.text !=='Time de Gigantes') {
              return (
                <Option onClick={() => goToPage(option.link)} key={option.key}>
                  <img src={option.icon} alt="Ícone" />
                  <Title className="optionTitle">{option.text}</Title>
                </Option>
              )
            }
          }
        )}
      </Container>
  );
};

export default OptionsGrid;
