import React, { useEffect, useCallback, useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import MeusCorretoresLista from './MeusCorretoresLista';
import ScrollToTopOnMount from '../../../components/ScrollToTopOnMount';
import TarefasAtividadesMain from '../TarefasAtividadesMain';

const MeusCorretores: React.FC = () => {
  const { path, url } = useRouteMatch();  

  return (
    <Switch>
      <Route exact path={`${path}/meus-corretores`}>
        <>
          <MeusCorretoresLista />
        </>
      </Route>
      <Route path={`${path}/meus-corretores/:corretorTipo/:id`}>
        <>
          <ScrollToTopOnMount />
          <TarefasAtividadesMain tipo_pagina="corretor" />
        </>
      </Route>
    </Switch>
  );
};

export default MeusCorretores;
