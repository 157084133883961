import styled from 'styled-components';
import colors from '../../../styles/colors';

interface IContactDataProps {
  product?: boolean;
}

export const TegraContactGroupsContainer = styled.div`
  margin-top: 2.25rem;
  &:not(:nth-child(2)),
  &:not(:nth-child(1)) {
    margin-top: 3.5rem;
  }
`;

export const ContactItem = styled.div`
  padding-bottom: 1.4rem;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

export const ContactGroupsTitle = styled.h1`
  font-size: 1.8rem;
  color: #272723;
  margin-bottom: 3.5rem;
  @media (max-width: 374px) {
    font-size: 1.75rem;
  }
`;

export const ContactPicture = styled.img`
  border-radius: 50%;
  border: 1px solid #60605d;
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin-bottom: 1.375rem;
  overflow: hidden;
  display: inline-block;
  @media (min-width: 992px) {
    width: 110px;
    height: 110px;
    margin-bottom: 0;
  }
`;

export const ContactDataContainer = styled.div`
  @media (min-width: 992px) {
    flex-basis: 33%;
    max-width: 33%;
    margin-left: 0.5rem;
  }
`;

export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: end;
  }
`;

export const ContactDataName = styled.div`
  margin-bottom: 1.5rem;
  color: #41413e;
  font-weight: 500;
  font-size: 1.33rem;
  @media (min-width: 992px) {
    margin-left: 1.875rem;
    margin-bottom: 0;
    
  }
`;

export const ContactDataTitle = styled.div<IContactDataProps>`
  font-size: 1rem;
  font-weight: 500;
  overflow-wrap: break-word;
  color: #737371;
  @media (max-width: 374px) {
    font-size: 1.05rem;
  }
  &:last-child {
    margin-bottom: ${props => (props.product ? '1.5rem' : '0')};
    @media (min-width: 992px) {
      margin-bottom: 0;
    }
  }
`;

export const ContactLinkTitle = styled.a<IContactDataProps>`
  font-size: 0.8rem;
  font-weight: 500;
  color: #737371;
  @media (max-width: 374px) {
    font-size: 0.5rem;
  }
  @media (min-width: 992px) {
    margin-left: 1.875rem;
    margin-bottom: 0;
  }
`;

export const ContactDataContent = styled.div<IContactDataProps>`
  margin-bottom: ${props => (props.product ? '0' : '1.5rem')};
  font-size: 0.7rem;
  color: #a3a3a1;
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const ContactDataContentTel = styled.div<IContactDataProps>`
  margin-bottom: ${props => (props.product ? '0' : '1.5rem')};
  font-size: 0.7rem;
  color: #a3a3a1;
  @media (min-width: 992px) {
    margin-bottom: 0;
    margin-left: 1.875rem;
  }
`;
