/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import {
  RankingTableContainer,
  RankingTableHeader,
  RankingTableHeaderContainer,
  RankingTableBody,
} from './styles';
import { IRanking, ISelfRanking } from '..';
import RankingRow from './RankingRow';

interface IRankingTableProps {
  rankingList: IRanking[];
  rankingSelfRow?: ISelfRanking;
}

const RankingTable: React.FC<IRankingTableProps> = ({
  rankingList,
  rankingSelfRow,
}) => {
  return (
    <div>
      {rankingSelfRow && (
        <RankingTableContainer>
          <RankingTableHeaderContainer>
            <tr>
              <th />
              <th />
              <RankingTableHeader selfRow>
                Minha posição do ranking :
              </RankingTableHeader>
            </tr>
          </RankingTableHeaderContainer>
          <RankingRow
            rankingItem={rankingSelfRow.profile}
            position={rankingSelfRow.position}
            selfRow
          />
        </RankingTableContainer>
      )}

      <RankingTableContainer>
        <RankingTableHeaderContainer>
          <tr>
            <th />
            <th />
            {Object.keys(rankingList[0]).map(key =>
              key !== 'image' ? (
                <RankingTableHeader>{key}</RankingTableHeader>
              ) : null,
            )}
            <th />
          </tr>
        </RankingTableHeaderContainer>
        <RankingTableBody>
          {rankingList.map((rankingItem, position) => {
            if (rankingSelfRow && rankingSelfRow.position - 1 === position)
              return (
                <RankingRow
                  rankingItem={rankingItem}
                  position={position + 1}
                  selfRow
                />
              );
            return (
              <RankingRow rankingItem={rankingItem} position={position + 1} />
            );
          })}
        </RankingTableBody>
      </RankingTableContainer>
    </div>
  );
};

export default RankingTable;
