/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useCallback, useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import api from '../../services/api';
import TegraButton from '../TegraButton';
import uploadIcon from '../../assets/tegra-upload-icon.svg';

interface ImobiliariaCorretoresProps {
  id_imobiliaria: number | undefined;
  setLoading: Function;
  onChange: Function;
  setErroArquivo: Function;
  autenticado?: boolean;
}

const ImobiliariaCorretores: React.FC<ImobiliariaCorretoresProps> = ({
  id_imobiliaria,
  setLoading,
  onChange,
  setErroArquivo,  
  autenticado,
}) => {
  const hiddenFileInput = React.useRef<any>(null);
  const [corretores, setCorretores] = useState<any[]>([
    {
      valid: undefined,
      tx_cpf: '',
    },
  ]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function loadCorretores() {
      setLoading(true);
      let url = 'Cadastro/corretores';
      if (autenticado === true) {
        url = 'Perfil/corretores';
      }
      const res = await api.get<any[]>(url, {
        params: {
          id_imobiliaria,
        },
      });
      setLoading(false);
      const { data } = res;
      setCorretores(
        data.map((d: any) => ({
          ...d,
          valid: true,
        })),
      );
    }
    if (id_imobiliaria) {
      loadCorretores();
    }
  }, [id_imobiliaria]);

  const validateCPF = (value: string): boolean | undefined => {
    const cpf = value.replace(/[^\d]+/g, '');
    if (cpf === '' || cpf.length !== 11) return undefined;
    // Elimina CPFs invalidos conhecidos
    if (
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    )
      return false;
    // Valida 1o digito
    let add = 0;
    for (let i = 0; i < 9; i += 1)
      add += parseInt(cpf.charAt(i), 10) * (10 - i);

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(add)) {
      return undefined;
    }

    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9), 10)) return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i += 1)
      add += parseInt(cpf.charAt(i), 10) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10), 10)) return false;
    return true;
  };

  const changeCPF = useCallback(
    (e, index) => {
      e.preventDefault();
      const obj = {
        tx_cpf: e.target.value,
        valid: validateCPF(e.target.value),
      };
      setCorretores(corretores.map((c, i) => (i === index ? obj : c)));
    },
    [corretores],
  );

  const remover = useCallback(
    (e, index) => {
      e.preventDefault();
      setCorretores(corretores.filter((c, i) => i !== index));
    },
    [corretores],
  );

  const addCorretor = (): void => {
    setCorretores(corretores.concat([{ valid: true, tx_cpf: '' }]));
  };
  const getSituacao = (obj: any): string => {
    if (!obj.id) return 'Aguardando aprovação';
    switch (obj.status) {
      case -1:
        return 'Aguardando aprovação';
      case 0:
        return 'Reprovado';
      case 1:
        return 'Aprovado';
      default:
        return '?';
    }
  };

  useEffect(() => {
    onChange(corretores);
  }, [corretores, onChange]);

  function processData(csv: any) {
    const allTextLines = csv.split(/\r\n|\n/);
    const lines = allTextLines.map((data: any) => data.split(';'));
    setCorretores(
      corretores.concat(
        lines
          .map((l: any) => l[0])
          .filter((l: any, index: any) => index !== 0 && !!l)
          .map((l: any) => ({
            valid: validateCPF(l),
            tx_cpf: l,
          })),
      ),
    );
  }

  function fileReadingFinished(event: any) {
    const csv = event.target.result;
    processData(csv);
  }

  function errorHandler(event: any) {
    if (event.target.error.name === 'NotReadableError') {
      console.log('Cannot read file!');
    }
  }

  function getAsText(fileToRead: any) {
    const reader = new FileReader();
    // Read file into memory as UTF-8
    reader.readAsText(fileToRead);
    // Handle errors load
    reader.onload = fileReadingFinished;
    reader.onerror = errorHandler;
  }

  const readCsv = useCallback(
    e => {
      const file = e.target.files[0];
      if (file.type === "application/vnd.ms-excel"){
        if (window.FileReader) {
          // FileReader are supported.
          setErroArquivo(false);
          getAsText(file);          
        }  
      }
      else{
        setErroArquivo(true);
      }
    },
    [corretores],
  );

  const handleClick = () => {
    if (hiddenFileInput?.current) {
      hiddenFileInput?.current?.click();
    }
  };

  return (
    <>
      <table className="default">
        <thead>
          <tr>
            <th>CPF</th>
            <th>Situação</th>
            <th className="actions">
              <TegraButton isAction className="small" onClick={addCorretor}>
                Adicionar
              </TegraButton>
            </th>
          </tr>
        </thead>
        <tbody>
          {corretores.map((c, index) => (
            <tr>
              <td>
                <ReactInputMask
                  disabled={!!c.id}
                  className={`default ${c.valid === false ? 'error' : ''}`}
                  mask="999.999.999-99"
                  value={c.tx_cpf}
                  onChange={e => changeCPF(e, index)}
                />
              </td>
              <td>{getSituacao(c)}</td>
              <td>
                <TegraButton
                  isAction
                  className="cancelar small"
                  onClick={e => remover(e, index)}
                >
                  Remover
                </TegraButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="float-right text-right">
        <p className="text-right">Importar arquivo</p>
        <div className="upload-btn-wrapper">
          <button type="button" className="uploadBtn" onClick={handleClick}>
            <span>Enviar arquivo</span>
            <img src={uploadIcon} alt="Upload" />
          </button>
          <input type="file" onChange={readCsv} ref={hiddenFileInput} />
        </div>
        <a
          href="/static/media/corretores.1fbd9b18.csv"
          className="d-inline-block mt-2"
          download="corretores.csv"
          target='_blank'
        >
          Baixar modelo
        </a>

      </div>
      <div className="clear" />
    </>
  );
};

export default ImobiliariaCorretores;
