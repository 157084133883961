import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';

import { useParams } from 'react-router-dom';
import { FormHandles, Form } from '@unform/core';
import { useTable } from 'react-table';
import placeHolderImg from '../../assets/tegra-blog-item-thumbnail.png';
import {
  Container,
  Content,
  Subtitle,
  SectionContent,
  Implantacao,
  FichaTecnica,
  TabelaProduto,
  Espelho,
} from './styles';
import ProdutoHeader from './ProdutoHeader';
import TegraDivider from '../../components/TegraDivider';
import TegraSelect from '../../components/TegraSelect';
import TegraProdutoTabela from './TegraProdutoTabela';
import TegraEspelhoStatusTabela from './TegraEspelhoStatusTabela';
import TegraEspelhoTabela from './TegraEspelhoTabela';
import api from '../../services/api';
import { useLoader } from '../../hooks/loader';
import { useAuth } from '../../hooks/auth';
import { LoadingContainer, LoadingWrapper } from '../../hooks/loader/styles';
import FirstImage from '../../hooks/loader/LoaderImages/FirstImage';
import SecondImage from '../../hooks/loader/LoaderImages/SecondImage';
import ThirdImage from '../../hooks/loader/LoaderImages/ThirdImage';
import colors from '../../styles/colors';
import ContactFloatCardProduct from '../../components/ContactFloatCardProduct';

interface DetalhesData {
  codigo_bloco_sap: string;
  // espelho: IEspelho;
  // tabela: string;
  arquivo: string;
  nome_produto: string;
  codigo_empreendimento_sap: string;
}

interface IBloco {
  codigo_bloco_sap: string;
  nome_bloco: string;
  nome_empreendimento: string;
  arquivoImplantacao?: string;
  arquivoImplantacao1?: string;
  arquivoImplantacao2?: string;
  arquivoImplantacao3?: string;
  codigo_empreendimento_sap: string;
}

interface SelectOption {
  value: string;
  label: string;
}

interface IResponseEmprendimento {
  result: {
    nome: string;
    espelhosTabelas: {
      urL_espelho: string;
    };
    fichaTecnica: string;
  };
}

const EspelhosTabelaDetalhes: React.FC = () => {
  const [produtoData, setProdutoData] = useState<DetalhesData>(
    {} as DetalhesData,
  );

  // const { setLoaderActive } = useLoader();

  const [blocos, setBlocos] = useState<IBloco[]>([]);
  const [selectValue, setSelectValue] = useState({});
  const [pdfTabelaEspelho, setpdfTabelaEspelho] = useState<string>('');
  const [icTabela, setIcTabela] = useState<boolean>(true);
  const [icEspelho, setIcEspelho] = useState<boolean>(true);
  const [fichaTecnica, setFichaTecnica] = useState('');
  const [implantacao, setImplantacao] = useState<any[] | any>([]);
  const { user } = useAuth();
  const [isLoadingTorre, setIsLoadingTorre] = useState(true);
  const [imageChangeInterval, setImageChangeInterval] = useState<number>();
  const [loadingImageIndex, setLoadingImageIndex] = useState<number>(1);

  useEffect(() => {
    if (isLoadingTorre) {
      setImageChangeInterval(
        window.setInterval(() => {
          setLoadingImageIndex(oldIndex => (oldIndex + 1) % 3);
        }, 2000),
      );
    } else {
      clearInterval(imageChangeInterval);
    }
  }, [isLoadingTorre, blocos]);

  const blocosOptions = useMemo<SelectOption[]>(() => {
    return blocos.map(bloco => ({
      value: bloco.codigo_empreendimento_sap + '__' + bloco.codigo_bloco_sap,
      label: bloco.nome_bloco,
    }));
  }, [blocos]);

  const { id } : any = useParams();

  const handleBlocoChange = useCallback(
    ({ value }) => {
      setIsLoadingTorre(true);
      const findBloco = blocos.find(
        bloco =>
          bloco.codigo_empreendimento_sap + '__' + bloco.codigo_bloco_sap ===
          value,
      );
      if (findBloco) {
        // att espelho
        setProdutoData(prev => ({
          ...prev,
          codigo_bloco_sap: findBloco.codigo_bloco_sap,
          codigo_empreendimento_sap: findBloco.codigo_empreendimento_sap,
        }));

        buildImplantacao([
          findBloco.arquivoImplantacao,
          findBloco.arquivoImplantacao1,
          findBloco.arquivoImplantacao2,
          findBloco.arquivoImplantacao3,
        ]);

        setSelectValue({
          value:
            findBloco.codigo_empreendimento_sap +
            '__' +
            findBloco.codigo_bloco_sap,
          label: findBloco.nome_bloco,
        });
      }
    },
    [blocos],
  );

  useEffect(() => {
    async function verificaPDFTabelaEspelho(): Promise<void> {
      try {
        const response = await api.get(
          `EspelhosTabelas/getPDFTabelaEspelho/${id}`,
        );
        console.log('response.data.pdf', response.data.pdf)
        console.log('response.data.ic_tabela', response.data.ic_tabela)
        console.log('response.data.ic_espelho', response.data.ic_espelho)
        setpdfTabelaEspelho(response.data.pdf);
        setIcTabela(response.data.ic_tabela);
        setIcEspelho(response.data.ic_espelho);
        loadBlocos();



      } finally {
        setInterval(() => {
          setIsLoadingTorre(false);
        }, 3000);
      }
    }

    async function loadBlocos(): Promise<void> {
      try {

        const response = await api.get<IBloco[]>(
          `EspelhosTabelas/blocos/${id}`,
        );
        setBlocos(response.data);

        setProdutoData(prev => ({
          ...prev,
          codigo_bloco_sap: response.data[0].codigo_bloco_sap,
          codigo_empreendimento_sap: response.data[0].codigo_empreendimento_sap,
        }));

        buildImplantacao([
          response.data[0].arquivoImplantacao,
          response.data[0].arquivoImplantacao1,
          response.data[0].arquivoImplantacao2,
          response.data[0].arquivoImplantacao3,
        ]);

        setSelectValue({
          value:
            response.data[0].codigo_empreendimento_sap +
            '__' +
            response.data[0].codigo_bloco_sap,
          label: response.data[0].nome_bloco,
        });
      } finally {
        setInterval(() => {
          setIsLoadingTorre(false);
        }, 3000);
      }
    }

    async function loadEmpreendimento(): Promise<void> {
      try {
        setIsLoadingTorre(true);
        const response = await api.get<IResponseEmprendimento>(
          `Empreendimento/byId`,
          {
            params: {
              id_empreendimento: id,
            },
          },
        );
        const { result } = response.data;

        setFichaTecnica(result.fichaTecnica);

        setProdutoData(prev => ({
          ...prev,
          nome_produto: result.nome,
          arquivo: result.espelhosTabelas?.urL_espelho,
        }));
      } finally {}
    }

    verificaPDFTabelaEspelho();
    loadEmpreendimento();

  }, [id, setIsLoadingTorre]);

  const formRef = useRef<FormHandles>(null);

  function renderFichaTecnica() {
    return { __html: fichaTecnica };
  }

  function buildImplantacao(urls: any[] | any) {
    let urlsFilter = urls.filter(function (url: string | undefined) {
      return url !== null;
    });
    setImplantacao(urlsFilter);
  }

  return (
    <Container>
      <ProdutoHeader
        nome={produtoData.nome_produto}
        pdfTabelaEspelho={pdfTabelaEspelho}
        arquivo={`EspelhosTabelas/EspelhoPDF?id=${id}&parcerias=${
          user.tipo === 0
        }&isLab=${user.isLab}`}
      />
      { !pdfTabelaEspelho &&
      <Content>
        <Form ref={formRef} onSubmit={() => {}}>
          <section>
            <Subtitle>Torre</Subtitle>
            <div className="select">
              <TegraSelect
                label=""
                name="espelho"
                placeholder="Nome do bloco"
                onChange={handleBlocoChange}
                noOptionsMessage={() => 'Nenhum selecionado'}
                options={blocosOptions}
                defaultValue={selectValue}
                // erro de compilacao
                // value={selectValue}
              />
            </div>
            <TegraDivider color="#e6e6e6" axis="x" height={1} />
          </section>
        </Form>
        {isLoadingTorre && (
          <LoadingContainer className="LoadParcial" active={isLoadingTorre}>
            <LoadingWrapper>
              {loadingImageIndex === 0 && (
                <>
                  <FirstImage color={colors.lightGrey} />{' '}
                  <FirstImage color={colors.primaryColor} />
                </>
              )}
              {loadingImageIndex === 1 && (
                <>
                  <SecondImage color={colors.lightGrey} />{' '}
                  <SecondImage color={colors.primaryColor} />
                </>
              )}
              {loadingImageIndex === 2 && (
                <>
                  <ThirdImage color={colors.lightGrey} />{' '}
                  <ThirdImage color={colors.primaryColor} />
                </>
              )}
            </LoadingWrapper>
          </LoadingContainer>
        )}
          <div className={isLoadingTorre? "isLoadingTorre" : ''}>
            { icEspelho &&
              <SectionContent id="espelho">
              <Espelho>
                {id && <TegraEspelhoStatusTabela idEmpreendimento={id} />}

                {id && produtoData.codigo_bloco_sap && (
                  <TegraEspelhoTabela
                    idEmpreendimento={id}
                    idBloco={produtoData.codigo_bloco_sap}
                    codigo={produtoData.codigo_empreendimento_sap}
                  />
                )}
              </Espelho>
            </SectionContent>
            }

            {id && produtoData.codigo_bloco_sap && icTabela && (
              <section>
                <Subtitle>Tabela</Subtitle>
                <TegraDivider color="#e6e6e6" axis="x" height={1} />
                <SectionContent>
                  <TabelaProduto>
                    <TegraProdutoTabela
                      idEmpreendimento={id}
                      idBloco={produtoData.codigo_bloco_sap}
                      codigo={produtoData.codigo_empreendimento_sap}
                    />
                  </TabelaProduto>
                </SectionContent>
              </section>
            )}

            {fichaTecnica !== '' && (
              <section>
                <Subtitle>Ficha Técnica</Subtitle>
                <TegraDivider color="#e6e6e6" axis="x" height={1} />
                <SectionContent>
                  <FichaTecnica>
                    <div dangerouslySetInnerHTML={renderFichaTecnica()} />
                  </FichaTecnica>
                </SectionContent>
              </section>
            )}

            {implantacao.length > 0 && (
              <section>
                <Subtitle>Implantação</Subtitle>
                <TegraDivider color="#e6e6e6" axis="x" height={1} />
                <SectionContent>
                  {implantacao.map((i: any) => {
                    var imageUrl = `${process.env.REACT_APP_VWEB}${i}?sv=2019-10-10&ss=b&srt=co&sp=rl&se=2028-04-30T23:39:02Z&st=2020-04-30T15:39:02Z&spr=https&sig=13blUgEFPVvR7xx7uU19mQ%2BQNrSmOUg8IWoMFB1Jw5g%3D`;
                    return (
                      <Implantacao>
                        <img src={imageUrl} alt="Imagem da implantação" />
                      </Implantacao>
                    );
                  })}
                </SectionContent>
              </section>
            )}
          </div>
      </Content>
      }
      { !!pdfTabelaEspelho &&
        <Content>
          <embed src={pdfTabelaEspelho} width="100%" height="780"></embed>
        </Content>
      }
      <ContactFloatCardProduct id_produto={id}/>
    </Container>
  );
};

export default EspelhosTabelaDetalhes;
