import styled from 'styled-components';

interface LinkGroupItemWrapperProps {
  columns: boolean;
}

export const LinkGroupItemWrapper = styled.div<LinkGroupItemWrapperProps>`
  width: ${props => (props.columns ? '50%' : 'unset')};
  margin-bottom: 1.2rem;
  color: white;
`;
