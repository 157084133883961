import React from 'react';
import { HamburgerBar, NavHamburguerMenuContainer } from './styles';

const NavHamburguerMenu: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  onClick,
}) => {
  return (
    <NavHamburguerMenuContainer onClick={onClick}>
      <HamburgerBar />
      <HamburgerBar />
      <HamburgerBar />
    </NavHamburguerMenuContainer>
  );
};

export default NavHamburguerMenu;
