import React from 'react';
import ProductMaterials from '../..';

const Images: React.FC = () => {
  return (
    <ProductMaterials
      title="Imagens"
      subtitle=""
      description="Baixe fotos e perspectivas dos nossos empreendimentos para compartilhar com seus clientes e prospects."
      showColumnTitle={false}
      actions={['download']}
      apiUrl="ImagensEmpreendimento"
      columns={[{ label: 'Label', field: 'tx_produto' }]}
      anchorName="Imagens"
      onClickItem={() => localStorage.setItem('SCROLL', '#Imagens')}
    />
  );
};

export default Images;
