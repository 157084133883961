import React from 'react';
import ProductMaterials from '../..';

const MateriaisCorretor: React.FC = () => {
  return (
    <ProductMaterials
      title="Materiais Gerais"
      subtitle="Materiais do Corretor"
      description="Baixe materiais do corretor."
      showColumnTitle={false}
      actions={['open', 'download']}
      apiUrl="MateriaisGerais"
      columns={[
        { label: 'Arquivo', field: 'nome' },
        { label: 'Tipo', field: 'extensao' },
      ]}
      anchorName="MateriaisCorretor"
      onClickItem={() => localStorage.setItem('SCROLL', '#Email')}
    />
  );
};

export default MateriaisCorretor;
