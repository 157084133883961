import React, { useEffect, useState } from 'react';
import { FooterLinkGroup } from '..';
import { LinkGroupWrapper, LinkGroupTitle } from './styles';
import LinkGroupItem from './LinkGroupItem';
import { useAuth } from '../../../hooks/auth';

interface LinkGroupProps {
  group: FooterLinkGroup;
  isParceiro?: boolean;
}
const LinkGroup: React.FC<LinkGroupProps> = ({ group, isParceiro }) => {
  const { user } = useAuth();
  const [menuData, setMenuData] = useState<any>();

  useEffect(() => {
    const mappedLinks = group.items.filter(footerLink => {
      if (!user.acessos.minhaPagina && footerLink.link === '/minha-pagina') {
        return false;
      }
      if (
        (isParceiro || !user.acessos.ranking) &&
        footerLink.link === '/ranking/corretores'
      ) {
        return false;
      }
      if (!user.acessos.sugestoes && footerLink.link === '/sugestoes') {
        return false;
      }
      if (
        !user.acessos.perguntasFrequentes &&
        footerLink.link === '/perguntas-frequentes'
      ) {
        return false;
      }
      if (!user.acessos.produtos && footerLink.link === '/produtos') {
        return false;
      }
      if (
        !user.acessos.calendarioTrabalho &&
        footerLink.link === '/calendario-de-eventos'
      ) {
        return false;
      }
      if (
        !user.acessos.premiacaoMetas &&
        footerLink.link === '/premiacoes-e-metas'
      ) {
        return false;
      }
      return true;
    });
    setMenuData(mappedLinks)
  }, [user]);

  return (
    <LinkGroupWrapper columns={group.columns}>
      {group.title ? <LinkGroupTitle>{group.title}:</LinkGroupTitle> : ''}
      
      {menuData && menuData.map((footerLink: { link: string; label: string; }) => {
        return (
          <LinkGroupItem
            key={`link-group-item-${footerLink.link}`}
            columns={group.columns}
            link={footerLink.link}
            label={footerLink.label}
            externalLink='https://www.contatoseguro.com.br/jeitocerto'
          />
        );
      })}
    </LinkGroupWrapper>
  );
};

export default LinkGroup;
