import styled from 'styled-components';

export const Container = styled.div`
  & > .divider {
    margin-top: 40px;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      margin-top: 80px;
    }
  }
`;

export const InputContainer = styled.div`
    margin-top: 5px;

    .labelContainer{
        p{
            margin-bottom: 11px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.23;
            -webkit-letter-spacing: normal;
            -moz-letter-spacing: normal;
            -ms-letter-spacing: normal;
            letter-spacing: normal;
            text-align: left;
            color: #60605d;
            margin-bottom: 11px;
            margin-top: 15.5px;
        }
    }
    .InputCpfCnpj{
        padding: 10px;
        height: 40px;
        border: solid 1px #adadab;
        background-color: #ffffff;
        width: 100%;    
    }
`;

export const SearchContainer = styled.div`
  margin-top: 54px;
`;

export const Title = styled.strong`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #272723;
`;

export const InfoLabel = styled.p`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
    margin-bottom: 11px;
    margin-top: 30px;
`;


export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  //margin-top: 40px;

  @media (max-width: 768px) {
    justify-content: flex-start;
    button {
      flex: 1;
      width: 100%;
    }
  }
`;

export const ResultBox = styled.ul`
  display: flex;
  justify-content: center;
  padding-top: 50px;
`;

export const DadosList = styled.ul`
  position: relative;
  width: 894px;
  max-width: 100%;
  background-color: #e6e6e6;
  padding-bottom: 40.5px;
  padding: 20px 30px;
  padding-top: 0;
`;

export const DadosItem = styled.li`
  display: flex;
  justify-content: space-between;

  padding-top: 20px;
  padding-bottom: 20px;

  border-bottom: 2px solid #fff;

  &:last-child {
    border-bottom: 2px solid #fff;
  }

  @media (max-width: 600px) {
    padding-bottom: 44px;
  }
`;


export const DadosItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin-right: 7%;

  @media (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 0.5rem;
    max-width: 100%;
  }

  p {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
  }

  strong {
    display: block;
    margin-top: 16px;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #272723;

    @media (max-width: 600px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }


`;
