import React from 'react';
import ProductMaterials from '../..';

const MirrorsTables: React.FC = () => {
  return (
    <ProductMaterials
      title="Espelhos e tabelas"
      subtitle=""
      description="As tabelas necessárias com metragens e precificações."
      showColumnTitle={false}
      actions={['open']}
      apiUrl="EspelhosTabelas"
      columns={[{ label: 'Label', field: 'nome' }]}
      anchorName="Espelhos"
      onClickItem={() => localStorage.setItem('SCROLL', '#Espelhos')}
    />
  );
};

export default MirrorsTables;
