import { parseISO, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

export default function getFormattedDateAndTime(date: string): string {
  const parsedDate = parseISO(date);
  try {
    const formattedTime = format(parsedDate, 'dd/MM/yyyy - HH:ii', {
      locale: ptBR,
    });
    return formattedTime;
  } catch {
    return '';
  }
}
