import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TegraButton from '../../components/TegraButton';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import {
  SuggestionsInput,
  SuggestionsContainer,
  SuggestionsWrapper,
  ErrorsContainer,
  SucessoContainer,
} from './styles';
import { useLoader } from '../../hooks/loader';
import api from '../../services/api';
import sucessoIcon from '../../assets/sucesso_icon.svg';

const TreinamentosDetalhes: React.FC = () => {
  const [suggestion, setSuggestion] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [sucesso, setSucesso] = useState(false);

  const { setLoaderActive } = useLoader();

  const submitSuggestion = useCallback(async () => {
    if (suggestion === '') {
      setError('Preencha o campo para enviar');
      return;
    }

    setLoaderActive(true);
    try {
      const response = await api.post(`/Sugestoes?txt_mensagem=${suggestion}`);
      const { sucesso: enviado } = response.data;
      if (enviado) {
        setSucesso(true);
        setLoaderActive(false);
      }
    } catch (e: any) {
      if (e.response) {
        setError(e.response.data.erro);
      }
    }
    setLoaderActive(false);
  }, [setLoaderActive, suggestion]);

  useEffect(() => {
    if (sucesso) {
      setError('');
    }
  }, [sucesso]);

  return (
    <SuggestionsWrapper>
      {!sucesso ? (
        <>
          <TegraOpaqueHero
            title="Sugestões"
            description={(
              <p>
                Tem uma sugestão do que podemos adicionar ou melhorar?
                <br />
                Conte pra gente abaixo!
              </p>
            )}
          />
          <SuggestionsContainer>
            {error && (
              <ErrorsContainer>
                <p>{error}</p>
              </ErrorsContainer>
            )}
            <SuggestionsInput
              onChange={e => setSuggestion(e.target.value)}
              rows={10}
              placeholder="Insira sua sugestão"
            />
            <TegraButton isAction onClick={() => submitSuggestion()}>
              Enviar
            </TegraButton>
          </SuggestionsContainer>
        </>
      ) : (
        <SucessoContainer>
          <img src={sucessoIcon} alt="Sucesso" />
          <h1>Sugestão enviada com sucesso</h1>
          <p>Obrigado por nos enviar sua sugestão!</p>
          <TegraButton isAction onClick={() => setSucesso(false)}>
            Nova sugestão
          </TegraButton>
        </SucessoContainer>
      )}
    </SuggestionsWrapper>
  );
};

export default TreinamentosDetalhes;
