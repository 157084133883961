import React from 'react';
import { ISVGImage } from './FirstImage';

const SecondImage: React.FC<ISVGImage> = ({ color }) => {
  const styleLine = {
    fill: 'none',
    stroke: color,
    strokeMiterlimit: 10,
    strokeWidth: '4.71872102038689px',
  };
  const stylePolyline = {
    fill: 'none',
    stroke: color,
    strokeMiterlimit: 10,
    strokeWidth: '5.13385404029066px',
  };

  const styleLogoTegra = {
    fill: color,
    stroke: 'none',
    strokeMiterlimit: 0,
    strokeWidth: 0,
  };
  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
      <g>
        <polyline
          points="25.2,132.4 48.8,132.4 48.8,26.3 93.8,7.6 93.8,132.4 114.5,132.4"
          style={stylePolyline}
        />
        <path d="M79.1,36.2" style={styleLine} />
        <path d="M55.1,36.2" style={styleLine} />
        <line x1="55.1" y1="43.9" x2="86.1" y2="43.9" style={styleLine} />
        <line x1="55.1" y1="51.6" x2="86.1" y2="51.6" style={styleLine} />
        <line x1="55.1" y1="59.3" x2="86.1" y2="59.3" style={styleLine} />
        <line x1="55.1" y1="66.9" x2="86.1" y2="66.9" style={styleLine} />
        <line x1="55.1" y1="74.6" x2="86.1" y2="74.6" style={styleLine} />
        <line x1="55.1" y1="82.3" x2="86.1" y2="82.3" style={styleLine} />
        <line x1="55.1" y1="90" x2="86.1" y2="90" style={styleLine} />
        <line x1="55.1" y1="97.7" x2="86.1" y2="97.7" style={styleLine} />
        <line x1="55.1" y1="105.4" x2="86.1" y2="105.4" style={styleLine} />
        <line x1="55.1" y1="113" x2="86.1" y2="113" style={styleLine} />
        <line x1="55.1" y1="120.7" x2="86.1" y2="120.7" style={styleLine} />
        <line x1="55.1" y1="128.4" x2="86.1" y2="128.4" style={styleLine} />
      </g>
      <path
        d="M82.5,34.6l0.9-2.2l0.9,2.2H82.5z M77.2,33.2L77.2,33.2l-1.2,0v-2h1.2c0.5,0,1,0.4,1,1
        C78.2,32.8,77.7,33.2,77.2,33.2 M84.1,29.7h-1.4l-2.9,7.1l-1.3-2.3c0.8-0.4,1.4-1.3,1.4-2.2c0-1.4-1.1-2.5-2.5-2.5h0h-2.9v3.5h-3.2
        v1.5h1.4c-0.4,0.5-1.1,0.9-1.8,0.9c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c0.5,0,0.9,0.1,1.2,0.4l1.2-1.2
        c-0.7-0.5-1.5-0.8-2.4-0.8c-2.1,0-3.9,1.7-3.9,3.9c0,0.8,0.2,1.5,0.6,2.1h-4v-1.3h2.7v-1.6h-2.7v-1.3h3.3v-1.6H54.8v1.6h2.7v5.8
        h1.7v-5.8H62v5.8h4.5l1.3-1.3c0.7,0.9,1.8,1.5,3,1.5c1.6,0,3-1,3.6-2.5v2.3H76v-2.4h0.8l1.4,2.4h3.3l0.4-1H85l0.4,1h1.8L84.1,29.7z
        "
        style={styleLogoTegra}
      />
    </svg>
  );
};

export default SecondImage;
