import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  MouseEvent,
} from 'react';

import {
  Switch,
  Route,
  useParams,
  useLocation,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';

import { Form, SubmitHandler, FormHandles } from '@unform/core';
import { isToday, isThisWeek } from 'date-fns/esm';
import { parse, format } from 'date-fns';
import { toast } from 'react-toastify';
import { ReactComponent as EmailIcon } from '../../../assets/espaco_gerente_options/tegra-icon-email.svg';
import { ReactComponent as LocationIcon } from '../../../assets/espaco_gerente_options/tegra-icon-location.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/espaco_gerente_options/tegra-icon-people.svg';
import { ReactComponent as TelephoneIcon } from '../../../assets/espaco_gerente_options/tegra-icon-telephone.svg';
import { ReactComponent as FiltroIcon } from '../../../assets/tegra-icon-filtrar.svg';
import { ReactComponent as OrdenarIcon } from '../../../assets/tegra-icon-ordenar.svg';

import { ReactComponent as CloseFilterIcon } from '../../../assets/tegra-icon-close.svg';
import defaultImage from '../../../assets/avatar.svg';

import {
  Container,
  Profile,
  ProfileAvatar,
  ProfileInfo,
  Content,
  Options,
  OptionsRight,
  OptionsLeft,
  ListActions,
  OptionsFiltroEOrdenar,
  OptionsRightHeader,
  OptionsRightFilterActionsContainer,
  TarefasList,
} from './styles';
import Atividades, { IAtividade } from '../Atividades';

import DadosCorretor, {ProfileFormData} from '../DadosCorretor';


import avatarPlaceholder from '../../../assets/tegra-avatar.png';
import TegraDivider from '../../../components/TegraDivider';
import TegraButton from '../../../components/TegraButton';
import TegraOptions from '../../../components/TegraOptions';
import TegraOptionsLinks from '../../../components/TegraOptionsLinks';
import TegraNovaTarefa from '../TegraNovaTarefa';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import getFormattedDate from '../../../utils/date/getFormattedDate';
import Tarefa, { IComentario, ITarefa } from '../Tarefa';
import { useLoader } from '../../../hooks/loader';
import { ICorretor } from '../MeusCorretores/MeusCorretoresLista';

export interface ICorretorSelecionado {
  id: number;
  nome: string;
  creci: string;
  imagem: any;
  tipo: any;
  email?: any;
}

interface INavOption {
  name: string;
  label: string;
  link: any;
}

export interface IResponsavel {
  label: string;
  tipo: number;
  value: string;
}

const navOptions: INavOption[] = [
  {
    label: 'Tarefas',
    name: 'tarefas',
    link: '',
  },
  {
    label: 'Atividades',
    name: 'atividades',
    link: 'atividades',
  },
];

const navOptionsCorretor: INavOption[] = [
  {
    label: 'Tarefas',
    name: 'tarefas',
    link: '',
  },
  {
    label: 'Atividades',
    name: 'atividades',
    link: 'atividades',
  },
  {
    label: 'Dados do Corretor',
    name: 'dadoscorretor',
    link: 'dadoscorretor',
  },
];


export interface INovaTarefaFormData {
  tipo: string;
  prazo: string;
  conteudo: string;
  responsavel: IResponsavel;
  arquivos: [];
  empreendimentoRelacionado: {
    value: string;
    tipo: string;
    label: string;
  };
  enderecoVisita: string;
  localVisita: {
    value: string;
    label: string;
  };
}

interface ITarefasFiltroFormData {
  tipo: string;
  status: string;
  periodo: string;
}

interface TarefasProps {
  id: string;
  tarefas: ITarefa[];
}

const IconTipo = ['', 'email', 'visita', 'reuniao', 'ligacao'];

interface TarefasAtividadesMainProps {
  tipo_pagina: 'minha' | 'corretor';
  user_id?: number;
}

const TarefasAtividadesMain: React.FC<TarefasAtividadesMainProps> = ({
  tipo_pagina,
  user_id,
}) => {
  const { setLoaderActive } = useLoader();
  const { id: corretor_id, corretorTipo } : any = useParams();
  const tarefasFiltroFormRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();

  const [tarefas, setTarefas] = useState<ITarefa[]>([]);
  const [corretor, setCorretor] = useState<ICorretorSelecionado>();

  const [atividades, setAtividades] = useState<IAtividade[]>([]);
  const [dadoscorretor, setDadosCorretor] = useState<ProfileFormData>();

  const [totalPaginasAtividades, setTotalPaginasAtividades] = useState<number>(
    0,
  );
  const [totalPaginasTarefas, setTotalPaginasTarefas] = useState<number>(0);

  const [editingTarefa, setEditingTarefa] = useState<ITarefa>({} as ITarefa);
  const [filteredTerefas, setFilteredTarefas] = useState<ITarefa[]>([]);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const history = useHistory();



  const [tipoOptions, setTipoOptions] = useState([
    {
      icon: <EmailIcon />,
      name: 'email',
    },
    {
      icon: <TelephoneIcon />,
      name: 'ligacao',
    },
    {
      icon: <PeopleIcon />,
      name: 'reuniao',
    },
    {
      icon: <LocationIcon />,
      name: 'visita',
    },
  ]);

  const [periodoOptions, setPeriodoOptions] = useState([
    {
      label: 'Todas',
      name: 'todas',
      selected: true,
    },
    {
      label: 'Esta Semana',
      name: 'esta_semana',
    },
    {
      label: 'Hoje',
      name: 'hoje',
    },
  ]);

  const [statusOptions, setStatusOptions] = useState([
    {
      label: 'Todas',
      name: 'todas',
    },
    {
      label: 'Pendentes',
      name: 'pendentes',
      selected: true,
    },
    {
      label: 'Finalizadas',
      name: 'finalizadas',
    },
  ]);

  const [filtroShow, setFiltroShow] = useState(false);

  const handleFiltroShow = useCallback((show: boolean) => {
    setFiltroShow(show);
  }, []);

  const handleApplyFilter = useCallback(() => {
    // aplicar filtro
    setFiltroShow(false);
  }, []);

  const [showEditTarefa, setShowEditTarefa] = useState(false);
  const loadTarefas = (): void => {
    async function loadMinhasTarefas(): Promise<void> {
      try {
        setLoaderActive(true);
        const response = await api.get(`Gerente/tarefas`, {
          params: { pagina: 1, quantidade: 10 },
        });
        const { items, paginas } = response.data;
        if (items) {
          setTarefas(items);
        }
        setTotalPaginasTarefas(paginas || 1);
      } catch {
        console.error('Não foi possível carregar minhas tarefas');
      } finally {
        setLoaderActive(false);
      }
    }

    async function loadCorretorTarefas(): Promise<void> {
      try {
        setLoaderActive(true);

        const response = await api.get(`Tarefa/usuario/:id`, {
          params: { id: corretor_id, pagina: 1, quantidade: 10 },
        });

        const { items, paginas } = response.data;

        if (items) {
          setTarefas(items);
        }
        setTotalPaginasTarefas(paginas || 1);
      } catch {
        console.error('Não foi possível carregar tarefas do corretor');
      } finally {
        setLoaderActive(false);
      }
    }
    // pegar tarefas da api
    switch (tipo_pagina) {
      case 'minha':
        loadMinhasTarefas();
        break;
      case 'corretor':
        loadCorretorTarefas();
        break;
      default:
        break;
    }
  };

  const handleSubmitNovaTarefa: SubmitHandler<INovaTarefaFormData> = (
    data,
  ): void => {
    const {
      tipo,
      prazo,
      conteudo,
      arquivos,
      empreendimentoRelacionado,
      enderecoVisita,
      localVisita,
    } = data;
    let { responsavel } = data;
    setLoaderActive(true);

    const id_tipoTarefa = IconTipo.findIndex(item => item === tipo);

    if (id_tipoTarefa <= 0) {
      return;
    }
    const [day, month, year] = prazo.split('/');
    let prz: any;
    try {
      prz = new Date(`${month}/${day}/${year}`);
    } catch {}
    if (!prz || isNaN(prz)) {
      setLoaderActive(false);
      toast('Informe um prazo válido', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        type: 'error',
      });
      return;
    }
    const dt_prazo = prz.toISOString();

    const { id_tarefa } = editingTarefa;

    const action = id_tarefa ? 'Tarefa/update' : 'Tarefa/create';
    if (tipo_pagina !== 'minha' && corretor) {
      responsavel = {
        tipo: corretor?.tipo,
        value: corretor?.id.toString(),
        label: corretor?.nome.toString(),
      };
    }
    // nova tarefa
    api
      .post(action, {
        arquivos: [arquivos],
        id_tarefa,
        id_tipoTarefa,
        id_tipoCorretor: responsavel ? Number(responsavel.tipo) : undefined,
        id_responsavel: responsavel ? Number(responsavel.value) : undefined,
        txt_responsavel: responsavel ? responsavel.label : undefined,
        dt_prazo,
        txt_descricao: conteudo,
        txt_empreendimentoRelacionado: empreendimentoRelacionado?.label,
        txt_enderecoVisita: enderecoVisita,
        txt_localVisita: localVisita?.label,
      })
      .then(res => {
        const { sucesso, mensagem } = res.data;
        if (sucesso) {
          loadTarefas();
        }
      });

    //setShowEditTarefa(false);
  };

  const handleTarefaFiltroSelect: SubmitHandler<ITarefasFiltroFormData> = (
    data,
  ): void => {
    const { tipo, periodo, status } = data;
    const filtered = tarefas.filter(tarefa => {
      const parsedDate = new Date(tarefa.dt_prazo);
      const statusOk =
        status === 'todas' ||
        (status === 'finalizadas' && tarefa.finalizada) ||
        (status === 'pendentes' && !tarefa.finalizada);
      let isInPeriodo = true;

      if (periodo === 'hoje') {
        isInPeriodo = isToday(parsedDate);
      } else if (periodo === 'esta_semana') {
        isInPeriodo = isThisWeek(parsedDate);
      }
      const isType = !tipo || IconTipo[tarefa.id_tipoTarefa] === tipo;
      return isType && isInPeriodo && statusOk;
    });
    setFilteredTarefas([...filtered]);
  };
  useEffect(() => {
    loadTarefas();
  }, [tipo_pagina, user_id, corretor_id]);

  useEffect(() => {
    if (tipo_pagina === 'minha') {
      setCorretor({
        id: user.id,
        nome: user.apelido,
        creci: user.creci,
        imagem: user.foto,
        tipo: user.tipo,
        //idCargoSigavi: user.idCargoSigavi,
      });
    } else {
      const getCorretor = async (): Promise<void> => {
        const rsp = await api.get(`Gerente/corretor/:id/:tipo`, {
          params: { id: corretor_id, tipo: corretorTipo },
        });
        const usr = rsp.data;
        setCorretor({
          id: usr.id_parceiro,
          nome: usr.tx_razaoSocial || usr.tx_nome,
          creci: usr.tx_creci || usr.nu_creci,
          imagem: usr.tx_foto,
          tipo: usr.id_tipoParceiro,
          email: usr.tx_email,
          //idCargoSigavi: usr.id_cargoSigavi,
        });
        setDadosCorretor(usr);
      };
      getCorretor();
    }
  }, [corretorTipo, corretor_id, tipo_pagina, user]);

  const loadAtividades = (): void => {
    // pegar atividades da api

    async function load(ativUrl: string, id?: string): Promise<void> {
      try {
        setLoaderActive(true);
        const response = await api.get(ativUrl, {
          params: { pagina: 1, quantidade: 10, id },
        });

        const { items, paginas } = response.data;

        if (items) {
          setAtividades(items);
        }
        setTotalPaginasAtividades(paginas || 1);
        setLoaderActive(false);
      } catch {
        console.error('Não foi possível carregar atividades');
      }
    }
    // pegar Atividades da api
    switch (tipo_pagina) {
      case 'minha':
        load(`Gerente/atividades`);
        break;
      case 'corretor':
        load(`Atividades/usuario/:id`, corretor_id);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    loadAtividades();
  }, [tipo_pagina, user_id, corretor_id, setLoaderActive]);

  useEffect(() => {
    tarefasFiltroFormRef?.current?.submitForm();
  }, [tarefas]);

  const handleShowEditTarefa = useCallback(
    (e, { is_new_tarefa = false } = {}) => {
      if (e) e.preventDefault();
      if (is_new_tarefa) {
        setEditingTarefa({
          tipo_pagina,
        } as ITarefa);
      }
      if (!showEditTarefa)
        document?.getElementById('tarefa-container')?.scrollIntoView({
          behavior: 'smooth',
        });

      setShowEditTarefa(!showEditTarefa);
    },
    [corretor, showEditTarefa],    
  );

  useEffect(() => {
    !pathname.includes('tarefas') &&
      !showEditTarefa &&
      setShowEditTarefa(false);
  }, [pathname, showEditTarefa]);

  const [currentTarefasPage, setCurrentTarefasPage] = useState(1);

  const [currentAtividadesPage, setCurrentAtividadesPage] = useState(1);

  const handleLoadMoreTarefas = useCallback(() => {
    setLoaderActive(true);

    const ativUrl =
      tipo_pagina === 'minha' ? `Gerente/tarefas` : `tarefas/usuario/:id`;
    const id = tipo_pagina === 'minha' ? undefined : corretor_id;
    api
      .get(ativUrl, {
        params: { pagina: currentTarefasPage + 1, quantidade: 10, id },
      })
      .then(res => {
        const {
          data: { items },
        } = res;
        if (items.length > 0) {
          setTarefas([...tarefas, ...items]);
          setCurrentTarefasPage(currentTarefasPage + 1);
        }
        setLoaderActive(false);
      });
  }, [tarefas, currentTarefasPage, setLoaderActive]);

  const handleLoadMoreAtividades = useCallback(() => {
    setLoaderActive(true);
    const ativUrl =
      tipo_pagina === 'minha' ? `Gerente/atividades` : `atividades/usuario/:id`;
    const id = tipo_pagina === 'minha' ? undefined : corretor_id;
    api
      .get(ativUrl, {
        params: { pagina: currentAtividadesPage + 1, quantidade: 10, id },
      })
      .then(res => {
        const {
          data: { items },
        } = res;
        if (items.length > 0) {
          setAtividades([...atividades, ...items]);
          setCurrentAtividadesPage(currentAtividadesPage + 1);
        }
        setLoaderActive(false);
      });
  }, [atividades, currentAtividadesPage, setLoaderActive]);

  const handleSubmitComentario = useCallback(
    (comentario: IComentario) => {
      api
        .post('Comentarios/create', {
          ...comentario,
          id_possuinte: Number(comentario.id_tarefa),
          id_tipo: 1,

          id_usuario: user.id,
          txt_usuario: user.nome,
        })
        .then(res => {
          const { sucesso } = res.data;
          if (sucesso) {
            setTarefas(prev => {
              const tarefaId = Number(comentario.id_tarefa);
              const updated = prev;
              const findIndex = prev.findIndex(
                findTarefa => findTarefa.id_tarefa === tarefaId,
              );
              if (findIndex >= 0) {
                updated[findIndex].comentarios.push({
                  ...comentario,
                  id_tipo: 1,

                  id_usuario: user.id,
                  txt_usuario: user.nome,
                });
              }
              return [...updated];
            });
          }
        });
    },
    [user],
  );

  const handleEditTarefa = useCallback(
    (tarefa: ITarefa) => {
      setEditingTarefa(tarefa);
      document?.getElementById('tarefa-container')?.scrollIntoView({
        behavior: 'smooth',
      });

      setShowEditTarefa(true);
    },
    [handleShowEditTarefa],
  );

  const handleDeleteTarefa = useCallback(
    id_tarefa => {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm('Tem certeza que deseja excluir esta tarefa?')) return;
      api
        .delete('Tarefa', {
          params: {
            id: id_tarefa,
          },
        })
        .then(response => {
          const { data } = response;
          if (data.sucesso) {
            if (editingTarefa.id_tarefa === Number(id_tarefa)) {
              setEditingTarefa({} as ITarefa);
            }
            setTarefas(prev => {
              const updatedTarefas = prev.filter(
                tarefa => tarefa.id_tarefa !== id_tarefa,
              );

              return [...updatedTarefas];
            });
          }
        });
    },
    [editingTarefa],
  );

  const generatePassword = () => {
    const alpha = 'abcdefghijklmnopqrstuvwxyz';
    const alphaUpper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const symbols = '!@#$%^&*_-+=';
    let password = '';
    for (let i = 1; i < 8; i++) {
      if (i <= 2) {
        password += alpha.charAt(Math.floor(Math.random() * alpha.length));
      }
      if (i > 2 && i <= 4) {
        password += alphaUpper.charAt(
          Math.floor(Math.random() * alphaUpper.length),
        );
      }
      if (i > 4 && i <= 6) {
        password += numbers.charAt(Math.floor(Math.random() * numbers.length));
      }
      if (i >= 6) {
        password += symbols.charAt(Math.floor(Math.random() * symbols.length));
      }
    }
    var shufflePass = password
      .split('')
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join('');
    return shufflePass;
  };

  const handleEditar = useCallback((id: string) => {
    history.push(`/dashboard/corretor/editar/${id}`);
  }, []);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const clickCancel = () => {
    setPopupOpen(false);
  };

  const clickOk = useCallback(() => {
    setPopupOpen(false);
    setLoaderActive(true);
    api
      .post('Gerente/reset-senha-gerente', {
        tx_senha: generatePassword(),
        tx_email: corretor?.email,
        tx_nome: corretor?.nome,
        id_gerente: user.id,
        id_corretor: corretor?.id,
        tipo: corretor?.tipo,
      })
      .then(res => {
        if(res.data.sucesso){
          toast(res.data.mensagem, {
            position: 'top-right',
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            type: 'success',
          });
          setPopupOpen(false);
          setLoaderActive(false);
        }else{
          toast(res.data.mensagem, {
            position: 'top-right',
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            type: 'error',
          });
          setPopupOpen(false);
          setLoaderActive(false);
        }
      })
      .catch(() => {
        toast('Desculpe aconteceu algum erro, tente novamente mais tarde', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          type: 'error',
        });
        setPopupOpen(false);
        setLoaderActive(false);
      });
  }, [corretor, user]);

  return (
    <Container>
      {popupOpen && corretor && (
        <div className="popupFade">
          <div className="popupContent">
            <div className="popupBox">
              <p>Tem certeza que quer resetar a senha de {corretor.nome}?</p>
              <div className="popupButtons">
                <button onClick={clickCancel} className="btn-popup">
                  cancelar
                </button>
                <button onClick={clickOk} className="btn-popup">
                  confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {corretor && (
        <>
          <Profile>
            <ProfileAvatar
              src={corretor.imagem || defaultImage}
              onError={(e: any) => {
                e.target.src = defaultImage;
              }}
            />
            <ProfileInfo>
              <strong>{corretor.nome}</strong>
              <p>{`CRECI: ${corretor.creci}`}</p>
            </ProfileInfo>
            <div className="ml-auto df">
            {tipo_pagina !== 'minha' && 
              ((corretor.tipo === 1 && user.idCargoSigavi !== 14) || (corretor.tipo === 2) || (corretor.tipo === 21)) 
              && (
              <TegraButton
              isAction
              onClick={() => handleEditar(corretor_id)}
              >
                Editar Parceiro
              </TegraButton>
            )}
            {tipo_pagina !== 'minha' && corretor.tipo !== 0 && (
              <TegraButton
              isAction
              onClick={() => openPopup()}
              >
                Resetar Senha
              </TegraButton>
            )}
            </div>
          </Profile>
          <TegraDivider color="#e6e6e6" axis="x" height={1} />
        </>
      )}
      <Content>
        <OptionsFiltroEOrdenar>
          <div className="filtrar" onClick={() => handleFiltroShow(true)}>
            Filtrar
            <FiltroIcon />
          </div>
          <div className="ordenar">
            Ordenar
            <OrdenarIcon />
          </div>
        </OptionsFiltroEOrdenar>
        <Options>
          <OptionsLeft>
            <TegraButton
              isAction
              isDisabled={pathname.includes('atividades')}
              onClick={(e: MouseEvent) =>
                handleShowEditTarefa(e, { is_new_tarefa: true })
              }
            >
              Adicionar tarefa
            </TegraButton>
            <TegraOptionsLinks
              path={path}
              defaultSelected={
                pathname.includes('atividades') ? 'atividades' : ''                                
              }
              options={tipo_pagina === 'minha' ? navOptions : navOptionsCorretor}
              url={url}
            /> 
          </OptionsLeft>
          <OptionsRight show={filtroShow}>
            <OptionsRightHeader className="filter-header">
              <h1>Filtrar</h1>
              <CloseFilterIcon onClick={() => handleFiltroShow(false)} />
            </OptionsRightHeader>
            <TegraDivider axis="x" color="#fff" />
            <Form
              initialData={{ status: 'pendentes', periodo: 'todas' }}
              ref={tarefasFiltroFormRef}
              onSubmit={handleTarefaFiltroSelect}
            >
              <TegraOptions
                formRef={tarefasFiltroFormRef}
                name="tipo"
                options={tipoOptions}
                setOptions={setTipoOptions}
                canDeselect
              />
              <TegraOptions
                formRef={tarefasFiltroFormRef}
                name="status"
                options={statusOptions}
                setOptions={setStatusOptions}
              />
              <TegraOptions
                formRef={tarefasFiltroFormRef}
                name="periodo"
                defaultValue="todas"
                options={periodoOptions}
                setOptions={setPeriodoOptions}
              />
            </Form>
            <OptionsRightFilterActionsContainer>
              <span>Limpar Filtros</span>
              <TegraButton isAction onClick={() => handleApplyFilter()}>
                Aplicar filtro
              </TegraButton>
            </OptionsRightFilterActionsContainer>
          </OptionsRight>
        </Options>
        <TegraNovaTarefa
          className={showEditTarefa ? 'show-edit' : 'hide-edit'}
          onSubmit={handleSubmitNovaTarefa}
          formDefaultValues={editingTarefa}
          setShowEditTarefa={setShowEditTarefa}
        />
        <Switch>
          <Route exact path={`${path}/dadoscorretor`}>
            <DadosCorretor dados={dadoscorretor} />
          </Route> 
          <Route exact path={`${path}/atividades`}>
            <>
              <Atividades
                // id={user_id}
                atividades={atividades}
              />
              {atividades.length === 0 && <>Sem atividades registradas</>}
              {currentAtividadesPage < totalPaginasAtividades && (
                <ListActions>
                  <TegraButton isAction onClick={handleLoadMoreAtividades}>
                    Carregar mais
                  </TegraButton>
                </ListActions>
              )}
            </>
          </Route>
          <Route exact path={`${path}`}>
            <>
              <TarefasList>
                {filteredTerefas.map(tarefa => (
                  <Tarefa
                    handleDeleteTarefa={handleDeleteTarefa}
                    editarTarefa={handleEditTarefa}
                    sendNovoComentario={handleSubmitComentario}
                    tarefa={tarefa}
                    reload={loadTarefas}
                  />
                ))}
              </TarefasList>
              {filteredTerefas.length === 0 && <>Sem tarefas registradas</>}
              {currentTarefasPage < totalPaginasTarefas && (
                <ListActions>
                  <TegraButton isAction onClick={handleLoadMoreTarefas}>
                    Carregar mais
                  </TegraButton>
                </ListActions>
              )}
            </>
          </Route>
        </Switch>
      </Content>
    </Container>
  );
};

export default TarefasAtividadesMain;
