import React from 'react';
import ProductMaterials from '../..';

const DescriptiveMemo: React.FC = () => {
  return (
    <ProductMaterials
      title="Memorial Descritivo"
      subtitle=""
      description="Os documentos com tudo o que está representado no projeto de cada um dos nossos empreendimentos."
      showColumnTitle={false}
      actions={['open', 'download']}
      apiUrl="Memorial"
      columns={[
        { label: 'Produto', field: 'tx_produto' },
        { label: 'Memorial', field: 'nome' },
      ]}
      anchorName="Memorial"
      onClickItem={() => localStorage.setItem('SCROLL', '#Memorial')}
    />
  );
};

export default DescriptiveMemo;
