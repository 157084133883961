import styled from 'styled-components';

export const TabsContainer = styled.div`
  .react-tabs {
    -webkit-tap-highlight-color: transparent;
    position: relative;

    &__tab-list {
      border-bottom: 1px solid #adadab;
      margin: 0 0 12px;
      padding: 0;
    }

    &__tab {
      & + li {
        margin-left: 35px;

        @media (max-width: 600px) {
          margin-left: 30px;
        }

        @media (max-width: 380px) {
          margin-left: 10px;
        }

        @media (max-width: 330px) {
          margin-left: 4px;
        }
      }

      a {
        text-decoration: none;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: left;
        color: #60605d;
      }
      display: inline-block;
      bottom: -1px;
      position: relative;
      list-style: none;
      padding: 10px 0px;
      cursor: pointer;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: left;
      color: #60605d;

      &--selected {
        background: #fff;
        border-bottom: 4px solid #ebb92d;
      }
    }

    &__tab-panel {
      display: none;

      &--selected {
        display: block;
      }
    }
  }
`;
