import styled from "styled-components";

import logoSioux from '../../assets/logo-sioux-b.svg';

export const TegraDetalhesContainer = styled.div`
    background-color: white;
`;

export const SignatureSioux = styled.div`
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;

    span {
        line-height: 10px;
        color: #adadab;
    }
    
    a {
        text-indent: -9999px;
        display: inline-flex;
        margin: 0 5px;
        width: 48px;
        height: 12px;
        background-color: #adadab;
        -webkit-mask-image: url(${logoSioux});
        mask-image: url(${logoSioux});
        align-items: center;
    }
`;