import React, { useState, useEffect } from 'react';

import { Container, Content } from './styles';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import PresencaLista from './PresencaLista';
import PresencaItem from './PresencaItem';

const EventosParcerias: React.FC = () => {
  const [detail, setDetail] = useState(false);
  const [item, setItem] = useState({});

  useEffect(() => {
    !detail && setItem({});
  }, [detail]);

  return (
    <Container>
      <TegraOpaqueHero
        title="Eventos Parcerias"
        description="Registre a presença dos corretores nos eventos"
      />
      <Content>
        {detail ? (
          <PresencaItem setDetail={setDetail} item={item}/>
        ) : (
          <PresencaLista setDetail={setDetail} setItem={setItem}  />
        )}
      </Content>
    </Container>
  );
};

export default EventosParcerias;
