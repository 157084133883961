import styled, { css } from 'styled-components';
import heroBackground from '../../../assets/tegra-opaque-hero-bg.png';
import colors from '../../../styles/colors';

interface ContainerProps {
  hasCategory: boolean;
}

export const Container = styled.header<ContainerProps>`
  background: url(${heroBackground});
  background-size: cover;
  width: 100%;
  height: 308.7px;

  @media (max-width : 800px){
    height: 500px;
  }

  padding: 62px 0px 190px;
  padding-left: calc(calc(calc(100vw - calc(1140px + 3.5rem)) / 2) + 2.25rem);
  margin-bottom: 40px;

  @media (max-width: 600px) {
    padding-left: 33px;

    ${props =>
      props.hasCategory &&
      css`
        padding-top: 30px;
      `}
  }
`;

interface HeroContentProps {
  hasCategory: boolean;
}

export const HeroContent = styled.div<HeroContentProps>`
  display: flex;
  position: relative;

  ${props =>
    props.hasCategory &&
    css`
      .divider {
        position: absolute;
        bottom: -11px;
      }
    `}

  .info {
    margin-left: 26px;

    @media (max-width: 600px) {
      max-width: 100%;
      margin-left: 0;

      .divider {
        display: none;
      }
    }
  }

  @media (max-width: 600px) {
    .divider {
      display: none;
    }
  }

  a {
    color: #ffffff;
    padding: 10px 15px;
    background-color: #ebb92d;
    text-decoration: none;
  }

  .infos-adicionais {
    display: flex;
    padding-bottom: 20px;

    @media (max-width : 1100px){
      display: block;
    }

    @media (max-width: 800px) {
      position: absolute;
      margin-top: 150px;
      margin-left: 36px;
    }

   

    .item-info {
      display: flex;
      align-items: center;
      margin-right: 15px;
      font-size: 12px;

      @media (max-width : 1100px){
        margin-bottom: 15px;

        .metragem{
          width: 100%;
        }
    
        .dorm{
            width: 100%;
        }
    
        .vaga{
            width: 100%;
        }
    
        .dinheiro{
          width: 30.0000%;
          flex: 0 0 30.0000%;
          max-width: 30.0000%;
        }
      }

      img {
        margin-right: 15px;
      }

      p {
        font-weight: 600;
        color: #60605d;
      }
    }
  }
`;

export const Title = styled.h1`
  max-width: 600px;
  padding-right: 20px;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.5px;
  text-align: left;
  color: #60605d;
  word-wrap: break-word;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

export const Subtitle = styled.h2`
  font-size: 24px;
  color: ${colors.featuredBlack};
  font-weight: 500;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #60605d;
  width: 500px;
  max-width: 100%;
  margin-bottom: 25px;

  strong {
    font-weight: bold;
    font-size: 14px;
  }

  @media (max-width: 600px) {
    width: 305px;
  }
`;

export const StatusObra = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #60605d;
  width: 500px;
  max-width: 100%;

  strong {
    font-weight: bold;
    font-size: 14px;
  }

  @media (max-width: 600px) {
    width: 305px;
  }
`;

export const SubDescription = styled.p`
  font-weight: normal;
  color: ${colors.inputBorder};
  font-size: 12px;
  margin-top: 0.3rem;
`

export const Category = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #60605d;
  margin-bottom: 5px;
`;
