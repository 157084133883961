import styled from "styled-components";
import colors from "../../../styles/colors";
import { RankingTableItem, RankingTableImage } from "./RankingRow/styles";

interface IRankingTableItemProps {
    selfRow?: boolean;
}

export const RankingTableContainer = styled.table`
    border-collapse: collapse;
    margin: 0 -2.25rem;
    width: calc(100% + 4.5rem);
    border-bottom: 2.5rem;
    margin-top: 50px;
`;

export const RankingTableHeader = styled.th<IRankingTableItemProps>`
    font-size: 13px;
    font-weight: bold;
    color: ${colors.primaryColor};
    padding-bottom : ${props => props.selfRow ? '30px;' : '0.6rem'};
    line-height: 1;
    text-align: left;
`;

export const RankingTableHeaderContainer = styled.thead`
    display: none;
    @media(min-width: 992px) {
        display: table-header-group;
    }
`;

export const RankingTableBody = styled.tbody`
    ${RankingTableItem}{
        &:nth-of-type(1){
            border-left: 12px solid ${colors.rankingFirst};
            padding: 0;
            ${RankingTableImage}{
                background-color: ${colors.rankingFirst};
                width: 50px;
                height: 50px;
            }
        }
        &:nth-of-type(2){
            border-left: 12px solid ${colors.rankingSecond};
            ${RankingTableImage}{
                background-color: ${colors.rankingSecond};
                width: 50px;
                height: 50px;
            }
        }
        &:nth-of-type(3){
            border-left: 12px solid ${colors.rankingThird};
            ${RankingTableImage}{
                background-color: ${colors.rankingThird};
                width: 50px;
                height: 50px;
            }
        }
    }
`