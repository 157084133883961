export default function getFormattedMoney(value: number): string {
  if (!value) {
    return '';
  }
  const formatter = new Intl.NumberFormat('pr-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });

  const formattedMoney = formatter.format(value);

  return formattedMoney;
}
