import styled from 'styled-components';

export const Container = styled.div``;

export const TarefaItem = styled.div`
  &:not(:first-child) {
    margin-top: 80px;
  }
`;

export const TarefaItemDescription = styled.div`
  margin-top: 35px;
  padding-bottom: 36px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #60605d;

  border-bottom: 1px solid #707070;
`;

export const TarefaItemFile = styled.div`
  margin: 15px auto;
`;
export const ComentariosAction = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const TarefaItemComentarios = styled.div`

  h3 {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;

    text-align: left;

    font-size: 14px;
    letter-spacing: 0.14px;
    color: #60605d;

    span {
      font-size: 12px;
      color: #adadab;
      line-height: 1.5;
      letter-spacing: 0.12px;
    }
  }
`;

export const ComentariosBox = styled.div`
  padding: 27px 31px;
  border: solid 1px #e6e6e6;
`;

export const TarefaItemComentariosList = styled.ul`
  list-style: none;

  li {
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #e6e6e6;
  }
`;

export const ComentarioContent = styled.div`
  margin-top: 4px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #60605d;
`;

export const ComentarioHeader = styled.div`
  display: flex;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.12px;
  text-align: left;

  span {
    color: #adadab;
  }

  time,
  strong {
    font-weight: bold;
  }

  time.data {
    padding-right: 5px;
    border-right: 1px solid #ebb92d;
  }

  strong.autor {
    margin-left: 5px;
    margin-right: 5px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    time.data {
      padding-right: 0px;
      border-right: none;
    }

    strong.autor {
      margin-top: 5px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
`;

export const TarefaItemActions = styled.div`
  display: flex;

  .divider {
    margin-left: 22px;
  }

  @media (max-width: 600px) {
    margin-top: 25px;
  }

  button {
    cursor: pointer;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-left: 22px;
    }
    svg {
      margin-left: 10px;
      width: 10px;
      height: 10px;
      path {
        fill: #60605d;
      }
    }
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.12px;
    text-align: left;
    color: #60605d;
    text-decoration: underline;
  }

  form {
    display: flex;
    margin-left: 22px;
  }
`;

export const TarefaItemUltimaAtualizacao = styled.div`
  height: 23px;
  background-color: #60605d;
  padding: 4px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.12px;
  text-align: left;
  color: #ffffff;
`;

export const TarefaItemInfo = styled.div`
  display: flex;
  align-items: center;

  .left {
    display: flex;
    align-items: center;
  }

  .left svg {
    margin-right: 10px;
    path {
      fill: #ebb92d;
    }
  }

  span {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #adadab;
    display: block;

    &.restante {
      display: inline;
      @media (max-width: 768px) {
        display: block;
      }
    }

    &.prazo {
      margin-right: 40px;
    }

    &.nome {
      margin-left: 40px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 12px;
      }
    }

    @media (max-width: 600px) {
      &.nome {
        margin-left: 0px;
        margin-top: 32px;
      }
    }
  }

  strong {
    color: #60605d;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .left svg {
      margin-right: 20px;
    }
    .divider {
      display: none;
    }
  }
`;

export const TarefaItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 26px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
