import React from 'react';
import { Container } from './styles';

interface ITegraBannerEquipeProps {
  gerenteEquipe: string;
}

const TegraBannerEquipe: React.FC<ITegraBannerEquipeProps> = ({
  gerenteEquipe,
}) => (
  <Container>
    <div className="banner">
      <div className="bannerText">Equipe {gerenteEquipe}</div>
    </div>
    
  </Container>
);

export default TegraBannerEquipe;
