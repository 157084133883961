import styled, { css, keyframes } from 'styled-components';
import produtoFaixaAmarela from '../../assets/tegra-faixa-amarela-produto.png';

export const Container = styled.div`
  margin-left: 15px;

  @media (max-width: 1200px) {
    margin-left: 0px;
  }

`;

export const ProdutosContainer = styled.div`
  width: 100%;
  display: grid;
  /* grid-gap: 41px 30px;
  padding: 51px; */
  grid-gap: 30px 30px;
  grid-template-columns: repeat(auto-fit, minmax(332px, 380px));
  //justify-content: start;
  justify-content: center;
  //padding-left: 50px;
  //padding-right: 50px;



  @media (max-width: 1024px) {
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, 100%);
    //justify-content: start;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

interface ProdutoContainerProps {
  bg: any;
}

// const zoom_in = keyframes`
//   from {
//     background-size: cover;
//   }
//   to {
//     background-size: 120%;
//   }
// `;

export const ProdutoContainer = styled.div`
  width: 100%;
  position: relative;
  height: 530px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  cursor: pointer;

  .produto-destaque {
    background-color: #EBB92D;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    padding-left: 30px;
    font-weight: bold;
    span {
      display: block;
      margin-top: 7px;
    }
    & > img {
      max-width: 18px;
      margin-right: 12px;
      margin-top: 6px;
      pointer-events: none;
    }
    &:after{
      content: '';
      background-color: #EBB92D;
      width: 110%;
      height: 30px;
      transform: rotate(-2deg);
      left: -10px;
      position: absolute;
      bottom: -8px;
      z-index: -1;
    }
  }

  @media (max-width: 375px) {
    width: 100%;
  }

  & > img {
    max-width: 100%;
    height: auto;
    min-height: 100%;
    display: block;
    position: absolute;
    transition: 0.3s all ease-out;
    object-fit: cover;
    object-position: center;
    //object-position: left;

    @media (max-width: 425px) {
      left: 10px;
    }
  }

  img:hover {
    transform: scale(1.2);
  }

  p.descriptionBelowTitle {
    display: none;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #e6e6e6;
    padding: 10px 20px 12px;

    @media (max-width: 600px) {
      display: block;
    }
  }
`;

export const Info = styled.div`
  height: 169px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    margin-top: 15px;
    height: 80px;
    padding: 28px 30px;
  }
`;

export const ProdutoInfoContainer = styled.div`
  padding: 45.7px 18px 15.5px 42px;
  background-color: rgba(96, 96, 93, 0.7);
  position: relative;
  .divider {
    margin-right: 19px;
    margin-left: 19px;
    height: 80px;
  }

  @media (max-width: 600px) {
    padding: 0px 0px 30px 0px;
    .divider {
      height: 60px;
    }
  }

  @media (min-width: 601px) {
    display: flex;
    align-items: center;
  }

  &:before {
    content: '';
    height: 26px;
    position: absolute;
    left: 0;
    top: -26px;
    width: 100%;
    bottom: 60px;
    background: url(${produtoFaixaAmarela});
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const ProdutoFooterContainer = styled.div`
  height: 60px;
  background-color: #60605d;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* bottom: 0; */
  z-index: 1;

  padding: 22px 33px;

  a {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    text-decoration: underline;
  }
`;

export const ProdutoLocalidade = styled.div`
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #fcb813;

  h3 {
    font-size: 24px;
    font-weight: normal;

    @media (max-width: 600px) {
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
    }
  }

  strong {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const ProdutoDescription = styled.div`
  h2 {
    font-size: 22px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #e6e6e6;

    @media (max-width: 600px) {
      font-weight: 300;
    }
  }

  p {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    text-align: left;
    color: #e6e6e6;

    @media (max-width: 600px) {
      display: none;
    }
  }
`;
