import React from 'react';

import Collapsible from 'react-collapsible';

import { Container } from './styles';

interface TegraExpandingContentProps {
  title: any;
}

const TegraExpandingContent: React.FC<TegraExpandingContentProps> = ({
  title,
  children,
}) => {
  return (
    <Container>
      <Collapsible trigger={title}>{children}</Collapsible>
    </Container>

    // <Container {...rest}>
    //   <CustomAccordionItem uuid={title}>
    //     <CustomAccordionItemHeading>
    //       <CustomAccordionItemButton>
    //         <h2>{title}</h2>
    //         <button type="button">
    //           <img src={arrowDownIcon} alt="Seta" />
    //         </button>
    //       </CustomAccordionItemButton>
    //     </CustomAccordionItemHeading>
    //     <CustomAccordionItemPanel>{children}</CustomAccordionItemPanel>
    //   </CustomAccordionItem>
    // </Container>
  );
};

export default TegraExpandingContent;
