import styled, { css } from 'styled-components';

export const Container = styled.div`
  a {
    text-decoration: none;
  }
`;

export const OptionBox = styled.div`
  display: flex;
  padding: 8px;
  height: 50px;
  background-color: #e6e6e6;

  @media (max-width: 600px) {
    justify-content: center;
    a {
      flex: 1;
    }
  }
`;

interface OptionProps {
  selected?: boolean;
}

export const Option = styled.div<OptionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
  min-width: 50px;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.12px;
  text-align: right;
  color: #60605d;

  svg path {
    fill: #adadab;
  }

  ${props =>
    props.selected &&
    css`
      background-color: #ebb92d;
      color: #272723;

      svg path {
        fill: #60605d;
      }
    `}
`;
