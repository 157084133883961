import React from 'react';
import ProductMaterials from '../..';

const IMInfo: React.FC = () => {
  return (
    <ProductMaterials
      title="Informações de IM"
      subtitle=""
      description="Materiais de produtos concorrentes (inteligência de mercado) que podem te ajudar a embasar melhor os seus argumentos de venda."
      showColumnTitle={false}
      actions={['open', 'download']}
      apiUrl="InformacoesIM"
      columns={[
        { label: 'Produto', field: 'tx_produto' },
        { label: 'Treinamento', field: 'nome' },
      ]}
      anchorName="Informacoes"
      onClickItem={() => localStorage.setItem('SCROLL', '#Informacoes')}
    />
  );
};

export default IMInfo;
