import { AccountInfo } from '@azure/msal-browser';
import React, { createContext, useCallback, useState, useContext } from 'react';
import AzureAuthenticationContext from '../azure/azure-authentication-context';
import api from '../services/api';
import { processLogin, setToken } from '../utils/login';

export interface User {
  id: number;
  matricula: string;
  nome: string;
  apelido: string;
  isPrimeiroAcesso: boolean;
  idRegional: number;
  idEstado: number;
  creci: string;
  deletarTarefas: boolean;
  exportarCorretores: boolean;
  minhaPagina: boolean;
  foto: string;
  tipo: number;
  idCargoSigavi: number;
  id_tipoParceiro?: number;
  isGerente: boolean;
  isSuperintendente: boolean;
  isAdmin: boolean;
  isRH: boolean;
  isEstagiario: boolean;
  isLab: boolean;
  is_senhaResetadaAdm: number;
  documento: string;
  acessos:{
    premiacaoMetas: boolean;
    calendarioTrabalho: boolean;
    relatorios: boolean;
    ranking: boolean;
    contatos: boolean;
    sugestoes: boolean;
    perguntasFrequentes: boolean;
    pagadoria: boolean;
    hypnobox: boolean;
    produtos: boolean;
    minhaPagina: boolean;
    presencaPlantao: boolean;
    registroVisita: boolean;
    evento:boolean;
    eventosParcerias:boolean;
    lideranca: boolean;
    espacoGerente: boolean;
    academia: boolean;
  },
  termoAceiteConduta: boolean;
  termoAceiteEtica: boolean;
  termoAceiteImagem: boolean;
  termoAceiteLgpd: boolean;
  termoAceiteMascara: boolean;
  termoAceiteAbrainc: boolean;
  termoAceiteResponsabilidade : boolean;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  documento: string;
  senha: string;
  from_parcerias: boolean;
}

interface SignInCredentialsMessage {
  documento: string;
  senha: string;
  from_parcerias: boolean;
  acesso_whats: boolean;
}

interface SignInAdmCredentials {
  regional: string;
  token: string;
  uf: string;
}

interface AuthContextData {
  user: User;
  token: string;
  authenticatedAzureAD: boolean;
  signInRegister(token: string): Promise<void>;
  signIn(credentials: SignInCredentialsMessage): Promise<void>;
  signInAdm(credentials: SignInAdmCredentials): Promise<void>;
  signInAzureAD(): Promise<any>;
  signOut(): Promise<void>;

  updateUser(user: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);
//const [loginErro, setloginErro] = useState(false);

const AuthProvider: React.FC = ({ children }) => {
  const [authenticatedAzureAD, setAuthenticatedAzureAD] = useState<boolean>(false);
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Tegra:token');
    const user = localStorage.getItem('@Tegra:user');

    
    if (token && user) {      
      const userJson = JSON.parse(user)

      if (userJson.acessos === undefined){
        return {} as AuthState;
      }

      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });
  
  const signInRegister = useCallback(async (token: string) => {
    api.defaults.headers.authorization = `Bearer ${token}`;
    const response = await api.post('Login/relogin');
    const { access_token, data: user } = response.data;
    const usr = processLogin(user, access_token);
    setData({ token, user: usr });
  }, []);

  const signInAdm = useCallback(async ({ regional, token, uf }) => {
    const params = new URLSearchParams();
    params.append('idRegional', regional);
    params.append('token', token);
    params.append('uf', uf);

    const response = await api.post('Login/Adm', params);
    const { access_token, data: user } = response.data;
    const usr = processLogin(user, access_token);

    setData({ token, user: usr });
  }, []);

  const signInAzureAD = useCallback(async () => {
    const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext();
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    const msie11 = ua.indexOf("Trident/");
    const isIE = msie > 0 || msie11 > 0;
    const typeName = "loginPopup";

    const logInType = isIE ? "loginRedirect" : typeName;

    // Azure Login
    return await authenticationModule.login(
      logInType,
      async (user: AccountInfo, token: string) => {

        setAuthenticatedAzureAD(user?.name ? true : false);

        /* verificar retorno  setAuthenticatedAzureAD*/
        setToken(token);

        api.defaults.headers.authorization = `Bearer ${token}`;

        
        const response = await api.post('Login/loginAD');
            
        const { data: loggedUser } = response.data;
        const usr = processLogin(loggedUser, token);
        setData({ token, user: usr });

      },
      async (error) => {
        return error;
        //alert(error);
      });
  }, []);

  const signIn = useCallback(async ({ documento, senha, from_parcerias, acesso_whats }) => {
    const params = new URLSearchParams();
    params.append('tx_documento', documento);
    params.append('tx_senha', senha);
    params.append('from_parcerias', from_parcerias);
    params.append('acesso_whats', acesso_whats);

    const response = await api.post('Login', params);
    const { access_token: token, data: user } = response.data;
    const usr = processLogin(user, token);

    setData({ token, user: usr });
  }, []);

  const signOut = useCallback(async () => {
    localStorage.removeItem('@Tegra:token');
    localStorage.removeItem('@Tegra:user');
    sessionStorage.removeItem('@Tegra:user');
    localStorage.removeItem('modalExpertegra');
    setData({} as AuthState);
    const response = await api.post('Login/Logout');
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      processLogin(user);

      setData(prev => ({
        token: prev.token,
        user,
      }));
    },
    [setData],
  );

  return (        
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        authenticatedAzureAD,
        signInRegister,
        signInAdm,
        signInAzureAD,
        token: data.token,
        signOut,
        updateUser,
      }}
    >
     {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be within an AuthProvider component');
  }

  return context;
}

export { AuthProvider, useAuth };
