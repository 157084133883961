import React from 'react';
import {
  RankingTableItem,
  RankingColumnHeader,
  RankingTableImage,
  RankingTablePosition,
  RankingTableDataWrapper,
  RankingTableColumn,
  RankingTableTitle,
  RankingTableData,
  EquipeLink,
  RankingTableColumnDesktop,
  RankingDesktopImage,
} from './styles';
import { IRanking, ILink } from '../..';
import defaultImage from '../../../../assets/avatar.svg';

interface IRankingRowProps {
  rankingItem: IRanking;
  position: number;
  selfRow?: boolean;
}

const RankingRow: React.FC<IRankingRowProps> = ({
  rankingItem,
  position,
  selfRow = false,
}) => {
  return (
    <RankingTableItem selfRow={selfRow}>
      <RankingColumnHeader>
        <RankingTableImage
          src={((rankingItem.image as string) as string) || defaultImage}
          onError={(e: any) => {
            e.target.src = defaultImage;
          }}
        />
        <RankingTablePosition>{position}º</RankingTablePosition>
      </RankingColumnHeader>
      <RankingTableDataWrapper>
        {Object.keys(rankingItem).map(key => {
          if (key !== 'image') {
            return (
              <RankingTableColumn>
                <RankingTableTitle>{key}</RankingTableTitle>
                <RankingTableData>
                  {typeof rankingItem[key] === 'string' ||
                  typeof rankingItem[key] === 'number' ? (
                    rankingItem[key]
                  ) : (
                    <EquipeLink to={(rankingItem[key] as ILink).link}>
                      {(rankingItem[key] as ILink).label}
                    </EquipeLink>
                  )}
                </RankingTableData>
              </RankingTableColumn>
            );
          }
        })}
      </RankingTableDataWrapper>
      {Object.keys(rankingItem).map((key, itemIndex) => {
        if (key !== 'image') {
          return (
            <RankingTableColumnDesktop>
              <RankingTableTitle>{key}</RankingTableTitle>
              <RankingTableData>
                {itemIndex === 1 ? (
                  <RankingDesktopImage
                    src={(rankingItem.image as string) || defaultImage}
                    onError={(e: any) => {
                      e.target.src = defaultImage;
                    }}
                  />
                ) : null}
                {typeof rankingItem[key] === 'string' ||
                typeof rankingItem[key] === 'number' ? (
                  rankingItem[key]
                ) : (
                  <EquipeLink to={(rankingItem[key] as ILink).link}>
                    {(rankingItem[key] as ILink).label}
                  </EquipeLink>
                )}
              </RankingTableData>
            </RankingTableColumnDesktop>
          );
        }
      })}
    </RankingTableItem>
  );
};

export default RankingRow;
