import styled from "styled-components";
import colors from "../../../../styles/colors";


export const HamburgerBar = styled.div`
    background-color: ${colors.primaryColor};
    height: 2px;
    width: 100%;
    margin: 5px 0;
`;

export const NavHamburguerMenuContainer = styled.div`
    width: 18px;
    cursor: pointer;
    @media(min-width: 992px) {
        display: none;
    }
`;