import styled from 'styled-components';

export const Container = styled.div`
  min-height: 509px;
  width: 100%;

  thead th {
    font-size: 8px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.08px;
    text-align: center;
    color: #60605d;
  }
  tbody {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 0.1px;
    text-align: center;
    color: #fff;

    th {
      width: 30px;
      height: 32px;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: 0.1px;
      text-align: center;
      color: #60605d;
    }
  }
`;

export const Table = styled.table`
  margin-bottom: 16px;
`;

interface StatusType {
  [key: string]: string;
}

const statuses: StatusType = {
  vendida: '#1a6793',
  estoque: '#FFF',
  disponível: '#FFF',
  reservada: '#e07d29',
  foradevenda: '#adadab',
  'contrato em processo': '#ebb92c',
  contratoemprocesso: '#ebb92c',
  'contrato assinado': '#67b9d9',
  contratoassinado: '#67b9d9',
  permuta: '#000000',
};

const statuses_text: StatusType = {
  vendida: '#fff',
  estoque: '#272723',
  disponivel: '#272723',
  reservada: '#fff',
  foradevenda: '#fff',
  'contrato em processo': '#272723',
  contratoemprocesso: '#272723',
  'contrato assinado': '#272723',
  contratoassinado: '#272723',
  permuta: '#FFFFFF',
};

interface TableCellProps {
  status: string;
}

export const TableCell = styled.div<TableCellProps>`
  background-color: ${props => statuses[props.status]};
  display: flex;
  padding: 1px;
  min-height: 32px;
  border: solid 1px #e6e6e6;
  color: ${props => statuses_text[props.status]};
  x: ${props => props.status};

  div.info {
    margin: 3px 3px 3px 5px;
  }
  span {
    display: block;
    text-align: left;
  }
`;

export const TableCellIcons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space_between;
  /* margin-right: 6px; */
`;
