import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding-bottom: 120px;
`;

export const Content = styled.div`
  max-width: 1120px;
  width: 100%;
  margin: 41px auto;
  padding-left: 36px;
  padding-right: 36px;
`;
