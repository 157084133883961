/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  RefObject,
} from 'react';

import { useField, FormHandles } from '@unform/core';
import { Container, StyledCheckbox, Icon } from './styles';
import checkItem from '../../assets/tegra-icon-check.svg';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  defaultChecked?: boolean;
  name: string;
  label: string;
  formRef: RefObject<FormHandles>;
  handleOnChange?: any;
}

const TegraCheckbox: React.FC<InputProps> = ({
  name,
  label,
  disabled = false,
  style,
  checked,
  handleOnChange,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  return (
    <Container style={style}>
      <label>
        <input
          checked={checked}
          ref={inputRef}
          disabled={disabled}
          name={fieldName}
          id={name}
          type="checkbox"
          onChange={handleOnChange}
          {...rest}
        />
        <StyledCheckbox checked={checked || false} defaultChecked={false}>
          <Icon src={checkItem} />
        </StyledCheckbox>
        <span>{label}</span>
      </label>
    </Container>
  );
};

export default TegraCheckbox;
