import styled, { keyframes } from 'styled-components';
import arrowDownIcon from '../../assets/tegra-arrow-down-icon.svg';

export const Container = styled.div`
  .Collapsible__trigger {
    position: relative;
    h3 {
      position: relative;
      cursor: pointer;
      padding: 10px 0;
    }
    h3:after {
      content: url(${arrowDownIcon});
      position: absolute;
      width: 22px;
      padding: 10px 0;
      height: 11px;
      right: 10px;
      top: 5px;
      display: block;
      transition: transform 300ms;
    }

    &.is-open {
      h3:after {
        transform: rotateZ(180deg);
      }
    }
  }
`;
