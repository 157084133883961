import React, { useState, useEffect, useCallback } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import {
  Container,
  BlogItem,
  BlogItemThumbnail,
  BlogItemInfo,
  OutrosDestaques,
  OutrosDestaquesItem,
  OutrosDestaquesItemActions,
  ContainerEvento,
  DataEvento,
} from './styles';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import api from '../../services/api';
import TegraButtonExtra from '../TegraButtonExtra';
import baixarIcon from '../../assets/tegra-icon-baixar.svg';
import claraThumb from '../../assets/clara-thumb.jpg';
import facebookIcon from '../../assets/facebook_icon.svg';
import instagramIcon from '../../assets/instagram_icon.svg';
import linkedinIcon from '../../assets/linkedin_icon.svg';
import youtubeIcon from '../../assets/youtube_icon.svg';
import iconCalendar from '../../assets/calendar-image.png';
import { formatDiagnosticsWithColorAndContext } from 'typescript';
interface IBlogItem {
  imagem?: any;
  pdf?: any;
  pdfnome?: string;
  titulo: string;
  subtitulo: string;
  conteudo: string;
  link: string;
  data: string;
  id: string;
  tipo: number;
  assinadoClara: boolean;
}

interface IDestaque {
  imagem?: any;
  titulo: string;
  categoria: | 'Calendário de Eventos';
  link: string;
  data: string;
  id: number;
  id_tipo: number;
  chamada: string;
}

interface ITegraBlogDetailsProps {
  blogItem: IBlogItem;
  destaques?: IDestaque[];
}

interface ItemDestaque {
  id_novidade: number;
  id_tipo: number;
  tx_titulo: string;
  tx_subtitulo: string;
  tx_descricao: string;
  tx_chamada: string;
  data_inicio: string;
  nu_estado: number;
  nu_publico: number;
  tx_thumb: number;
  ic_assinadoClara: boolean;
}

interface DestaqueResponse {
  items: ItemDestaque[];
}

const TegraBlogDetailsCalendario: React.FC<ITegraBlogDetailsProps> = ({
  // destaques,
  blogItem,
}) => {
  const [destaques, setDestaques] = useState<IDestaque[]>([]);
  const NomeDoPdf = blogItem.pdfnome ? blogItem.pdfnome : blogItem.titulo;
  const history = useHistory();
  const { path, url } = useRouteMatch();

  async function loadDestaque(
    endpoint: 'CalendarioTrabalho',
    slug:
      | 'calendario-de-eventos',
  ): Promise<void> {
    try {
      const {
        data: { items },
      } = await api.get<DestaqueResponse>('CalendarioTrabalho/:proximoseventos', {
        params: { pagina: 1, quantidade: 5 },
      });
      let newDestaque: IDestaque[] = [];
      items.map(mapDestaque => {
        var idNovidade = mapDestaque.id_novidade.toString();
        if (idNovidade === blogItem.id) {
          return;
        } else {
          newDestaque.push({
            titulo: mapDestaque.tx_titulo,
            id: mapDestaque.id_novidade,
            link: `/dashboard/${slug}/${mapDestaque.id_novidade}`,
            id_tipo: mapDestaque.id_tipo,
            imagem: mapDestaque.tx_thumb,
            categoria: 'Calendário de Eventos',
            data: mapDestaque.data_inicio,
            chamada: mapDestaque.tx_chamada,
          });
        }
      });
      setDestaques(newDestaque);
    } catch {}
  }

  useEffect(() => {
    setDestaques([]);
    loadDestaque('CalendarioTrabalho', 'calendario-de-eventos');
  }, [blogItem]);

  const downloadAs = (url: string, name: string) => {
    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
      })
      .then((response: any) => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = `${name.replace(/\s/g, '')}.pdf`;
        a.click();
      })
      .catch((err: any) => {
        console.log('error', err);
      });
  };

  const dataFormatada = ((date: string) => { 
    if(date) {
      const dia = date.substring(0, 2)
      let mes = date.substring(3, 5)
      const ano = date.substring(6)

      switch (mes) {
        case '01':
          mes = 'jan.'
          break;
        case '02':
          mes = 'fev.'
          break;
        case '03':
          mes = 'mar.'
          break;
        case '04':
          mes = 'abr.'
          break;
        case '05':
          mes = 'mai.'
          break;
        case '06':
          mes = 'jun.'
          break;
        case '07':
          mes = 'jul.'
          break;
        case '08':
          mes = 'ago.'
          break;
        case '09':
          mes = 'set.'
          break;
        case '10':
          mes = 'out.'
          break;
        case '11':
          mes = 'nov.'
          break;
        case '12':
          mes = 'dez.'
          break;
      }
      return `${dia} de ${mes} de ${ano}`
    }

    return date
  });

  return (
    <Container>
      <ScrollToTopOnMount />
      <BlogItem>
        <BlogItemInfo>
          { blogItem.assinadoClara && (
            <div className="containerClara">
              <img src={claraThumb} alt="Clara Thumb" />
              <div>
                <p>Por: <strong>Clara</strong></p>
                <time>{dataFormatada(blogItem.data)} em <a onClick={() => history.push(`/dashboard/calendario-de-eventos`)}>Calendário de Eventos</a></time>
              </div>
            </div>
          )}
          { !blogItem.assinadoClara && (
            <time>{dataFormatada(blogItem.data)} em <a onClick={() => history.push(`/dashboard/calendario-de-eventos`)}>Calendário de Eventos</a></time>
          )}
          <h2>{blogItem.titulo}</h2>
          <strong>{blogItem.subtitulo}</strong>
          {blogItem.imagem && (
            <BlogItemThumbnail src={blogItem.imagem} alt="Imagem do post" />
          )}
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: blogItem.conteudo }}
          />
          {blogItem.pdf && (
            <div className="downloadArea">
              <TegraButtonExtra
                icon={baixarIcon}
                isAction
                onClick={() => downloadAs(blogItem.pdf, NomeDoPdf)}
              >
                Download
              </TegraButtonExtra>
              <p>PDF: {NomeDoPdf}</p>
            </div>
          )}
          { blogItem.assinadoClara && (
            <div className='bioClara'>
              <img className='imgClara' src={claraThumb} alt="Clara Thumb" />
              <div>
                <h2>CLARA</h2>
                <p>Olá, eu sou a Clara, a assistente virtual da Tegra.
                <br></br>
                Cheguei para humanizar a comunicação com nossos clientes, 
                colaboradores e parceiros.
                <br></br>
                Você irá me encontrar por aqui e em outros canais digitais, 
                compartilhando novidades e conteúdos relevantes, além de 
                ajudar com informações sobre nossos empreendimentos.
                </p>
                <div className='icons'>
                  <a href="https://pt-br.facebook.com/tegraincorporadora/" target='_blank'><img src={facebookIcon} alt="Icone Facebook"/></a>
                  <a href="https://www.instagram.com/tegraincorporadora/" target='_blank'><img src={instagramIcon} alt="Icone Instagram"/></a>
                  <a href="https://www.youtube.com/c/TegraIncorporadora" target='_blank'><img src={youtubeIcon} alt="Icone Youtube"/></a>
                  <a href="https://www.linkedin.com/company/tegraincorporadora/" target='_blank'><img src={linkedinIcon} alt="Icone Linkedin"/></a>
                </div>
              </div>
            </div>
          )}
        
        </BlogItemInfo>
      </BlogItem>
      <OutrosDestaques>
        {destaques.length !== 0 ? (
          <>
            <h3>Próximos</h3>
            <div>
              {destaques.map(destaque => (
                <React.Fragment>
                  {destaque !== undefined && (
                    <ContainerEvento>
                        <DataEvento style={{ backgroundImage: `url(${iconCalendar})`, backgroundRepeat: 'no-repeat', backgroundSize: '80px 70px' }}>
                          <div className='mesEvento'>
                            <p>{moment(destaque.data.substring(6,10) +'-'+
                                      destaque.data.substring(3,5) +'-'+
                                      destaque.data.substring(0,2)).format('MMM').toUpperCase()}
                            </p>
                          </div>
                          <div className='diaEvento'>
                            <p>{destaque.data.substring(0,2)}</p>
                          </div>
                        </DataEvento>
                        <OutrosDestaquesItem>
                          {/*destaque.imagem && (
                            <Link to={destaque.link}>
                              <img
                                src={destaque.imagem}
                                alt={`Imagem do ${destaque.titulo}`}
                              />
                            </Link>
                          )*/}
                          <Link to={destaque.link}>
                            <strong>{destaque.titulo}</strong>
                            <p style={{maxWidth: '180px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{destaque.chamada}</p>
                          </Link>
                          <OutrosDestaquesItemActions>
                            <Link to={destaque.link}>Ver mais</Link>
                          </OutrosDestaquesItemActions>
                        </OutrosDestaquesItem>
                    </ContainerEvento>

                  )}
                </React.Fragment>
              ))}
            </div>
          </>
        ) : (
          false
        )}
      </OutrosDestaques>
    </Container>
  );
};

export default TegraBlogDetailsCalendario;
