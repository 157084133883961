import { parseISO, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

export default function getFormattedTime(date: string): string {
  const parsedDate = parseISO(date);
  const formattedTime = format(parsedDate, 'HH:ii', {
    locale: ptBR,
  });
  return formattedTime;
}
