import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding-bottom: 120px;
  position: relative;

  .texto-legal{
    font-size: 12px;
    padding: 0px 30px;
    color: #60605D;

    p, span, strong 
    {
      padding-top: 45px;
      color: #60605D;
    }
  }

  .banco-financiador {
    padding: 30px;

    img {
      width: 125px;
      margin: 10px 0px 0px;
    }
    
  }
`;

export const PageAnchor = styled.a``;

export const Content = styled.div`
  margin-top: 40px;
  max-width: 1280px;
  margin: 40px auto;

  position: relative;

  & > div {
    /* padding: 0 51px; */

    &.photoGallery {
      @media (max-width: 768px) {
        padding: 0;
      }

      &.planta .coluna {
        margin-bottom: 40px;
      }
    }

    &.videosGallery {
      @media (max-width: 768px) {
        /* padding: 0; */
        margin-top: 200px;
      }
    }
  }
`;
