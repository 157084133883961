/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { RefObject, useState, useCallback } from 'react';

import { Container as GridContainer, Row, Col } from 'react-grid-system';
import { FiChevronLeft } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Link } from 'react-router-dom';
import TegraButton from '../../../../components/TegraButton';
import TegraDivider from '../../../../components/TegraDivider';
import {
  Wrapper,
  WizardNavigationContainer,
  WizardNavigationGoBack,
  Description,
  Subtitle,
  ExtraActions,
  GoBackContainer,
  Title,
  ErrorsContainer,
  BlogAction,
} from './styles';

import TegraFileInput from '../../../../components/TegraFileInput';
import api from '../../../../services/api';
import { CorretorFormData } from '..';
import ajaxLoading from '../../../../assets/ajax-loader.gif';
// import { StepProps, withWizard } from 'react-albus';

interface StepProps {
  id: string;
  formRef: RefObject<FormHandles>;
  formData: CorretorFormData;
  changeStep: (step: string) => void;
}

const StepThree: React.FC<StepProps> = ({ formRef, formData, changeStep }) => {
  const [defaultValues] = useState<CorretorFormData>(() => {
    const data = formData as CorretorFormData;
    return data;
  });

  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleStepSubmit = useCallback((botao) => {
    let data: CorretorFormData = {} as CorretorFormData;

    if (!formRef.current) {
      return;
    }

    const cadastro = JSON.parse(sessionStorage.getItem('_cadastro') || '');
    if (botao == "fim"){
      var erros = [''];      
      if (cadastro.files === null || cadastro.files === undefined){
        erros = ['CNH, CPF ou RG', 'CRECI', 'Comprovante de residência'];
        setErrors(erros);
        return;
      } 
      else if (cadastro.files.length < 3){
         for(var i = 0; i <= 2; i++ ){           
            if (!cadastro.files.find((f: { tipoDocumento: number }) => {return f.tipoDocumento === i;}))
            {
              if (i == 0){
                erros.push('CNH, CPF ou RG');
              }
              else if (i == 1){
                erros.push('CRECI');
              }
              else if (i == 2){
                erros.push('Comprovante de residência');
              }
            }
          }
          setErrors(erros);
          return;
      }
    }

    data = formRef.current.getData() as CorretorFormData;

    const multipart = new FormData();
    if (data.comp_residencia_doc) {
      multipart.append('documento', data.comp_residencia_doc);
    }
    setLoading(true);
    formRef.current?.submitForm();
    // formRef.current.submitForm();
  }, [formRef, defaultValues]);

  const handlePrevious = useCallback(() => {
    const step = sessionStorage.getItem('_corretorSelecionado')
      ? 'stepOne'
      : 'stepTwo';
    changeStep(step);
  }, [changeStep]);

  return (
    <Wrapper>
      <ExtraActions>
        <GoBackContainer>
          <FiChevronLeft size={10} />
          <span onClick={handlePrevious}>Voltar</span>
        </GoBackContainer>
        <Link to="/" className="jaPossuoCadastro">
          Já possuo cadastro
        </Link>
      </ExtraActions>
      <Title>Cadastre-se</Title>
      <Subtitle>Sou um corretor</Subtitle>
      <TegraDivider axis="x" color="#e6e6e6" />
      <Description>
        Agora, faça o envio dos arquivos para validação do seu cadastro.
      </Description>
      {errors.length >= 1 && (
        <ErrorsContainer>
          {errors.map(error => (
            <p>{
              <div dangerouslySetInnerHTML={{ __html: error }} />              
              }</p>
          ))}
        </ErrorsContainer>
      )}
      <GridContainer
        style={{
          padding: 0,
          marginLeft: '0px',
          marginRight: '0px',
          maxWidth: '100%',
        }}
      >
        <Row>
          <Col sm={12} md={5} xl={5}>
            <Description>CNH, CPF ou RG</Description>
          </Col>
          <Col sm={12} md={7} xl={7}>
            <TegraFileInput
              parceiro={defaultValues.id_parceiro}
              formRef={formRef}
              setErrors={setErrors}
              name="identificacao_doc"
            />
          </Col>
        </Row>
        <TegraDivider axis="x" color="#e6e6e6" />
        <Row>
          <Col sm={12} md={5} xl={5}>
            <Description>CRECI</Description>
          </Col>
          <Col sm={12} md={7} xl={7}>
            <TegraFileInput
              parceiro={defaultValues.id_parceiro}
              formRef={formRef}
              setErrors={setErrors}
              name="creci_doc"
            />
          </Col>
        </Row>
        <TegraDivider axis="x" color="#e6e6e6" />
        <Row>
          <Col sm={12} md={5} xl={5}>
            <Description>Comprovante de residência</Description>
          </Col>
          <Col sm={12} md={7} xl={7}>
            <TegraFileInput
              parceiro={defaultValues.id_parceiro}
              formRef={formRef}
              setErrors={setErrors}
              name="comp_residencia_doc"
            />
          </Col>
        </Row>

        {errors.length >= 1 && (
          <ErrorsContainer>
            {errors.map(error => (
              <p dangerouslySetInnerHTML={{ __html: error }} />
            ))}
          </ErrorsContainer>
        )}
        <TegraDivider axis="x" color="#e6e6e6" />
        <WizardNavigationContainer>
          <WizardNavigationGoBack>
            <FiChevronLeft size={10} />
            <span onClick={handlePrevious}>Voltar</span>
          </WizardNavigationGoBack>
          
          <BlogAction> 
            <TegraButton isDisabled={loading} isAction onClick={() => handleStepSubmit('depois')}>
                Enviar Depois
                {loading && <img src={ajaxLoading} alt="Ícone do botão" />}
            </TegraButton>
            <TegraButton isDisabled={loading} isAction onClick={() => handleStepSubmit('fim')} style={{marginRight:'0px'}}>
              Finalizar
              {loading && <img src={ajaxLoading} alt="Ícone do botão" />}
            </TegraButton>
          </BlogAction>

        </WizardNavigationContainer>
      </GridContainer>
    </Wrapper>
  );
};

export default StepThree;
