import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const BlogItem = styled.div`
  /*margin-right: 61px;*/
  margin-right: 25px;
  flex: 1;
  max-width: 709px;
  padding-top: 30px;

  @media (max-width: 800px) {
    margin-right: 0;
  }
`;

export const BlogItemThumbnail = styled.img`
  width: 100%;
  max-width: 709px;
  max-height: 474px;
  object-fit: cover;
  margin-top: 34px;
`;

export const BlogItemInfo = styled.div`
  & > div.containerClara {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    img {
      border-radius: 50%;
      border: 1px solid #ebb92d;
      width: 70px;
      margin-right: 20px;
    }

    div {
      p {
        color: #adadab;

        strong {
          color: black;
          font-weight: 600;
        }
      }
      time {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.12px;
        text-align: left;
        color: #adadab;
        display: block;
    
        a {
          font-weight: 600;
          cursor: pointer;
          color: #80807d;
        }
    
        @media (max-width: 600px) {
          margin-top: 20px;
        }
      }
    }
  }
  & > time {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.12px;
    text-align: left;
    color: #adadab;
    display: block;
    margin-bottom: 20px;

    a {
      font-weight: 600;
      cursor: pointer;
      color: #80807d;
    }

    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }

  & > h2 {
    font-size: 36px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 0.36px;
    text-align: left;
    color: #272723;
  }
  & > strong {
    display: block;
    margin-top: 17px;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
  }

  & > div.content {
    margin-top: 17px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #60605d;
    overflow-wrap: break-word;

    & h2{
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 0.14px;
    }
    & h3{
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 0.14px;
    }
    & h4{
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 0.14px;
    }
    & h5{
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 0.14px;
    }
    & h6{
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 0.14px;
    }

    img {
      max-width: 100%;
    }
  }
  & .downloadArea{
    margin-top: 20px;
    display: flex; 
    align-items: center;
    border-top: 1px solid #adadab;
    padding-top: 10px;
    & button{
      margin-left: 0px;
      margin-right: 15px;
    }
  }

  & div.bioClara {
    background-color: #f9f9f9;
    padding: 25px;
    display: flex;
    margin-top: 40px;


    .imgClara {
      border-radius: 50%;
      border: 1px solid #ebb92d;
      width: 70px;
      height: 70px;
      margin-right: 20px;
    }

    div {
      
      h2 {
        color: black;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 20px;
      }

      p {
        line-height: 18px;
      }

      .icons {
        margin-top: 15px;

        img {
          width: 20px;
          margin-right: 5px;
        }
      }
    }
  }
  
`;

export const OutrosDestaques = styled.div`
  border-left: 1px solid #e6e6e6;
  /*padding-left: 40px;*/
  padding-left: 25px;
  max-width: 327px;
  width: 100%;

  @media (max-width: 800px) {
    max-width: 100%;
    border-left: none;
    border-top: 1px solid #e6e6e6;
    padding-left: 0px;
    padding-top: 35px;
    margin-top: 38.5px;
  }

  & > div {
    @media (max-width: 800px) {
      display: grid;
      grid-gap: 40px 40px;
      /*grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));*/
    }

    @media (max-width: 425px) {
      grid-template-columns: repeat(auto-fit, minmax(1fr, 1fr));
      display: block;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
    margin-bottom: 17px;
  }
`;

export const OutrosDestaquesItem = styled.div`
  padding-bottom: 35px;
  margin-left: 10px;
  width: 100%;

  /*
  &:not(:first-child) {
    margin-top: 40px;

    @media (min-width: 401px) and (max-width: 800px) {
      margin-top: 0px;
    }
  }
*/
  span {
    display: block;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.12px;
    text-align: left;
    color: #adadab;
  }

  img {
    max-width: 100%;
    width: 302px;
    max-height: 218px;
    object-fit: cover;
    display: block;
    margin-top: 5px;

    @media (max-width: 800px) {
      width: 100%;
    }
  }

  time {
    display: block;
    margin-top: 6px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.12px;
    text-align: left;
    color: #adadab;

    @media (max-width: 800px) {
      margin-top: 10px;
    }
  }

  strong {
    display: block;
    margin-top: 6px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
  }

  a {
    text-decoration: none;
  }

  div {
    margin-top: 10px;
  }
`;

export const OutrosDestaquesItemActions = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #272723;
    text-decoration: underline;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }
`;

export const ContainerEvento = styled.div`
  display: flex;
  margin-top: 15px;
  border-bottom: 1px solid #e6e6e6;
`;


export const DataEvento = styled.div`
  min-width: 80px;
  max-height: 70px;
  display: flex;
  flex-direction: column;

  .mesEvento{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 16px;
    p{
      color: #FFFFFF;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .diaEvento{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 53px;

    p{
      font-size: 40px;
      color: #272723;
      font-weight: 500;
    }
  }

`;

