import styled from 'styled-components';
import dropdownChevron from '../../../../assets/arrow-icon.svg';
import colors from '../../../../styles/colors';
import { NavMenuItem } from '../styles';
import zIndex from '../../../../utils/zIndex';

interface DropdownContentProps {
  active: boolean;
}

export const DropdownContent = styled.div<DropdownContentProps>`
  display: ${props => (props.active ? 'block' : 'none')};
  background-color: ${colors.darkGreyBackground};
  margin: 0 -1.5rem -0.625rem;
  padding: 1.25rem 1.5rem 0;
  font-size: 12px;
  @media (min-width: 992px) {
    position: absolute;
    text-align: center;
    z-index: ${zIndex.dropdown};
    top: 100%;   
    white-space: nowrap;
  }
`;

export const DropdownParent = styled.div<DropdownContentProps>`
  position: relative;
  margin-bottom: ${props => (props.active ? '0.625rem' : '0')};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 1.57;
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const DropdownLabel = styled.div<DropdownContentProps>`
  @media (min-width: 992px) {
    position: relative;
  }
  &::before {
    content: '';
    position: absolute;
    background-image: url(${dropdownChevron});
    width: 10px;
    height: 10px;
    left: 0;
    top: 25%;
    background-repeat: no-repeat;
    background-position: center center;
    transform: ${props => (props.active ? 'rotate(180deg)' : 'unset')};
    @media (min-width: 992px) {
      left: unset;
      right: -1rem;
      top: calc(50% - 5px);
    }
  }
`;

export const DropdownChild = styled.div`
  padding-bottom: 1.25rem;
`;

export const DropdownItem = styled(NavMenuItem)`
  cursor: pointer;
  @media (min-width: 992px) {
    position: relative;
    &:hover {
      ${DropdownLabel} {
        &::before {
          transform: rotate(180deg);
        }
      }
      ${DropdownContent} {
        display: block;
      }
    }
  }
`;

export const DropdownUserImage = styled.img`
  margin-left: 2rem;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  background-color: #797974;
`;
