/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useRef, useEffect } from 'react';

import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  Container as GridContainer,
  Row,
  Col,
  Hidden,
  Visible,
} from 'react-grid-system';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useLocation } from 'react-router';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  MaterialsContainer,
  MaterialsTable,
  MaterialsTableRow,
  MaterialsTableData,
  MaterialsTableTitle,
  MaterialsTableLabel,
  MaterialsActionsWrapper,
  ProductMaterialsTitleWrapper,
  TabContainer,
} from './styles';
import {
  Container,
  Content,
  SearchBox,
  FiltrarContainer,
  LoadMore,
} from '../Products/styles';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import TegraDivider from '../../components/TegraDivider';
import TegraFiltro from '../../components/TegraFiltro';
import filtrarIcon from '../../assets/tegra-icon-filtrar.svg';
import searchIcon from '../../assets/tegra-icon-search.svg';
import TegraInput from '../../components/TegraInput';
import api from '../../services/api';
import { useLoader } from '../../hooks/loader';
import TegraButton from '../../components/TegraButton';
import MaterialsTableAction from './MaterialsTableAction';
import TegraModalCompartilhar from '../../components/TegraModalCompartilhar';
import TegraTabsContainer from '../../components/TegraTabsContainer';

import {
  getTelegramURL,
  getFacebookURL,
  getWhatsappURL,
} from '../../utils/share';
import { useAuth } from '../../hooks/auth';
import { ToSlug } from '../../services/helpers';
import placeholderThumb from '../../assets/tegra-blog-item-thumbnail.png';

export interface IProductMaterial {
  id: string;
  id_produto: string;
  tipoDocumento: 'Arquivo' | 'Vídeo';
  urlFacebook: string;
  urlTelegram: string;
  urlWhats: string;
  urlDocumento: string;
  urlVideo: string;
  tx_download?: string;
  tx_produto: string;
  uf: string;
  cidade: string;
  slug: string;
  localidade: {
    bairro: string;
    cidade: string;
    uf?: string;
  };
  columns: { [key: string]: string };
}

export interface IFiltroFormData {
  tipo_produto: string;
  estagio_obra: string;
  regioes: string;
  search: string;
  bairros: string;
  dormitorios: string;
  aerea: string;
  preco: string;
}

export interface IFiltroItemOption {
  label: string;
  value: string;
}

export interface IFiltroItemOptionRange {
  label_from: string;
  label_to: string;
}

export interface IFiltroItem {
  label: string;
  name: string;
  type: 'checkbox' | 'multiselect' | 'range';
  options?: IFiltroItemOption[] | IFiltroItemOptionRange;
}

export interface IEmpreendimento {
  id: number;
  nome: string;

  bairro: string;

  cidade: string;

  metragemDe: number;
  metragemAte: number;

  isExibidoComoOportunidade: true;

  metragemDescricao: string;
  valor: number;
  valorAproximado: number;
  dormitoriosDe: string;
  dormitoriosAte: string;
  dormitoriosDescricao: string;
  imagemBuscaPequenaLoja: string;
  imagemBuscaPequena: string;
  imagemBuscaMedia: string;
  imagemBuscaGrande: string;

  descricao: string;
  empreendimentoTipoId: number;
  empreendimentoTipo: string;
  empreendimentoAndamentoId: number;
  empreendimentoAndamento: string;

  chamada: string;

  distancia: number;
  recomendadoEstado: true;

  metragemDeLoja: number;
  metragemAteLoja: number;
}

interface IProductColumn {
  label: string;
  field: string;
}

interface IProductMaterialsProps {
  title: string;
  subtitle: string;
  description: string;
  showColumnTitle: boolean;
  actions: ('open' | 'download' | 'share' | 'simulate')[];
  apiUrl: string;
  columns: IProductColumn[];
  anchorName: string;
  onClickItem?: () => any;
}

const ProductMaterials: React.FC<IProductMaterialsProps> = ({
  title,
  subtitle,
  description,
  actions,
  apiUrl,
  columns,
  anchorName,
  onClickItem,
}) => {
  const location = useLocation();
  const { user } = useAuth();
  const { setLoaderActive } = useLoader();
  const [filtroShow, setFiltroShow] = useState(false);
  const [produtos, setProdutos] = useState<IProductMaterial[]>([]);
  const [lastSearch, setLastSearch] = useState<IFiltroFormData>(
    {} as IFiltroFormData,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [modalActive, setModalActive] = useState<boolean>(false);
  const [modalUrlFacebook, setModalUrlFacebook] = useState<string>('');
  const [modalUrlTelegram, setModalUrlTelegram] = useState<string>('');
  const [modalUrlWhats, setModalUrlWhats] = useState<string>('');

  const filtroFormRef = useRef<FormHandles>(null);

  const isGeneralDocumentsRoute = location.pathname.endsWith("materiais-gerais") || 
                                  location.pathname.endsWith("materiais-corretor") || 
                                  location.pathname.endsWith("manuais") || 
                                  location.pathname.endsWith("ciclo-do-negocio") ||
                                  location.pathname.endsWith("ferramentas") ||
                                  location.pathname.endsWith("treinamento-diversos");
  
  //const { path } = useRouteMatch();
  const path  = 'dashboard/materiais-de-produtos';

  const doSearch = async (
    filtro?: IFiltroFormData,
    force = false,
    page: number | undefined = undefined,
  ): Promise<void> => {
    if (!force && JSON.stringify(filtro) === JSON.stringify(lastSearch)) return;
    const data: IFiltroFormData = filtro || ({} as IFiltroFormData);
    const { search } = data;

    setLastSearch(data);
    const pagina = page || currentPage;
    const params = new URLSearchParams();
    params.append('nu_pagina', pagina.toString());
    params.append('nu_quantidade', '19');
    if (
      data.aerea ||
      data.preco ||
      data.tipo_produto ||
      data.estagio_obra ||
      data.regioes ||
      data.bairros ||
      data.dormitorios ||
      search
    ) {
      if (data.aerea !== '') {
        const area = JSON.parse(data.aerea as string);
        params.append('nu_areaDe', area.min || 0);
        params.append('nu_areaAte', area.max || 999999);
      }

      if (data.preco !== '') {
        const preco = JSON.parse(data.preco as string);
        params.append('nu_precoDe', preco.min || 0);
        params.append('nu_precoAte', preco.max || 999999);
      }
      if (
        data.tipo_produto !== '' &&
        JSON.parse(data.tipo_produto).length !== 0
      ) {
        JSON.parse(data.tipo_produto).forEach((item: string) =>
          params.append('id_tipo', item),
        );
      }
      if (
        data.estagio_obra !== '' &&
        JSON.parse(data.estagio_obra).length !== 0
      ) {
        JSON.parse(data.estagio_obra).forEach((item: string) =>
          params.append('id_andamento', item),
        );
      }
      if (data.regioes !== '' && JSON.parse(data.regioes).length !== 0) {
        JSON.parse(data.regioes).forEach((item: string) =>
          params.append('regioes', item),
        );
      }
      if (data.bairros !== '' && JSON.parse(data.bairros).length !== 0) {
        JSON.parse(data.bairros).forEach((item: string) =>
          params.append('bairros', item),
        );
      }
      if (
        data.dormitorios !== '' &&
        JSON.parse(data.dormitorios).length !== 0
      ) {
        JSON.parse(data.dormitorios).forEach((item: string) =>
          params.append('dormitorios', item),
        );
      }

      if (search) {
        params.append('pesquisa', search);
      }
    }

    params.append('archorName',anchorName);

    setLoaderActive(true);
    const response = await api.get(apiUrl, { params });

    const { items, paginas } = response.data;
    const newProdutos = pagina === 1 ? [] : produtos;

    items.forEach((item: any) => {
      item.tx_tipoDocumentoGeral = item.id_tipoDocumentoGeral === 1 ? 'Vídeo' : 'Arquivo';

      const obj: IProductMaterial = {
        columns: {},
        id: item.id, //id do material
        urlTelegram: getTelegramURL(item.urL_documento),
        urlFacebook: getFacebookURL(item.urL_documento),
        urlWhats: getWhatsappURL(item.urL_documento),
        tipoDocumento: item.tx_tipoDocumentoGeral,
        urlDocumento: item.urL_documento,
        urlVideo: item.urL_video,
        tx_download: item.tx_download,
        id_produto: item.id_produto,
        tx_produto: item.tx_produto,
        uf: item.uf,
        cidade: item.cidade,
        slug: item.slug,
        localidade: {
          bairro: item.bairro,
          cidade: item.cidade,
          uf: item.uf,
        },
      };

      columns.forEach(column => {
        obj.columns[column.label] = item[column.field] || '';
      });

      newProdutos.push(obj);
    });

    setProdutos(newProdutos);
    setLoaderActive(false);
    setTotalPages(paginas);
    if (page !== currentPage) setCurrentPage(page || 1);
  
  };

  const handleLoadMore = useCallback(() => {
    doSearch(lastSearch, true, currentPage + 1);
  }, [produtos, lastSearch, currentPage, doSearch]);

  const searchAPIDebounced = AwesomeDebouncePromise(
    async (data: IFiltroFormData) => {
      await doSearch(data);
      setCurrentPage(1);
    },
    600,
  );

  const handleFiltroFormSubmit = useCallback(
    (data): void => {
      searchAPIDebounced(data);
    },
    [searchAPIDebounced],
  );

  const handleFiltroShow = useCallback((shouldShow: boolean) => {
    setFiltroShow(shouldShow);
  }, []);

  const openShare = (item: IProductMaterial): void => {
    setModalUrlFacebook(item.urlFacebook);
    setModalUrlTelegram(item.urlTelegram);
    setModalUrlWhats(item.urlWhats);
    setModalActive(true);
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <Container>
      <ProductMaterialsTitleWrapper>
        <TegraOpaqueHero title={title} subtitle={subtitle} description={description} />
      </ProductMaterialsTitleWrapper>
      {(title === 'Materiais Gerais') && (
        <TabContainer>
          <TegraTabsContainer>
                <Tabs defaultIndex={
                  subtitle === "E-mail Marketing" ? 0 :
                  /*subtitle === "Redes Sociais" ? 1 */
                  subtitle === "Peças e Anúncios" ? 1 :
                  subtitle === "Outras Imagens" ? 2 :
                  subtitle === "Masterplan" ? 3 :
                  subtitle === "Materiais do Corretor" ? 4 :
                  subtitle === "Manuais" ? 5 : 0
                }>
                  <TabList>
                    <Tab>
                      <Link to={`/dashboard/materiais-de-produtos/email-marketing`}>Email Marketing</Link>
                    </Tab>
                    {/*
                    <Tab>
                      <Link to={`/dashboard/materiais-de-produtos/redes-sociais`}>Redes Sociais</Link>
                    </Tab>
                    */}
                    <Tab>
                      <Link to={`/dashboard/materiais-de-produtos/pecas-anuncios`}>Peças e Anúncios</Link>
                    </Tab>
                    <Tab>
                      <Link to={`/dashboard/materiais-de-produtos/outras-imagens`}>Outras Imagens</Link>
                    </Tab>
                    <Tab>
                      <Link to={`/dashboard/materiais-de-produtos/masterplan`}>Masterplan</Link>
                    </Tab>
                    <Tab>
                      <Link to={`/dashboard/materiais-de-produtos/materiais-corretor`}>Materiais do Corretor</Link>
                    </Tab>
                    <Tab>
                      <Link to={`/dashboard/materiais-de-produtos/manuais`}>Manuais</Link>
                    </Tab>
                    {/*
                    <Tab>
                      <Link to={`/dashboard/materiais-de-produtos/materiais-gerais`}>Materiais Gerais</Link>
                    </Tab>                    
                    */}
                  </TabList>
                </Tabs>
          </TegraTabsContainer>
        </TabContainer>        
      )}
            {(title === 'Treinamentos') && (
        <TabContainer>
          <TegraTabsContainer>
                <Tabs defaultIndex={
                  subtitle === "Produtos" ? 0 :
                  subtitle === "Ciclo do Negócio" ? 1 :
                  subtitle === "Ferramentas" ? 2 :
                  subtitle === "Diversos" ? 3 : 0
                }>
                  <TabList>
                    <Tab>
                      <Link to={`/dashboard/materiais-de-produtos/treinamento-de-produtos`}>Produtos</Link>
                    </Tab>
                    <Tab>
                      <Link to={`/dashboard/materiais-de-produtos/ciclo-do-negocio`}>Ciclo do Negócio</Link>
                    </Tab>
                    <Tab>
                      <Link to={`/dashboard/materiais-de-produtos/ferramentas`}>Ferramentas</Link>
                    </Tab>
                    <Tab>
                      <Link to={`/dashboard/materiais-de-produtos/treinamento-diversos`}>Diversos</Link>
                    </Tab>
                  </TabList>
                </Tabs>
          </TegraTabsContainer>
        </TabContainer>        
      )}
      <Content>
        <GridContainer
          className="grid-container"
          style={{
            padding: 0,
            marginLeft: '0px',
            maxWidth: '100%',
            marginRight: '0px',
          }}
        >
          <Row style={{justifyContent: 'center'}}>
            {/* Filtro de materiais */}
            <Col sm={12} md={4} xl={4} xxl={3} style={{ display: isGeneralDocumentsRoute ? 'none' : 'block' }}>
              <Form ref={filtroFormRef} onSubmit={handleFiltroFormSubmit}>
                {filtroShow && (
                  <TegraFiltro
                    filtroFormRef={filtroFormRef}
                    doSearch={doSearch}
                    handleFiltroShow={handleFiltroShow}
                    idEstado={user.idEstado}
                  />
                )}
                {/* <Hidden sm={false} md={false} xl xxl lg> */}
                <SearchBox>
                  <Hidden sm={false} md xl xxl lg>
                    <FiltrarContainer>
                      <button
                        type="submit"
                        onClick={() => handleFiltroShow(true)}
                      >
                        Filtrar
                        <img src={filtrarIcon} alt="Filtrar" />
                      </button>
                    </FiltrarContainer>
                    <TegraDivider axis="x" height={1} color="#ADADAB" />
                  </Hidden>
                  <TegraInput
                    label="Buscar produto"
                    name="search"
                    formRef={filtroFormRef}
                    icon={searchIcon}
                    realTime
                  />
                </SearchBox>
                
                <Visible sm={false} md xl xxl lg>
                  <TegraFiltro
                    doSearch={doSearch}
                    filtroFormRef={filtroFormRef}
                    handleFiltroShow={handleFiltroShow}
                    idEstado={user.idEstado}
                  />
                </Visible>
              </Form>
            </Col>

            <Col sm={12} md={8} xl={8} style={{ padding: 0 }}>
              {produtos.length > 0 && (                
                <MaterialsContainer>                  
                  <MaterialsTable>
                    <tbody>
                      <MaterialsTableRow>
                        {Object.keys(produtos[0].columns).map(key => (
                          <th>
                            <MaterialsTableTitle>
                              {key === 'Label' || key === 'Produto'
                                ? 'Empreendimento'
                                : key}                                
                            </MaterialsTableTitle>
                          </th>
                        ))}
                        <MaterialsTableTitle />
                      </MaterialsTableRow>
                      {produtos.map((item: IProductMaterial) => (
                        <MaterialsTableRow>
                          {Object.keys(item.columns).map(key => (
                            <MaterialsTableData>
                              <MaterialsTableLabel>
                                {(key === 'Label' ||
                                  key === 'Produto' ||
                                  key === 'Empreendimento') && (
                                  <>
                                    <Link
                                      to={`/dashboard/produtos/${item.localidade.uf?.toLowerCase()}/${ToSlug(
                                        item.localidade.cidade,
                                      )}/${item.slug}`}
                                      onClick={onClickItem}
                                    >
                                      {item.columns[key]}
                                    </Link>
                                  </>
                                )}
                                {key !== 'Imagem' &&
                                  key !== 'Label' &&
                                  key !== 'Produto' &&
                                  item.columns[key]}
                                {key === 'Imagem' && (
                                  <img
                                    src={item.columns[key] || placeholderThumb}
                                    alt="Vídeo pré-visualização"
                                    onError={(e: any) => {
                                      // e.target.src = placeholderThumb;
                                    }}
                                  />
                                )}
                              </MaterialsTableLabel>
                            </MaterialsTableData>
                          ))}
                          <MaterialsTableData>
                            <MaterialsActionsWrapper length={actions.length}>
                              {actions.map(actionType => (                                
                                <MaterialsTableAction
                                  actionType={actionType}
                                  id={item.id_produto}
                                  openShare={() => openShare(item)}
                                  anchorName={anchorName}
                                  link={item.tipoDocumento === 'Arquivo' ? item.urlDocumento : item.urlVideo}
                                  download={item.tx_download}
                                  titulo={subtitle != ''?subtitle: title}
                                  produto={item.tx_produto}
                                  uf={item.uf}
                                  cidade={item.cidade}
                                  slug={item.slug}
                                  id_material={item.id} //id do produto
                                />
                                
                              ))}
                            </MaterialsActionsWrapper>
                          </MaterialsTableData>
                        </MaterialsTableRow>
                      ))}
                    </tbody>
                  </MaterialsTable>
                </MaterialsContainer>
              )}

              {totalPages > currentPage && (
                <LoadMore>
                  <TegraButton isAction onClick={handleLoadMore}>
                    Carregar mais
                  </TegraButton>
                </LoadMore>
              )}
            </Col>
          </Row>
        </GridContainer>
      </Content>
      <TegraModalCompartilhar
        isOpen={modalActive}
        handleClose={() => setModalActive(false)}
        urlFacebook={modalUrlFacebook}
        urlTelegram={modalUrlTelegram}
        urlWhats={modalUrlWhats}
      />
    </Container>
  );
};

export default ProductMaterials;
