import styled from "styled-components";
import colors from "../../styles/colors";

export const FooterWrapper = styled.div`
    padding: 2.5rem 2.5rem 4.5rem;
    max-width: calc(1140px + 5rem);
`;

export const FooterContainer = styled.div`
    background-color: ${colors.greyBackground};
    display: flex;
    justify-content: center;
`;
export const FooterTrademark = styled.h3`  
    padding-top: 4.5rem;
    font-family: Montserrat;
    font-size: 14px;
    color: #adadab;
    font-weight: light;
`;

export const LinkGroupWrapper = styled.div`
    display:flex;
    flex-direction: column;
    @media(min-width: 992px) {
        flex-direction: row;
    }
    margin: 2rem 0;
`;


export const FooterWrapperSmall = styled.div`
    max-width: calc(1140px + 5rem);
    display: flex;
    height: 50px;
    align-items: center;
`;

export const FooterTrademarkSmall = styled.h3`  
    font-family: Montserrat;
    font-size: 14px;
    color: #adadab;
    font-weight: light;
`;
