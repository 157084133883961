import styled from 'styled-components';
import colors from '../../styles/colors';

export const FeedbackWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
`

export const FeedbackContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.25rem 2.25rem 7.5rem;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
`;

export const FeedbackIcon = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 2.5rem;
`;

export const FeedbackTitle = styled.h1`
  text-align: center;
  font-size: 3.125rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
`;

export const FeedbackDescription = styled.p`
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 0.75rem;
  color: ${colors.greyBackground};
`;