import React, { useRef, useEffect, SelectHTMLAttributes } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';
import { useField } from '@unform/core';
import { Container } from './styles';

// background-color: #66b7d9;

interface Props extends SelectProps<OptionTypeBase, true> {
  name: string;
  label?: string;
  defaultValue?: object;
}

const TegraSelect: React.FC<Props> = ({ name, label, defaultValue, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName,  registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      // getValue: (ref: any) => {
      //   if (rest.isMulti) {
      //     if (!ref.state.value) {
      //       return [];
      //     }

      //     return ref.state.value.map((option: OptionTypeBase) => option.value);
      //   } else {
      //     if (!ref.state.value) {
      //       return '';
      //     }

      //     return ref.state.value.value as string;
      //   }
      // },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container>
      {label && <p>{label}</p>}
      <ReactSelect
        // onChange={()=>}
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
    </Container>
  );
};

export default TegraSelect;
