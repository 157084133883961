import axios from 'axios';

export const baseURL = process.env.REACT_APP_APIURL;
const api = axios.create({ baseURL });

api.interceptors.response.use(
  response => {
    const userLocal = localStorage.getItem('@Tegra:user');
    const userSession = sessionStorage.getItem('@Tegra:user');
    
    if (userSession !== null && userLocal !== null) {
      let userLocalJSON = JSON.parse(userLocal);
      let userSessionJSON = JSON.parse(userSession);

      if (userLocalJSON?.id !== userSessionJSON?.id) {
        localStorage.removeItem('@Tegra:user');
        sessionStorage.removeItem('@Tegra:user');
        window.location.href = '/';
        return Promise.reject('Sem acesso');
      } 
    } else if (userSession === null && userLocal !== null) {
      sessionStorage.setItem('@Tegra:user', userLocal)
    } else if (userSession !== null && userLocal === null) {
      localStorage.removeItem('@Tegra:user');
      sessionStorage.removeItem('@Tegra:user');
      localStorage.removeItem('modalExpertegra');
      window.location.href = '/';
      return Promise.reject('Sem acesso');
    }

    return response;
  },
  error => {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem('@Tegra:user');
      localStorage.removeItem('@Tegra:token');
      localStorage.removeItem('modalExpertegra');
      window.location.href = '/';
    }
    // Do something with response error
    return Promise.reject(error);
  },
);
export default api;
