import styled, { css } from 'styled-components';

interface ContainerProps {
  axis: 'y' | 'x';
  color: string;
  marginLeft?: number;
  marginRight?: number;
  height?: number;
  width?: number;
}

export const Text = styled.h1``;

const dividerAxisVariations = {
  x: css`
    height: 0.6px;
    max-width: 100%;
  `,
  y: css`
    width: 0.6px;
    height: 100%;
  `,
};

export const Container = styled.div<ContainerProps>`
  ${props => dividerAxisVariations[props.axis || 'x']}
  background-color: ${props => props.color};

  ${props =>
    props.height &&
    css`
      height: ${props.height}px;
    `}

  
  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `}

  margin-left: ${props => (props.marginLeft ? `${props.marginLeft}px` : '0px')};
  margin-right: ${props =>
    props.marginRight ? `${props.marginLeft}px` : '0px'};
`;
