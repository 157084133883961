import React, { useState, useCallback } from 'react';
import { Document, pdfjs, Page } from 'react-pdf';

import dummyPdf from '../../assets/dummy.pdf';
import iconArrowLeft from '../../assets/tegra-icon-white-arrow-left.svg';

import { Container, FileNavLeft, FileNavRight } from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface TegraFileViewrProps {
  link: string;
}

const TegraFileViewer: React.FC<TegraFileViewrProps> = ({ link }) => {
  // trocar para carregamento por link
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = useCallback(({ numPages: numPages_ }) => {
    setNumPages(numPages_);
  }, []);

  const changePage = useCallback(offset => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }, []);

  const previousPage = useCallback(() => {
    changePage(-1);
  }, [changePage]);

  const nextPage = useCallback(() => {
    changePage(1);
  }, [changePage]);

  return (
    <Container>
      <Document
        className="document"
        file={dummyPdf}
        // file={{
        //   url: link,
        //   httpHeaders: { 'X-CustomHeader': '40359820958024350238508234' },
        //   withCredentials: true,
        // }}
        onLoadSuccess={onDocumentLoadSuccess}
        renderMode="svg"
      >
        <Page pageNumber={pageNumber} />
        <div className="pagesNav">
          <FileNavLeft
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            <img src={iconArrowLeft} alt="Anterior" />
          </FileNavLeft>
          <FileNavRight
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            <img src={iconArrowLeft} alt="Anterior" />
          </FileNavRight>
        </div>
      </Document>
    </Container>
  );
};

export default TegraFileViewer;
