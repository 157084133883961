import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1202px;
  /* overflow: auto; */
  max-height: 356px;
  display: flex;
  justify-content: center;
  table {
    width: 100%;
    border-spacing: collapse;
    border: 1px solid #fff;

    thead {
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: 0.1px;
      text-align: center;
      color: #ffffff;
    }

    th {
      background-color: #60605d;
      padding: 10px;
    }

    tbody {
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: 0.1px;
      text-align: center;
      color: #60605d;

      td {
        padding: 2px 18px;
      }

      tr:nth-child(odd) {
        td {
          background-color: #e6e6e6;
        }
      }

      tr:nth-child(even) {
        td {
          background-color: transparent;
        }
      }
    }
  }
`;
