import styled from 'styled-components';

export const Container = styled.div`
  p {
    margin-bottom: 11px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;

    margin-bottom: 11px;
    margin-top: 15.5px;
  }

  .react-select__control {
    border: solid 1px #adadab;
    border-radius: 0;
  }
`;
