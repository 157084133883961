/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useState, useEffect } from 'react';
import { Container, Content, MensagemSucesso } from './styles';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import api from '../../services/api';
import { useAuth, User } from '../../hooks/auth';
import { useLoader } from '../../hooks/loader';
import ImobiliariaCorretores from '../../components/ImobiliariaCorretores';
import TegraButton from '../../components/TegraButton';
import { MensagemErro } from '../MyProfile/styles';

const MyProfileCorretores: React.FC = () => {
  const auth = useAuth();
  const { setLoaderActive } = useLoader();
  const [idImob, setIdImob] = useState<any>(1);
  const [corretores, setCorretores] = useState<any[]>([]);
  const [sucesso, setSucesso] = useState<boolean | undefined>(undefined);
  const [mensagemRetorno, setMensagemRetorno] = useState<string>('');
  const [erroArquivo, setErroArquivo] = useState<boolean>(false);


  
  useEffect(() => {
    if (erroArquivo){      
      setMensagemRetorno("O arquivo enviado para a associação de corretores a sua imobiliária deve ser um arquivo no formato '.csv'");
    }
    else{
      setMensagemRetorno('');
    }
  }, [erroArquivo]);

  const salvar = useCallback(async () => {
    const invalidCpf = corretores.filter(c => c.valid !== true).length;
    console.log(erroArquivo);
    if (invalidCpf > 0) {
      setMensagemRetorno(`Ops, parece que ${invalidCpf} CPF está incorreto`);
      setSucesso(false);
      return;
    }
    setLoaderActive(true);
    const res = await api.post('Perfil/corretores', {
      corretores: corretores.map(c => ({
        ...c,
      })),
    });

    const { sucesso: sucesso2 } = res.data;
    setSucesso(sucesso2);
    if (sucesso2) {
      setMensagemRetorno('Dados salvos com sucesso!');
      setIdImob(idImob + 1);
    } else {
      setMensagemRetorno('Ocorreu um erro ao tentar salvar, tente novamente');
    }
    setLoaderActive(false);
  }, [corretores, idImob, setLoaderActive, erroArquivo]);

  return (
    <Container>
      <TegraOpaqueHero
        title="Meus corretores"
        description="Informe os corretores de sua imobiliária."
      />
      <Content>
        {mensagemRetorno && sucesso && (
          <>
            <MensagemSucesso>{mensagemRetorno}</MensagemSucesso>
          </>
        )}
        {mensagemRetorno && !sucesso && (
          <>
            <MensagemErro>{mensagemRetorno}</MensagemErro>
          </>
        )}
        <ImobiliariaCorretores
          onChange={setCorretores}
          setLoading={setLoaderActive}
          id_imobiliaria={idImob}
          setErroArquivo={setErroArquivo}
          autenticado
        />
        <TegraButton
          className="salvar mt-2"
          type="button"
          isAction
          onClick={() => salvar()}
        >
          Salvar
        </TegraButton>
      </Content>
    </Container>
  );
};

export default MyProfileCorretores;
