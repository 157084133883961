import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 50%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 58.5px;
  background: #fff;
  padding: 40px;
  .NotFound {
    &__title {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
    }
    &__text {
      margin-top: 15px;
      font-size: 16px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: rgb(96, 96, 93);
    }
    &__action {
      margin-top: 30px;
      align-self: flex-end;
      &Link{
        font-size: 16px;
        text-decoration: none;
        height: 49px;
        padding: 15px;
        color: #60605d;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-transform: uppercase;
      }
    }
  }
`;
