import React, { useState, useEffect, useCallback } from 'react';

import { Container, Content, BlogAction } from './styles';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import TegraBlogOverview from '../../components/TegraBlogOverview';

import TegraButton from '../../components/TegraButton';
import api from '../../services/api';
import { useLoader } from '../../hooks/loader';

interface IBlogItem {
  imagem?: any;
  titulo: string;
  subtitulo: string;
  conteudo: string;
  link: string;
  chamada: string;
  data: string;
  id: string;
}

interface ItemPremiacao {
  id_novidade: number;
  id_tipo: number;
  tx_titulo: string;
  tx_subtitulo: string;
  tx_descricao: string;
  tx_chamada: string;
  data_inicio: string;
  nu_estado: number;
  nu_publico: number;
  tx_thumb: number;
}

interface ResponsePremiacao {
  items: ItemPremiacao[];
  paginas: number;
}

const PremiacoesEMeta: React.FC = () => {
  const [blogItems, setBlogItems] = useState<IBlogItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { setLoaderActive } = useLoader();

  useEffect(() => {
    // chamar api para pegar itens
    async function loadItems(): Promise<void> {
      setLoaderActive(true);
      const { data } = await api.get<ResponsePremiacao>('Premiacao', {
        params: {
          pagina: 1,
          quantidade: 6,
        },
      });
      const items: IBlogItem[] = data.items.map(item => ({
        titulo: item.tx_titulo,
        subtitulo: item.tx_subtitulo,
        id: `${item.id_novidade}`,
        imagem: item.tx_thumb,
        data: item.data_inicio,
        conteudo: item.tx_descricao,
        chamada: `${item.tx_chamada}`,
        link: `${item.id_novidade}`,
      }));

      setTotalPages(data.paginas);
      setBlogItems([...items]);
      setLoaderActive(false);
    }

    loadItems();
  }, [setLoaderActive]);

  const handleLoadMore = useCallback(async () => {
    setLoaderActive(true);
    const { data } = await api.get<ResponsePremiacao>('Premiacao', {
      params: {
        pagina: currentPage + 1,
        quantidade: 6,
      },
    });
    const items: IBlogItem[] = data.items.map(item => ({
      titulo: item.tx_titulo,
      subtitulo: item.tx_subtitulo,
      id: `${item.id_novidade}`,
      imagem: item.tx_thumb,
      data: item.data_inicio,
      conteudo: item.tx_descricao,
      link: `${item.id_novidade}`,
      chamada: `${item.tx_chamada}`,
    }));

    setTotalPages(data.paginas);
    setBlogItems([...blogItems, ...items]);
    setCurrentPage(currentPage + 1);
    setLoaderActive(false);
  }, [blogItems, currentPage, setLoaderActive]);

  return (
    <Container>
      <TegraOpaqueHero
        title="Premiações e Metas"
        description=" "
      />
      <Content>
        <TegraBlogOverview pageSlug="premiacoes-de-meta" items={blogItems} />
       
        {currentPage + 1 <= totalPages && (
          <BlogAction>
            <TegraButton isAction onClick={handleLoadMore}>
              Carregar mais
            </TegraButton>
          </BlogAction>
        )}
      </Content>
    </Container>
  );
};

export default PremiacoesEMeta;
