/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import arrowDownIcon from '../../assets/tegra-arrow-down-icon.svg';

import {
  Container,
  CustomAccordionItem,
  CustomAccordionItemHeading,
  CustomAccordionItemButton,
  CustomAccordionItemPanel,
} from './styles';

import Pdf1 from '../../assets/CodigoDeVestimenta.pdf';
import Pdf2 from '../../assets/Codigo-de-Conduta.pdf';
import Pdf3 from '../../assets/GuiaBoasPratica.pdf';
import Pdf4 from '../../assets/IntegracaoAlmaTegra2021.pdf';
import Pdf5 from '../../assets/LGPD2021.pdf';

//import Pdf from '../../assets/CodigoDeVestimenta.pdf';

interface TegraAccordionItem {
  title: string;
  content: string;
  uuid: string;
}

interface TegraAccordionProps {
  items: Array<TegraAccordionItem>;
  style: object;
}


const TegraAccordion: React.FC<TegraAccordionProps> = ({
  items,
  style,
  ...rest
}) => {
  return (
    <Container style={style} {...rest}>
      {items.map(item => (
        <CustomAccordionItem key={item.uuid} uuid={item.uuid}>
          <CustomAccordionItemHeading>
            <CustomAccordionItemButton>
              <h2>{item.title}</h2>
              <button type="button">
                <img src={arrowDownIcon} alt="Seta" />
              </button>
            </CustomAccordionItemButton>
          </CustomAccordionItemHeading>
          <CustomAccordionItemPanel>
            { item.title === 'Normas e Procedimentos' ? (
              <>
                <a href={Pdf1} target="_blank">
                  <u>Clique aqui para ver o Código de Vestimenta.</u>
                </a><br /><br />
                <a href={Pdf2} target="_blank">
                  <u>Clique aqui para ver o Código de Conduta.</u>
                </a><br /><br />
                <a href={Pdf3} target="_blank">
                  <u>Clique aqui para ver o Guia Abrainc de Boas Práticas.</u>
                </a><br /><br />    
                {/* <a href={Pdf4} target="_blank">
                  <u>Clique aqui para ver Integração - Alma Tegra 2021.</u>
                </a><br /><br />    
                <a href={Pdf5} target="_blank">
                  <u>Clique aqui para ver LGPD.</u>
                </a><br /><br /> */}
              </>
            ) : (
              <div dangerouslySetInnerHTML={{__html: item.content}} />
              
            )}    
          </CustomAccordionItemPanel>
        </CustomAccordionItem>
      ))}
    </Container>
  );
};

export default TegraAccordion;
