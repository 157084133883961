import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Form, SubmitHandler } from '@unform/core';
import {
  Container,
  Content,
  MostrarTodosContainer,
  ImoveisContainer,
  PaginaPersonalizada,
  PaginaPersonalizadaActions,
  ExternalLink,
} from './styles';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import TegraCheckbox from '../../components/TegraCheckbox';
import TegraImovelMinhaPagina from './TegraImovelMinhaPagina';
import TegraButtonExtra from '../../components/TegraButtonExtra';
import shareIcon from '../../assets/tegra-icon-share.svg';
import openIcon from '../../assets/tegra-icon-open.svg';
import TegraTabsContainer from '../../components/TegraTabsContainer';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useLoader } from '../../hooks/loader';
import TegraModalCompartilhar from '../../components/TegraModalCompartilhar';
import {
  getTelegramURL,
  getFacebookURL,
  getWhatsappURL,
} from '../../utils/share';
import { ToSlug } from '../../services/helpers';
import { toast } from 'react-toastify';

interface PageAllFormProps {
  mostrar_todos: boolean;
}

interface Empreendimento {
  imagem: any;
  tipo: 'residencial' | 'sala_comercial';
  oculto: boolean;
  empreendimentoTipoNome: string;
  selecionados: number[];
  caminho: string;
  andamento: string;
  acao: number;
  isBanner: boolean;
  hasVideo: boolean;
  id: number;
  nome: string;
  chamada: string;
  imagemBuscaPequena: string;
  imagemBuscaGrande: string;
  slug: string;
  valorDe: number;
  valorAte: number;
  metragemDe: number;
  metragemAte: number;
  dormitoiosDe: number;
  dormitoiosAte: number;
  empreendimentoAndamentoId: number;
  empreendimentoAndamento: string;
  cidadeId: number;
  cidade: string;
  bairroId: number;
  bairro: string;
  empreendimentoTipoId: number;
  empreendimentoTipo: string;
  urlVirtual: string;
  uf: string;
}

interface EmpreendimentoResponse {
  empreendimentos: Empreendimento[];
  minhaURL: string;
}

interface IEmpreendimentoStatusFormData {
  mostrar_na_pagina: boolean;
  mostrar_banner_na_pagina: boolean;
}

const getUserName = (str: string): string => {
  try {
    return str.split(' ')[0];
  } catch (e) {
    return '';
  }
};

const MyPage: React.FC = () => {
  const { user } = useAuth();
  const { setLoaderActive } = useLoader();
  const allFormRef = useRef(null);
  const [imoveis, setImoveis] = useState<Empreendimento[]>([]);
  const [showShareModal, setShareModal] = useState<boolean>(false);

  const imoveisResidenciais = useMemo(() => {
    const countResidenciais = imoveis.filter(
      imovel =>
        imovel.empreendimentoTipoNome === 'Apartamento' && !imovel.oculto,
    ).length;
    return countResidenciais.toString();
  }, [imoveis]);

  const imoveisSalas = useMemo(() => {
    const countSala = imoveis.filter(
      imovel =>
        (imovel.empreendimentoTipoNome === 'Escritórios' || imovel.empreendimentoTipoNome === 'Escritório') && !imovel.oculto,
    ).length;
    const formattedCount = countSala.toString();
    return formattedCount;
  }, [imoveis]);

  const imoveisMisto = useMemo(() => {
    const countSala = imoveis.filter(
      imovel =>
        imovel.empreendimentoTipoNome === 'Mixed Used' && !imovel.oculto,
    ).length;
    const formattedCount = countSala.toString();
    return formattedCount;
  }, [imoveis]);

  const banners = useMemo(() => {
    const countBanner = imoveis.filter(imovel => imovel.isBanner).length;
    const formattedCount = countBanner.toString();
    return formattedCount;
  }, [imoveis]);

  const handleAbrirPaginaPersonalizada = useCallback(() => {
    // TODO abrir página personalizada
  }, []);

  const handleShowAllInMyPage: SubmitHandler<PageAllFormProps> = (
    data,
  ): void => {
    if (!allFormRef.current) {
      throw new Error('Sem form');
    }
    const status = imoveis.find(i => i.oculto) !== undefined;

    api.post('/MinhaPagina/pagina', {
      ids_empreendimentos: [...imoveis.map(item => item.id)],
      status,
    });

    const updatedImoveisList = imoveis.map(imovel => ({
      ...imovel,
      oculto: !status,
    }));
    setImoveis([...updatedImoveisList]);
  };

  const handleShow = useCallback(
    (id, oculto) => {
      const findImovelIndex = imoveis.findIndex(imovel => imovel.id === id);
      if (findImovelIndex < 0) {
        return;
      }

      api.post('/MinhaPagina/pagina', {
        ids_empreendimentos: [Number(id)],
        status: !oculto,
      });

      setImoveis(prev => {
        const update = prev;
        const findImovel = update[findImovelIndex];
        findImovel.oculto = oculto;
        update[findImovelIndex] = findImovel;
        return [...update];
      });
    },
    [imoveis],
  );

  const handleBanner = useCallback(
    (id, isBanner) => {
      const findImovelIndex = imoveis.findIndex(imovel => imovel.id === id);
      if (findImovelIndex < 0) {
        return;
      }

      api.post('/MinhaPagina/banner', {
        ids_empreendimentos: [Number(id)],
        status: isBanner,
      });

      setImoveis(prev => {
        const update = prev;
        const findImovel = update[findImovelIndex];
        findImovel.isBanner = isBanner;
        findImovel.oculto = !isBanner;
        update[findImovelIndex] = findImovel;
        return [...update];
      });
    },
    [imoveis],
  );

  useEffect(() => {
    setLoaderActive(true);
    api
      .get<EmpreendimentoResponse>('MinhaPagina', {
        // params: { nu_quantidade: 10 },
      })
      .then(res => {
        const { empreendimentos } = res.data;
        setImoveis(prev => [...prev, ...empreendimentos]);
      })
      .finally(() => setLoaderActive(false));
  }, [user.id]);

  const notify = () => toast("Link Copiado!");
  function copy() {
    navigator.clipboard.writeText(minhaURL);
  };

  const bannersLimitReached = Number(banners) === 3;
  const name = getUserName(user.apelido || user.nome);
  const minhaURL =
    user.idCargoSigavi === 14
      ? `http://portaldeparcerias.tegravendas.com.br/cadastro/${
          user.idRegional === 1 ? 'sp' : user.idRegional === 2 ? 'rj' : 'sp'
        }/${ToSlug(user.apelido)}`
      : `${process.env.REACT_APP_CORRETOR}${ToSlug(user.apelido)}/${
          user.idRegional === 1 ? 'sp' : user.idRegional === 2 ? 'rj' : 'sp'
        }`;

  return (
    <Container>
      <TegraModalCompartilhar
        handleClose={() => setShareModal(false)}
        isOpen={showShareModal}
        urlFacebook={getFacebookURL(minhaURL)}
        urlTelegram={getTelegramURL(minhaURL)}
        urlWhats={getWhatsappURL(minhaURL)}
      />
      {user.idCargoSigavi === 14 ? (
        <>
          <TegraOpaqueHero
            title="Minha página"
            description={<>Compartilhe seu link para que um corretor se torne um parceiro da Tegra. <br/>
              Quando o parceiro se cadastrar utilizando o seu link, o parceiro fará parte da sua carteira de parceiros de forma automática.</>}
          />
          <Content>
            <PaginaPersonalizada>
              <p>{name}, compartilhe esse link com seus parceiros.</p>
              <PaginaPersonalizadaActions>
                <ExternalLink>
                  <a
                    href={minhaURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Abrir página personalizada"
                  >
                    <TegraButtonExtra
                      onClick={handleAbrirPaginaPersonalizada}
                      isAction={false}
                      isExtra
                      icon={openIcon}
                    >
                      Abrir
                    </TegraButtonExtra>
                  </a>
                </ExternalLink>

                <TegraButtonExtra
                  onClick={() => setShareModal(true)}
                  isAction={false}
                  isExtra
                  icon={shareIcon}
                >
                  Compartilhar
                </TegraButtonExtra>

                <TegraButtonExtra
                    onClick={() => {
                      notify();
                      copy();
                    } }
                    isAction={false}
                    isExtra>
                  Copiar Link
                </TegraButtonExtra>

              </PaginaPersonalizadaActions>
            </PaginaPersonalizada>
          </Content>
        </>
      ) : (
        <>
          <TegraOpaqueHero
            title="Minha página"
            description={
              <>
                <strong>{imoveisResidenciais}
                 {Number(imoveisResidenciais) < 2 &&(
                    ' imóvel residencial'
                  )
                  }
                  {Number(imoveisResidenciais) > 1 &&(
                    ' imóveis residenciais'
                  )
                  }
               </strong>,{' '}

                <strong>{imoveisSalas}
                 {Number(imoveisSalas) < 2 &&(
                    ' sala comercial'
                  )
                  }
                  {Number(imoveisSalas) > 1 &&(
                    ' salas comerciais'
                  )
                  }
               </strong> e {' '}
               <br />
                <strong>{imoveisMisto} mixed used </strong>
                ativos na sua página
                <br />
                <strong>{banners} destaques</strong> ativos na sua página (
                {3 - Number(banners)} restantes)
              </>
            }
          />
          <Content>
            <PaginaPersonalizada>
              <p>{name}, veja a sua página personalizada</p>
              <PaginaPersonalizadaActions>
                <ExternalLink>
                  <a
                    href={minhaURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Abrir página personalizada"
                  >
                    <TegraButtonExtra
                      onClick={handleAbrirPaginaPersonalizada}
                      isAction={false}
                      isExtra
                      icon={openIcon}
                    >
                      Abrir
                    </TegraButtonExtra>
                  </a>
                </ExternalLink>

                <TegraButtonExtra
                  onClick={() => setShareModal(true)}
                  isAction={false}
                  isExtra
                  icon={shareIcon}
                >
                  Compartilhar
                </TegraButtonExtra>

                <TegraButtonExtra
                    onClick={() => {
                      notify();
                      copy();
                    } }
                    isAction={false}
                    isExtra>
                  Copiar Link
                </TegraButtonExtra>

              </PaginaPersonalizadaActions>
            </PaginaPersonalizada>

            <TegraTabsContainer>
              <Tabs>
                <TabList>
                  <Tab>Todos</Tab>
                  <Tab>Selecionados</Tab>
                  <Tab>Não selecionados</Tab>
                </TabList>

                <MostrarTodosContainer>
                  <Form ref={allFormRef} onSubmit={handleShowAllInMyPage}>
                    <TegraCheckbox
                      formRef={allFormRef}
                      checked={!imoveis.find(i => i.oculto)}
                      name="mostrar_todos"
                      handleOnChange={handleShowAllInMyPage}
                      label="Mostrar todos na minha página"
                    />
                  </Form>
                </MostrarTodosContainer>

                <TabPanel>
                  <ImoveisContainer>
                    {imoveis.map(imovel => (
                      <TegraImovelMinhaPagina
                        handleShow={handleShow}
                        handleBanner={handleBanner}
                        key={imovel.id}
                        imovel={imovel}
                        bannersLimitReached={bannersLimitReached}
                      />
                    ))}
                  </ImoveisContainer>
                </TabPanel>

                <TabPanel>
                  {imoveis
                    .filter(imovel => !imovel.oculto)
                    .map(imovel => (
                      <TegraImovelMinhaPagina
                        handleShow={handleShow}
                        handleBanner={handleBanner}
                        key={`selecionados-${imovel.id}`}
                        imovel={imovel}
                        bannersLimitReached={bannersLimitReached}
                      />
                    ))}
                </TabPanel>

                <TabPanel>
                  {imoveis
                    .filter(imovel => imovel.oculto)
                    .map(imovel => (
                      <TegraImovelMinhaPagina
                        handleShow={handleShow}
                        handleBanner={handleBanner}
                        key={`nao-selecionados-${imovel.id}`}
                        imovel={imovel}
                        bannersLimitReached={bannersLimitReached}
                      />
                    ))}
                </TabPanel>
              </Tabs>
            </TegraTabsContainer>
          </Content>
        </>
      )}
    </Container>
  );
};

export default MyPage;
