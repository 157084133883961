/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Form, FormHandles } from '@unform/core';
import { Container as GridContainer, Row, Col } from 'react-grid-system';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import moment from 'moment';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import {
  Container,
  CorretoresContainer,
  SearchContainer,
  CorretoresTable,
  ListaAction,
  FormActions,
  ContainerModal,
  Espace,
} from './styles';
import TegraSelect from '../../../components/TegraSelect';
import openIcon from '../../../assets/tegra-icon-open.svg';
import TegraDivider from '../../../components/TegraDivider';
import TegraButtonExtra from '../../../components/TegraButtonExtra';
import TegraButton from '../../../components/TegraButton';
import api from '../../../services/api';
import { useLoader } from '../../../hooks/loader';
import { useAuth } from '../../../hooks/auth';
import { groupBy } from '../../../services/helpers';
import 'moment/locale/pt';
import { CloseButton } from '../../../components/ModalRegional/styles';
import { ReactComponent as CloseIcon } from '../../../assets/tegra-icon-close.svg';
import ajaxLoading from '../../../assets/ajax-loader.gif';
import TegraTextArea from '../../../components/TegraTextArea';
import { ContainerTextArea } from '../../RegistroVisita/styles';
//import './PresencaLista.sass';

moment.locale('pt');
moment.updateLocale('pt', {
  weekdaysMin: 'dom._seg._ter._qua._qui._sex._sáb.'.split('_'),
});

const searchFormInitialData = {
  tipo_parceiro: {
    value: undefined,
    label: 'Todos',
  },
  status: {
    value: undefined,
    label: 'Todos',
  },
  regional: {
    value: undefined,
    label: 'Todas',
  },
  superintendente: {
    value: '',
    label: '',
  },
  gerente: {
    value: '',
    label: '',
  },
  busca: '',
};

export interface ICorretor {
  id_parceiro: string;
  id_tipoCorretor: number;
  id_tipoParceiro: number;
  txt_tipoParceiro: string;
  tx_razaoSocial: string;
  tx_nome: string;
  tx_creci: string;
  tx_email: string;
  superintendente: string;
  gerente: string;
  tx_uf: string;
  tx_regional: string;
  id_situacao: string;
  tx_situacao: string;
}

interface Filter {
  id?: number;
  nome: string;
}

interface Regional extends Filter {
  uf: string;
}

interface Estado extends Filter {
  uf: string;
  regionais: {
    regional: Filter;
  }[];
}
interface ToggleProps {
  setDetail: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  setItem: (value: any) => void;
}

export const optionsRegional = [
  { label: 'Tegra SP', value: 1 },
  { label: 'Tegra Campinas', value: 3 },
  { label: 'Tegra RJ', value: 2 },
  { label: 'Tegra Lab', value: 4 },
];

export const optionsTipoEvento = [
  { label: 'Visita na Imobiliária - Parceiro', value: 1 },
  { label: 'Treinamento na imobiliária', value: 2 },
  { label: 'Treinamento no Stand - PDV', value: 3 },
  { label: 'Endo marketing com promotores - Ativação', value: 4 },
  { label: 'Reunião Online', value: 5 },
  { label: 'Reunião Online com Cliente', value: 6 },
  { label: 'Outros', value: 7 },
];

export const optionsLocalEvento = [
  { label: 'Casa Tegra', value: 1 },
  { label: 'Ponto de venda', value: 2 },
  { label: 'Escritório', value: 3 },
  { label: 'Tegra Store Sul', value: 4 },
  { label: 'Tegra Store Leste', value: 5 },
  { label: 'Imobiliária Parceiro', value: 6 },
];

export const qtdePessoasEvento = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '+ de 8', value: 8 },
];

const PresencaLista: React.FC<ToggleProps> = ({ setDetail, setItem }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any[]>([]);
  const [gerentes, setGerentes] = useState<any[]>([]);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment());
  const [isOpen, setIsOpen] = useState<any>(false);
  const [regional, setRegional] = useState<any>('');
  const [regionalSel, setRegionalSel] = useState<any>('');
  // const [estadoSel, setEstadoSel] = useState<any>('');
  const [corretor, setCorretor] = useState<any>([]);
  const [gerente, setGerente] = useState<any>('');
  const [focusedInput, setFocusedInput] = useState(false);
  const [focusDate, setFocusDate] = useState<any>();
  const [date, setDate] = useState(moment());
  const [produto, setProduto] = useState<any>('');
  const [tipoevento, setTipoEvento] = useState<any>('');
  const [localEvento, setLocalEvento] = useState<any>('');
  const [qtdePessoasvento, setQtdePessoasEvento] = useState<any>('');
  const [resumoEvento, setResumoEvento] = useState<any>('');

  const [corretores, setCorretores] = useState<any[]>([]);
  // const [corretoresModal, setCorretoresModal] = useState<any[]>([]);
  const [estados, setEstados] = useState<Estado[]>([]);
  const [estadosModal, setEstadosModal] = useState<Estado[]>([]);
  const [estadosSelect, setEstadosSelect] = useState<any[]>([]);
  const [estado, setEstado] = useState<any>([]);
  const [estadoModal, setEstadoModal] = useState<any>([]);
  const [regionais, setRegionais] = useState<Filter[]>([]);
  const [allRegionais, setAllRegionais] = useState<Regional[]>([]);
  const [totalPaginas, setTotalPaginas] = useState<number>(1);
  const [empreendimentos, setEmpreendimentos] = useState<any[]>([]);
  const [empreendimentosFiltro, setEmpreendimentosFiltro] = useState<any[]>([]);
  const [empreendimentosModal, setEmpreendimentosModal] = useState<any[]>([]);
  const [allEmpreendimento, setAllEmpreendimentos] = useState<any[]>([]);
  const [supervisorEmpreendimento, setSupervisorEmpreendimentos] = useState<
    any[]
  >([]);
  const [supervisorNomeEmpreendimento, setSupervisorNomeEmpreendimento] =
    useState<any[]>([]);
  const { setLoaderActive } = useLoader();
  const { user } = useAuth();
  const [selectCorretores, setSelectCorretores] = useState<any[]>([
    { corretor: { label: 'Todos', value: '' }, nu_order: 0 },
  ]);

  // Router
  const { path, url } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    async function loadGerentes() {
      // const resGerentes = await api.get<any[]>(`Gerente/listGerentes`);
      const resGerentesParcerias = await api.get<any[]>(
        `Gerente/listGerentesParcerias`,
      );

      if (user.idCargoSigavi == 14) {
        setGerentes(
          resGerentesParcerias.data
            .filter(e => e.tx_apelido == user.apelido)
            .map((d: any) => ({
              label: d.tx_apelido,
              value: d.id_corretorSigavi,
              sup: d.id_sup,
            })),
        );
        setGerente(
          resGerentesParcerias.data
            .filter(e => e.tx_apelido == user.apelido)
            .map((d: any) => ({
              label: d.tx_apelido,
              value: d.id_corretorSigavi,
              sup: d.id_sup,
            }))[0],
        );
      } else if (user.idCargoSigavi == 27) {
        setGerentes(
          resGerentesParcerias.data
            .filter(e => e.tx_equipe == user.apelido)
            .map((d: any) => ({
              label: d.tx_apelido,
              value: d.id_corretorSigavi,
              sup: d.id_sup,
            })),
        );
      } else {
        setGerentes([]);
      }

      loadCorretores();
      // loadEstado();
      setRegional(optionsRegional.find(x => x.value == user.idRegional));
      setRegionalSel(optionsRegional.find(x => x.value == user.idRegional));
    }

    // async function loadCorretores() {
    //   const res = await api.get(`evento/CorretoresImoveisList`, {
    //     params: { nu_pagina: 1, nu_quantidade: 10 },
    //   });
    //   setCorretores(
    //     res.data.map((d: any) => ({
    //       label: d.tx_apelido,
    //       value: d.id_corretorSigavi,
    //       empresaSigavi: d.tx_empresaSigavi,
    //     })),
    //   );
    // }

    async function loadEstado() {
      // carregar regionais da api
      const estadoRes = await api.get<Estado[]>(`Estados`);
      const { data: estadoData } = estadoRes;
      estadoData.unshift({
        nome: 'Todos',
        uf: '',
        id: undefined,
        regionais: [],
      });
      setEstados(estadoData);
      // if ([8, 10, 16, 18, 23, 26, 28].indexOf(user.idCargoSigavi) > -1) {
      //   setEstadosModal(estadoData.filter(e => e.id == user.idEstado));
      //   setEstadosSelect(
      //     estadoData
      //       .map(est => ({
      //         value: est.uf,
      //         label: est.nome,
      //         id: est.id,
      //       }))
      //       .filter(e => e.id == user.idEstado),
      //   );

      //   setEstadoModal(
      //     estadoData
      //       .map(est => ({
      //         value: est.uf,
      //         label: est.nome,
      //         id: est.id,
      //       }))
      //       .filter(e => e.id == user.idEstado)[0],
      //   );
      // } else {
        setEstadosModal(estadoData);
        setEstadosSelect(
          estadoData.map(est => ({
            value: est.uf,
            label: est.nome,
            id: est.id,
          })),
        );
      // }

      setEstado(estadoData.find(x => x.id == user.idEstado));
      // setEstadoSel(estadoData.find(x => x.id == user.idEstado));
    }

    async function loadRegionais() {
      //setAllRegionais(regionalData);
      setAllRegionais([
        { id: undefined, nome: 'Todas', uf: '' },
        { id: 1, nome: 'Tegra SP', uf: 'SP' },
        { id: 2, nome: 'Tegra RJ', uf: 'RJ' },
        { id: 3, nome: 'Tegra Campinas', uf: 'SP' },
        { id: 4, nome: 'Tegra Lab', uf: 'SP' },
      ]);
    }

    async function load() {
      try {
        // carregar corretores da api
        setLoaderActive(true);

        // loadCorretores();
        loadGerentes();
        // loadEstado();
        loadRegionais();
        
        const respEsc = await api.get(`Escritorio/GetAllEscritorios`);
        const resEmp = await api.get(`Empreendimento/listallpresencaoplantao`);
        setAllEmpreendimentos(
          FormatarListaEmpreendimento(resEmp.data, respEsc.data),
        );

        if (user.idCargoSigavi == 18 || user.idCargoSigavi == 8 || user.idCargoSigavi == 10)  {
          const resSup = await api.get(
            `Empreendimento/listEmpreendimentosNaoVendidosPorSupervisor`,
            {
              params: {
                tx_apelido: user.apelido,
              },
            },
          );
          setSupervisorEmpreendimentos(
            FormatarListaEmpreendimento(resSup.data, respEsc.data),
          );
          setSupervisorNomeEmpreendimento(
            resSup.data
              .filter(item => item.id !== undefined)
              .map((c: any) => c.nome),
          );
        }
        setLoaderActive(false);
      } catch (e) {
        console.log(e);
      }
    }
    load();
    doSearch();
  }, []);

  const ListaEmpreendimento = useCallback(async () => {
    if (user.idCargoSigavi == 18 || user.idCargoSigavi == 8 || user.idCargoSigavi == 10) {
      setEmpreendimentosFiltro(supervisorEmpreendimento);
    } else {
      setEmpreendimentosFiltro(allEmpreendimento);
    }
  }, [empreendimentos, supervisorEmpreendimento]);

  function FormatarListaEmpreendimento(resp, respEsc) {
    const est = [
      { label: 'Rio de Janeiro', value: 19 },
      { label: 'São Paulo', value: 26 },
      { label: 'Campinas', value: 4793 },
    ];

    const arr = resp.map((c: any) => ({
      value: c.id,
      label: c.nome,
      tipo: c.logradouro,
      cidadeid: c.cidadeId,
      estado: est.find(
        e => e.value === (c.cidadeId === 4793 ? c.cidadeId : c.estadoId),
      )?.label,
      isEscritorio: false,
    }));

    const Escritorio = respEsc.map((x: any) => ({
      value: x.id,
      label: x.nome,
      tipo: null,
      cidadeid: x.cidadeId,
      estado: x.tx_estado,
      isEscritorio: true,
    }));

    var empLista = [...Escritorio, ...arr];

    empLista.sort((a, b) => a.label.localeCompare(b.label, 'pt'));

    const g = groupBy(empLista, 'estado');
    const lst: any[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const e in g) {
      if (g[e].length > 0) {
        lst.push({
          label: e,
          options: g[e],
        });
      }
    }
    return lst;
  }

  useEffect(() => {
    if (!estado.id && !regional.id) {
      setEmpreendimentos(allEmpreendimento);
    } else if (regional.id) {
      setEmpreendimentos(
        regional.id == 1 || regional.id == 4
          ? allEmpreendimento.filter(e => e.label === 'São Paulo')
          : regional.id == 3
          ? allEmpreendimento.filter(e => e.label === 'Campinas')
          : allEmpreendimento.filter(e => e.label === 'Rio de Janeiro'),
      );
    } else {
      setEmpreendimentos(
        estado.nome === 'São Paulo'
          ? allEmpreendimento.filter(
              e => e.label === 'São Paulo' || e.label === 'Campinas',
            )
          : allEmpreendimento.filter(e => e.label === estado.nome),
      );
    }
  }, [allEmpreendimento, estado, regional]);


  const encontrarCorretorFormRef = useRef<FormHandles>(null);

  async function loadCorretores() {
    setCorretores([]);
    const gerenteId = user.idCargoSigavi == 14 ? user.id : gerente.value;

    if (gerenteId) {
      const resCoretores = await api.get<any[]>(
        `Gerente/listCorretoresByGerente`,
        { params: { gerente: gerenteId } },
      );

      setCorretores(
        resCoretores.data.map((d: any) => ({
          label: d.tx_razaoSocial,
          value: d.id_parceiro,
        })),
      );
    }
  }

  useEffect(() => {
    loadCorretores();
  }, [gerente]);

  useEffect(() => {
    if (!regionalSel) {
      setEmpreendimentosModal([]);
      // setCorretoresModal([]);
    } else {
      setSelectCorretores([
        { corretor: { label: 'Todos', value: '' }, nu_order: 0 },
      ]);
      setProduto('');
      // setCorretoresModal(
      //   corretores.filter(e => {
      //     if (regionalSel.value == 1) {
      //       return e.empresaSigavi === 'Tegra SP';
      //     } else if (regionalSel.value == 3) {
      //       return e.empresaSigavi === 'Tegra Campinas';
      //     } else if (regionalSel.value == 4) {
      //       return e.empresaSigavi === 'Tegra Lab';
      //     } else {
      //       return e.empresaSigavi === 'Tegra RJ';
      //     }
      //   }),
      // );
      if (regionalSel.value == 1 || regionalSel.value == 4) {
        setEmpreendimentosModal(
          empreendimentosFiltro.filter(e => e.label === 'São Paulo'),
        );
      } else if (regionalSel.value == 2) {
        setEmpreendimentosModal(
          empreendimentosFiltro.filter(e => e.label === 'Rio de Janeiro'),
        );
      } else if (regionalSel.value == 3) {
        setEmpreendimentosModal(
          empreendimentosFiltro.filter(e => e.label === 'Campinas'),
        );
      }
    }
  }, [empreendimentosFiltro, estadoModal, regionalSel]);

  const getParams = () => {
    if (!encontrarCorretorFormRef.current) {
      return {
        dataInicio: startDate.toDate(),
        dataFim: endDate.toDate(),
        page: currentPage,
      };
    }
    
    const searchData = encontrarCorretorFormRef.current.getData() as any;
    // eslint-disable-next-line consistent-return
    return {
      corretor: searchData?.corretores?.value,
      dataInicio: startDate.toDate(),
      dataFim: endDate.toDate(),
      empreendimento: searchData?.empreendimento?.isEscritorio
        ? null
        : searchData?.empreendimento?.value,
      estado: searchData?.estado?.id,
      gerente: user.idCargoSigavi == 14 ? user.id : searchData?.gerente?.value ,
      regional: searchData?.regional?.value,
      tipo_evento: searchData?.tipoEvento?.value,
      local_evento: searchData?.localEvento?.value,
      qtde_pessoas: searchData?.qtdePessoasEvento?.value,
      page: currentPage,
      superintendente: user.idCargoSigavi == 27 ? user.id : 0,
      escritorio: searchData?.empreendimento?.isEscritorio
        ? searchData?.empreendimento?.value
        : null,
    };
  };

  const doSearch = () => {
    setLoaderActive(true);
    const params = getParams();
    api.get(`eventoparcerias/EventosParceirosList`, { params }).then((res: any) => {
      const {
        data: { lista, nu_total },
      } = res;
      setList([...(currentPage === 1 ? [] : list), ...lista]);
      setTotalPaginas(nu_total);
      setLoaderActive(false);
    });
  };

  const handleLoadMore = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage]);

  const handleSearch = useCallback(() => {
    setCurrentPage(1);
    doSearch();
  }, [startDate, endDate]);

  // useEffect(() => {
  //   doSearch();
  // }, [currentPage]);

  const exportList = async (): Promise<void> => {
    setLoaderActive(true);
    const params = getParams();

    const response = await api.get(`eventoparcerias/getRelatorioParceria`, {
      params,
      responseType: 'blob',
    });

    const nav = window.navigator as any;
    if (nav.msSaveOrOpenBlob) {
      // IE11
      nav.msSaveOrOpenBlob(new Blob([response.data]), 'MeusCorretores.xlsx');
    } else {
      const urlDown = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlDown;
      link.setAttribute('download', 'Presenca_Evento_Parceria.xlsx');
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(urlDown);
      }, 100);
    }
    setLoaderActive(false);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const handleSubmit = () => {
    if (!tipoevento.value || !produto.value) {
      toast('Preencha todos campos para adicionar', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        type: 'error',
      });
      return;
    }

    for (let i = 0; i < selectCorretores.length; i = i + 1) {
      if (selectCorretores[i].corretor.value === '') {
        toast('Preencha todos campos para adicionar', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          type: 'error',
        });
        return false;
      }
    }

    let data = {
      dt_data: new Date(
        Date.UTC(
          date.toDate().getFullYear(),
          date.toDate().getMonth(),
          date.toDate().getDate(),
          date.toDate().getHours(),
          date.toDate().getMinutes(),
          date.toDate().getSeconds(),
        ),
      ),
      corretores: selectCorretores.map((sc: any, index: number) => {
        return { id_corretor: sc.corretor.value, nu_order: index + 1 };
      }),
      tipo_evento: tipoevento.value,
      local_evento: localEvento.value,
      qtde_pessoas: qtdePessoasvento.value,
      resumo_evento: resumoEvento,
      id_produto: produto.isEscritorio ? null : produto.value,
      id_responsavel: user.id,
      id_superintendente: gerente.sup,
      id_gerente: gerente.value,
      id_escritorio: produto.isEscritorio ? produto.value : null,
    };
    console.log('DATAA', data);
    setLoading(true);
    api
      .post(`eventoparcerias/insertEventoParcerias`, data)
      .then(() => {
        setIsOpen(false);
        toast('Presença registrada com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          type: 'success',
        });
        handleSearch();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast('Ocorreu um erro ao inserir, tente novamente mais tarde', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          type: 'error',
        });
      });
  };

  const handleCancel = () => {
    setDate(moment());
    setCorretor('');
    setProduto('');
    setTipoEvento('');
    setIsOpen(false);
  };

  const handleLimpar = () => {
    if (encontrarCorretorFormRef.current) {
      encontrarCorretorFormRef.current.reset();
      setStartDate(moment().startOf('month'));
      setEndDate(moment());
      if (user.idCargoSigavi == 27) {
        setGerente('');
      }
    }
    handleSearch();
  };

  const addCorretor = () => {
    setSelectCorretores(prevState => [
      ...prevState,
      { corretor: { label: 'Todos', value: '' }, nu_order: 0 },
    ]);
  };

  const removeCorretor = (idx: any, id: any) => {
    id !== ''
      ? setSelectCorretores(
          selectCorretores.filter(el => el.corretor.value !== id),
        )
      : setSelectCorretores(selectCorretores.filter((el, i) => i !== idx));
  };

  const hadleChangeCorretor = (idx: any) => (e: any) => {
    let newArr = [...selectCorretores];
    newArr[idx].corretor = e;
    setSelectCorretores(newArr);
  };

  const handleClickDetail = (item: any) => {
    console.log('item',item);
    setItem(item);    
    setDetail(true);
  };


  const onChangeGerente = useCallback((e:any) => {
    setGerente(e);
    setSelectCorretores([{ corretor: { label: 'Todos', value: '' }, nu_order: 0 },]);
  }, [gerente, selectCorretores]);

  return (
    <Container>
      <SearchContainer>
        <Form
          ref={encontrarCorretorFormRef}
          onSubmit={handleSearch}
          initialData={searchFormInitialData}
        >
          <GridContainer
            className="grid-container"
            style={{
              padding: 0,
              marginLeft: '0px',
              maxWidth: '100%',
              marginRight: '0px',
            }}
          >
            <Row style={{ marginRight: '0px', marginTop: '10px' }}>
              <Col sm={12} md={6} lg={6} xl={4}>
                <label className="custom-label">Datas</label>
                <DateRangePicker
                  isOutsideRange={(day: any) => false}
                  focusedInput={focusedInput}
                  startDate={startDate}
                  startDateId="your_unique_start_date_id"
                  endDate={endDate}
                  minimumNights={0}
                  endDateId="your_unique_end_date_id"
                  onFocusChange={(f: any) => setFocusedInput(f)}
                  onDatesChange={(obj: any) => {
                    setStartDate(obj.startDate);
                    setEndDate(obj.endDate);
                  }}
                />
              </Col>

              <Col sm={12} md={6} lg={6} xl={4}>
                <TegraSelect
                  placeholder="Todos"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  label="Regional"
                  name="regional"
                  isDisabled={true}
                  inputValue={regional.label}
                  value={regionalSel}
                  options={regionais.map(regional => ({
                    value: regional.id,
                    label: regional.nome,
                  }))}
                />
              </Col>
            </Row>
            <Row style={{ marginRight: '0px', marginTop: '10px' }}>
              <Col sm={12} md={6} lg={6} xl={4}>
                <TegraSelect
                  placeholder="Todos"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  label="Gerente"
                  name="gerente"
                  onChange={setGerente}
                  value={user.idCargoSigavi == 14 ? gerentes[0] : gerente}
                  options={user.idCargoSigavi == 14 ? [] : gerentes}
                  isDisabled={user.idCargoSigavi == 14 ? true : false}
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={4}>
                <TegraSelect
                  placeholder="Todos"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  label="Corretores"
                  name="corretores"
                  options={corretores}
                />
              </Col>

              <Col sm={12} md={6} lg={6} xl={4}>
                <TegraSelect
                  placeholder="Todos"
                  className="empreendimentoRelacionado"
                  label="Empreendimento"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  name="empreendimento"
                  options={empreendimentos}
                />
              </Col>
            </Row>
            <Row style={{ marginRight: '0px', marginTop: '10px' }}>
              <Col sm={12} md={6} lg={6} xl={4}>
                <TegraSelect
                  placeholder="Selecionar"
                  className="tipoEvento"
                  label="Tipo de Evento"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  name="tipoEvento"
                  options={optionsTipoEvento}
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={4}>
                <TegraSelect
                  placeholder="Selecionar"
                  className="localEvento"
                  label="Local do Evento"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  name="localEvento"
                  options={optionsLocalEvento}
                />
              </Col>
              <Col sm={12} md={6} lg={6} xl={4}>
                <TegraSelect
                  placeholder="Selecionar"
                  className="qtdePessoasEvento"
                  label="Quantidade de Pessoas"
                  noOptionsMessage={() => 'Nenhum selecionado'}
                  name="qtdePessoasEvento"
                  options={qtdePessoasEvento}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12} xl={12}>
                <FormActions className="flex-start">
                  <div>
                    <TegraButton isAction onClick={handleLimpar}>
                      LIMPAR
                    </TegraButton>
                    <TegraButton isAction onClick={handleSearch}>
                      BUSCAR
                    </TegraButton>
                    <TegraButton isAction onClick={exportList}>
                      EXPORTAR
                    </TegraButton>
                  </div>
                  <TegraButton
                    isAction
                    onClick={() => {
                      setIsOpen(true);
                      ListaEmpreendimento();
                    }}
                  >
                    ADICIONAR
                  </TegraButton>
                </FormActions>
              </Col>
            </Row>
          </GridContainer>
        </Form>
      </SearchContainer>
      <TegraDivider color="#e6e6e6" axis="x" height={1} />
      <CorretoresContainer>
        <CorretoresTable>
          <thead>
            <tr>
              <th>Data</th>
              <th>Tipo Evento</th>
              <th>Local do Evento</th>
              <th>Quantidade de Pessoas</th>
              <th>Produto</th>
              <th>UF</th>
              <th>Corretores</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {list.map(item => (
              <tr key={item.id_parceiro}>
                <td>
                  <span className="caption">Data</span>
                  {moment(item.dt_data).format('DD/MM/yyyy')}
                </td>
                <td>
                  <span className="caption">Tipo Evento</span>
                  {
                    optionsTipoEvento.find(x => x.value == item.tipo_evento)
                      ?.label
                  }
                </td>
                <td>
                  <span className="caption">Local do Evento</span>
                  {
                    optionsLocalEvento.find(x => x.value == item.local_evento)
                      ?.label
                  }
                </td>
                <td>
                  <span className="caption">Quantidade de Pessoas</span>
                  {
                    qtdePessoasEvento.find(x => x.value == item.qtde_pessoas)
                      ?.label
                  }
                </td>
                <td>
                  <span className="caption">Produto</span>
                  {item.produto?.nome}
                </td>
                <td>
                  <span className="caption">UF</span>
                  {item.estado?.uf}
                </td>
                <td>
                  <span className="caption">Corretores</span>
                  {item.nu_entradas}
                </td>
                <td className="action">
                  {([8, 10, 16, 18, 23, 26, 28].indexOf(user.idCargoSigavi) <=
                    -1 ||
                    (user.idCargoSigavi != 8 && user.idCargoSigavi != 10 &&
                      user.idRegional == item.id_empresaSigavi) ||
                    (user.idCargoSigavi != 8 && user.idCargoSigavi != 10 &&
                      user.idRegional == 4 &&
                      item.id_empresaSigavi == 1) ||
                    ((user.idCargoSigavi == 18 || user.idCargoSigavi == 8 || user.idCargoSigavi == 10) &&
                      supervisorNomeEmpreendimento.indexOf(item.produto?.nome) >
                        -1)) && (
                    <TegraButtonExtra
                      onClick={() => handleClickDetail(item)}
                      isAction={false}
                      isExtra
                    >
                      Visualizar
                    </TegraButtonExtra>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </CorretoresTable>
        {currentPage < totalPaginas && (
          <ListaAction>
            <TegraButton isAction onClick={handleLoadMore}>
              Carregar mais
            </TegraButton>
          </ListaAction>
        )}
      </CorretoresContainer>

      {/* Modal de inclusão */}
      <Modal
        isOpen={isOpen}
        contentLabel="Presença Plantão"
        portalClassName="modal-presenca"
      >
        <ContainerModal>
          <CloseButton onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </CloseButton>
          <h3>Adicionar presença em evento</h3>

          <Form
            // ref={encontrarCorretorFormRef}
            onSubmit={handleSubmit}
            // initialData={searchFormInitialData}
          >
            <label className="custom-label">Data</label>
            <SingleDatePicker
              isOutsideRange={(day: any) => false}
              focused={focusDate}
              date={date}
              id="start_date_id2"
              onFocusChange={(f: any) => setFocusDate(f.focused)}
              onDateChange={(date: any) => {
                setDate(date);
              }}
            />

            <TegraSelect
              placeholder="Selecione a Regional"
              noOptionsMessage={() => 'Nenhum selecionado'}
              label="Regional"
              name="regional"
              onChange={setRegionalSel}
              isDisabled={true}
              inputValue={regional.label}
              value={regionalSel}
            />

            <TegraSelect
              placeholder={
                empreendimentosModal.length > 0
                  ? 'Nenhum'
                  : 'Nenhum produto disponível'
              }
              noOptionsMessage={() =>
                empreendimentosModal.length > 0
                  ? 'Nenhum selecionado'
                  : 'Nenhum produto disponível'
              }
              label="Produto"
              name="produto"
              onChange={setProduto}
              options={empreendimentosModal}
              value={produto}
            />

            <TegraSelect
              placeholder="Selecione o tipo evento"
              noOptionsMessage={() => 'Nenhum selecionado'}
              label="Tipo evento"
              name="tipoevento"
              onChange={setTipoEvento}
              options={optionsTipoEvento}
            />

            <TegraSelect
              placeholder="Selecionar"
              className="localEvento"
              label="Local do Evento"
              noOptionsMessage={() => 'Nenhum selecionado'}
              name="localEvento"
              onChange={setLocalEvento}
              options={optionsLocalEvento}
            />

            <TegraSelect
              placeholder="Selecionar"
              className="qtdePessoasEvento"
              label="Quantidade de Pessoas"
              noOptionsMessage={() => 'Nenhum selecionado'}
              name="qtdePessoasEvento"
              onChange={setQtdePessoasEvento}
              options={qtdePessoasEvento}
            />

            <ContainerTextArea>
              <p>Resumo do evento</p>
              <TegraTextArea
                name="resumoEvento"
                style={{ width: '100%' }}
                onChange={e => setResumoEvento(e.target.value)}
              />
            </ContainerTextArea>

            <TegraSelect
              placeholder="Todos"
              noOptionsMessage={() => 'Nenhum selecionado'}
              label="Gerente"
              name="gerente"
              onChange={onChangeGerente}
              value={user.idCargoSigavi == 14 ? gerentes[0] : gerente}
              options={user.idCargoSigavi == 14 ? [] : gerentes}
              isDisabled={user.idCargoSigavi == 14 ? true : false}
            />

            {selectCorretores.map((val, idx) => {
              return (
                <div className="corretor-select" key={idx}>
                  <TegraSelect
                    placeholder="Todos"
                    noOptionsMessage={() => 'Nenhum selecionado'}
                    label={`Corretor #${idx + 1}`}
                    name="corretor"
                    onChange={hadleChangeCorretor(idx)}
                    value={val.corretor}
                    options={corretores}
                  />

                  {idx === 0 && selectCorretores.length === 1 ? (
                    <button className="plus" onClick={addCorretor} />
                  ) : idx !== selectCorretores.length - 1 ? (
                    <button
                      className="minus"
                      onClick={() => removeCorretor(idx, val.corretor.value)}
                    />
                  ) : (
                    <>
                      <button
                        className="minus"
                        onClick={() => removeCorretor(idx, val.corretor.value)}
                      />
                      <button className="plus" onClick={addCorretor} />
                    </>
                  )}
                </div>
              );
            })}
            <Espace />
          </Form>
        </ContainerModal>
        <ListaAction>
          <TegraButton isAction isDisabled={loading} onClick={handleCancel}>
            Cancelar
          </TegraButton>
          <TegraButton isAction isDisabled={loading} onClick={handleSubmit}>
            Salvar
            {loading && <img src={ajaxLoading} alt="Ícone do botão" />}
          </TegraButton>
        </ListaAction>
      </Modal>
    </Container>
  );
};

export default PresencaLista;
