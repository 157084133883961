import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import { Form, SubmitHandler } from '@unform/core';
import {
  Container,
  ImovelImagem,
  ImovelInfoActions,
  ImovelInfoHeader,
  ImovelInfo,
} from './styles';
import { ToSlug } from '../../../services/helpers';
import TegraDivider from '../../../components/TegraDivider';
import TegraCheckbox from '../../../components/TegraCheckbox';
import api from '../../../services/api';

interface Imovel {
  imagem: any;
  tipo: 'residencial' | 'sala_comercial';
  oculto: boolean;
  empreendimentoTipoNome: string;
  selecionados: number[];
  caminho: string;
  andamento: string;
  acao: number;
  isBanner: boolean;
  hasVideo: boolean;
  id: number;
  nome: string;
  chamada: string;
  imagemBuscaPequena: string;
  imagemBuscaGrande: string;
  slug: string;
  valorDe: number;
  valorAte: number;
  metragemDe: number;
  metragemAte: number;
  dormitoiosDe: number;
  dormitoiosAte: number;
  empreendimentoAndamentoId: number;
  empreendimentoAndamento: string;
  cidadeId: number;
  cidade: string;
  bairroId: number;
  bairro: string;
  empreendimentoTipoId: number;
  empreendimentoTipo: string;
  urlVirtual: string;
  uf: string;
}

interface TegraImovelMinhaPaginaProps {
  imovel: Imovel;
  handleBanner: Function;
  handleShow: Function;
  bannersLimitReached: boolean;
}

interface ImovelFormProps {
  mostrar_na_pagina: boolean;
  mostrar_banner_na_pagina: boolean;
}
interface IAndamentoTypes {
  [key: number]:
    | 'lancamento'
    | 'breve_lancamento'
    | 'construcao'
    | 'entregue'
    | 'futuro_lancamento';
}
const Andamentos: IAndamentoTypes = {
  4: 'lancamento',
  3: 'breve_lancamento',
  2: 'construcao',
  1: 'entregue',
  5: 'futuro_lancamento',
};

const TegraImovelMinhaPagina: React.FC<TegraImovelMinhaPaginaProps> = ({
  imovel,
  handleBanner,
  handleShow,
  bannersLimitReached,
}) => {
  const formRef = useRef(null);

  const handleFormSubmit: SubmitHandler<ImovelFormProps> = (data): void => {
    if (!formRef.current) {
      throw new Error('Sem form');
    }

    // handleOptionsChange(imovel.id, data);
  };

  // TODO
  // verificar se o path esta correto
  const path = 'produtos';

  return (
    <Container>
      <Link to={`${path}/${imovel.uf?.toLowerCase()}/${ToSlug(imovel.cidade,)}/${imovel.slug}`}>
        <ImovelImagem
          bg={imovel.imagemBuscaGrande}
          status={Andamentos[imovel.empreendimentoAndamentoId]}
        />
      </Link>

      <ImovelInfo>
        <ImovelInfoHeader>
          <strong>{imovel.bairro}</strong>
          <h1>{imovel.nome}</h1>
          <p>{`${imovel.metragemDe}m² até ${imovel.metragemAte}m² · ${imovel.chamada}`}</p>
        </ImovelInfoHeader>

        <TegraDivider axis="x" color="#E6E6E6" height={1} />

        <ImovelInfoActions>
          <Form
            ref={formRef}
            onSubmit={handleFormSubmit}
            initialData={{
              mostrar_na_pagina: !imovel.oculto,
              mostrar_banner_na_pagina: imovel.isBanner,
            }}
          >
            <TegraCheckbox
              checked={!imovel.oculto}
              formRef={formRef}
              handleOnChange={() => handleShow(imovel.id, !imovel.oculto)}
              name="mostrar_na_pagina"
              label="Mostrar na minha página"
            />

            <TegraCheckbox
              checked={imovel.isBanner}
              formRef={formRef}
              name="mostrar_banner_na_pagina"
              handleOnChange={() => handleBanner(imovel.id, !imovel.isBanner)}
              label="Mostrar destaque na minha página"
              {...(bannersLimitReached && !imovel.isBanner
                ? { style: { opacity: 0.5, pointerEvents: 'none' } }
                : {})}
            />
          </Form>
        </ImovelInfoActions>
      </ImovelInfo>
    </Container>
  );
};

export default TegraImovelMinhaPagina;
