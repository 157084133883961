/* eslint-disable @typescript-eslint/ban-types */
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  RefObject,
} from 'react';

import { useField, FormHandles } from '@unform/core';
import {
  Container,
  CheckboxContainer,
  StyledCheckbox,
  Icon,
  Content,
} from './styles';

import searchIcon from '../../assets/tegra-icon-search.svg';

import checkItem from '../../assets/tegra-icon-check.svg';
import TegraInput from '../TegraInput';

interface MultiCheckboxProps {
  name: string;
  items: {
    label: string;
    value: string;
    defaultChecked?: boolean;
    checked?: boolean;
  }[];
  formRef: RefObject<FormHandles>;
  isGrid?: boolean;
  highlightChecked?: boolean;
  underline?: boolean;
  hasSearch?: boolean;
  setItems: Function;
}

// interface ICheckboxProps {
//   label: string;
//   value: string;
// }

const TegraMultiCheckbox: React.FC<MultiCheckboxProps> = ({
  name,
  items,
  setItems,
  isGrid = false,
  highlightChecked = false,
  underline = false,
  hasSearch = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField } = useField(name);
  const [search, setSearch] = useState('');

  // useEffect(() => {
  //   const defaultChecked = items
  //     .filter(item => item.defaultChecked)
  //     .map(item => item.value);
  //   setAreChecked([...defaultChecked]);
  // }, []);

  const handleOnSearch = useCallback(event => {
    const { value } = event.target;
    setSearch(value);
  }, []);

  const handleOnChange = useCallback(
    item => {
      // const { value, label, checked } = event.target;
      const newItems = [...items];
      const newItem = newItems.find(i => i.value === item.value);
      if (newItem) newItem.checked = !newItem.checked;
      setItems(newItems);
    },
    [items, setItems],
  );

  // useEffect(() => {
  //   if (!formRef.current) {
  //     throw new Error('No form');
  //   }
  //   formRef.current.submitForm();
  // }, [formRef, name]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <input name={name} ref={inputRef} type="text" />

      {hasSearch && (
        <div className="search-box">
          <TegraInput
            className="search"
            label=""
            icon={searchIcon}
            name={`${name}-search`}
            onChange={handleOnSearch}
          />
        </div>
      )}
      <Content isGrid={isGrid}>
        {items.map(item => (
          <CheckboxContainer
            underline={underline}
            checked={!!item.checked}
            highlightChecked={highlightChecked}
            onClick={() => handleOnChange(item)}
            key={item.value}
            isNotInSearch={
              hasSearch &&
              item.label.toLowerCase().indexOf(search.toLowerCase()) === -1
            }
          >
            {/* <label> */}
            <StyledCheckbox checked={item.checked || false}>
              <Icon src={checkItem} />
            </StyledCheckbox>
            <span>{item.label}</span>
            {/* </label> */}
          </CheckboxContainer>
        ))}
      </Content>
    </Container>
  );
};

export default TegraMultiCheckbox;
