import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useLoader } from '../../hooks/loader';

const SignInToken: React.FC = () => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const { signInRegister } = useAuth();
  const history = useHistory();
  const { setLoaderActive } = useLoader();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function doLogin() {
      if (params.get('token')) {
        await signInRegister(params.get('token') || '');
      }
      history.push(`/`);
    }
    doLogin();
  }, [history, signInRegister]);

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
  }, []);
  setLoaderActive(true);
  return <div>Loading..</div>;
};

export default SignInToken;
