import React, { CSSProperties, FC, useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import TegraSelect from '../../../components/TegraSelect';
import './PresencaSelectDnD.sass';
//import { FaArrowsAltV } from 'react-icons/fa';
import { useAuth } from '../../../hooks/auth';
/*
const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
};

const handleStyle: CSSProperties = {
  backgroundColor: 'green',
  width: '1rem',
  height: '1rem',
  display: 'inline-block',
  marginRight: '0.75rem',
  cursor: 'move',
}
*/

export interface CardProps {
  id: any;
  corretor: any;
  id_relogio_ponto: any;
  id_responsavel: number;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  selectCorretoresLength: number;
  corretores:  any[];
  hadleChangeCorretor: (value: any) => any;  
  addCorretor: () => void;
  removeCorretor: (idx: any, id_corretor: any, id_relogio_ponto: any) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const PresencaSelectDnD: FC<CardProps> = ({ id, corretor, id_relogio_ponto, id_responsavel, index, moveCard, selectCorretoresLength, corretores, hadleChangeCorretor,  addCorretor, removeCorretor}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { user } = useAuth();

  const ItemTypes = {
    CARD: 'card',
  }

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging, opacity }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  drag(drop(ref));
  return (
    <div className="corretor-select withDnD" ref={ref} style={{ opacity }} data-handler-id={handlerId}>      
      <TegraSelect
        placeholder="Todos"
        noOptionsMessage={() => 'Nenhum selecionado'}
        label={`Corretor #${index + 1}`}
        name="corretor"
        onChange={hadleChangeCorretor(index)}
        value={corretor}
        options={corretores}
        isDisabled={user.idCargoSigavi != 25 && user.id != id_responsavel && corretor.value !== ''}
      />
      {/*
      <button ref={drag} className="rearrange">
        <FaArrowsAltV />
      </button>
      */}
      {index === 0 && selectCorretoresLength === 1 ? (
        <button className="plus" onClick={addCorretor} />
      ) : 
        ( (user.idCargoSigavi == 25 || user.id == id_responsavel || corretor.value === '') &&
          (
            index !== selectCorretoresLength - 1 ? (
              <button
                className="minus"
                onClick={() =>
                  removeCorretor(index, corretor.value, id_relogio_ponto)
                }          
              />
            ) : (
              <>
                <button
                  className="minus"
                  onClick={() =>
                    removeCorretor(index, corretor.value, id_relogio_ponto)
                  }
                />
                <button className="plus" onClick={addCorretor} />
              </>
            )
  
          )  
        )
      }
      </div>    
  );
};