import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 50px;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    display: block;
    width: 100%;
    margin-bottom: 11px;
    margin-top: 15.5px;
  }

  input {
    outline: 0 !important;
    padding: 10px;
    height: 40px;
    border: solid 1px #adadab;
    background-color: #ffffff;
    width: 100%;

    &::placeholder {
      color: #adadab;
      text-transform: capitalize;
    }
  }
`;

export const Title = styled.h2`
  margin-top: 20px;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.36px;
  text-align: left;
  color: #272723;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #60605d;
  margin-top: 8px;

  @media (max-width: 768px) {
    max-width: 302px;
    text-align: justify;
  }
`;

export const FinanciamentoContainer = styled.div`
  display: flex;
  margin-top: 34px;
  align-items: center;

  & > div {
    flex: 50%;
    width: 50%;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    & > div {
      flex: 1;
      width: 100%;
    }
  }
`;

export const FinanciamentoHeader = styled.div`
  padding: 0 30px;
`;

export const FinanciamentoFormContainer = styled.div`
  margin-right: 30px;
  padding: 0 30px;

  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;

export const FinanciamentoResultadoContainer = styled.div`
  height: 209.8px;
  border: solid 1px #e6e6e6;
  background-color: #e6e6e6;
  position: relative;
  padding: 29px 44px;
  margin-top: 15px;

  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 35%;
    left: -15px;
    border-top: 30px solid transparent;
    border-right: 35px solid #e6e6e6;
    border-bottom: 30px solid transparent;
  }

  @media (max-width: 768px) {
    margin-top: 39px;

    &:before {
      top: -15px;
      left: 42%;
      border: none;
      border-left: 35px solid transparent;
      border-right: 35px solid transparent;
      border-bottom: 40px solid #e6e6e6;
    }
  }

  @media (max-width: 375px) {
    &:before {
      left: 37%;
    }
  }

  strong {
    display: block;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.7;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
  }

  .title {
    margin-bottom: 16.5px;
  }

  span {
    display: block;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.14px;
  }

  div {
    margin-top: 15.5px;

    strong {
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }
`;

export const FinanciamentoFormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 19.5px;

  @media (max-width: 768px) {
    justify-content: center;

    button {
      flex: 1;
      width: 100%;
    }
  }
`;
