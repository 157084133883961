/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  useCallback,
  useRef,
  MouseEvent,
} from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';

import { ReactComponent as EmailIcon } from '../../../assets/espaco_gerente_options/tegra-icon-email.svg';
import { ReactComponent as LocationIcon } from '../../../assets/espaco_gerente_options/tegra-icon-location.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/espaco_gerente_options/tegra-icon-people.svg';
import { ReactComponent as TelephoneIcon } from '../../../assets/espaco_gerente_options/tegra-icon-telephone.svg';
import { ReactComponent as PersonIcon } from '../../../assets/espaco_gerente_options/tegra-icon-person.svg';
import { ReactComponent as EditIcon } from '../../../assets/tegra-icon-edit.svg';
import { ReactComponent as TrashIcon } from '../../../assets/tegra-icon-delete.svg';

import getDifferenceInDays from '../../../utils/date/getDifferenceInDays';

import {
  TarefaItem,
  TarefaItemDescription,
  TarefaItemFile,
  TarefaItemComentarios,
  TarefaItemActions,
  TarefaItemUltimaAtualizacao,
  TarefaItemInfo,
  TarefaItemHeader,
  TarefaItemComentariosList,
  ComentarioHeader,
  ComentarioContent,
  ComentariosBox,
  ComentariosAction,
} from './styles';
import TegraDivider from '../../../components/TegraDivider';
import TegraButtonExtra from '../../../components/TegraButtonExtra';
import TegraExpandingContent from '../../../components/TegraExpandingContent';
import TegraCheckbox from '../../../components/TegraCheckbox';
import TegraTextArea from '../../../components/TegraTextArea';
import TegraInput from '../../../components/TegraInput';
import getFormattedDate from '../../../utils/date/getFormattedDate';
import api from '../../../services/api';
import { useLoader } from '../../../hooks/loader';
import { useAuth } from '../../../hooks/auth';

export interface IComentario {
  id_comentario?: string;
  txt_usuario: string;
  id_usuario: number;
  txt_comentario: string;
  // id_tarefa?: string;
  id_tipo: 1;
  id_tarefa?: number;
  cadastrado: string;
  editado: string;
}

export interface ITarefa {
  id_tarefa: number;
  id_tipoTarefa: number;
  txt_descricao: string;
  dt_prazo: string;
  txt_responsavel?: string;
  txt_empreendimentoRelacionado?: string;
  txt_enderecoVisita?: string;
  txt_localVisita?: string;
  id_responsavel?: string;
  id_tipoCorretor?: string;
  comentarios: IComentario[];
  editado?: string;
  arquivos: string[];
  finalizada?: boolean;
  tipo_pagina: string;
}
interface TarefasProps {
  // id: string;
  tarefa: ITarefa;
  editarTarefa: Function;
  sendNovoComentario: Function;
  handleDeleteTarefa: Function;
  reload?: Function;
}

interface ITipoIconById {
  [key: string]: string;
}

const IconTipo: ITipoIconById = {
  '1': 'email',
  '2': 'visita',
  '3': 'reuniao',
  '4': 'ligacao',
};

interface ITipoIcon {
  [key: string]: any;
}
const tiposIcons: ITipoIcon = {
  email: <EmailIcon />,
  visita: <LocationIcon />,
  reuniao: <PeopleIcon />,
  ligacao: <TelephoneIcon />,
};

interface ComentarioFormData {
  txt_comentario: string;
  id_tarefa: string;
}

interface FinalizarTarefaForm {
  finalizada: boolean;
}

const Tarefa: React.FC<TarefasProps> = ({
  // id,
  tarefa,
  editarTarefa,
  sendNovoComentario,
  handleDeleteTarefa,
  reload,
}) => {
  const [openNovoComentario, setOpenNovoComentario] = useState(false);
  const comentarioForm = useRef<FormHandles>(null);
  const { setLoaderActive } = useLoader();
  const { user } = useAuth();

  const statusFinalizadaFormRef = useRef<FormHandles>(null);

  const handleComentarioSubmit: SubmitHandler<ComentarioFormData> = data => {
    sendNovoComentario(data);
  };

  const download = useCallback(
    (e: MouseEvent): void => {
      e.preventDefault();
      const preview = tarefa.arquivos[0];
      api
        .get(`Tarefa/download/${preview}`, {
          responseType: 'blob',
        })
        .then(response => {
          const nav = (window.navigator as any);        
          if (nav.msSaveOrOpenBlob) {
            // IE11
            nav.msSaveOrOpenBlob(
              new Blob([response.data]),
              preview,
            );
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', preview);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(url);
            }, 100);
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    [tarefa],
  );

  const handleStatusFinalizada = useCallback(
    (id_tarefa: number) => {
      if (!statusFinalizadaFormRef.current) {
        return;
      }
      setLoaderActive(true);
      const {
        finalizada,
      } = statusFinalizadaFormRef.current.getData() as FinalizarTarefaForm;

      api
        .post('Tarefa/statusFinalizada', { id_tarefa, finalizada })
        .then(res => {
          const { data: resData } = res;
          if (resData.sucesso && reload) {
            reload();
          }
        });
    },
    [reload, setLoaderActive],
  );

  const handleNovoComentario = useCallback(() => {
    if (!openNovoComentario) {
      setOpenNovoComentario(true);
    } else {
      if (!comentarioForm.current) {
        throw new Error('no form');
      }
      comentarioForm.current.submitForm();

      setOpenNovoComentario(false);
    }
  }, [openNovoComentario]);

  return (
    <TarefaItem key={tarefa.id_tarefa}>
      {tarefa.editado && (
        <TarefaItemUltimaAtualizacao>
          {tarefa.editado}
        </TarefaItemUltimaAtualizacao>
      )}
      <TarefaItemHeader>
        <TarefaItemInfo>
          <div className="left">
            {tiposIcons[IconTipo[`${tarefa.id_tipoTarefa}`]]}
            <span className="prazo">
              Prazo: <strong>{getFormattedDate(tarefa.dt_prazo)}</strong>
              <span className="restante">
                {' '}
                {getDifferenceInDays(tarefa.dt_prazo) !== 0
                  ? `(em ${getDifferenceInDays(tarefa.dt_prazo)} dias)`
                  : '(hoje)'}
              </span>
            </span>
          </div>
          {tarefa.txt_responsavel && (
            <>
              <TegraDivider color="#ebb92d" axis="y" height={33} />
              <span className="nome">
                <PersonIcon />
                <strong>{tarefa.txt_responsavel}</strong>
              </span>
            </>
          )}
        </TarefaItemInfo>
        <TarefaItemActions>
          {!tarefa.finalizada && (
            <button type="button" onClick={() => editarTarefa(tarefa)}>
              Editar
              <EditIcon />
            </button>
          )}

          {user.deletarTarefas && (
            <button
              type="button"
              onClick={() => handleDeleteTarefa(tarefa.id_tarefa)}
            >
              Deletar
              <TrashIcon />
            </button>
          )}

          <TegraDivider color="#EBB92D" axis="y" height={32} />
          <Form
            ref={statusFinalizadaFormRef}
            onSubmit={() => handleStatusFinalizada(tarefa.id_tarefa)}
          >
            <TegraCheckbox
              label="Finalizar"
              checked={tarefa.finalizada}
              disabled={tarefa.finalizada}
              formRef={statusFinalizadaFormRef}
              onChange={() => statusFinalizadaFormRef.current?.submitForm()}
              name="finalizada"
            />
          </Form>
          {/* <TegraCheckbox */}
        </TarefaItemActions>
      </TarefaItemHeader>
      <TarefaItemDescription>
        {tarefa.txt_descricao}
        {tarefa.arquivos && tarefa.arquivos.filter(a => a !== '').length > 0 && (
          <TarefaItemFile>
            <a href="#" onClick={download}>
              Baixar anexo
            </a>
          </TarefaItemFile>
        )}
      </TarefaItemDescription>
      <TarefaItemComentarios>
        <TegraExpandingContent title={<h3>Comentários</h3>}>
          <ComentariosBox>
            <TarefaItemComentariosList>
              {tarefa.comentarios.map(comentario => (
                <li key={comentario.id_comentario}>
                  <ComentarioHeader>
                    {comentario.cadastrado && (
                      <time className="data">{comentario.cadastrado}</time>
                    )}
                    <strong className="autor">
                      Por: {comentario.txt_usuario}
                    </strong>
                    {comentario.editado && (
                      <span className="ultima-atualizacao">
                        ({comentario.editado})
                      </span>
                    )}
                  </ComentarioHeader>
                  <ComentarioContent>
                    {comentario.txt_comentario}
                  </ComentarioContent>
                </li>
              ))}
            </TarefaItemComentariosList>
            {openNovoComentario && (
              <Form ref={comentarioForm} onSubmit={handleComentarioSubmit}>
                <TegraInput
                  label=""
                  style={{ display: 'none' }}
                  type="text"
                  name="id_tarefa"
                  defaultValue={tarefa.id_tarefa}
                />
                <TegraTextArea
                  name="txt_comentario"
                  placeholder="Deixe aqui o seu comentário"
                />
              </Form>
            )}
            <ComentariosAction>
              <TegraButtonExtra isAction onClick={handleNovoComentario}>
                Comentar
              </TegraButtonExtra>
            </ComentariosAction>
          </ComentariosBox>
        </TegraExpandingContent>
      </TarefaItemComentarios>
    </TarefaItem>
  );
};

export default Tarefa;
