import React from 'react';

import { FiChevronLeft } from 'react-icons/fi';
import { Link, Switch, useRouteMatch, useParams } from 'react-router-dom';
import {
  ExtraActions,
  GoBackContainer,
  Title,
  Options,
  Option,
  Content,
  Subtitle,
} from './styles';

import iconCorretor from '../../../assets/tegra-icon-corretor.svg';
import iconImobiliaria from '../../../assets/tegra-icon-imobiliaria.svg';

const TegraSignUpOptions: React.FC = () => {
  const { url } = useRouteMatch();
  const { uf, id } : any = useParams();
  
  let urlPath = url;

  if (url.slice(-1) === '/'){
    urlPath = url.slice(0, -1);
  }

  const urlCompleta = new URL(window.location.href)
  const parametroToken = urlCompleta.searchParams.get('tk') === null? "" :
                         "/?tk=" + urlCompleta.searchParams.get('tk');

  return (
    <>
      <ExtraActions>
        <GoBackContainer>
          <FiChevronLeft size={10} />
          <Link to="/">Voltar</Link>
        </GoBackContainer>
        <Link to="/" className="jaPossuoCadastro">
          Já possuo cadastro
        </Link>
      </ExtraActions>
      <Content>
        <Title>Cadastre-se</Title>

        <Options>
          <Link to={`${urlPath}/imobiliaria${parametroToken}`}>
            <Option key="imobialiária">
              <img src={iconImobiliaria} alt="Imobiliária" />
              {/* <FiHome size={40} color="#ebb92d"/> */}
              <Title className="optionTitle">Sou uma imobiliária</Title>
            </Option>
          </Link>
          <Link to={`${urlPath}/corretor-autonomo${parametroToken}`}>
            <Option key="corretor">
              {/* <FiUser size={40} color="#ebb92d"/> */}
              <img src={iconCorretor} alt="Corretor" />
              <Title className="optionTitle">Sou um corretor autônomo</Title>
            </Option>
          </Link>
        </Options>
      </Content>
    </>
  );
};

export default TegraSignUpOptions;
