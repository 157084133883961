import styled from 'styled-components';

export const Container = styled.div`
  min-height: 89px;
  padding: 20px 82px;
  background-color: #e6e6e6;

  @media (max-width: 600px) {
    padding: 36px 36px;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  a {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #60605d;
    text-decoration: none;
  }
`;

export const NomeDoProdutoBox = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  svg {
    margin-right: 10px;
  }

  span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #60605d;
  }
`;

export const Actions = styled.div`
  display: flex;

  button {
    a {
      text-decoration: none;
      color: inherit;
    }
  }

  button:not(:first-child) {
    margin-left: 40px;
  }


  @media (max-width: 1200px) {
    button:not(:first-child) {
      margin-left: 10px;
    }   

    button {
      a{
        text-align: center;
      }
    }         
  }

  @media (max-width: 800px) {
    display: flex;
    flex-wrap: wrap;
    button {
      margin-top: 10px;
      flex: 30%;
      width: 50%;
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    button {
      margin-top: 10px;
      flex: 50%;
      width: 50%;
    }
  }  
`;

export const ListaAction = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-top: 41px;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    button {
      flex: 1;
      width: 100%;
    }
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 40px;
  top: 35px;
  cursor: pointer;

  @media (max-width: 768px) {
    right: 20px;
  }
`;


export const ImprimirMsg = styled.div`
  h3{
    font-size: 20px;
    margin-bottom: 20px;
  }

  p{
    line-height: 1.8;
    color: #60605d;
  }
`;