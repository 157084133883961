import React, { useState, useEffect } from 'react';
import { NavMainContainer, NavMainWrapper } from './styles';
import NavHamburguerMenu from './NavHamburguerMenu';
import TegraNavIcon from '../../TegraNavIcon';
import NavMenu from './NavMenu';
import manageBodyScroll from '../../../utils/document/manageBodyScroll';
import { useAuth } from '../../../hooks/auth';

interface NavMainProps {
  tegraLogo: string;
  title: string;
  description: string;
  menuData: MenuData[];
}

interface MenuData {
  active: boolean;
  label: string;
  link: string;
  children?: MenuData[];
}

const NavMain: React.FC<NavMainProps> = ({
  tegraLogo,
  title,
  description,
  menuData,
}) => {
  const [active, setActive] = useState(false);

  const { user } = useAuth();
  useEffect(() => {
    if (active) {
      manageBodyScroll.remove();
    } else {
      manageBodyScroll.add();
    }
  }, [active]);

  return (
    <NavMainContainer>
      <NavMainWrapper>
        <TegraNavIcon
          description={description}
          tegraLogo={tegraLogo}
          title={title}
          link={"/"}
        />
        <NavHamburguerMenu onClick={() => setActive(true)} />
        <NavMenu
          icon={user.foto}
          active={active}
          name={user.nome?.split(' ')[0]}
          isParceiro={user.tipo === 0}
          isAdmin={user.isAdmin}
          isImobiliaria={user.id_tipoParceiro === 1}
          menuData={menuData}
          closeMenu={() => setActive(false)}
        />
      </NavMainWrapper>
    </NavMainContainer>
  );
};

export default NavMain;
