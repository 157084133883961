import styled, { css } from 'styled-components';

// Faixas de status
import tegraImovelLancamento from '../../../assets/tegra-imovel-lancamento.png';
import tegraImovelBreveLancamento from '../../../assets/tegra-imovel-breve-lancamento.png';
import tegraImovelConstrucao from '../../../assets/tegra-imovel-construcao.png';
import tegraImovelPronto from '../../../assets/tegra-imovel-pronto.png';

import tegraImovelLancamentoSm from '../../../assets/tegra-imovel-lancamento-sm.png';
import tegraImovelBreveLancamentoSm from '../../../assets/tegra-imovel-breve-lancamento-sm.png';
import tegraImovelConstrucaoSm from '../../../assets/tegra-imovel-construcao-sm.png';

export const Container = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

interface ImovelImagemProps {
  bg: any;
  status: string;
}

type statusOptions = {
  [key: string]: string;
};

const statuses: statusOptions = {
  lancamento: tegraImovelLancamento,
  breve_lancamento: tegraImovelBreveLancamento,
  construcao: tegraImovelConstrucao,
  entregue: tegraImovelPronto,
};

const statuses_sm: statusOptions = {
  lancamento: tegraImovelLancamentoSm,
  breve_lancamento: tegraImovelBreveLancamentoSm,
  construcao: tegraImovelConstrucaoSm,
  entregue: tegraImovelPronto,
};

export const ImovelImagem = styled.div<ImovelImagemProps>`
  width: 278px;
  max-width: 100%;
  position: relative;
  height: 185px;

  @media (max-width: 600px) {
    width: 302px;
    height: 201px;
  }

  ${props =>
    props.bg &&
    css`
      background: url(${props.bg});
      background-size: contain;
      background-repeat: no-repeat;
    `}

  ${props =>
    props.status &&
    css`
      &:before {
        content: '';
        background: url(${statuses[props.status]});
        background-size: cover;
        @media (max-width: 600px) {
          background: url(${statuses_sm[props.status]});
        }
        position: absolute;
        width: 100%;
        height: 44px;
        top: 0;
        left: 0;
      }
    `}
`;

export const ImovelInfo = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 600px) {
    margin-top: 17px;
    margin-left: 0;
  }

  flex: 1;
`;

export const ImovelInfoHeader = styled.div`
  max-width: 278px;
  strong {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #60605d;
  }

  h1 {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
    margin-bottom: 5px;
  }

  p {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.12px;
    text-align: left;
    color: #adadab;
  }
`;

export const ImovelInfoActions = styled.div`
  padding-top: 20px;
  padding-bottom: 34px;

  & > *:nth-child(even) {
    margin-top: 7px;
  }
`;
