import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Container = styled.div`
  .guia-de-bairro {
    display: flex;

    @media (max-width: 991px) {
      display: block;
    }

    .title {
      width: 16.6667%;
      flex: 0 0 16.6667%;
      max-width: 16.6667%;

      @media (max-width: 991px) {
        width: 100%;
        max-width: 100%;
        margin-bottom: 50px;
      }

      h1 {
        padding-left: 30px;
        padding-right: 56px;
      }
    }

    .mapa {
      width: 100%;
      padding-right: 30px;
      width: 83.3333%;
      flex: 0 0 83.3333%;
      max-width: 83.3333%;
      position: relative;

      @media (max-width: 991px) {
        width: 100%;
        max-width: 100%;
        padding-left: 30px;
      }

      .descricao {
        position: absolute;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        padding: 40px;
        min-width: 440px;
        min-height: auto;
        width: 15vw;
        height: auto;
        background-color: #ffffff;
        top: 160px;
        max-height: 340px;
        overflow: hidden;

        @media (max-width: 1000px) {
          top: 0px;
          width: 94%;
        }

        .blocoHtml1 {
          display: -webkit-box;
          -webkit-line-clamp: 10;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 340px;
          overflow: hidden;
        }

        .endereco {
          font-weight: 600;
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          img {
            margin-right: 10px;
          }
        }
      }

      .bairro {
        h2 {
          font-size: 20px;
          margin: 20px 0px 15px;
        }

        .curiosidades {
          display: flex;

          @media (max-width: 1100px) {
            display: block;
          }

          .topicos {
            padding: 10px;

            @media (max-width: 1100px) {
              width: 400px;
              margin-bottom: 20px;
            }

            .titulo-topicos {
              color: #ebb92d;
              font-weight: 500;
              font-size: 22px;
              display: flex;
              align-items: center;
              margin-bottom: 20px;

              img {
                margin-right: 8px;
              }
            }

            .descricao-topicos {
              color: #60605d;

              p {
                color: #60605d;
              }
            }
          }
        }
      }
      
      .tudo-sobre-bairro{
        display: flex;
        width: 100%;
        justify-content: flex-end;
        //margin-top: 12px;
      
        .link-guiabairro{
            height: 33px;
            padding: 8px;
            font-size: 11px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;             
            background-color: white;
            color: #ebb92d;
            border: 1px solid #ebb92d;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            display: flex;
            align-items: center;

            img{
              margin-left: 8px;
            }
        }       
      }
    }
  }
`;


