import React from 'react';
import ProductMaterials from '../..';

const MarketingEmail: React.FC = () => {
  return (
    <ProductMaterials
      //title="E-mail Marketing"
      title="Materiais Gerais"
      subtitle="E-mail Marketing"
      description="Baixe modelos prontos para se comunicar com seus clientes diretamente por e-mail."
      showColumnTitle={false}
      actions={['open','download']}
      apiUrl="MateriaisGerais"
      columns={[
        { label: 'Produto', field: 'tx_produto' },
        { label: 'E-mail', field: 'nome' },
      ]}
      anchorName="Email"
      onClickItem={() => localStorage.setItem('SCROLL', '#Email')}
    />
  );
};

export default MarketingEmail;
