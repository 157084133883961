import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.ul`
  list-style: none;
`;

export const BlogItem = styled.li`
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e6e6;
  animation: ${fadeIn} 500ms linear forwards;

  &:not(:first-child) {
    margin-top: 40px;
  }
`;

export const BlogItemContent = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  a {
    text-decoration: none;
  }
`;

export const BlogItemThumbnail = styled.img`
  width: 100%;
  max-width: 247px;
  max-height: 165px;
  object-fit: cover;
  margin-right: 30px;

  @media (max-width: 768px) {
    margin-right: 0;
    max-width: 100%;
  }

  @media (min-width: 400px) and (max-width: 600px) {
    /* align-self: center; */
  }
`;

export const BlogItemInfo = styled.div`
  flex: 1;
  time {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.12px;
    text-align: left;
    color: #adadab;
    display: block;

    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  h2 {
    font-size: 36px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 0.36px;
    text-align: left;
    color: #272723;
  }
  strong {
    display: block;
    margin-top: 17px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
  }

  p {
    margin-top: 17px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #60605d;
    max-width: 40rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const BlogItemActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  a {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #272723;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }
`;
