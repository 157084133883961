import React from 'react';

import 'simplebar/dist/simplebar.min.css';

import {
  CustomScrollbar,
} from './styles';

interface TegraScrollbarProps {
  // height: number;
  // width: number;
}

const TegraScrollBar: React.FC<TegraScrollbarProps> = ({
  children,
  // height,
  // width,
  ...rest
}) => {
  return <CustomScrollbar autoHide={false}>{children}</CustomScrollbar>;
};

export default TegraScrollBar;
