import styled from 'styled-components';
import colors from '../../styles/colors';

export const SuggestionsInput = styled.textarea`
  font-family: MontSerrat;
  font-size: 13px;
  margin-bottom: 2rem;
  color: ${colors.darkGreyBackground};
  border-color: ${colors.lightGrey};
  width: 100%;
  padding: 0.7rem;
  margin-top: 90px;
  &:focus::placeholder {
    color: transparent;
  }
`;

export const SuggestionsContainer = styled.div`
  top: -4.8rem;
  position: relative;
  padding: 2.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  margin: auto;
  @media (min-width: 375px) {
    top: -8rem;
  }
`;

export const SuggestionsWrapper = styled.div`
  background-color: white;
  header {
    padding-left: 0;
    & > div {
      padding-left: 30px;
      margin: auto;
      width: 100%;
      max-width: 1130px;
      @media (max-width: 374px) {
        padding: 0 30px;
        h1 {
          font-size: 45px;
        }
      }
    }
  }
`;

export const ErrorsContainer = styled.div`
  margin-top: 20px;
  background: #d84128;
  padding: 15px;
  width: 100%;

  p {
    color: #fff;

    & + p {
      margin-top: 5px;
    }
  }
`;

export const SucessoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 100px;

  img {
    max-width: 119px;
  }

  h1 {
    margin-top: 40px;
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 0.5px;
    text-align: center;
    color: #60605d;
  }

  p {
    margin-top: 20px;
    margin-bottom: 36px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: center;
    color: #60605d;
  }

  @media (max-width: 600px) {
    padding: 40px 20px;

    h1 {
      font-size: 32px;
    }

    p {
      font-size: 14px;
    }

    button {
      width: 100%;
    }
  }
`;
