import styled from "styled-components";
import colors from "../../styles/colors";

export const ContatosPageContainer = styled.div`
    background-color: white;
`

export const ContatosContentWrapper = styled.div`
    padding: 2.25rem;
`

export const ContactsManagementSearchValue = styled.div`
    font-size: 1.25rem;
    color: ${colors.greyBackground};
    margin-top: 2.25rem;
`;
export const SearchValue = styled.span`
    font-weight: bold;
    color: ${colors.featuredBlack};
`

export const ContactsTabListWrapper = styled.div`
    @media(max-width: 424px) {
        ul {
            display: flex;
            justify-content: space-between;
        }
    }
    @media(max-width: 374px) {
        li {
            font-size: 11px !important;
        }
    }
`