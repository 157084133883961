import React from 'react';
import { Container as GridContainer, Row, Col } from 'react-grid-system';
import { Container, Content, Menu, MenuItem, AccordionContent, Body, Buttons, Button } from './styles';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import TegraDivider from '../../components/TegraDivider';
import TegraAccordion from '../../components/TegraAccordion';
import { ButtonContainer } from '../../components/TegraNav/styles';
import { ButtonSerch } from '../../components/TegraFiltroHorizontal/styles';
import Pdf2 from '../../assets/Codigo-de-Conduta.pdf';
import { url } from 'inspector';
import { useHistory } from 'react-router-dom';

const FaqCompliance: React.FC = () => {
    const history = useHistory();
    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch(Pdf2).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'CodigoCondutaEtica';
                alink.click();
            })
        })
    }


      return (
        <Container>
        <TegraOpaqueHero
          title="Compliance"
          description=""
        />
        <Content>
        <GridContainer
          className="grid-container"
          style={{ padding: 0, marginLeft: '0px', maxWidth: '100%' }}
        >
            <Row>
                <Col className="accordion-col" sm={12} md={8} xl={9}>
                    <Body>
                        <h3>Arquivo</h3>
                        <TegraDivider axis="x" color="#d1d1cf" />
                        <Buttons>
                            <p>Código de Conduta</p>
                            <button onClick={() => window.open(Pdf2,'_blank')}>Visualizar<img src="/static/media/tegra-icon-open.9c21d613.svg" alt="Ícone do botão"></img></button>
                            <button onClick={onButtonClick}>Download<img src="/static/media/tegra-icon-baixar.11ad3678.svg" alt="Ícone do botão"></img></button>
                        </Buttons>
                        <TegraDivider axis="x" color="#d1d1cf" />
                        <Button>
                            <p>Compliance</p>
                            <button onClick={() => history.push(`/dashboard/compliance/`)}>Visualizar<img src="/static/media/tegra-icon-open.9c21d613.svg" alt="Ícone do botão"></img></button>
                        </Button>
                    </Body>
                </Col>
            </Row>
        </ GridContainer>
        </Content>
        </Container>
    );
};
  
  export default FaqCompliance;