import React, { useState, useEffect, useCallback } from 'react';

import { useParams } from 'react-router-dom';
import { Container, Content, BlogAction } from './styles';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import TegraBlogOverviewCalendario from '../../components/TegraBlogOverviewCalendario';

import TegraButton from '../../components/TegraButton';
import api from '../../services/api';
import { useLoader } from '../../hooks/loader';

interface IBlogItem {
  imagem?: any;
  titulo: string;
  subtitulo: string;
  conteudo: string;
  link: string;
  chamada: string;
  data: string;
  id: string;
}

interface ItemCalendario {
  id_novidade: number;
  id_tipo: number;
  tx_titulo: string;
  tx_subtitulo: string;
  tx_descricao: string;
  tx_chamada: string;
  data_inicio: string;
  nu_estado: number;
  nu_publico: number;
  tx_thumb: number;
}

interface ResponseCalendario {
  items: ItemCalendario[];
  paginas: number;
}

const CalendarioTrabalho: React.FC = () => {
  const [blogItems, setBlogItems] = useState<IBlogItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { setLoaderActive } = useLoader();
  const { slug } : any = useParams();

  useEffect(() => {
    // chamar api para pegar itens
    async function loadItems(): Promise<void> {
      setLoaderActive(true);

      var dataBusca = '';
      if (slug){
        if (window.location.hostname.toLowerCase().indexOf('localhost') === 0 || window.location.hostname.toLowerCase().indexOf('qa') === 0){
          dataBusca = slug; //slug.substring(0,2)+'-'+slug.substring(3,5)+'-'+slug.substring(6,10);
        }
        else {
          dataBusca = slug.substring(3,5)+'-'+slug.substring(0,2)+'-'+slug.substring(6,10); //mm-dd-yyyy
        }
      }

      const { data } = 
        !slug ?
          await api.get<ResponseCalendario>('CalendarioTrabalho/:proximoseventos', {
            params: {
              pagina: 1,
              quantidade: 6,
            },
          })
        :
        await api.get<ResponseCalendario>('CalendarioTrabalho/:getalldate', {
          params: {
            dtbusca: dataBusca,
            pagina: 1,
            quantidade: 6,
          },
        });

      const items: IBlogItem[] = data.items.map(item => ({
        titulo: item.tx_titulo,
        subtitulo: item.tx_subtitulo,
        id: `${item.id_novidade}`,
        imagem: item.tx_thumb,
        data: item.data_inicio,
        conteudo: item.tx_descricao,
        chamada: `${item.tx_chamada}`,
        link: `${item.id_novidade}`,
      }));

      setTotalPages(data.paginas);
      setBlogItems([...items]);
      setLoaderActive(false);
    }

    loadItems();
  }, [setLoaderActive]);

  const handleLoadMore = useCallback(async () => {
    setLoaderActive(true);
    const { data } = await api.get<ResponseCalendario>('CalendarioTrabalho', {
      params: {
        pagina: currentPage + 1,
        quantidade: 6,
      },
    });
    const items: IBlogItem[] = data.items.map(item => ({
      titulo: item.tx_titulo,
      subtitulo: item.tx_subtitulo,
      id: `${item.id_novidade}`,
      imagem: item.tx_thumb,
      data: item.data_inicio,
      conteudo: item.tx_descricao,
      link: `${item.id_novidade}`,
      chamada: `${item.tx_chamada}`,
    }));
    setTotalPages(data.paginas);
    setBlogItems([...blogItems, ...items]);
    setCurrentPage(currentPage + 1);
    setLoaderActive(false);
  }, [blogItems, currentPage, setLoaderActive]);

  return (
    <Container>
      <TegraOpaqueHero
        title="Calendário de Eventos"
        description="Fique por dentro das datas importantes da Tegra para suas atividades."
      />
      <Content>
        <TegraBlogOverviewCalendario
          pageSlug="/dashboard/calendario-de-eventos"
          items={blogItems}
        />
        {currentPage + 1 <= totalPages && (
          <BlogAction>
            <TegraButton isAction onClick={handleLoadMore}>
              Carregar mais
            </TegraButton>
          </BlogAction>
        )}
      </Content>
    </Container>
  );
};

export default CalendarioTrabalho;
