// import logoTegra from '../assets/tegra-logo.png';
//import tegraVendasLogo from '../assets/tegra-logo.svg';
import tegraLogo from '../assets/tegra-vendas-lab.svg';
import parceriasLogo from '../assets/tegra-parcerias.png';
import tegraVendasLogo from '../assets/tegra-logo.png';

export const isParcerias = (): boolean =>
  window.location.hostname.toLowerCase().indexOf('portaldeparcerias') === 0 ||
  window.location.hostname.toLowerCase().indexOf('qaportaldeparcerias') === 0;

export const getLink = (parcerias: boolean): any => {
  const qa =
    window.location.hostname.toLowerCase().indexOf('qa') === 0 ? 'qa' : '';
  if (parcerias)
    return `https://${qa}portaldeparcerias.tegravendas.com.br/`;
  return `https://${qa}portaldocorretor.tegravendas.com.br/`;
};


export const getLogoByDomain = (isLogin: boolean): any => {
  if (
    window.location.hostname.toLowerCase().indexOf('portaldeparcerias') === 0 ||
    window.location.hostname.toLowerCase().indexOf('qaportaldeparcerias') === 0
  )
    return parceriasLogo;
  if (isLogin) return tegraVendasLogo;
  return tegraLogo;
};

export const getLogoByTipo = (tipo: any, isLab: any = null): any => {
  if (tipo === 0) return parceriasLogo;
  if (isLab) return tegraLogo;
  return tegraVendasLogo;
};

export const getTituloByDomain = (br = false): any => {
  if (
    window.location.hostname.toLowerCase().indexOf('portaldeparcerias') === 0 ||
    window.location.hostname.toLowerCase().indexOf('qaportaldeparcerias') === 0
  )
    return `Portal de ${br ? '<br/>' : ''} Parcerias`;
  return `Portal do ${br ? '<br/>' : ''} Corretor`;
};

export const getTituloByTipo = (tipo: any, br = false): any => {
  if (tipo === 0) return `Portal de ${br ? '<br/>' : ''} Parcerias`;
  return `Portal do ${br ? '<br/>' : ''} Corretor`;
};
