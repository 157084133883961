import styled from 'styled-components';
import optionBgImageYellow from '../../../assets/tegra-barra_amarela.png';
import optionBgImageGray from '../../../assets/tegra-barra_cinza.png';

export const Container = styled.div`
  display: grid;
  grid-gap: 41px 30px;
  padding: 51px 41px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-content: center;
  margin-top: -9%;

  .principal {
    background-color: #ebb92d !important;
    background-image: url(${optionBgImageGray});
  }

  @media (max-width: 1920px) {
    margin-top: -7%;
  }

  @media (min-width: 1921px) {
    margin-top: -5%;
  }

  @media (max-width: 1640px) {
    margin-top: -8%;
    grid-template-columns: repeat(auto-fit, minmax(292px, 1fr));
  }

  @media (max-width: 1268px) {
    margin-top: -6%;
  }

  @media (max-width: 600px) {
    margin-top: -20%;
  }

  @media (max-width: 425px) {
    margin-top: -30%;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #272723;

  &.optionTitle {
    width: 190px;
    margin-left: 20px;

    @media (max-width: 600px) {
      width: 188.8px;
    }
  }
`;

export const Option = styled.div`
  display: flex;
  /* justify-content: space-around; */
  padding: 55px 42px;

  @media (max-width: 600px) {
    padding: 42px 31px;
  }
  align-items: center;
  align-content: center;

  height: 130px;
  width: 100%;
  max-width: 100%;
  background-color: #e6e6e6;
  cursor: pointer;
  transition: transform 500ms;
  background-image: url(${optionBgImageYellow});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% auto;

  @media (max-width: 600px) {
    margin: 0;
    width: 100%;
    /*
    &:first-child {
      @media (max-width: 600px) {
        margin: 0;
        margin-bottom: 40px;
      }
    } */
  }

  &:hover {
    transform: scale(1.05);
  }
`;
