import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 40px;
`;

export const AtividadesList = styled.ul`
  list-style: none;

  li {
    padding-bottom: 26px;
    padding-top: 20px;

    display: flex;

    border-top: 1px solid #e6e6e6;

    &:not(:last-child) {
      border-bottom: 1px solid #e6e6e6;
    }
  }
`;

export const AtividadeIcon = styled.div`
  svg {
    margin-top: 3px;
    width: 22px;
    height: 22px;
  }
`;

export const AtividadeInfo = styled.div`
  margin-left: 10px;
`;

export const AtividadeDateTime = styled.div`
  margin-bottom: 5px;

  time,
  .atividadeUsuario {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.12px;
    text-align: left;
    color: #adadab;
    &.data {
      padding-right: 5px;
    }
    &.hora {
      padding-right: 5px;
      padding-left: 5px;
      margin-right: 5px;
      border-right: 1px solid #ebb92d;
      border-left: 1px solid #ebb92d;
    }
  }
`;

export const AtividadeTitle = styled.strong`
  display: block;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #60605d;
`;
