import { FooterLinkGroup } from "../components/TegraFooter";

const footerLinks: FooterLinkGroup[] = [
	{
		columns: true,
		items: [
			{
				label: 'Home',
				link: '/',
			},
			{
				label: 'Produtos',
				link: '/produtos',
			},
			{
				label: 'Minha página',
				link: '/minha-pagina',
			},
			{
				label: 'Premiações e Metas',
				link: '/premiacoes-e-metas',
			},
			{
				label: 'Novidades e ofertas',
				link: '/novidades',
			},
			{
				label: 'Ranking',
				link: '/ranking/corretores',
			},
			{
				label: 'Perguntas Frequentes',
				link: '/perguntas-frequentes',
			},
			{
				label: 'Sugestões',
				link: '/sugestoes',
			},
			{
				label: 'Meus Dados',
				link: '/perfil/meus-dados',
			},
			// {
			// 	label: 'Meus Documentos',
			// 	link: '/perfil/meus-documentos',
			// 	onlyParceiros: true,
			// },
		],
	},
	{
		title: 'Materiais de Produtos',
		columns: true,
		items: [
			{
				label: 'Espelhos e Tabelas',
				link: '/materiais-de-produtos/espelhos-e-tabelas',
			},
			{
				label: 'Apresentações',
				link: '/materiais-de-produtos/apresentacoes',
			},
			{
				label: 'Vídeos',
				link: '/materiais-de-produtos/videos',
			},
			{
				label: 'IPTU e Condomínio',
				link: '/materiais-de-produtos/iptu-e-condominio',
			},
			{
				label: 'Imagens',
				link: '/materiais-de-produtos/imagens',
			},
			{
				label: 'Simule financiamento',
				link: '/materiais-de-produtos/simule-financiamento',
			},
			{
				label: 'Tour Virtual',
				link: '/materiais-de-produtos/tour-virtual',
			},
			{
				label: 'Plantas',
				link: '/materiais-de-produtos/plantas',
			},
			{
				label: 'Treinamentos',
				link: '/materiais-de-produtos/treinamentos',
			},
		],
	},
	{
		title: 'Contatos',
		columns: false,
		items: [
			{
				label: 'Tegra Vendas',
				link: '/contatos/vendas',
			},
			{
				label: 'Corretores e gerentes',
				link: '/contatos/gerentes',
			},
			{
				label: 'Tegra Incorporadora',
				link: '/contatos/incorporadora',
			},
		],
	},
];

export default footerLinks;