import styled from "styled-components";

export const NavDescription = styled.h4`
    margin-left: 2rem;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 300;
    width: 60px;
    color: white;
    align-self: center;
`;

export const NavTitleContainer = styled.div`
    display: flex;
    width: 6.75rem;
    flex-direction: column;
`;

export const NavTitle = styled.span`
    font-family: Montserrat;
    text-transform: uppercase;
    color:white;
    font-weight: light;
    font-size: 0.65rem;
    text-align: end;
    text-decoration: none;
`;

export const NavLogo = styled.img`
    width: 100%;
    margin-bottom: 0.15rem;
`;

export const TegraNavIconContainer = styled.div`
    display:flex;    
`;