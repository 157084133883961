import { 
    RankingSelfContainer,
    RankingSelfBackIcon,
    RankingSelfImage,
    RankingSelfData,
    RankingSelfHeader,
    RankingSelfDivider,
} from "./styles";
import React from "react";
import { useHistory } from 'react-router-dom';

interface IRankingSelfBannerProps {
    image: string; 
    name: string;
    sales: string;
}

const RankingSelfBanner: React.FC<IRankingSelfBannerProps> = ({image, name, sales}) => {
    const history = useHistory();

    return (
        <RankingSelfContainer>
            <RankingSelfHeader>
                <RankingSelfBackIcon onClick={() => history.goBack()}/>
                <RankingSelfImage src={image} />    
            </RankingSelfHeader>
            <RankingSelfData>{name}</RankingSelfData>
            <RankingSelfDivider />
            <RankingSelfData>{sales}</RankingSelfData>
        </RankingSelfContainer>
    );
};


export default RankingSelfBanner;