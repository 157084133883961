/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Form, FormHandles } from '@unform/core';
import { Container as GridContainer, Row, Col } from 'react-grid-system';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import moment from 'moment';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import {
  Container,
  CorretoresContainer,
  HeaderItem,
  CorretoresTable,
  ListaAction,
  FormActions,
  ContainerModal,
  Espace,
} from './styles';
import TegraSelect from '../../../components/TegraSelect';
import TegraInput from '../../../components/TegraInput';
import openIcon from '../../../assets/tegra-icon-open.svg';
import TegraDivider from '../../../components/TegraDivider';
import TegraButtonExtra from '../../../components/TegraButtonExtra';
import TegraButton from '../../../components/TegraButton';
import api from '../../../services/api';
import { useLoader } from '../../../hooks/loader';
import { useAuth } from '../../../hooks/auth';
import { groupBy } from '../../../services/helpers';
import 'moment/locale/pt';
import { CloseButton } from '../../../components/ModalRegional/styles';
import { ReactComponent as CloseIcon } from '../../../assets/tegra-icon-close.svg';
import ajaxLoading from '../../../assets/ajax-loader.gif';
import { FiChevronLeft } from 'react-icons/fi';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {PresencaSelectDnD} from '../PresencaSelectDnD';
import update from 'immutability-helper';
import userEvent from '@testing-library/user-event';

moment.locale('pt');
moment.updateLocale('pt', {
  weekdaysMin: 'dom._seg._ter._qua._qui._sex._sáb.'.split('_'),
});

export interface encontrarCorretorFormRef {
  id_parceiro: string;
  id_tipoCorretor: number;
  id_tipoParceiro: number;
  txt_tipoParceiro: string;
  tx_razaoSocial: string;
  tx_nome: string;
  tx_creci: string;
  tx_email: string;
  superintendente: string;
  gerente: string;
  tx_uf: string;
  tx_regional: string;
  id_situacao: string;
  tx_situacao: string;
}

interface Filter {
  id?: number;
  nome: string;
}

interface ToggleProps {
  setDetail: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  item: any;
}

const PresencaItem: React.FC<ToggleProps> = ({ setDetail, item }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState<any>(false);
  const [focusDate, setFocusDate] = useState<any>();
  const [date, setDate] = useState(moment(item?.dt_data));

  const [corretores, setCorretores] = useState<any[]>([]);
  const [totalPaginas, setTotalPaginas] = useState<number>(1);
  const { setLoaderActive } = useLoader();
  const [selectCorretores, setSelectCorretores] = useState<any[]>([
    { corretor: { label: 'Todos', value: '' }, nu_order: 0 },
  ]);
  const { user } = useAuth();

    useEffect(() => {
    async function loadCorretores() {
      //const res = await api.get(`Ponto/CorretoresImoveisList`, {
      const res = await api.get(`Ponto/CorretoresGerentesTerceirosList`, {
        params: { nu_pagina: 1, nu_quantidade: 10 },
      });
      setCorretores(
        res.data.map((d: any) => ({
          label: d.tx_apelido,
          value: d.id_corretorSigavi,
          empresaSigavi: d.tx_empresaSigavi,
          empresaSigaviId: d.id_empresaSigavi,
          canalId: d.id_canal,
          canal: d.canal,
        })).filter((e: any) => {
          if(item.id_empresaSigavi == 1){
            //return e.empresaSigavi === 'Tegra SP' || e.empresaSigavi === 'Tegra Lab'
            return e.empresaSigavi === 'Tegra SP' && e.canalId === item.id_canal
          }
          else if(item.id_empresaSigavi == 3){
            return e.empresaSigavi === 'Tegra Campinas' && e.canalId === item.id_canal
          }else{
            return e.empresaSigavi === 'Tegra RJ' && e.canalId === item.id_canal
          }

          /*
          if(item.estado.uf === 'SP'){
            return e.empresaSigavi === 'Tegra SP' || e.empresaSigavi === 'Tegra Campinas' || e.empresaSigavi === 'Tegra Lab'
          }else{
            return e.empresaSigavi === 'Tegra RJ'
          }
          */
        }),
      );
    }

    async function load() {
      try {
        // carregar corretores da api
        setLoaderActive(true);
        loadCorretores();
        setLoaderActive(false);
      } catch (e) {
        console.log(e);
      }
    }
    load();
  }, []);

  const encontrarCorretorFormRef = useRef<FormHandles>(null);

  const getParams = () => {
    if (!encontrarCorretorFormRef.current) {
      return {
        dataInicio: item.dt_data,
        dataFim: item.dt_data,
        empreendimento: item.isEscritorio ? null : item.id_produto,
        escritorio: item.isEscritorio ? item.id_produto : null,
        estado: item.id_uf,
        page: currentPage,
        periodo: item.tx_periodo,
        canal: item.id_canal,
        gerente: user.idCargoSigavi === 5 || user.idCargoSigavi === 14 ? user.id : null,
        apelidoSuperintendente: user.idCargoSigavi === 26 ? user.apelido : null
      };
    }
    const searchData = encontrarCorretorFormRef.current.getData() as any;
    // eslint-disable-next-line consistent-return
    return {
      dataInicio: item.dt_data,
      dataFim: item.dt_data,
      empreendimento: item.isEscritorio ? null : item.id_produto,
      escritorio: item.isEscritorio ? item.id_produto : null,
      estado: item.id_uf,
      regional: item.id_empresaSigavi,
      corretor: searchData?.corretores?.value,
      /*gerente: searchData?.gerente?.value,*/
      gerente: user.idCargoSigavi === 5 || user.idCargoSigavi === 14 ? user.id : searchData?.gerente?.value,
      page: currentPage,
      periodo: item.tx_periodo,
      canal: item.id_canal,
      apelidoSuperintendente: user.idCargoSigavi === 26 ? user.apelido : null
    };
  };
  

  const doSearch = () => {
    setLoaderActive(true);
    const params = getParams();
    api.get(`Ponto/PontosListItem`, { params }).then((res: any) => {
      const {
        data: { lista, nu_total },
      } = res;
      setList([...(currentPage === 1 ? [] : list), ...lista]);
      setTotalPaginas(nu_total);
      setLoaderActive(false);
    });
  };

  const handleLoadMore = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage]);

  useEffect(() => {
    doSearch();
  }, [currentPage]);

  const updateEditList = () => {
    var corretoresRegistrados: any[] = [];
    list.map((i, idx) => {
      corretoresRegistrados.push({
        corretor: { label: i.corretor?.tx_apelido, value: i.id_corretor },
        nu_order: i.nu_order,
        id_relogio_ponto: i.id_relogio_ponto,
        id_responsavel: i.id_responsavel,
      });
    });
    setSelectCorretores(corretoresRegistrados);
  };

  useEffect(() => {
    updateEditList();
  }, [list]);

  const exportList = async (): Promise<void> => {
    setLoaderActive(true);
    const params = getParams();

    const response = await api.get(`Ponto/getRelatorio`, {
      params,
      responseType: 'blob',
    });

    const nav = (window.navigator as any);        
        if (nav.msSaveOrOpenBlob) {
      // IE11
      nav.msSaveOrOpenBlob(
        new Blob([response.data]),
        'MeusCorretores.xlsx',
      );
    } else {
      const urlDown = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlDown;
      link.setAttribute('download', 'Presenca_Plantao.xlsx');
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(urlDown);
      }, 100);
    }
    setLoaderActive(false);
  };

  const handleSubmit = () => {
    for(let i = 0; i < selectCorretores.length; i = i + 1) {
      if(selectCorretores[i].corretor.value === ''){
        toast('Preencha todos campos para adicionar', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          type: 'error',
        });
        return false;
      }
    }

    let data = {
      dt_data: new Date(Date.UTC(date.toDate().getFullYear(),
      date.toDate().getMonth(),
      date.toDate().getDate(),
      date.toDate().getHours(),
      date.toDate().getMinutes(),
      date.toDate().getSeconds())),
      corretores: selectCorretores.map((sc: any, index: number) => {
        return {
          id_corretor: sc.corretor.value,
          nu_order: index + 1,
          id_relogio_ponto: sc.id_relogio_ponto,
          id_responsavel: user.id,
        };
      }),
      tx_periodo: item.tx_periodo,
      id_produto: item.isEscritorio ? null : item.id_produto,
      id_escritorio: item.isEscritorio ? item.id_produto : null,
      id_canal: item.id_canal,
      tx_canal: item.tx_canal
    };

    setLoading(true);
    api
      .post(`Ponto/updatePonto`, data)
      .then(() => {
        setIsOpen(false);
        toast('Presença registrada com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          type: 'success',
        });
        setCurrentPage(1);
        doSearch();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast('Ocorreu um erro ao inserir, tente novamente mais tarde', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          type: 'error',
        });
      });
  };

  const handleCancel = () => {
    setDate(moment());
    setIsOpen(false);
    updateEditList();
  };

  const handleExcluir = (id: any) => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('Tem certeza que deseja excluir este registro?')) return;
    setLoaderActive(true);
    api.delete(`Ponto/deletePonto/${id}`).then(() => {
      toast('Presença removida com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        type: 'success',
      });
      setCurrentPage(1);
      doSearch();
    });
    if (list.length === 1) {
      setDetail(false);
    }
  };

  const addCorretor = () => {
    setSelectCorretores(prevState => [
      ...prevState,
      { corretor: { label: 'Todos', value: '' }, nu_order: 0, id_responsavel: user.id },
    ]);    
    
  };

  const removeCorretor = (idx: any, id: any, id_relogio_ponto: any) => {
    if (id_relogio_ponto) {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm('Tem certeza que deseja excluir este registro? Excluir agora vai desconsiderar qualquer mudança que tenha feito!')) return;
      setLoaderActive(true);
      api.delete(`Ponto/deletePonto/${id_relogio_ponto}`).then(() => {
        toast('Presença removida com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          type: 'success',
        });
        setCurrentPage(1);
        doSearch();
      });
      setSelectCorretores(
        selectCorretores.filter(el => el.corretor.value !== id),
      );
      if (list.length === 1) {
        setDetail(false);
        setIsOpen(false);
      }
      return;
    }

    if (id !== '') {
      setSelectCorretores(
        selectCorretores.filter(el => el.corretor.value !== id),
      );
    } else {
      setSelectCorretores(selectCorretores.filter((el, i) => i !== idx));
    }
  };

  const hadleChangeCorretor = (idx: any) => (e: any) => {
    let newArr = [...selectCorretores];
    newArr[idx].corretor = e;
    setSelectCorretores(newArr);
  };

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = selectCorretores[dragIndex]
      setSelectCorretores(
          update(selectCorretores, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        )
    },
    [selectCorretores],
  );

  const handdleAdd = () =>{
    setIsOpen(true);
    addCorretor();
  }

  return (
    <Container>
      <HeaderItem>
        <a href="#" onClick={() => setDetail(false)} className="link-back">
          <FiChevronLeft />
          Voltar
        </a>
        <TegraButton isAction onClick={exportList}>
          EXPORTAR
        </TegraButton>
      </HeaderItem>
      <ul className="itemInfo__list">
        <li className="itemInfo__itemTitle">
          <h4>
            Produto: <b>{item.produto?.nome}</b>
          </h4>
        </li>
        <li className="itemInfo__itemText">
          <span>
            UF: <b>{item.estado?.uf}</b>
          </span>
        </li>
        <li className="itemInfo__itemText">
          <span>
            Data: <b>{moment(item?.dt_data).format('DD/MM/yyyy')}</b>
          </span>
        </li>
        <li className="itemInfo__itemText">
          <span>
            Periodo: <b>{item?.tx_periodo}</b>
          </span>
        </li>
      </ul>
      <TegraDivider color="#e6e6e6" axis="x" height={1} />
      <CorretoresContainer>
        <CorretoresTable>
          <thead>
            <tr>
              <th>Posição</th>
              <th>Corretor</th>
              <th>Data</th>
              <th>Período</th>
              <th>Gerente</th>
              <th>Produto</th>
              <th>Regional</th>
              <th>UF</th>
              <th>Empresa de Venda</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {list.map((i, idx) => (
              <tr key={i.id_parceiro}>
                <td>
                  <span className="caption">Posição</span>
                  {idx + 1}°
                </td>
                <td>
                  <span className="caption">Corretor</span>
                  {i.corretor?.tx_apelido}
                </td>
                <td>
                  <span className="caption">Data</span>
                  {moment(i.dt_data).format('DD/MM/yyyy')}
                </td>
                <td>
                  <span className="caption">Perídodo</span>
                  {i.tx_periodo}
                </td>
                <td>
                  <span className="caption">Gerente</span>
                  {i.corretor?.tx_equipe}
                </td>
                <td>
                  <span className="caption">Produto</span>
                  {i.produto?.nome}
                </td>
                <td>
                  <span className="caption">Regional</span>
                  {i.regional?.tx_empresaSigavi}
                </td>
                <td>
                  <span className="caption">UF</span>
                  {i.estado?.uf}
                </td>
                <td>
                  <span className="caption">Empresa de Venda</span>
                  {i.tx_canal}
                </td>
                <td className="action">
                  {
                    ((
                      user.idCargoSigavi === 8 || // Coordenador de Produto
                      user.idCargoSigavi === 10 || // Supervisor de Venda
                      user.idCargoSigavi === 18 // Coordenador de Produto 2
                    ) || 
                    (
                      (user.idCargoSigavi !== 5 && user.idCargoSigavi !== 14 && user.idCargoSigavi !== 26) &&
                      (user.idCargoSigavi == 25 || user.id == i.id_responsavel)
                    )) && 
                    (
                    <div>
                      <TegraButtonExtra
                        onClick={() => setIsOpen(true)}
                        isAction={false}
                        isExtra
                        icon={openIcon}
                      >
                        Editar
                      </TegraButtonExtra>
                      <TegraButtonExtra
                        onClick={() => handleExcluir(i.id_relogio_ponto)}
                        isAction={false}
                        isExtra
                        icon={openIcon}
                      >
                        Excluir
                      </TegraButtonExtra>                      
                    </div>
                    )

                  }
                </td>
              </tr>
            ))}
          </tbody>
        </CorretoresTable>
        {
          (user.idCargoSigavi !== 5 && user.idCargoSigavi !== 14 && user.idCargoSigavi !== 26) &&
          (
            <TegraButton isAction onClick={() => handdleAdd()}>
              ADICIONAR
            </TegraButton>
          )
        }


        {currentPage < totalPaginas && (
          <ListaAction>
            <TegraButton isAction onClick={handleLoadMore}>
              Carregar mais
            </TegraButton>
          </ListaAction>
        )}
      </CorretoresContainer>
      <Modal
        isOpen={isOpen}
        contentLabel="Presença Plantão"
        portalClassName="modal-presenca"
      >
        <ContainerModal>
        <CloseButton onClick={() => handleCancel()}>
            <CloseIcon />
          </CloseButton>
          <h3>Adicionar presença em plantão</h3>

          <Form
            // ref={encontrarCorretorFormRef}
            onSubmit={handleSubmit}
            // initialData={searchFormInitialData}
          >
            <label className="custom-label">Data</label>
            <SingleDatePicker
              isOutsideRange={(day: any) => false}
              focused={focusDate}
              onFocusChange={(f: any) => setFocusDate(f.focused)}
              onDateChange={(date: any) => {
                setDate(date);
              }}
              date={moment(item?.dt_data)}
              id="start_date_id2"
              disabled
            />
            <TegraInput
              value={item.produto?.nome}
              label="Produto"
              name="produto"
              disabled
            />
            <TegraInput
              value={item?.tx_periodo}
              label="Período"
              name="periodo"
              disabled
            />
            <DndProvider backend={HTML5Backend}>
              {selectCorretores.map((val, idx) => {
                return (
                  <>
                  <PresencaSelectDnD
                    id={val.corretor.value}
                    corretor={val.corretor}
                    id_relogio_ponto={val.id_relogio_ponto}
                    id_responsavel={val.id_responsavel}
                    index={idx}
                    moveCard={moveCard}
                    selectCorretoresLength={selectCorretores.length}
                    corretores={corretores}
                    hadleChangeCorretor={hadleChangeCorretor}
                    addCorretor={addCorretor}
                    removeCorretor={removeCorretor}
                  />
                  </>
                );
              })}
            </DndProvider>
            <Espace/>
          </Form>
        </ContainerModal>
        <ListaAction>
            <TegraButton isAction isDisabled={loading} onClick={handleCancel}>
              Cancelar
            </TegraButton>
            <TegraButton isAction isDisabled={loading} onClick={handleSubmit}>
              Salvar
              {loading && <img src={ajaxLoading} alt="Ícone do botão" />}
            </TegraButton>
          </ListaAction>
      </Modal>
    </Container>
  );
};

export default PresencaItem;
