import React from 'react';
import {
  TegraCorretorOuterWrapper,
  TegraPaginaCorretorContainer,
  TegraCorretorWrapper,
  TegraCorretorDescription,
  TegraCorretorName,
  CorretorDescriptionRow,
  TegraCorretorDivider,
  TegraCorretorIntroduction,
  CorretorBackContainer,
  CorretorBackIcon,
} from './styles';
import TegraRoundImage from '../TegraRoundImage';
import TegraButton from '../TegraButton';
import chevron from '../../assets/tegra-arrow-down-icon.svg';
import TegraNavIcon from '../TegraNavIcon';
import { getLogoByTipo, getTituloByTipo } from '../../utils/logo';
import {  useParams } from 'react-router-dom';

export interface TegraUser {
  name: string;
  creci: string;
  email: string;
  phone: string;
  image: string;
  description: string;
  tipo: number;
  isLab: boolean;
}

interface TegraPresentationHeaderProps {
  user: TegraUser;
  details?: boolean;
}

interface Params {
  regional: any;
  apelido: any;
}

const TegraPresentationHeader: React.FC<TegraPresentationHeaderProps> = ({
  user,
  details,
}) => {
  const { regional, apelido } : any = useParams();
  return (
    <TegraCorretorOuterWrapper>
      <TegraPaginaCorretorContainer>
        <TegraCorretorWrapper>
          <TegraNavIcon
            tegraLogo={getLogoByTipo(user.tipo, user.isLab)}
            description={''}
            title=''
            link={`/${apelido}/${regional}`}
          />
          <TegraRoundImage src={user.image} size="124px" />

          <TegraCorretorDescription>
            <TegraCorretorName>{user.name}</TegraCorretorName>

            {!!user.creci && (
              <CorretorDescriptionRow>
                CRECI: {user.creci}
              </CorretorDescriptionRow>
            )}

            <CorretorDescriptionRow>{user.email}</CorretorDescriptionRow>

            <CorretorDescriptionRow>{user.phone}</CorretorDescriptionRow>
          </TegraCorretorDescription>

          <TegraCorretorDivider />

          <TegraCorretorIntroduction details>
            {user.description}
          </TegraCorretorIntroduction>
        </TegraCorretorWrapper>

        {/* Kikuti: removendo até achar uma solução
        {!!details && (
          <CorretorBackContainer>
            <TegraButton
              isAction
              onClick={() => { }}
            >
              <CorretorBackIcon src={chevron} alt="Ícone do botão" />
              VOLTAR PARA OS RESULTADOS
            </TegraButton>
          </CorretorBackContainer>
        )} */}
      </TegraPaginaCorretorContainer>
    </TegraCorretorOuterWrapper>
  );
};

export default TegraPresentationHeader;
