import React, { useState, useEffect } from 'react';
import UndecoratedLink from '../UndecoratedLink';
import {
  ContactBarContainer,
  ContactBarTitle,
  ContactBarItem,
  ItemIcon,
  ItemBody,
  TitleBreak,
  FormEmail,
} from './styles';
import phoneIcon from '../../assets/phone-icon.png';
import whatsappIcon from '../../assets/whatsapp-icon.png';
import mailIcon from '../../assets/mail-icon.png';
import api from '../../services/api';

interface ITegraContactBar {
  tel: string;
  mail: string;
  name: string;
}


const TegraContactBar: React.FC<ITegraContactBar> = ({ tel, mail, name }) => {
  const [isForm, SetIsForm] = useState<boolean>(false);
  const [isSucesso, SetIsSucesso] = useState<boolean>(false);
  const [formData, SetFormData] = useState<any>({});
  const toggleForm = () => (isForm ? SetIsForm(false) : SetIsForm(true));

  const handleChange = (event:any) => {
    const auxValues = { ...formData };
    auxValues[event.target.name] = event.target.value;
    SetFormData(auxValues);
  };

  const handleSubmit = (e:any) => { 
    e.preventDefault();

    console.log(formData);
    api.post('Email/paginaCorretor/sendLead', formData)
    .then((res) => {
      SetIsSucesso(true)
      document.getElementById('feedback-email')!.innerText ="E-mail enviado com sucesso";
    })
    .catch((err) =>{
      document.getElementById('feedback-email')!.innerText = "Ops parece que houve algum erro, por favor tente novamente mais tarde.";
      console.log(err)
    });
  };

  useEffect(() =>{
    const auxValues = { ...formData };
    auxValues['MailTo'] = mail;
    auxValues['MailToName'] = name;
    SetFormData(auxValues);
  }, [mail])

  return (
    <ContactBarContainer>
      {!isForm ? (
        <>
          <ContactBarTitle>
            Compre <TitleBreak />
            seu imóvel
          </ContactBarTitle>
          <ContactBarItem>
            <a href={`tel:+55${tel}`}>
              <ItemIcon src={phoneIcon} />
              <ItemBody>{tel}</ItemBody>
            </a>
          </ContactBarItem>
          <ContactBarItem>
            <a
              href={`https://api.whatsapp.com/send?phone=55${tel.replace(/\D/g, '')}`}
              target="_blank"
            >
              <ItemIcon src={whatsappIcon} />
              <ItemBody>{tel}</ItemBody>
            </a>
          </ContactBarItem>
          <ContactBarItem>
            <button onClick={() => toggleForm()}>
              <ItemIcon src={mailIcon} />
              <ItemBody>E-mail</ItemBody>
            </button>
          </ContactBarItem>
        </>
      ) : (
        <FormEmail>
          <div className="header-email">
            <span>E-mail</span>
            <button className="close-email" onClick={() => toggleForm()}>
              &gt;
            </button>
          </div>
          <div className="body-email">
            <form
              onSubmit={(e) => handleSubmit(e)}
              id="formcontato"
              className="formContato"
            >
              <div className="box-input -op2">
                <label>
                  Nome:
                  <input
                    type="text"
                    name="Nome"
                    className="input"
                    required
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className="box-input -op2">
                <label>
                  Telefone:
                  <input
                    type="tel"
                    name="Telefone"
                    id="Telefone"
                    className="input"
                    required
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className="box-input -op2">
              <label>
                  E-mail:
                  <input
                    type="email"
                    name="Email"
                    id="Email"
                    className="input"
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className="box-textarea">
                <textarea
                  name="Mensagem"
                  placeholder="Mensagem"
                  className="textarea"
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <div className="checkbox">
                <label className="texto-check">
                  <input
                    type="checkbox"
                    name="PoliticaPrivacidade"
                    id="PoliticaPrivacidade"
                    onChange={handleChange}
                    required
                  />
                  <span className="checkmark"></span>
                  <span>
                    Li e concordo com a{' '}
                    <a
                      href="https://www.tegraincorporadora.com.br/politicaprivacidade/"
                      target="_blank"
                    >
                      politica de privacidade.
                    </a>
                  </span>
                </label>
              </div>
              {!isSucesso &&
                <button type="submit" className="btn">Enviar</button>
              }              
              <span id="feedback-email" className="feedback"></span>
            </form>
          </div>
        </FormEmail>
      )}
    </ContactBarContainer>
  );
};

export default TegraContactBar;
