import styled from "styled-components";

export const TegraCorretor = styled.div`
    background-color: white;

    .header-container{
        position: relative;
        z-index:1;

        @media (max-width: 1024px){
            z-index: 0;
        }
    }

    & .submenu .container .flow-through a p{
        color: #000;
        text-align: center;
    }

    & .submenu .container .flow-through a:hover p{
        color: #FFF;
    }

    & .submenu .container .flow-through a.active p{
        color: #FFF;
    }

    & #main.pageDetalhe #plantas .carousel-plantas .info-ap{
        margin-top: 5vh;
        height: 5vh;
    }
    
`;