import React from 'react';

import {
  Switch,
  useRouteMatch,
  Route,
  useParams,
  useHistory,
} from 'react-router-dom';
import { Container, SignUpOptionsBox } from './styles';
import TegraIntroHeader from '../../components/TegraIntroHeader';

import TegraCorretorSteps from './TegraCorretorSteps';
import TegraImobiliariaSteps from './TegraImobiliariaSteps';
import TegraSignUpOptions from './TegraSignUpOptions';
import api from '../../services/api';
import { ToSlug } from '../../services/helpers';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { Console } from 'console';

const SignUp: React.FC = () => {
  const { path } = useRouteMatch();
  const { apelido, uf } = useParams<any>();
  const history = useHistory();
  const slugApelido = ToSlug(apelido);

  if (apelido !== slugApelido) {
    history.push(`/cadastro/${uf}/${slugApelido}`);
    return <></>;
  }

  if (uf && apelido && !sessionStorage.getItem('_corretorSelecionado')) {
    api
      .post('Cadastro/getCorretor', { tx_uf: uf, tx_gerente: apelido })
      .then(res => {
        sessionStorage.setItem(
          '_corretorSelecionado',
          JSON.stringify(res.data),
        );
      })
      .catch(err => {});
  }
  else if (!uf && !apelido) {
    sessionStorage.removeItem('_corretorSelecionado');
  }

  return (
    <Container>
      <TegraIntroHeader dividerAxis="y" dividerColor="#ebb92d" />
      <SignUpOptionsBox>
        <Switch>
          <Route exact path={path} component={TegraSignUpOptions} />
          
          <Route path={`${path}/imobiliaria`}>
            <>
              <TegraImobiliariaSteps/>
            </>
          </Route>
          <Route path={`${path}/corretor-autonomo`}>
            <>
              <TegraCorretorSteps />
            </>
          </Route>
        </Switch>
      </SignUpOptionsBox>
    </Container>
  );
};

export default SignUp;
