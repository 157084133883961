import React, {useCallback} from 'react';

import {
  RelatorioTableContainer,
  RelatorioTableHeader,
  RelatorioTableRow,
} from './styles';
import { IRelatorio } from '..';
import { useHistory } from 'react-router-dom';

interface IRelatorioTableProps {
  relatorioList: IRelatorio[];
  tipo: string;
  dateFrom: string;
  dateTo: string;
}

const RelatorioTable: React.FC<IRelatorioTableProps> = ({ relatorioList, tipo, dateFrom, dateTo}) => {
  const history = useHistory();
  return (
    <RelatorioTableContainer>
    <thead>
      { tipo == 'Acessos' ? 
        <RelatorioTableRow>
          <RelatorioTableHeader>Responsável</RelatorioTableHeader>
          <RelatorioTableHeader>Corretor / Imobiliária</RelatorioTableHeader>
          <RelatorioTableHeader>Superintendente</RelatorioTableHeader>
          <RelatorioTableHeader>Gerente</RelatorioTableHeader>
          <RelatorioTableHeader>Último download</RelatorioTableHeader>
          <RelatorioTableHeader>Downloads</RelatorioTableHeader>
        </RelatorioTableRow>
      : tipo == 'AcessosDetalhes' ?
        <RelatorioTableRow>
          <RelatorioTableHeader>Material</RelatorioTableHeader>
          <RelatorioTableHeader>Categoria</RelatorioTableHeader>
          <RelatorioTableHeader>Empreendimento</RelatorioTableHeader>
          <RelatorioTableHeader>Downloads</RelatorioTableHeader>
          <RelatorioTableHeader>Último download</RelatorioTableHeader>
        </RelatorioTableRow>
      : tipo == 'Materiais' ? 
        <RelatorioTableRow>
          <RelatorioTableHeader>Empreendimento</RelatorioTableHeader>
          <RelatorioTableHeader>Total de download de materiais</RelatorioTableHeader>
          <RelatorioTableHeader>Detalhes</RelatorioTableHeader>
        </RelatorioTableRow>
      : tipo == 'MateriaisDetalhes' ? 
        <RelatorioTableRow>
          <RelatorioTableHeader>Material</RelatorioTableHeader>
          <RelatorioTableHeader>Downloads</RelatorioTableHeader>
          <RelatorioTableHeader>Detalhes</RelatorioTableHeader>
        </RelatorioTableRow>
      : 
        <RelatorioTableRow>
          <RelatorioTableHeader>Nome</RelatorioTableHeader>
          <RelatorioTableHeader>Superintendente</RelatorioTableHeader>
          <RelatorioTableHeader>Gerente</RelatorioTableHeader>
          <RelatorioTableHeader>Downloads</RelatorioTableHeader>
          {/* <RelatorioTableHeader>Ver mais</RelatorioTableHeader> */}
        </RelatorioTableRow>
      }
      
    </thead>
    { tipo == 'Acessos' ?
        <tbody> 
          {relatorioList.map(relatorioItem => (
            <RelatorioTableRow>
              {Object.keys(relatorioItem).map(key => {
                if (key == 'idTipoUsuario' || key == 'idUsuario'){
                  return null;
                }
                return (
                  <td>
                    {key == 'nome' ?
                      <a onClick={() => history.push(`/dashboard/relatorio/acessosdetalhes?id=${relatorioItem['idUsuario']}&idTipoUsuario=${relatorioItem['idTipoUsuario']}&inicio=${dateFrom}&fim=${dateTo}`)}>{relatorioItem[key]}</a>
                    :
                      <p>{relatorioItem[key]}</p>
                    }
                  </td>
                );
              })}
            </RelatorioTableRow>
          ))}
        </tbody>
      : tipo == 'AcessosDetalhes' ?
        <tbody> 
          {relatorioList.map(relatorioItem => (
            <RelatorioTableRow>
              {Object.keys(relatorioItem).map(key => {
                return (
                  <td>
                    <p>{relatorioItem[key]}</p>
                  </td>
                );
              })}
            </RelatorioTableRow>
          ))}
        </tbody>
      : tipo == 'Materiais' ?
        <tbody> 
          {relatorioList.map(relatorioItem => (
            <RelatorioTableRow>
              {Object.keys(relatorioItem).map(key => {
                if (key == 'empreendimentoId'){
                  return null;
                }
                return (
                  <td>
                    {key == 'detalhes' ? 
                      <a className="detalhes" onClick={() => history.push(`/dashboard/relatorio/materiaisdetalhes?id=${relatorioItem['empreendimentoId']}&inicio=${dateFrom}&fim=${dateTo}`)}>{relatorioItem[key]}</a>
                    :
                      <p>{relatorioItem[key]}</p>
                    }
                  </td>
                );
              })}
            </RelatorioTableRow>
          ))}
        </tbody> 
    :  tipo == 'MateriaisDetalhes' ? 
        <tbody> 
          {relatorioList.map(relatorioItem => (
            <RelatorioTableRow>
              {Object.keys(relatorioItem).map(key => {
                if (key == 'empreendimentoId'){
                  return null;
                }
                return (
                  <td>
                    {key == 'detalhes' ? 
                      <a className="detalhes" onClick={() => history.push(`/dashboard/relatorio/materiaisdetalhesusuarios?id=${relatorioItem['empreendimentoId']}&tipo=${relatorioItem['material']}&inicio=${dateFrom}&fim=${dateTo}`)}>{relatorioItem[key]}</a>
                    :
                      <p>{relatorioItem[key]}</p>
                    }
                  </td>
                );
              })}
            </RelatorioTableRow>
          ))}
        </tbody>
    :
        <tbody> 
          {relatorioList.map(relatorioItem => (
            <RelatorioTableRow>
              {Object.keys(relatorioItem).map(key => {
                return (
                  <td>
                    {key == 'verMais' ? 
                      <a className="detalhes" onClick={() => history.push(`/dashboard/relatorio/materiaisdetalhesusuarios?id=${relatorioItem['empreendimentoId']}&inicio=${dateFrom}&fim=${dateTo}`)}>{relatorioItem[key]}</a>
                    :
                      <p>{relatorioItem[key]}</p>
                    }
                  </td>
                );
              })}
            </RelatorioTableRow>
          ))}
        </tbody>
    }        
  </RelatorioTableContainer>
  );
};

export default RelatorioTable;
