import React, { useCallback } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import iconCalendar from '../../assets/calendar-image.png';

import moment from 'moment';

import {
  Container,
  BlogItem,
  BlogItemContent,
  BlogItemThumbnail,
  BlogItemInfo,
  BlogItemActions,
  ContainerEvento,
  DataEvento
} from './styles';

interface BlogItem {
  imagem?: any;
  titulo: string;
  subtitulo: string;
  conteudo: string;
  chamada?: string;
  link: string;
  data: string;
  id: string;
}

interface ITegraBlogOverviewProps {
  items: BlogItem[];
  pageSlug: string;
}

const TegraBlogOverviewCalendario: React.FC<ITegraBlogOverviewProps> = ({
  items,
  pageSlug,
}) => {
  //const { path } = useRouteMatch();
  //const { path } = pageSlug();

  return (
    <Container>
      {items.map(blogItem => (        
        <BlogItem key={blogItem.id}>
          <BlogItemContent>
            {/*blogItem.imagem && (
              <Link to={`${pageSlug}/${blogItem.link}`}>                
                <BlogItemThumbnail
                  src={blogItem.imagem}
                  alt={`Image do ${blogItem.titulo}`}
                />
              </Link>
            )*/}
            <Link to={`${pageSlug}/${blogItem.link}`}>
              
              <ContainerEvento>
                <DataEvento style={{ backgroundImage: `url(${iconCalendar})`, backgroundRepeat: 'no-repeat', backgroundSize: '110px 125px' }}>
                  <div className='mesEvento'>
                    <p>{moment(blogItem.data.substring(6,10) +'-'+
                               blogItem.data.substring(3,5) +'-'+
                               blogItem.data.substring(0,2)).format('MMM').toUpperCase()}
                    </p>
                  </div>
                  <div className='diaEvento'>
                    <p>{blogItem.data.substring(0,2)}</p>
                  </div>
                </DataEvento>
                <BlogItemInfo>
                  <h2>{blogItem.titulo}</h2>
                  <strong>{blogItem.subtitulo}</strong>
                  <p>{blogItem.chamada}</p>
                </BlogItemInfo>
              </ContainerEvento>

            </Link>
          </BlogItemContent>
          <BlogItemActions>
            <Link to={`${pageSlug}/${blogItem.link}`}>Ver mais</Link>
          </BlogItemActions>
        </BlogItem>
      ))}
    </Container>
  );
};

export default TegraBlogOverviewCalendario;
