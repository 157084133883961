import React, { useState, useEffect } from 'react';
import { FloatCard } from './styles';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { ReactComponent as TelephoneIcon } from '../../assets/espaco_gerente_options/tegra-icon-telephone.svg';
import { ReactComponent as EmailIcon } from '../../assets/espaco_gerente_options/tegra-icon-email.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/tegra-icon-arrow-down.svg';
import { ReactComponent as TelegramIcon } from '../../assets/Telegram_logo.svg';

export interface IGerenteData {
  tx_nome: string;
  tx_email: string;
  tx_telefone: string;
  tx_foto: string;
}

const ContactFloatCard: React.FC = () => {
  const { user } = useAuth();
  const [gerenteData, setGerenteData] = useState<IGerenteData>();
  const [aberto, setAberto] = useState<boolean>(
    localStorage.getItem('__popup') === '1',
  );

  useEffect(() => {
    if (!user) return;
    const data = window.sessionStorage.getItem('gerenteData');
    if (data) {
      const datajson = JSON.parse(data);
      setGerenteData(datajson);
    } else {
      api.get(`Perfil/gerente`).then(res => {
        setGerenteData(res.data);
        window.sessionStorage.setItem('gerenteData', JSON.stringify(res.data));
      });
    }
  }, [user]);

  const togglePopup = (e: any): void => {
    e.preventDefault();
    localStorage.setItem('__popup', aberto ? '0' : '1');
    setAberto(!aberto);
  };

  return (
    <>
      {user && user.tipo === 0 && gerenteData ? (
        <FloatCard className={aberto ? 'aberto' : 'fechado'}>
          <button
            type="button"
            className="FloatCard__header"
            onClick={togglePopup}
          >
            Canal de parcerias <ArrowDownIcon />
          </button>
          <div className="FloatCard__infos">
            <div className="FloatCard__infosTitle">
              <h6 className="FloatCard__infosTitleText">Fale com o Gerente</h6>
            </div>
            <div className="FloatCard__infosName">
              <span
                className="FloatCard__infosNamePic"
                style={{ backgroundImage: `url(${gerenteData.tx_foto}` }}
              />
              <div>
                <p className="FloatCard__infosNameText">
                  {gerenteData.tx_nome}
                </p>
                <a
                  href={`mailto:${gerenteData.tx_email}`}
                  className="FloatCard__infosEmailText"
                >
                  {gerenteData.tx_email}
                </a>
                <a
                  href={`tel:+55${gerenteData.tx_telefone}`}
                  className="FloatCard__infosPhoneText"
                >
                  {gerenteData.tx_telefone}
                </a>
              </div>
            </div>
            {/*user.idRegional !== 2 && (
              <div className="FloatCard__telegram">
                <div className="FloatCard__telegramCta">
                  <TelegramIcon className="FloatCard__telegramCtaIcon" />
                  <p className="FloatCard__telegramCtaText">
                    Participe do nosso grupo oficial do Telegram
                  </p>
                </div>
                <a
                  className="FloatCard__telegramAction"
                  target="_blank"
                  href="https://t.me/joinchat/H0rERina5tbz1ASy"
                >
                  Participar
                </a>
              </div>
            )*/}
          </div>
        </FloatCard>
      ) : (
        false
      )}
    </>
  );
};

export default ContactFloatCard;
