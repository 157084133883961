import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 25px;
  
  .show-edit,
  .hide-edit {
    transition: 0.3s opacity;
  }
  
  .hide-edit {
    opacity: 0;
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  .show-edit {
    opacity: 1;
  }

  .popupFade{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    background-color: rgba(0, 0, 0, 0.8);
    
    .popupContent{
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .popupBox{
        background-color: #fff;
        padding: 40px 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #000;
        min-width: 300px;

        p{
          margin-top: 0;
          margin-bottom: 1rem;
        }

        .popupButtons{
          display: flex;
          justify-content: space-around;
          width: 100%;
        }

        .btn-popup, .btn-popup:hover, .btn-popup:active, .btn-popup:link, .btn-popup:focus {
          color: #fff !important;
          background-color: #ffc107;
          border: 1px solid #ffc107;
          border-color: ;
          display: inline-block;
          padding: 0.25rem 0.5rem;
          font-size: 0.765625rem;
          line-height: 1.5;
          text-decoration: none;
          min-width: 60px;
          text-align: center;
        }
      }
    }
  }
`;

export const TarefasList = styled.div`
  margin-top: 40px;
`;

export const Content = styled.div`
  margin-top: 40px;
`;

export const Profile = styled.div`
  display: flex;
  margin-bottom: 21px;
  
  .ml-1{
    margin-left: 20px;
  }

  .ml-auto{
    margin-left: auto;
  }
  
  .df{
    display:flex;
  }

  .btn{
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;

    &-edit{
      color: #fff;
      background-color: #f86c6b;
      border-color: #f86c6b;
    }

    &-danger{
      color: #fff;
      background-color: #f86c6b;
      border-color: #f86c6b;
    }

    &:hover{
      filter: saturate(0.85);
    }
  }
`;

export const ProfileAvatar = styled.img`
  display: block;
  width: 55px;
  height: 55px;
  object-fit: contain;
  border-radius: 50%;
  background-color: #e1e1e1;
`;

export const ProfileInfo = styled.div`
  margin-left: 20px;
  strong {
    display: block;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #60605d;
  }

  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #60605d;
  }
`;

export const Options = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OptionsLeft = styled.div`
  display: flex;

  button {
    height: 49px;
    margin-right: 30px;
  }

  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column-reverse;

    button {
      width: 100%;
      margin-top: 40px;
    }
  }
`;

interface OptionsRightProps {
  show: boolean;
}

export const OptionsRight = styled.div<OptionsRightProps>`
  display: none;

  ${props =>
    props.show &&
    css`
      display: flex;
      flex-wrap: wrap;
    `}

  & > div:not(:last-child) {
    margin-right: 10px;

    @media (max-width: 1024px) {
      margin-right: 0;
    }
  }

  & > div:not(:first-child) {
    @media (max-width: 1024px) {
      margin-left: 10px;
    }
  }

  @media (max-width: 1024px) {
    justify-content: flex-end;
    & > div {
      margin-right: 0px;
      margin-top: 10px;
    }
  }

  @media (min-width: 769px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    background-color: #e6e6e6;

    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s;
    z-index: 5;
    .divider {
      margin-left: 0px !important;
      width: 100%;

      & ~ div:not(:last-child) {
        margin: 0 36px !important;
      }
    }

    form {
      padding: 36px 36px;
    }

    .status {
      background-color: #fff !important;
      min-height: 50px !important;
    }

    .tipo {
      min-height: 68px !important;
      grid-template-columns: repeat(4, 1fr);
    }

    .option:not(.option-selected) {
      background-color: #fff !important;
    }
  }
`;

export const OptionsRightHeader = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 36px; */
  padding: 36px 32px 0px;
  width: 100%;

  @media (min-width: 769px) {
    display: none;
  }

  h1 {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #272723;
  }
`;

export const OptionsRightFilterActionsContainer = styled.div`
  padding: 39.7px 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  @media (min-width: 600px) {
    display: none;
  }

  span {
    display: block;
    margin-bottom: 40.5px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #272723;
    text-decoration: underline;
  }

  button {
    width: 100%;
    margin: 0px 36px;
  }
`;

export const ListActions = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
    button {
      flex: 1;
      width: 100%;
      margin-bottom: 14px;
    }
  }
`;
export const OptionsFiltroEOrdenar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: 1px solid #adadab;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color: #60605d;
  margin-bottom: 20px;

  @media (min-width: 769px) {
    position: absolute;
    .filtrar {
      display: none;
    }
    top: 0px;
    right: 0px;
    max-width: 200px;
    justify-content: flex-end;
    /* max-width: 200px; */

    /* display: none; */
    border: none;
  }

  div {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-left: 10px;
      path {
        fill: #60605d;
      }
    }
  }
`;
