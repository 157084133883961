import React from 'react';
import { Container, NotFoundContainer } from './styles';
import { Link } from 'react-router-dom';
import TegraIntroHeader from '../../components/TegraIntroHeader';
import TegraButton from '../../components/TegraButton';
import { useLoader } from '../../hooks/loader';

const NotFound: React.FC = () => {
  const { setLoaderActive } = useLoader();
  setLoaderActive(false)
  return (
    <Container>
      <TegraIntroHeader dividerAxis="y" dividerColor="#ebb92d" />
      <NotFoundContainer>
        <h1 className="NotFound__title">Página não encontrada!</h1>
        <span className="NotFound__text">Não foi possível localizar a página que você está tentando acessar. Verifique se você digitou o endereço corretamente ou se a página realmente existe.</span>
        <TegraButton isAction className="NotFound__action">
          <Link to="/" className="NotFound__actionLink">
            Ir para a home
          </Link>
        </TegraButton>
      </NotFoundContainer>
    </Container>
  );
};

export default NotFound;
