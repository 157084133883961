import React, { useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Container, NomeDoProdutoBox, Actions, Content, ListaAction, CloseButton, ImprimirMsg } from './styles';
import TegraButton from '../../../components/TegraButton';
import ajaxLoading from '../../../assets/ajax-loader-yellow.gif';
import api, { baseURL } from '../../../services/api';
import axios from 'axios';
import Modal from 'react-modal';
import { ReactComponent as CloseIcon } from '../../../assets/tegra-icon-close.svg';


const customStyles = {
  content: {
    width: 740,
    padding: 40,
    paddingTop: 40,
    top: '50%',
    left: '50%',
    right: 'auto',
    color: '#60605d',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',    
  },
};

interface ProdutoHeaderProps {
  nome: string;
  arquivo: string;
  pdfTabelaEspelho: string;
}


const ProdutoHeader: React.FC<ProdutoHeaderProps> = ({ nome, arquivo, pdfTabelaEspelho }) => {
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [isOpen, setIsOpen] = useState<any>(false);
  const [impEspelhoTabela, setImpEspelhoTabela] = useState(false);
  

  const downloadAs = (url: string, name: string) => {
    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
      })
      .then((response: any) => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = `${name.replace(/\s/g, '')}.pdf`;
        a.click();
        setTimeout(() => {
          setLoading1(false);
          setLoading2(false);
        }, 100);
      })
      .catch((err: any) => {
        console.log('error', err);
        setTimeout(() => {
          setLoading1(false);
          setLoading2(false);
        }, 100);
      });
  };

  const handleDownload = (e: any, isEspelho): void => {
    e.preventDefault();
    if (isEspelho){
      setLoading1(true);
    }
    else{
      setLoading2(true);
    }   

    if (!pdfTabelaEspelho){
      api
      .get(`${arquivo}&download=true&impEspelhoTabela=${isEspelho}`, { responseType: 'blob' })
      .then(response => {
        const nav = (window.navigator as any);        
        if (nav.msSaveOrOpenBlob) {
          // IE11
          nav.msSaveOrOpenBlob(
            new Blob([response.data]),
            `${nome}.pdf`,
          );
        } else {
          const monthNames = [
            'JAN',
            'FEV',
            'MAR',
            'ABR',
            'MAI',
            'JUN',
            'JUL',
            'AGO',
            'SET',
            'OUT',
            'NOV',
            'DEZ',
          ];

          const d = new Date();
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `[${monthNames[d.getMonth()]}] - ${nome}.pdf`,
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            if (isEspelho){
              setLoading1(false);
            }
            else{
              setLoading2(false);
            }        
            window.URL.revokeObjectURL(url);
          }, 100);
        }
      })
      .catch(err => {
        console.error(err);
      });
    } 
    else{
      downloadAs(pdfTabelaEspelho, 'Espelho de vendas')
    }
    
  };

  return (
    <Container>
      <Modal
        isOpen={isOpen}
        style={customStyles}
        contentLabel="Aviso Impressão"        
      >
        <CloseButton onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </CloseButton>
        <ImprimirMsg>
          <h3>Aviso para Impressão</h3>
          <p>
            O arquivo será gerado para a impressão em uma folha A3, sendo assim, recomendamos que imprima  em uma folha A3 ao invés do convencional A4, facilitando a leitura, assim como a disposição das informações.          
          </p>
        </ImprimirMsg>

        <div className="modal-footer">
          <ListaAction>
          <TegraButton isAction
            onClick={() => {setIsOpen(false); window.open(!pdfTabelaEspelho ? baseURL + `${arquivo}&impEspelhoTabela=${impEspelhoTabela}` : pdfTabelaEspelho, "_blank")}}
          >
            Continuar
          </TegraButton>
            <TegraButton isAction 
              onClick={() => setIsOpen(false)}
            >
                Cancelar
            </TegraButton>
          </ListaAction>
        </div>
      </Modal>
      <Content>
        <Link to="/dashboard/materiais-de-produtos/espelhos-e-tabelas">
          <NomeDoProdutoBox>
            <FiChevronLeft />
            {nome}
          </NomeDoProdutoBox>
        </Link>
        <Actions>
            <TegraButton isAction isExtra>
              {!loading1 && (
                <a
                  href={`${arquivo}&download=true&impEspelhoTabela=true`}
                  download
                  onClick={(e) => handleDownload(e,true)}
                >
                  Baixar Espelho
                </a>
              )}
              {loading1 && (
                <img className="margin0" src={ajaxLoading} alt="Carregando" />
              )}
          </TegraButton>
          <TegraButton isAction isExtra
              onClick={() => [setIsOpen(true), setImpEspelhoTabela(true)]}>
                Imprimir Espelho
          </TegraButton>
          <TegraButton isAction isExtra>
              {!loading2 && (
                <a
                  href={`${arquivo}&download=true&impEspelhoTabela=false`}
                  download
                  onClick={(e) => handleDownload(e , false)}
                >
                  Baixar Tabela
                </a>
              )}
              {loading2 && (
                <img className="margin0" src={ajaxLoading} alt="Carregando" />
              )}
          </TegraButton>
          <TegraButton isAction isExtra
              onClick={() => [setIsOpen(true), setImpEspelhoTabela(false)]}>
                Imprimir Tabela
              {/*
              <a
                href={!pdfTabelaEspelho ? baseURL + arquivo : pdfTabelaEspelho}
                target="_blank"
                rel="noopener noreferrer"
              >
                imprimir
              </a>
              */}
          </TegraButton>

        </Actions>
      </Content>
    </Container>
  );
};

export default ProdutoHeader;
