import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useCallback,
  RefObject,
} from 'react';

import { useField, FormHandles } from '@unform/core';
import { OptionBox, Option, Container } from './styles';

interface TegraOptionsProps extends InputHTMLAttributes<HTMLInputElement> {
  options: any[];
  name: string;
  formRef: RefObject<FormHandles>;
  noPaddingAll?: boolean;
  shouldTriggerParent?: boolean;
  setOptions: Function;
  canDeselect?: boolean;
}

const TegraOptions: React.FC<TegraOptionsProps> = ({
  options,
  setOptions,
  formRef,
  noPaddingAll = false,
  shouldTriggerParent = true,
  name,
  canDeselect = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleOptionSelection = useCallback(
    selectedOption => {
      if (!formRef.current) {
        throw new Error('No form');
      }
      const valuePrev = formRef.current.getFieldValue(fieldName);
      const prev = options.find(o => o.name === valuePrev);
      let anySelected = false;
      setOptions(
        options.map(o => {
          const selected =
            o.name === selectedOption.name &&
            (!canDeselect || !prev || prev.name !== selectedOption.name);
          anySelected = anySelected || selected;
          return {
            ...o,
            selected,
          };
        }),
      );
      formRef.current.setFieldValue(
        fieldName,
        anySelected ? selectedOption.name : '',
      );
      if (shouldTriggerParent) {
        formRef.current.submitForm();
      }
    },
    [formRef, fieldName, options, setOptions, shouldTriggerParent, canDeselect],
  );

  return (
    <Container>
      <input
        ref={inputRef}
        type="text"
        name={name}
        {...rest}
        defaultValue={defaultValue}
      />
      <OptionBox noPaddingAll={noPaddingAll} className={name}>
        {options.map(option => (
          <Option
            className={option.selected ? 'option-selected' : 'option'}
            hasIcon={!!option.icon}
            hasLabel={!!option.label}
            key={option.name}
            selected={option.selected}
            onClick={() => handleOptionSelection(option)}
          >
            <span>{option.label}</span>
            {option.icon}
          </Option>
        ))}
      </OptionBox>
    </Container>
  );
};

export default TegraOptions;
