import api from '../services/api';

export const isLab = (idCargoSigavi: any): any =>
  idCargoSigavi === 38 || idCargoSigavi === 32 || idCargoSigavi === 33;

export const setToken = (token: string) => {
  if (token) localStorage.setItem('@Tegra:token', token);
};

export const getToken = () => {
  const token = localStorage.getItem('@Tegra:token');
  return token;
};

export const processLogin = (user: any, token?: any): any => {
  const usr = {
    ...user,
    isGerente: user.idCargoSigavi === 14 || user.idCargoSigavi === 27,
    isAdmin: user.idCargoSigavi === -1,
    isRH: user.idCargoSigavi === 12, // Recursos Humanos
    isSuperintendente:
      user.idCargoSigavi === 16 || // Diretor(a) Comercial
      user.idCargoSigavi === 20 || // Diretor Geral
      user.idCargoSigavi === 23 || // Diretor(a) Comercial 2
      user.idCargoSigavi === 26 || // Diretor de Vendas
      user.idCargoSigavi === 25 || // Master
      user.idCargoSigavi === 27 || // Superintendente de Parcerias
      user.idCargoSigavi === 28 || // Superintendente Online
      user.idCargoSigavi === 34 || // Superintendente - Campinas
      user.idCargoSigavi === 35 || // Diretor(a) de Tecnologia
      user.idCargoSigavi === 38 || // Diretoria TegraLab
      user.idCargoSigavi === 39, // Superintendente dos Coordenadores,
    isLab: isLab(user.idCargoSigavi),
    presencaPlantao: 
      user.idCargoSigavi === 8  || 
      user.idCargoSigavi === 10 ||
      user.idCargoSigavi === 25 ||
      user.idCargoSigavi === -1,
    evento: 
      user.idCargoSigavi === 8  || 
      user.idCargoSigavi === 10 ||
      user.idCargoSigavi === 25 ||
      user.idCargoSigavi === -1,  
    eventosParcerias: 
      user.idCargoSigavi === 8  || 
      user.idCargoSigavi === 10 ||
      user.idCargoSigavi === 25 ||
      // Validar acima
      user.idCargoSigavi === 14  || 
      user.idCargoSigavi === 27 ||
      user.idCargoSigavi === -1,     
    deletarTarefas:    
      user.idCargoSigavi === 16 ||
      user.idCargoSigavi === 20 ||
      user.idCargoSigavi === 23 ||
      user.idCargoSigavi === 26 ||      
      user.idCargoSigavi === 27 ||
      user.idCargoSigavi === 28 ||
      user.idCargoSigavi === 38,
    exportarCorretores:
      user.idCargoSigavi === -1 ||
      user.idCargoSigavi === 5  ||
      user.idCargoSigavi === 14 ||
      user.idCargoSigavi === 16 ||
      user.idCargoSigavi === 20 ||
      user.idCargoSigavi === 21 ||
      user.idCargoSigavi === 23 ||
      user.idCargoSigavi === 26 ||
      user.idCargoSigavi === 27 ||
      user.idCargoSigavi === 28 ||
      user.idCargoSigavi === 33 ||
      user.idCargoSigavi === 34 ||
      user.idCargoSigavi === 35 ||
      user.idCargoSigavi === 38 ||
      user.idCargoSigavi === 39 ||
      user.idCargoSigavi === 52,
    acessos: {
      premiacaoMetas: user.idCargoSigavi !== 0,
      calendarioTrabalho: user.idCargoSigavi !== 0,
      relatorios:
        user.idCargoSigavi === 5  || 
        user.idCargoSigavi === 6  ||
        user.idCargoSigavi === 7  ||
        user.idCargoSigavi === 12 ||
        user.idCargoSigavi === 14 ||
        user.idCargoSigavi === 16 ||
        user.idCargoSigavi === 20 ||
        user.idCargoSigavi === 21 ||
        user.idCargoSigavi === 23 ||
        user.idCargoSigavi === 25 ||
        user.idCargoSigavi === 26 ||
        user.idCargoSigavi === 27 ||
        user.idCargoSigavi === 28 ||                     
        user.idCargoSigavi === 33 ||
        user.idCargoSigavi === 34 || 
        user.idCargoSigavi === 39 ||                
        user.idCargoSigavi === 52 || 
        user.idCargoSigavi === -1,
      ranking:
        //user.idCargoSigavi === 63,
        user.idCargoSigavi === 3 && (user.idRegional === 1 || user.idRegional === 3)  || //Corretor(a) de Imóveis Tegra SP e Campinas
        user.idCargoSigavi === 5  && (user.idRegional === 1 || user.idRegional === 3) || //Gerente de Vendas Tegra SP e Campinas
        user.idCargoSigavi === 14  && (user.idRegional === 1 || user.idRegional === 3) || //Gerente de Parcerias Tegra SP e Campinas
        user.idCargoSigavi === 22 && (user.idRegional === 1 || user.idRegional === 3) || //Corretor Online Tegra SP e Campinas
        user.idCargoSigavi === 27  && (user.idRegional === 1 || user.idRegional === 3) || //Gerente de Parcerias Tegra SP e Campinas
        user.idCargoSigavi === 34  && (user.idRegional === 1 || user.idRegional === 3) || //Superintendente - Campinas Tegra SP e Campinas
        user.idCargoSigavi === 51 && (user.idRegional === 1 || user.idRegional === 3)  || //Corretor Onlinefull de Imóveis Tegra SP e Campinas
        user.idCargoSigavi === 52  && (user.idRegional === 1 || user.idRegional === 3), //Gerente Onlinefull SP e Campinas
        //user.idCargoSigavi === 14  && (user.idRegional === 1 || user.idRegional === 3)||  //Gerente de Parcerias - Campinas Tegra SP e Campinas 
        //user.idCargoSigavi === 27  && (user.idRegional === 1 || user.idRegional === 3),  //Superintendete de Parcerias - Campinas Tegra SP e Campinas

        // user.idCargoSigavi === 3 && user.idRegional !== 2 || //Corretor(a) de Imóveis
        // user.idCargoSigavi === 4 && user.idRegional !== 2 || //Financeiro        
        // user.idCargoSigavi === 6  || //Coordenador de Parcerias
        // user.idCargoSigavi === 7 && user.idRegional !== 2 || //Marketing
        // user.idCargoSigavi === 10 || //Supervisor de Vendas
        // user.idCargoSigavi === 12 && user.idRegional !== 2 || //Recursos Humanos
        // user.idCargoSigavi === 16 || //Diretor(a) Comercial
        // user.idCargoSigavi === 17 || //Supervisor de Parcerias
        // user.idCargoSigavi === 20 || //Diretor Geral
        // user.idCargoSigavi === 21 || //Coordenador On Line 
        // user.idCargoSigavi === 22 && user.idRegional !== 2 || //Corretor Online
        // user.idCargoSigavi === 23 || //Diretor(a) Comercial 2
        // user.idCargoSigavi === 25 || //Master 
        // user.idCargoSigavi === 32 && user.idRegional !== 2 || //Vendedor CLT
        // user.idCargoSigavi === 33 || //Gerente TegraLab
        // user.idCargoSigavi === 39 || //Superintendente dos Coordenadores                 
        // user.idCargoSigavi === 43 && user.idRegional !== 2,   //Em Liberação
      contatos:        
        user.idCargoSigavi === 3  ||
        user.idCargoSigavi === 4  ||
        user.idCargoSigavi === 5 ||
        user.idCargoSigavi === 6  ||
        user.idCargoSigavi === 7  ||
        user.idCargoSigavi === 8  ||
        user.idCargoSigavi === 10 ||
        user.idCargoSigavi === 12 ||
        user.idCargoSigavi === 14 ||
        user.idCargoSigavi === 16 ||
        user.idCargoSigavi === 17 ||
        user.idCargoSigavi === 18 ||
        user.idCargoSigavi === 20 ||
        user.idCargoSigavi === 21 ||
        user.idCargoSigavi === 22 ||
        user.idCargoSigavi === 23 ||       
        user.idCargoSigavi === 25 ||
        user.idCargoSigavi === 26 ||
        user.idCargoSigavi === 27 ||
        user.idCargoSigavi === 28 ||
        user.idCargoSigavi === 32 ||
        user.idCargoSigavi === 33 ||
        user.idCargoSigavi === 39 ||                     
        user.idCargoSigavi === 43 ||
        user.idCargoSigavi === 51 ||
        user.idCargoSigavi === 52 ,
      sugestoes:
        user.idCargoSigavi === 3  ||
        user.idCargoSigavi === 5  ||
        user.idCargoSigavi === 34 ||
        user.idCargoSigavi === 6  ||
        user.idCargoSigavi === 7  ||
        user.idCargoSigavi === 8  ||
        user.idCargoSigavi === 10 ||
        user.idCargoSigavi === 12 ||
        user.idCargoSigavi === 14 ||
        user.idCargoSigavi === 16 ||
        user.idCargoSigavi === 18 ||
        user.idCargoSigavi === 20 ||
        user.idCargoSigavi === 21 ||        
        user.idCargoSigavi === 23 ||
        user.idCargoSigavi === 22 ||
        user.idCargoSigavi === 26 ||
        user.idCargoSigavi === 27 ||
        user.idCargoSigavi === 28 ||        
        user.idCargoSigavi === 32 ||
        user.idCargoSigavi === 33 ||               
        user.idCargoSigavi === 39 ||
        user.idCargoSigavi === 43 ||
        user.idCargoSigavi === 51 ||
        user.idCargoSigavi === 52 ,
      perguntasFrequentes:
        user.idCargoSigavi === 0  ||
        user.idCargoSigavi === 3  ||
        user.idCargoSigavi === 4  ||
        user.idCargoSigavi === 5  ||
        user.idCargoSigavi === 6  ||
        user.idCargoSigavi === 7  ||
        user.idCargoSigavi === 8  ||
        user.idCargoSigavi === 10 ||
        user.idCargoSigavi === 12 ||
        user.idCargoSigavi === 14 ||
        user.idCargoSigavi === 16 ||
        user.idCargoSigavi === 18 ||
        user.idCargoSigavi === 20 ||
        user.idCargoSigavi === 21 ||
        user.idCargoSigavi === 22 ||
        user.idCargoSigavi === 23 ||
        user.idCargoSigavi === 25 ||
        user.idCargoSigavi === 26 ||        
        user.idCargoSigavi === 27 ||        
        user.idCargoSigavi === 28 ||        
        user.idCargoSigavi === 32 ||
        user.idCargoSigavi === 33 ||
        user.idCargoSigavi === 34 ||
        user.idCargoSigavi === 39 ||                
        user.idCargoSigavi === 43 ||
        user.idCargoSigavi === 51 ||
        user.idCargoSigavi === 52 ||        
        user.idCargoSigavi === -1, 
      academia:
        user.idCargoSigavi === 0  ||
        user.idCargoSigavi === 3  ||      
        user.idCargoSigavi === 4  ||
        user.idCargoSigavi === 5  ||
        user.idCargoSigavi === 34 ||
        user.idCargoSigavi === 6  ||
        user.idCargoSigavi === 7  ||
        user.idCargoSigavi === 8  ||
        user.idCargoSigavi === 10 ||
        user.idCargoSigavi === 12 ||
        user.idCargoSigavi === 14 ||
        user.idCargoSigavi === 16 ||
        user.idCargoSigavi === 17 ||
        user.idCargoSigavi === 18 ||
        user.idCargoSigavi === 19 ||
        user.idCargoSigavi === 20 ||
        user.idCargoSigavi === 21 ||
        user.idCargoSigavi === 22 ||
        user.idCargoSigavi === 23 ||
        user.idCargoSigavi === 25 ||
        user.idCargoSigavi === 26 ||
        user.idCargoSigavi === 27 ||
        user.idCargoSigavi === 28 ||
        user.idCargoSigavi === 32 ||
        user.idCargoSigavi === 33 ||
        user.idCargoSigavi === 39 ||
        user.idCargoSigavi === 43 ||
        user.idCargoSigavi === 51 ||        
        user.idCargoSigavi === 52 ||        
        user.idCargoSigavi === -1,
      pagadoria:
        user.idCargoSigavi === 14 ||
        user.idCargoSigavi === 5  ||
        user.idCargoSigavi === 34 ||
        user.idCargoSigavi === 39 ||
        user.idCargoSigavi === 27 ||
        user.idCargoSigavi === 33 ||
        user.idCargoSigavi === 32 ||
        user.idCargoSigavi === 22 ||
        user.idCargoSigavi === 21 ||
        user.idCargoSigavi === 20 ||
        user.idCargoSigavi === 16 ||
        user.idCargoSigavi === 23 ||
        user.idCargoSigavi === 4  ||
        user.idCargoSigavi === 7  ||
        user.idCargoSigavi === 3  ||
        user.idCargoSigavi === 43 ||
        user.idCargoSigavi === 51 ||
        user.idCargoSigavi === 52 ,
      hypnobox:
        user.idCargoSigavi === 3  ||  
        user.idCargoSigavi === 5  ||
        user.idCargoSigavi === 7  ||
        user.idCargoSigavi === 8  ||
        user.idCargoSigavi === 10 ||
        user.idCargoSigavi === 12 ||
        user.idCargoSigavi === 16 ||
        user.idCargoSigavi === 18 ||
        user.idCargoSigavi === 19 ||
        user.idCargoSigavi === 20 ||
        user.idCargoSigavi === 21 ||
        user.idCargoSigavi === 22 ||
        user.idCargoSigavi === 23 ||        
        user.idCargoSigavi === 25 ||
        user.idCargoSigavi === 26 ||
        user.idCargoSigavi === 28 ||
        user.idCargoSigavi === 32 ||
        user.idCargoSigavi === 33 ||
        user.idCargoSigavi === 43 ||
        user.idCargoSigavi === 51 ||
        user.idCargoSigavi === 52 ||
        user.idCargoSigavi === -1,
      produtos:
        user.idCargoSigavi === 0  ||
        user.idCargoSigavi === 3  ||      
        user.idCargoSigavi === 4  ||
        user.idCargoSigavi === 5  ||
        user.idCargoSigavi === 34 ||
        user.idCargoSigavi === 6  ||
        user.idCargoSigavi === 7  ||
        user.idCargoSigavi === 8  ||
        user.idCargoSigavi === 10 ||
        user.idCargoSigavi === 12 ||
        user.idCargoSigavi === 14 ||
        user.idCargoSigavi === 16 ||
        user.idCargoSigavi === 17 ||
        user.idCargoSigavi === 18 ||
        user.idCargoSigavi === 19 ||
        user.idCargoSigavi === 20 ||
        user.idCargoSigavi === 21 ||
        user.idCargoSigavi === 22 ||
        user.idCargoSigavi === 23 ||
        user.idCargoSigavi === 25 ||
        user.idCargoSigavi === 26 ||
        user.idCargoSigavi === 27 ||
        user.idCargoSigavi === 28 ||
        user.idCargoSigavi === 32 ||
        user.idCargoSigavi === 33 ||
        user.idCargoSigavi === 39 ||
        user.idCargoSigavi === 43 ||
        user.idCargoSigavi === 51 ||        
        user.idCargoSigavi === 52 ||        
        user.idCargoSigavi === -1,
      minhaPagina:
        !user.isEstagiario && (        
          user.idCargoSigavi === 3  ||
          user.idCargoSigavi === 5  || 
          user.idCargoSigavi === 34 ||
          user.idCargoSigavi === 14 ||
          user.idCargoSigavi === 15 ||
          user.idCargoSigavi === 22 ||
          user.idCargoSigavi === 26 ||
          user.idCargoSigavi === 32 ||
          user.idCargoSigavi === 39 ||          
          user.idCargoSigavi === 43 ||
          user.idCargoSigavi === 51 ||
          user.idCargoSigavi === 52
      ),
      presencaPlantao:       
        user.idCargoSigavi === 4  ||
        user.idCargoSigavi === 5  || // Gerente de Parceria
        user.idCargoSigavi === 7  ||
        user.idCargoSigavi === 8  ||
        user.idCargoSigavi === 10 ||
        user.idCargoSigavi === 12 ||
        user.idCargoSigavi === 14 || // Gerente de Parceria
        user.idCargoSigavi === 16 ||
        user.idCargoSigavi === 18 ||
        user.idCargoSigavi === 19 ||
        user.idCargoSigavi === 20 ||       
        user.idCargoSigavi === 23 ||
        user.idCargoSigavi === 25 ||
        user.idCargoSigavi === 26 || //Diretor de Vendas
        user.idCargoSigavi === 28 ||
        user.idCargoSigavi === 39 ||
        user.idCargoSigavi === -1 ,
      evento:       
        user.idCargoSigavi === 4  ||
        user.idCargoSigavi === 7  ||
        user.idCargoSigavi === 8  ||
        user.idCargoSigavi === 10 ||
        user.idCargoSigavi === 12 ||
        user.idCargoSigavi === 16 ||
        user.idCargoSigavi === 18 ||
        user.idCargoSigavi === 19 ||
        user.idCargoSigavi === 20 ||       
        user.idCargoSigavi === 23 ||
        user.idCargoSigavi === 25 ||
        user.idCargoSigavi === 26 ||
        user.idCargoSigavi === 28 ||
        user.idCargoSigavi === 39 ||
        user.idCargoSigavi === -1 ,     
      eventosParcerias:       
      /*
        user.idCargoSigavi === 4  ||
        user.idCargoSigavi === 7  ||
        user.idCargoSigavi === 8  ||
        user.idCargoSigavi === 10 ||
        user.idCargoSigavi === 12 ||
        user.idCargoSigavi === 16 ||
        user.idCargoSigavi === 18 ||
        user.idCargoSigavi === 19 ||
        user.idCargoSigavi === 20 ||       
        user.idCargoSigavi === 23 ||
        user.idCargoSigavi === 25 ||
        user.idCargoSigavi === 26 ||
        user.idCargoSigavi === 28 ||
        user.idCargoSigavi === 39 ||
        */
        // Validar itens acima
        user.idCargoSigavi === 14 ||
        user.idCargoSigavi === 27 ||
        user.idCargoSigavi === -1 ,    
      registroVisita:                           
         user.idCargoSigavi === 4  || //Financeiro
         user.idCargoSigavi === 5  || //Gerente de Venda
         user.idCargoSigavi === 7  || //Marketing 
         user.idCargoSigavi === 8  || //Coordenador de Produto
         user.idCargoSigavi === 10 || //Supervisor de Vendas
         user.idCargoSigavi === 12 || //Recursos Humanos
         user.idCargoSigavi === 14 || //Gerente de Parceria
         user.idCargoSigavi === 16 || //Diretor Comercial
         user.idCargoSigavi === 18 || //Coordenador de Produto 2
         user.idCargoSigavi === 19 || //ADM Sistema
         user.idCargoSigavi === 20 || //Diretor Geral         
         user.idCargoSigavi === 23 || //Diretor Comercial
         user.idCargoSigavi === 25 || //Master
         user.idCargoSigavi === 26 || //Diretor de Vendas
         user.idCargoSigavi === 27 || //Superintendente de Parcerias
         user.idCargoSigavi === 28 ||  //Superintendente Online
         user.idCargoSigavi === 39 ||  //Superintendente de  Coordenador
         user.idCargoSigavi === -1,
      lideranca:
        user.idCargoSigavi === 4  && (user.idRegional !== 2) ||
        user.idCargoSigavi === 5  && (user.idRegional !== 2) ||
        user.idCargoSigavi === 7  && (user.idRegional !== 2) ||
        user.idCargoSigavi === 8  && (user.idRegional !== 2) ||
        user.idCargoSigavi === 10  && (user.idRegional !== 2) ||
        user.idCargoSigavi === 12 && (user.idRegional !== 2) ||
        user.idCargoSigavi === 16 && (user.idRegional !== 2) ||
        user.idCargoSigavi === 18 && (user.idRegional !== 2) ||
        user.idCargoSigavi === 20 && (user.idRegional !== 2) ||
        user.idCargoSigavi === 21 && (user.idRegional !== 2) ||
        user.idCargoSigavi === 23 && (user.idRegional !== 2) ||
        user.idCargoSigavi === 25 && (user.idRegional !== 2) ||
        user.idCargoSigavi === 26 && (user.idRegional !== 2) ||
        user.idCargoSigavi === 27 && (user.idRegional !== 2) ||
        user.idCargoSigavi === 28 && (user.idRegional !== 2) ||
        user.idCargoSigavi === 33 && (user.idRegional !== 2) ||
        user.idCargoSigavi === 34 && (user.idRegional !== 2) ||
        user.idCargoSigavi === 39 && (user.idRegional !== 2) ||
        user.idCargoSigavi === 52 && (user.idRegional !== 2),
      espacoGerente:        
        user.idCargoSigavi === 5  ||
        user.idCargoSigavi === 34 ||
        user.idCargoSigavi === 7  ||
        user.idCargoSigavi === 12 ||
        user.idCargoSigavi === 14 ||
        user.idCargoSigavi === 16 ||
        user.idCargoSigavi === 20 ||
        user.idCargoSigavi === 21 ||
        user.idCargoSigavi === 23 ||
        user.idCargoSigavi === 25 ||
        user.idCargoSigavi === 26 ||
        user.idCargoSigavi === 27 ||
        user.idCargoSigavi === 28 ||        
        user.idCargoSigavi === 33 ||
        user.idCargoSigavi === 39 ||
        user.idCargoSigavi === 52,        
    },
  };

  setToken(token);
  localStorage.setItem('@Tegra:user', JSON.stringify(usr));
  sessionStorage.setItem('@Tegra:user', JSON.stringify(usr));
  const modalExpertegra = localStorage.getItem('modalExpertegra')
  if (modalExpertegra === null) {
    localStorage.setItem('modalExpertegra', 'false');
  }
  api.defaults.headers.authorization = `Bearer ${token}`;
  return usr;
};
