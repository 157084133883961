import styled from 'styled-components';

export const Container = styled.div`
  textarea {
    width: 100%;
    min-height: 100px;
    padding: 21px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.14px;
    text-align: left;
    color: #60605d;

    &::placeholder {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: left;
      color: #adadab;
    }

    /* scrollbar-color: #ebb92d #fff; */

    /* Track */
    /* &::-webkit-scrollbar-track {
      background: #fff;
    } */

    /* Handle */
    /* &::-webkit-scrollbar-thumb {
      background: #ebb92d;
    } */
  }

  textarea::-webkit-scrollbar {
    width: 19px;
    background: #fff;
  }

  /* Track */
  textarea::-webkit-scrollbar-track {
    /* background: #fff; */
    border: solid 1px #adadab;
  }

  /* Handle */
  textarea::-webkit-scrollbar-thumb {
    width: 11px;
    height: 40px;
    background-color: #ebb92d;
  }

  /* Handle on hover */
  textarea::-webkit-scrollbar-thumb:hover {
    background-color: #ebb92d;
    opacity: 0.7;
  }
`;
