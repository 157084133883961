import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(  
  <>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    <App />
  </>,
  document.getElementById('root'),
);
