/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import api, { baseURL } from '../../../services/api';

import {
  Container,
  Content,
  Title,
  ArquivosList,
  ArquivoActions,
  Arquivo,
  ArquivoHeader,
} from './styles';
import TegraButtonExtra from '../../../components/TegraButtonExtra';
import TegraDivider from '../../../components/TegraDivider';
import visualizarIcon from '../../../assets/tegra-icon-open.svg';
import visualizarDisabledIcon from '../../../assets/tegra-icon-open-disabled.svg';
import compartilharIcon from '../../../assets/tegra-icon-share.svg';

import baixarIcon from '../../../assets/tegra-icon-baixar.svg';
import baixarDisabledIcon from '../../../assets/tegra-icon-baixar-disabled.svg';
import TegraModalCompartilhar from '../../../components/TegraModalCompartilhar';
import { ReactComponent as CloseIcon } from '../../../assets/tegra-icon-close.svg';
import { useAuth } from '../../../hooks/auth';
import { log, ETipoAtividade, ETipoSessao } from '../../../utils/log';

interface TegraArquivoItem {
  visualizar: boolean;
  compartilhar: boolean;
  baixar: boolean;
  nome: string;
  produto: number;
  data_inclusao?: string;
  disable_buttons?: boolean;
  id: number;
  link?: string;
  urlWhats?: string;
  urlFacebook?: string;
  urlTelegram?: string;
}

interface TegraArquivosListProps {
  titulo: string;
  arquivos: TegraArquivoItem[];
  produto: string;
  id_empreendimento: number;
}

const TegraArquivosList: React.FC<TegraArquivosListProps> = ({
  titulo,
  arquivos,
  produto,
  id_empreendimento,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenFicha, setModalOpenFicha] = useState(false);
  const { user } = useAuth();
  const [viewingArquivo, setViewingArquivo] = useState<TegraArquivoItem>(
    {} as TegraArquivoItem,
  );

  const handleOpenModal = useCallback((arquivo: TegraArquivoItem) => {
    setViewingArquivo(arquivo);
    setModalOpen(true);
  }, []);

  const handleOpenModalFicha = useCallback((arquivo: TegraArquivoItem) => {
    setModalOpenFicha(true);
  }, []);

  const handleCloseModalFicha = useCallback((arquivo: TegraArquivoItem) => {
    setModalOpenFicha(false);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const abrir = (file?: TegraArquivoItem): void => {
    if (file && file.link) {
      window.open(file.link);
      log(
        produto,
        titulo,
        ETipoAtividade.Acessou,
        ETipoSessao.Produto,
        id_empreendimento,
        file.link,
        file.id,
        user,
      );
    }
  };

  const download = (file?: TegraArquivoItem): void => {
    if (file && file.link) {
      window.open(
        `${baseURL}Download?link=${decodeURIComponent(
          file.link,
        )}&empreendimento=${encodeURIComponent(file.produto)}&legendas=${
          file.nome
        }`,
      );
      log(
        produto,
        titulo,
        ETipoAtividade.Download,
        ETipoSessao.Produto,
        id_empreendimento,
        file.link,
        file.id,
        user,
      );
    }
  };

  return (
    <Container>
      <TegraModalCompartilhar
        handleClose={handleCloseModal}
        isOpen={modalOpen}
        urlFacebook={viewingArquivo.urlFacebook}
        urlWhats={viewingArquivo.urlWhats}
        urlTelegram={viewingArquivo.urlTelegram}
      />
      <Content>
        <Title>{titulo}</Title>
        <ArquivosList>
          {arquivos.map(arquivo => (
            <Arquivo key={`arquivo_list_${arquivo.id}`}>
              {arquivo.nome === "Ficha Técnica" &&
                <Modal
                  id="modalFicha"
                  className="modal-enabled"
                  isOpen={modalOpenFicha}
                >
                  <div className="modal-header">
                    <h2 className="modal-title" id="exampleModalLabel">
                      Ficha Técnica
                    </h2>
                    <div onClick={() => handleCloseModalFicha(arquivo)}>
                      <CloseIcon />
                    </div>
                  </div>
                  <div className="modal-body">
                    <p
                      dangerouslySetInnerHTML={{ __html: arquivo.link as string }}
                    />
                  </div>
                </Modal>
              }
              <ArquivoHeader>
                <strong>{arquivo.nome}</strong>
                {arquivo.data_inclusao && (
                  <span>Data de Inclusão: {arquivo.data_inclusao}</span>
                )}
              </ArquivoHeader>
              <ArquivoActions>
                {arquivo.visualizar &&
                  (arquivo.nome == 'Ficha Técnica' ? (
                    <button
                      className="buttonFicha"
                      type="button"
                      onClick={() => handleOpenModalFicha(arquivo)}
                    >
                      Visualizar
                      <img src={visualizarIcon} />
                    </button>
                  ) : (
                    <TegraButtonExtra
                      isAction={false}
                      onClick={() => abrir(arquivo)}
                      isDisabled={!!arquivo.disable_buttons}
                      icon={
                        !arquivo.disable_buttons
                          ? visualizarIcon
                          : visualizarDisabledIcon
                      }
                    >
                      Visualizar
                    </TegraButtonExtra>
                  ))}
                {arquivo.baixar && (
                  <TegraButtonExtra
                    isAction={false}
                    onClick={() => download(arquivo)}
                    isDisabled={!!arquivo.disable_buttons}
                    icon={
                      !arquivo.disable_buttons ? baixarIcon : baixarDisabledIcon
                    }
                  >
                    Baixar
                  </TegraButtonExtra>
                )}
                {arquivo.compartilhar && (
                  <TegraButtonExtra
                    isAction={false}
                    isDisabled={arquivo.disable_buttons}
                    icon={compartilharIcon}
                    onClick={() => handleOpenModal(arquivo)}
                  >
                    Compartilhar
                  </TegraButtonExtra>
                )}
              </ArquivoActions>
            </Arquivo>
          ))}
        </ArquivosList>
      </Content>
      <TegraDivider axis="x" height={1} color="#e6e6e6" />
    </Container>
  );
};

export default TegraArquivosList;
