import React, { useEffect, useCallback, useState } from 'react';
import {
    ListaAction,
    ContainerTextArea,
    ContainerModal,
  } from './../styles';

import { Form } from '@unform/core';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import TegraSelect from '../../../components/TegraSelect';
import TegraButton from '../../../components/TegraButton';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { groupBy } from '../../../services/helpers';
import 'moment/locale/pt';
import ajaxLoading from '../../../assets/ajax-loader.gif';
import './RegistroVisita.sass';
import TegraTextArea from '../../../components/TegraTextArea';
import dataJson from './../dataVisita.json';


interface IVisitaItem {
    tipoOperacao: number; 
    empreendimentos: any; 
    corretores: any;  
    gerentesVendas: any;
    gerentesParcerias: any;
    todosGerentes: any;
    superintendentes: any;
    itemAlterar: any;
    superintendentesModalBase: any;
    gerentesModalBase: any;
    corretoresModalBase: any;
    canalModal: any;
    onChange :() => any;
}


const FormRegistroVisita: React.FC<IVisitaItem> = 
    ({tipoOperacao, 
      empreendimentos, 
      corretores, 
      gerentesVendas, 
      gerentesParcerias, 
      todosGerentes, 
      superintendentes, 
      itemAlterar, 
      superintendentesModalBase,
      gerentesModalBase,
      corretoresModalBase,
      canalModal,
      onChange}) => {
    const [id_registroVisita,setRegistroVisita] = useState(null);
    const [loading, setLoading] = useState(false);
    const [focusDate, setFocusDate] = useState<any>();
    const [date, setDate] = useState(moment());
    const [regional, setRegional] = useState<any>(null); //Regional selecionada
    const [produto, setProduto] = useState<any>(null); //produto selecionado 
    const [empreendimentosLista, setEmpreendimentosLista] = useState<any[]>([]); //Lista de empreendimentos do corretor
    const [empreendimentosModal, setEmpreendimentosModal] = useState<any[]>([]); //Lista de empreendimentos exibidos no combobox
    const [tipo, setTipo] = useState<any>(null); //tipo selecionado 
    const [tipoVisita, setTipoVisita] = useState<any>(null); //Visita Selecionada
    const [canal, setCanal] = useState<any>(null);  //Canal Selecionado
    const [isParceiro, setIsParceiro] = useState(false); //Se é parceiro
    const [exibeCampos, setExibeCampos] = useState(false); //Exibe os campos
    const [isDesabilitaCorretor, setIsDesabilitaCorretor] = useState(true); 
    const [isDesabilitaGerente, setIsDesabilitaGerente] = useState(true); 
    const [isDesabilitaSuperintendente, setIsDesabilitaSuperintendente] = useState(true); 
    const [observacao, setObservacao] = useState<any>(''); //Observacao
    const [corretor, setCorretor] = useState<any>(null); //Corretor selecionado
    const [corretoresModal, setCorretoresModal] = useState<any[]>([]); //Lista de Corretores de Acordo com a empresa
    const [gerente, setGerente] = useState<any>(null); //Gerente selecionado
    const [gerentesModal, setGerentesModal] = useState<any[]>([]); //Lista de Gerentes Parceria
    const [superintendente, setSuperintendente] = useState<any>(null); //superintendente selecionado
    const [superintendenteModal, setSuperintendenteModal] = useState<any>(null); //superintendente modal
    const [isGerentePresente, setIsGerentePresente] = useState<any>(null); //Gerente Presente Selecionado
    const [isCoordenadorPresente, setIsCoordenadorPresente] = useState<any>(null); //Coordenador Presente Selecionado
    const [localAtendimento, setLocalAtendimento] = useState<any>(null); //Local selecionado
    const [localAtendimentoModal, setLocalAtendimentoModal] = useState<any>(null); //Local modal
    const [localAtendimentoModalBase, setLocalAtendimentoModalBase] = useState<any>(null); //Local modal Base

    const { user } = useAuth();
/*
    const superintendentesModalBase = dataJson.corretores.filter(x => (x.tipo === 'superintendente'));

    const gerentesModalBase = dataJson.corretores.filter(x => (x.tipo === 'gerente'));

    const corretoresModalBase = dataJson.corretores.filter(x => (x.tipo === 'corretor'));
*/
   //const localAtendimentoModalOutro =  await api.get(`visita/localAtendimento`);



    useEffect(() =>{       
        if (itemAlterar.id_registroVisita != undefined){
            handleAlterar(itemAlterar);
        }
        else{
          handleIncluir();
        }
      }, [itemAlterar] );

    function FormatarListaEmpreendimento(resp){
        resp.unshift({
          id: undefined,
          nome: 'Nenhum',
          logradouro: undefined,
        });
    
        const est = [
          { label: 'Rio de Janeiro', value: 19 },
          { label: 'São Paulo', value: 26 },
          { label: 'Campinas', value: 4793 },
        ];
    
        const arr = resp.map((c: any) => ({
          value: c.id,
          label: c.nome,
          tipo: c.logradouro,
          cidadeid: c.cidadeId,
          estado: est.find(e => e.value === (c.cidadeId === 4793 ? c.cidadeId : c.estadoId))?.label,
        }));
        const g = groupBy(arr, 'estado');
        const lst:any[] = [];

        for (const e in g) {
          if (g[e].length > 0) {
            lst.push({
              label: e,
              options: g[e],
            });
          }
        }
        return lst;
    }

    const ListaEmpreendimento = useCallback( async() =>{
        if (user.idCargoSigavi == 18 || user.idCargoSigavi == 8 || user.idCargoSigavi == 10){
          const resSup = await api.get(`Empreendimento/listEmpreendimentosNaoVendidosPorSupervisor`,
          {
            params: {
              tx_apelido: user.apelido,
            },
          },
        );
        return FormatarListaEmpreendimento(resSup.data);
        }
        else{
          return empreendimentos;
        }
      },[empreendimentos]);

    const ListaLocal = useCallback( async() =>{
      const lstLocal = await api.get(`visita/localAtendimento`);
      let resultado = lstLocal.data.map((d: any) => ({
        label: d.local,
        value: d.id
      }));
      //setLocalAtendimentoModalBase(resultado);
      //setLocalAtendimentoModal(resultado);
      return resultado;
    },[]);

    const handleIncluir = useCallback( async() =>{
      limpar();
      const lstEmp = await ListaEmpreendimento();
      const lstLocal = await ListaLocal();
      setLocalAtendimentoModalBase(lstLocal);
      setLocalAtendimentoModal(lstLocal);

      setEmpreendimentosModal(lstEmp);
      setEmpreendimentosLista(lstEmp);
      if ([8, 10, 16, 18, 23, 26, 28].indexOf(user.idCargoSigavi) > -1){
        setRegional(dataJson.regional.find(e => e.value === user.idRegional));
  
        if (lstEmp.length > 0)
        {
          let filtro = lstEmp.filter(function(z){
              return z.label == (user.idRegional === 2 ?'Rio de Janeiro' : user.idRegional === 3 ? 'Campinas' : 'São Paulo');
            }).map(function({label, options}){
              return {label, options};
          });
          setEmpreendimentosModal(filtro);
        }
        setExibeCampos(true);
      }
    },[empreendimentos, regional, empreendimentosModal, empreendimentosLista, localAtendimentoModalBase,localAtendimentoModalBase]);
      
      
    const handleAlterar = useCallback( async(item:any) =>{
        const lstEmp = await ListaEmpreendimento();
        const lstLocal = await ListaLocal();
        setLocalAtendimentoModalBase(lstLocal);
        setLocalAtendimentoModal(item.id_empresaSigavi === 1 ? lstLocal : lstLocal.filter(x => x.value === 1 || x.value === 5));        
        if (item.id_localAtendimento){
          setLocalAtendimento(lstLocal.find(e => e.value === item.id_localAtendimento));
        }
               
        setEmpreendimentosLista(lstEmp);
        setEmpreendimentosModal(lstEmp);
        setRegional(dataJson.regional.find(e => e.value === item.id_empresaSigavi));
        setCanal(canalModal.find(e => e.label === item.tx_canal));
        setTipo(dataJson.tipoimovel.find(e => e.label === item.tx_tipoImovel));
        setObservacao(item.tx_observacao);
        setIsGerentePresente(dataJson.gerentepresente.find(e => e.value === item.isGerentePresente));
        setIsCoordenadorPresente(dataJson.coordenadorpresente.find(e => e.value === item.isCoordenadorPresente));
        console.log("find",canalModal.find(e => e.label === item.tx_canal).value);
        AtualizarOpcoes(canalModal.find(e => e.label === item.tx_canal).value, item.id_empresaSigavi);

        /*
        AtualizarOpcoes(
          item.tx_canal === "Online"? 1 :
          item.tx_canal === "Online Full"? 16 :
          item.tx_canal === "Salão"? 2 :
          item.tx_canal === "Parcerias"? 3 :
          item.tx_canal === "Tegra Lab"? 4 :
          item.tx_canal === "EV LOPES VENDAS"? 5 :
          item.tx_canal === "EV LOPES CAMPINAS"? 6 :
          item.tx_canal === "EV HB BROKER"? 7 :
          item.tx_canal === "LG MOTA"? 8 :
          item.tx_canal === "PAULO MAURO"? 9:
          item.tx_canal === "LOPES RJ"? 10:
          item.tx_canal === "PATRIMÓVEL"? 11:
          item.tx_canal === "BR BROKERS"? 12:
          item.tx_canal === "SPIN"? 13:
          item.tx_canal === "EV EXTO"? 14:
          item.tx_canal === "Corretor On Helbor"? 15:17, item.id_empresaSigavi)
          */
        setDate(moment(item?.dt_data));
    
        if (lstEmp.length > 0)
        {
          let filtro = lstEmp.filter(function(z){
              return z.label == (item.id_empresaSigavi === 2 ?'Rio de Janeiro' : item.id_empresaSigavi === 3 ? 'Campinas' : 'São Paulo');
            }).map(function({label, options}){
              return {label, options};
          });
          setEmpreendimentosModal(filtro);
          let emp = filtro[0].options.find(p => p.value === item.id_produto);
          setProduto(emp);
        }
        setRegistroVisita(item.id_registroVisita);
        setTipoVisita(dataJson.tipovisita.find(e => e.label === item.tx_tipoVisita));
        setExibeCampos(true);
     
        if (item.tx_canal !== 'Online' &&
            item.tx_canal !== 'Online Full' &&
            item.tx_canal !== 'Salão' &&
            item.tx_canal !== 'Parcerias'){
          setSuperintendente(superintendentesModalBase.filter(e => e.label === item.superintendente.apelidoSuperintendente))
          setGerente(gerentesModalBase.filter(e => e.label === item.gerente.apelidoGerente))
          setCorretor(corretoresModalBase.filter(e => e.label === item.corretor.tx_apelido))
        }
        else if (item.tx_canal === "Parcerias"){
          setIsParceiro(true);
          setGerente(todosGerentes.filter(e => e.value === item.id_gerente));
        }
        else
        {
          setIsParceiro(false);
          setCorretor(corretores.find(e => e.value === item.id_corretor));
        }
      },[empreendimentos, corretores, corretoresModalBase, corretor,
        todosGerentes, gerentesModalBase, gerente, superintendentesModalBase, empreendimentosLista,
        localAtendimento, localAtendimentoModalBase,localAtendimentoModalBase]);



    const limpar = () =>{
        setDate(moment());
        setCorretor(null);
        setProduto(null);
        setGerente(null);
        setSuperintendente(null);
        setRegional(null);
        setCanal(null);
        setTipoVisita(null);
        setRegistroVisita(null);
        setExibeCampos(false);
        setIsParceiro(false);
        setTipo(null);
        setObservacao('');
      }

    const handleCancel = () => {
        limpar();        
      };      

    const handleSubmit = () => {
        if (!produto || !canal || !tipoVisita || !regional ||
            (!isParceiro && !corretor) || (isParceiro && !gerente) || 
            (isGerentePresente === null) || (isCoordenadorPresente === null) || !localAtendimento)
        {
          toast('Preencha todos campos para adicionar', {
            position: 'top-right',
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            type: 'error',
          });
          return;
        }
    
        //os canais 0 à 3 e o 15 precisam gravar o id do corretor
        let data = {
          id_registroVisita: id_registroVisita,
          dt_data: new Date(Date.UTC(date.toDate().getFullYear(),
                                      date.toDate().getMonth(),
                                      date.toDate().getDate(),
                                      date.toDate().getHours(),
                                      date.toDate().getMinutes(),
                                      date.toDate().getSeconds())),
          tx_tipoVisita: tipoVisita.label,
          tx_canal: canal.label,
          id_corretor: canal.value >= 5 && canal.value !== 16 ? null : corretor === null ? null: corretor.value,
          id_gerente: canal.value >= 5 && canal.value !== 16 ? null : gerente === null ? null : gerente.length === 0 ? null : gerente[0].value,
          id_superintendente: canal.value >= 5 && canal.value !== 16 ? null : superintendente === null ? null : superintendente.length === 0 ? null : superintendente[0].value,
          id_produto: produto.value,
          id_empresaSigavi: regional.value,
          tx_corretor: canal.value >= 5 && canal.value !== 16 ? (corretor[0] ? corretor[0].label : corretor.label) : null,
          tx_gerente: canal.value >= 5 && canal.value !== 16 ? (gerente[0] ? gerente[0].label : gerente.label) : null,
          tx_superintendente: canal.value >= 5 && canal.value !== 16 ? superintendente.label : null,
          id_responsavel: user.id,
          tx_tipoImovel: !tipo ? null :(tipo.label === 'Nenhum'? null :tipo.label),
          tx_observacao: observacao,
          isGerentePresente: isGerentePresente.value,
          isCoordenadorPresente: isCoordenadorPresente.value,
          id_localAtendimento: localAtendimento.value
        };
    
        setLoading(true);
    
        if (tipoOperacao == 1){
          api
          .post(`Visita/insertVisita`, data)
          .then(() => {
            toast('Visita registrada com sucesso', {
              position: 'top-right',
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
              type: 'success',
            });
            //handleSearch();
            setLoading(false);
            limpar();
            onChange();
          })
          .catch(() => {
            setLoading(false);
            toast('Ocorreu um erro ao inserir, tente novamente mais tarde', {
              position: 'top-right',
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
              type: 'error',
            });
          });
        }
        else if (tipoOperacao == 2){
          api
          .post(`Visita/updateVisita`, data)
          .then(() => {
            toast('Visita alterada com sucesso', {
              position: 'top-right',
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
              type: 'success',
            });
            //handleSearch();
            setLoading(false);
            limpar();
            onChange();
          })
          .catch(() => {
            setLoading(false);
            toast('Ocorreu um erro ao inserir, tente novamente mais tarde', {
              position: 'top-right',
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
              type: 'error',
            });
          });
        }
      };


    function AtualizarOpcoes(OpCanal,OpRegional){
      var regional = OpRegional === 1 ? 'Tegra SP' : OpRegional === 2 ? 'Tegra RJ' : OpRegional === 3 ? 'Tegra Campinas' : 'Tegra Lab';
      if (OpCanal >= 5 && OpCanal != 16){
          setCorretoresModal(
          corretoresModalBase.filter(e => e.OpCanal === OpCanal));

          setGerentesModal(
              gerentesModalBase.filter(e => e.OpCanal === OpCanal));

          setSuperintendenteModal(
              superintendentesModalBase.filter(e => e.OpCanal === OpCanal));
          setIsDesabilitaCorretor(true);
          setIsDesabilitaGerente(true);
          setIsDesabilitaSuperintendente(false);

      }
      else if (OpCanal === 16){                 
          setCorretoresModal(
          corretores.filter(e => {
            return (e.empresaSigavi === regional && e.id_cargoSigavi === 51)
          }),
          );
          setGerentesModal(gerentesVendas);
          setSuperintendenteModal(superintendentes);
          setIsParceiro(false);
          setIsDesabilitaCorretor(false);
          setIsDesabilitaGerente(true);
          setIsDesabilitaSuperintendente(true);

      }
      else if (OpCanal !== 3){     
          setCorretoresModal(
          corretores.filter(e => {
              if (OpRegional < 4 ){ //Regionais}
                return (OpCanal === 1  ? e.empresaSigavi === regional && e.id_cargoSigavi === 22 : //Online
                      e.empresaSigavi === regional && e.id_cargoSigavi !== 22)  //Salão
              } else if (OpRegional === 4) {
                return e.empresaSigavi === 'Tegra Lab'
              }
          }),
          );
          setGerentesModal(gerentesVendas);
          setSuperintendenteModal(superintendentes);
          setIsParceiro(false);
          setIsDesabilitaCorretor(false);
          setIsDesabilitaGerente(true);
          setIsDesabilitaSuperintendente(true);

      }
      else //Se for Parcerias
      {
          setGerentesModal(
          gerentesParcerias.filter(e => {
            return e.empresaSigavi === (OpRegional === 1 ? 'Tegra SP' : OpRegional === 2 ? 'Tegra RJ' : 'Tegra Campinas' );
            /*
              if(OpRegional === 1 ){
              return e.empresaSigavi === 'Tegra SP'
              }else if (OpRegional === 3) {
              return e.empresaSigavi === 'Tegra Campinas'
              }else if (OpRegional === 2) {
              return e.empresaSigavi === 'Tegra RJ'
              }
              */
          }),
          );
          setIsParceiro(true);
          setIsDesabilitaCorretor(true);
          setIsDesabilitaGerente(false);
          setIsDesabilitaSuperintendente(true);
          setSuperintendenteModal(superintendentes);
      }
    };

    const onChangeRegional = useCallback(
    (e) => {
        setRegional(e);
        setExibeCampos(true);
        setCorretor(null);
        setCorretoresModal([]);
        setGerente(null);
        setSuperintendente(null);
        setProduto(null);
        setTipo(null);

        if (e.value === 4){
            setCanal({ label: 'Tegra Lab', value: 4 });
            AtualizarOpcoes(4,e.value)
        }
        else if (e.value !== 4 && canal){
            setCanal(null);
            setIsDesabilitaCorretor(true);
            setIsDesabilitaGerente(true);
            setIsDesabilitaSuperintendente(true);
        }

        if (empreendimentosLista.length > 0)
        {
            let filtro = empreendimentosLista.filter(function(z){
                return z.label == (e.value === 2 ?'Rio de Janeiro' : e.value === 3 ? 'Campinas' : 'São Paulo');
                }).map(function({label, options}){
                return {label, options};
            });
            setEmpreendimentosModal(filtro);
        }

        //Local de Atendimento
        if (e.value === 1) {          
          setLocalAtendimentoModal(localAtendimentoModalBase);
        }
        else{
          setLocalAtendimentoModal(localAtendimentoModalBase.filter((i) => i.value === 1 || i.value === 5));
        }        
    },[regional, exibeCampos, corretor, corretoresModal, gerente, superintendente, produto, tipo, canal,
        empreendimentosModal,empreendimentos, superintendenteModal, empreendimentosLista, localAtendimentoModal] );

    const onChangeCanal = useCallback(
        (e) => {
          setCanal(e);
          if (regional){
            AtualizarOpcoes(e.value,regional.value)
            setExibeCampos(true);
            setCorretor(null);
            setGerente(null);
            setSuperintendente(null);
          }
        },
        [canal, regional, corretoresModal, exibeCampos, corretor, gerente, superintendente, superintendenteModal]
      );

      const onChangeSuperintendente = useCallback(
        (e) => {
          setSuperintendente(e);
          setCorretor(corretoresModal);
          setGerente(gerentesModal);
        },
        [superintendente, gerente, corretor]
      );

      useEffect(() =>{
        if (!isDesabilitaSuperintendente && superintendente){
          setCorretor(corretoresModal);
        }
        else if (corretor){
          setGerente(
            gerentesModal.filter(z => z.label === corretor.tx_equipe)
          );
        }
    
      }, [corretor] );
    
    
    
      useEffect(() =>{        
        if (!isDesabilitaSuperintendente && superintendente){
          setGerente(gerentesModal);
        }
        else{
          if (gerente && gerente.length > 0 ){
            setSuperintendente(
              superintendenteModal.filter(z => z.label === gerente[0].tx_equipe)
            );
          }
          else{
            setSuperintendente(null);
          }
        }
      },[gerente]);
      
    return (
        <>
            <Modal 
                isOpen={true}
                contentLabel="Registro de Visita"
                portalClassName="modal-presenca"
            >
          <ContainerModal>
              { (tipoOperacao == 1) && (
                  <h3>Adicionar registro de visita</h3>)
              }
              {
              (tipoOperacao == 2) && (
                  <h3>Editar registro de visita</h3>)
              }
            <Form
              onSubmit={handleSubmit}
            >
              <label className="custom-label">Data</label>
              <SingleDatePicker
                isOutsideRange={(day: any) => false}
                focused={focusDate}
                date={date}
                id="start_date_id2"
                onFocusChange={(f: any) => setFocusDate(f.focused)}
                onDateChange={(date: any) => {
                  setDate(date);
                }}
              />

              <TegraSelect
                placeholder="Selecione a Regional"
                noOptionsMessage={() => 'Nenhum selecionado'}
                label="Regional"
                name="regional"
                /*onChange={setRegional}*/
                onChange={(e) => onChangeRegional(e)}
                options={ [8, 10, 16, 18, 23, 26, 28].indexOf(user.idCargoSigavi) > -1 && user.idRegional != 1?                        
                          dataJson.regional.filter(item => item.value == user.idRegional) :
                          [8, 10, 16, 18, 23, 26, 28].indexOf(user.idCargoSigavi) > -1 && user.idRegional == 1?
                          dataJson.regional.filter(item => item.value == 1 || item.value == 4) :
                          dataJson.regional}
                value={regional}
              />

              <TegraSelect
                placeholder= {empreendimentosModal.length > 0?'Nenhum' : 'Nenhum produto disponível'}
                noOptionsMessage={() => empreendimentosModal.length > 0? 'Nenhum selecionado' : 'Nenhum produto disponível'}
                label="Produto"
                name="produto"
                onChange={setProduto}
                options={empreendimentosModal}
                value={produto}
              />
              <TegraSelect
                placeholder="Nenhum"
                noOptionsMessage={() => 'Nenhum selecionado'}
                label="Tipo do Imóvel"
                name="tipo"
                onChange={setTipo}
                options={[{label:"Nenhum",value:""}, ...dataJson.tipoimovel]}
                value={tipo}
              />    
              <TegraSelect
                placeholder="Selecione o tipo da visita"
                noOptionsMessage={() => 'Nenhum selecionado'}
                label="Tipo de Visita"
                name="tipoVisita"
                onChange={setTipoVisita}
                options={dataJson.tipovisita}
                value={tipoVisita}
              />
              <TegraSelect
                placeholder= "Selecione o local de atendimento"
                noOptionsMessage={() => 'Nenhum selecionado'}
                label="Local de Atendimento"
                name="localAtendimento"
                onChange={setLocalAtendimento}
                options={localAtendimentoModal}
                value={localAtendimento}
              />
              <TegraSelect
                placeholder="Selecione"
                noOptionsMessage={() => 'Nenhum selecionado'}
                label="Gerente Presente"
                name="isGerentePresente"
                onChange={setIsGerentePresente}
                options={dataJson.gerentepresente}
                value={isGerentePresente}
              />
              <TegraSelect
                placeholder="Selecione"
                noOptionsMessage={() => 'Nenhum selecionado'}
                label="Coordenador Presente"
                name="isCoordenadorPresente"
                onChange={setIsCoordenadorPresente}
                options={dataJson.coordenadorpresente}
                value={isCoordenadorPresente}
              />              
              <TegraSelect
                isDisabled={regional === null? true : regional.value === 4?  true: false}
                placeholder="Selecione o canal"
                noOptionsMessage={() => 'Nenhum selecionado'}
                label="Canal"
                name="canal"
                onChange={(e) => onChangeCanal(e)}
                options= {regional === null ? canalModal :
                          regional.value === 4 ? canalModal.filter(x=> x.empresaSigavi === 4) :
                          regional.value === 1 ? canalModal.filter(x=> x.empresaSigavi === 1 || x.empresaSigavi === 0) :
                          regional.value === 3 ? canalModal.filter(x=> x.empresaSigavi === 3 || x.empresaSigavi === 0) :
                          regional.value === 2 ? canalModal.filter(x=> x.empresaSigavi === 2 || x.empresaSigavi === 0) :
                          canalModal.filter(x=> x.empresaSigavi === 0)}
                value={canal}
              />
              { (!isParceiro &&  exibeCampos) && (

                  <div>
                  <TegraSelect
                      isDisabled={isDesabilitaCorretor}
                      noOptionsMessage={() => 'Nenhum selecionado'}
                      placeholder=""
                      label="Corretor"
                      name="corretor"
                      onChange= {(e) => setCorretor(e)}
                      value={corretor}
                      options={corretoresModal}
                  />
                  </div>)
              }

              { exibeCampos && (
                <div>
                  <TegraSelect
                      /*isDisabled={!isParceiro}*/
                      isDisabled={isDesabilitaGerente}
                      placeholder=""
                      noOptionsMessage={() => 'Nenhum selecionado'}
                      label="Gerente"
                      name="gerente"
                      onChange={(e) => {setGerente([e])}}
                      value={gerente}
                      options={gerentesModal}
                  />

                  <TegraSelect
                      isDisabled={isDesabilitaSuperintendente}
                      placeholder=""
                      noOptionsMessage={() => 'Nenhum selecionado'}
                      /*label= {isParceiro ? "Superintendente":"Diretor"}*/
                      label= {"Superintendente/ Diretor"}
                      name="superintendente"
                      onChange={(e) => onChangeSuperintendente(e)}
                      value={superintendente}
                      options={superintendenteModal}
                  />
                </div>)
              }
              <ContainerTextArea>
                <p>Observações</p>
                <TegraTextArea 
                name="observacao" 
                onChange={(e) => setObservacao(e.target.value)}
                value={observacao}
                />
              </ContainerTextArea>



            </Form>
          </ContainerModal>
          <ListaAction>
            <TegraButton isAction isDisabled={loading} onClick={() => {handleCancel(); onChange(); }}>
                Cancelar
              </TegraButton>
              <TegraButton isAction isDisabled={loading} onClick={() => {handleSubmit();}}>
                Salvar       
                {loading && <img src={ajaxLoading} alt="Ícone do botão" />}        
              </TegraButton>
            </ListaAction>

            </Modal>
        </>
    );
};

export default FormRegistroVisita;