/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-return-assign */
import React, { useState, useCallback, RefObject, useEffect } from 'react';

import { FormHandles } from '@unform/core';
import { Hidden } from 'react-grid-system';
import {
  FilterContainer,
  FilterContainerHeader,
  FilterContainerContent,
  FilterActionsContainer,
} from './styles';
import closeFilterIcon from '../../assets/tegra-icon-close.svg';
import TegraDivider from '../TegraDivider';
import TegraMultiCheckbox from '../TegraMultiCheckbox';
import TegraButton from '../TegraButton';
import TegraRange from '../TegraRange';
import api from '../../services/api';

interface IFiltroItemOption {
  label: string;
  value: string;
  checked?: boolean;
}

interface IFiltroItemOptionRange {
  label_from: string;
  label_to: string;
}

interface IFiltroItem {
  label: string;
  name: string;
  type: 'checkbox' | 'multiselect' | 'range' | 'search-checkbox';
  options?: IFiltroItemOption[] | IFiltroItemOptionRange;
}

const mockFiltros: IFiltroItem[] = [
  {
    label: 'Área',
    type: 'range',
    name: 'aerea',
    options: { label_from: 'De', label_to: 'Até' },
  },
  {
    label: 'Preço',
    type: 'range',
    name: 'preco',
    options: { label_from: 'Preço mínimo', label_to: 'Preço máximo' },
  },
];

interface IEmpreendimentoTipo {
  nome: string;
  id: string;
}

interface IEmpreendimentoAndamento {
  nome: string;
  id: string;
}

interface IEmpreendimentoRegiao {
  nome: string;
  id: string;
}

interface IEmpreendimentoBairro {
  nome: string;
  id: string;
}

interface TegraFiltroProps {
  handleFiltroShow: Function;
  doSearch: Function;
  filtroFormRef: RefObject<FormHandles>;
  idEstado: number;
}
const TegraFiltro: React.FC<TegraFiltroProps> = ({
  handleFiltroShow,
  filtroFormRef,
  doSearch,
  idEstado,
}) => {
  const [clearRange, setClearRange] = useState(false);
  const [filtros, setFiltros] = useState<IFiltroItem[]>(mockFiltros);

  const handleApplyFilter = useCallback(() => {
    if (!filtroFormRef.current) {
      throw new Error('Erro no form');
    }
    filtroFormRef.current.submitForm();
  }, [filtroFormRef]);

  const getAlterLabel = useCallback((filtro: IFiltroItem, side: string) => {
    let label = '';
    if (filtro.name === 'preco') {
      if (side === 'left') {
        label = 'Preço mínimo:';
      } else {
        label = 'Preço máximo';
      }
    }

    return label;
  }, []);

  const limparFiltros = useCallback((): void => {
    const lst = [...filtros];

    for (let i = 0; i < lst.length; i += 1) {
      const obj = lst[i];
      const opt = obj.options as IFiltroItemOption[];
      filtroFormRef?.current?.clearField(obj.name);
      if (opt) {
        obj.options = opt.map(o => ({ ...o, checked: false }));
      }
    }
    setClearRange(!clearRange);
    setFiltros(filtros);
    doSearch(undefined, true, 1);
  }, [clearRange, filtroFormRef, filtros, doSearch]);

  const getPlaceholder = useCallback((filtro: IFiltroItem) => {
    let placeholder = '';
    if (filtro.name === 'preco') {
      placeholder = 'R$';
    }

    return placeholder;
  }, []);

  useEffect(() => {
    async function loadFilterData(): Promise<void> {
      const { data: tipoData } = await api.get<IEmpreendimentoTipo[]>(
        'Empreendimento/tipos',
      );
      const { data: andamentoData } = await api.get<IEmpreendimentoAndamento[]>(
        'Empreendimento/andamentos',
      );

      let newFilters: IFiltroItem[] = [
        {
          label: 'Tipo de Produto',
          type: 'checkbox',
          name: 'tipo_produto',
          options: tipoData.map(tipo => ({
            label: tipo.nome,
            value: tipo.id,
          })),
        },
        {
          label: 'Estágio da obra',
          type: 'checkbox',
          name: 'estagio_obra',
          options: andamentoData.map(andamento => ({
            label: andamento.nome,
            value: andamento.id,
          })),
        },
      ];
      const { data: regioesData } = await api.get<IEmpreendimentoRegiao[]>(
        `Cidades/regioes/:id_cidade`,
        { params: { id_cidade: idEstado === 26 ? 5242 : 3589 } },
      );
      const { data: bairrosData } = await api.get<IEmpreendimentoBairro[]>(
        `Cidades/bairros/:id_cidade`,
        { params: { id_cidade: idEstado === 26 ? 5242 : 3589 } },
      );

      newFilters = [
        ...newFilters,
        ...([
          {
            label: 'Regiões',
            type: 'checkbox',
            name: 'regioes',
            options: regioesData.map(regiao => ({
              label: regiao.nome,
              value: regiao.id,
            })),
          },
          {
            label: 'Bairros',
            type: 'search-checkbox',
            name: 'bairros',
            options: bairrosData.map(bairro => ({
              label: bairro.nome,
              value: bairro.id,
            })),
          },
          {
            label: 'Dormitórios',
            type: 'checkbox',
            name: 'dormitorios',
            options: [
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4+', value: '4' },
            ],
          },
        ] as IFiltroItem[]),
      ];

      setFiltros(prev => {
        return [...newFilters, ...prev];
      });
    }

    loadFilterData();
  }, []);

  const setFiltro = (filtro: IFiltroItem, items: IFiltroItemOption[]): void => {
    const lst = [...filtros];
    lst[lst.findIndex(i => i.name === filtro.name)].options = items;
    setFiltros(lst);

    lst.forEach(i => {
      const opt = i.options as IFiltroItemOption[];
      if (opt && opt.filter)
        filtroFormRef?.current?.setFieldValue(
          i.name,
          JSON.stringify(opt?.filter(l => l.checked).map(l => l.value)),
        );
    });
    filtroFormRef?.current?.submitForm();
  };

  return (
    <FilterContainer>
      <FilterContainerHeader className="filter-header">
        <h1>Filtrar</h1>
        <img
          src={closeFilterIcon}
          alt="Fechar filtro"
          onClick={() => handleFiltroShow(false)}
        />
      </FilterContainerHeader>
      <TegraDivider axis="x" color="#fff" />
      <FilterContainerContent>
        {filtros.map(filtro => (
          <li key={filtro.name}>
            <strong>{filtro.label}</strong>
            <div>
              {filtro.type === 'checkbox' && Array.isArray(filtro.options) && (
                <TegraMultiCheckbox
                  name={filtro.name}
                  formRef={filtroFormRef}
                  items={filtro.options}
                  setItems={(items: IFiltroItemOption[]) =>
                    setFiltro(filtro, items)}
                />
              )}
              {filtro.type === 'search-checkbox' &&
                Array.isArray(filtro.options) && (
                  <TegraMultiCheckbox
                    hasSearch
                    name={filtro.name}
                    formRef={filtroFormRef}
                    items={filtro.options}
                    setItems={(items: IFiltroItemOption[]) =>
                      setFiltro(filtro, items)}
                  />
                )}

              {filtro.type === 'range' && (
                <TegraRange
                  alterLeftLabel={getAlterLabel(filtro, 'left')}
                  alterRightLabel={getAlterLabel(filtro, 'right')}
                  placeholder={getPlaceholder(filtro)}
                  formRef={filtroFormRef}
                  name={filtro.name}
                  clearRange={clearRange}
                />
              )}
            </div>
          </li>
        ))}
      </FilterContainerContent>
      <Hidden sm={false} md xl xxl lg>
        <TegraDivider axis="x" color="#fff" />
      </Hidden>
      <FilterActionsContainer>
        <span onClick={limparFiltros}>Limpar Filtros</span>
        <TegraButton isAction onClick={() => handleApplyFilter()}>
          Aplicar filtro
        </TegraButton>
      </FilterActionsContainer>
    </FilterContainer>
  );
};

export default TegraFiltro;
