import styled from 'styled-components';

export const Container = styled.div`
  & > .divider {
    margin-top: 40px;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      margin-top: 80px;
    }
  }
`;

export const SearchContainer = styled.div`
  margin-top: 54px;
`;

export const Title = styled.strong`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #272723;
`;

export const CorretoresContainer = styled.strong``;

export const CorretoresTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  thead {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #ebb92d;
    vertical-align: middle;
    padding-left: 15px;
    padding-bottom: 10px;
    th {
      padding: 0 10px 10px 10px;
      border-bottom: 1px solid #e6e6e6;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  tbody {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.12px;
    text-align: left;
    color: #272723;

    tr {

      td {
        padding: 5px 10px;
        border-bottom: 1px solid #e6e6e6;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.action {
          width: 230px;
          button {
            float: right;
            margin-left: 5px;
          }
        }
      }
    }

    .caption {
      display: none;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: left;
      color: #ebb92d;
    }
  }

  @media (max-width: 768px) {
    thead {
      display: none;
    }
    tbody tr {
      display: flex;
      flex-wrap: wrap;
      /* padding: 15px 0px; */

      td {
        width: 100%;
        margin-top: 11px;
        .caption {
          display: block;
        }
      }

      .action,
      button {
        width: 100%;
        margin-top: 12px;
      }
    }
  }
`;

export const ListaAction = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-top: 41px;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    button {
      flex: 1;
      width: 100%;
    }
  }
`;

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  @media (max-width: 768px) {
    justify-content: flex-start;
    button {
      flex: 1;
      width: 100%;
    }
  }
`;

export const ContainerPaginate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  flex-wrap: wrap;

  .descSelect{
    padding-right: 20px;
    color: #272723;
    padding-top: 10px;
  }

  .selectPage{
    padding-top: 10px;
    width: 80px;
  }

  .quantRegistro{
    padding-left: 20px;
    color: #272723;
    padding-top: 10px;
  }
`;
