/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { ReactComponent as CloseIcon } from '../../assets/tegra-icon-close.svg';
import api from '../../services/api';
import { processLogin } from '../../utils/login';
import zIndex from '../../utils/zIndex';

import { CloseButton, ModalTitle, Select } from './styles';

const customStyles = {
  content: {
    width: 740,
    padding: 40,
    paddingTop: 80,
    top: '50%',
    left: '50%',
    right: 'auto',
    color: '#272723',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: '#272723',
    zIndex: zIndex.tegraShareModal,
  },
};

const TegraAccordion: React.FC<any> = ({ isOpen, setIsOpen }) => {
  const [novaRegional, setNovaRegional] = useState('');
  useEffect(() => {
    async function changeRegional(regional: any) {
      try {
        const rsp = await api.post('Login/changeRegional', {
          regional: Number(regional),
        });

        const { access_token: token, data: objUser } = rsp.data;
        processLogin(objUser, token);
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      } catch {
        // eslint-disable-next-line no-console
        console.log('Não foi possível trocar de regional');
      }
    }
    if (novaRegional) changeRegional(novaRegional);
  }, [novaRegional]);
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Trocar de regional"
    >
      <CloseButton onClick={() => setIsOpen(false)}>
        <CloseIcon />
      </CloseButton>
      <ModalTitle>Trocar de regional</ModalTitle>
      <Select
        value={novaRegional}
        onChange={e => setNovaRegional(e.target.value)}
      >
        <option value="">-- Selecione uma regional --</option>
        <option value="1">Tegra SP</option>
        <option value="2">Tegra RJ</option>
        <option value="3">Tegra Campinas</option>
        <option value="4">Tegra Lab</option>
      </Select>
    </Modal>
  );
};

export default TegraAccordion;
