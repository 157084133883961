import React from 'react';
import {
  TegraContactGroupsContainer,
  ContactItem,
  ContactGroupsTitle,
  ContactPicture,
  ContactDataName,
  ContactDataTitle,
  ContactDataContent,
  ContactDataContentTel,
  ContactDataContainer,
  ContactInfoContainer,
  ContactLinkTitle,
} from './styles';
import defaultImage from '../../../assets/avatar.svg';

export interface IContacts {
  image: string;
  name: string;
  apelido: string;
  team: string;
  products?: string[];
  occupation: string;
  email: string;
  phone: string;
}

export interface IContactGroup {
  title?: string;
  contacts: IContacts[];
}

interface ITegraContactGroupsProps {
  contactGroup: IContactGroup;
}

const TegraContactGroups: React.FC<ITegraContactGroupsProps> = ({
  contactGroup,
}) => {
  return (    
    <TegraContactGroupsContainer>
      {contactGroup.title && (
        <ContactGroupsTitle>{contactGroup.title}</ContactGroupsTitle>
      )}
      {contactGroup.contacts.map(contactItem => (
        <ContactItem key={`i-contact-${contactItem.name}`}>
          <ContactPicture
            alt={`Imagem do contato ${contactItem.apelido}`}
            src={contactItem.image || defaultImage}
            onError={(e: any) => {
              e.target.src = defaultImage;
            }}
          />
          <ContactInfoContainer>
            <ContactDataContainer>
              <ContactDataName>{contactItem.apelido}</ContactDataName>
              <ContactLinkTitle href={`mailto:${contactItem.email}`}>
                {contactItem.email}
              </ContactLinkTitle>
              <ContactDataContentTel>{contactItem.phone}</ContactDataContentTel>
            </ContactDataContainer>
            <ContactDataContainer>
              <ContactDataTitle>{contactItem.team}</ContactDataTitle>
              <ContactDataContent>{contactItem.occupation}</ContactDataContent>
            </ContactDataContainer>
            {contactItem.products && (
              <ContactDataContainer>
                <ContactDataContent product>
                  {contactItem.products.length === 0 ? '' : 'Produtos'}
                </ContactDataContent>
                {contactItem.products.map(product => (
                  <ContactDataTitle key={`contact-product-${product}`} product>
                    {product}
                  </ContactDataTitle>
                ))}
              </ContactDataContainer>
            )}
          </ContactInfoContainer>
        </ContactItem>
      ))}
    </TegraContactGroupsContainer>
  );
};

export default TegraContactGroups;
