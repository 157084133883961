import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import getCurrentDate from '../../utils/date/getCurrentDate'
import { useParams } from 'react-router-dom';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import TegraTabsContainer from '../../components/TegraTabsContainer';
import {
  RankingPageContainer,
  RankingContentWrapper,
  RankingActionContainer,
  RankingTabsWrapper,
} from './styles';
import RankingTable from './RankingTable';
import RankingSelfBanner from './RankingSelfBanner';
import TegraBannerEquipe from '../../components/TegraBannerEquipe';
import { useHistory } from 'react-router-dom';
import TegraButton from '../../components/TegraButton';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { useLoader } from '../../hooks/loader';
import { AnyRecord } from 'dns';

export interface ILink {
  label: string;
  link: string;
}

export interface ISelfRanking {
  profile: IRanking;
  position: number;
}

export interface IRanking {
  Equipe: ILink;
  [key: string]: string | ILink;
}

const Ranking: React.FC = () => {
  const PAGE_SIZE = 10;

  const tabNumber = Object.freeze({ CORRETORES: 0, GERENTE: 1, EQUIPE: 2 });

  const { tab } : any = useParams();
  const { user } = useAuth();
  const history = useHistory();

  const { setLoaderActive } = useLoader();
  const [rankingSelf, setRankingSelf] = useState<ISelfRanking>();

  const [gerentesRankingTotal, setGerentesRankingTotal] = useState<IRanking[]>([],);
  const [gerentesRanking, setGerentesRanking] = useState<IRanking[]>([]);
  const [corretoresRankingTotal, setCorretoresRankingTotal] = useState<IRanking[]>([]);
  const [corretoresRanking, setCorretoresRanking] = useState<IRanking[]>([]);
  const [rankingTotal, setRankingTotal] = useState<string>();

  const [equipesRankingTotal, setEquipesRankingTotal] = useState<IRanking[]>(
    [],
  );
  const [equipesRanking, setEquipesRanking] = useState<IRanking[]>([]);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const fetchRanking = async (): Promise<void> => {
    setLoaderActive(true);
    if (selectedIndex === tabNumber.CORRETORES) {
      const response = await api.get('Ranking/:corretores');

      const corretoresRankingTotalPlaceholder: IRanking[] = [];
      setRankingTotal(response.data.vendaTotalFormatado);
      const apiResponse = response.data.listRanking;

      const rankingSelfPlaceholder = response.data.eu;

      if (rankingSelfPlaceholder) {
        setRankingSelf({
          profile: {
            image: rankingSelfPlaceholder.tx_foto,
            Corretor: rankingSelfPlaceholder.nome,
            'VGV Vendido': rankingSelfPlaceholder.valorFormatado,
            Equipe: {
              link: `/dashboard/ranking/${rankingSelfPlaceholder.equipe}`,
              label: rankingSelfPlaceholder.equipe,
            },
          },
          position: rankingSelfPlaceholder.nu_rank,
        });
      }else{
        setRankingSelf(undefined);
      }

      apiResponse.forEach((rankingItem: any) => {
        const rankingPlaceholder: IRanking = {
          image: rankingItem.tx_foto,
          Corretor: rankingItem.nome,
          'VGV Vendido': rankingItem.valorFormatado,
          Equipe: {
            link: `/dashboard/ranking/${rankingItem.equipe}`,
            label: rankingItem.equipe,
          },
        };
        corretoresRankingTotalPlaceholder.push(rankingPlaceholder);
      });
      setCorretoresRankingTotal(corretoresRankingTotalPlaceholder);
      setCorretoresRanking(
        corretoresRankingTotalPlaceholder.slice(0, PAGE_SIZE),
      );
    } else if (selectedIndex === tabNumber.GERENTE) {
      const response = await api.get('Ranking/:gerentes');

      const gerentesRankingTotalPlaceholder: IRanking[] = [];
      setRankingTotal(response.data.vendaTotalFormatado);
      const apiResponse = response.data.listRanking;

      apiResponse.forEach((rankingItem: any) => {
        const rankingPlaceholder: IRanking = {
          image: rankingItem.tx_foto,
          Gerente: rankingItem.nome,
          'VGV Vendido': rankingItem.valorFormatado,
          Equipe: {
            link: `/dashboard/ranking/${rankingItem.nome}`,
            label: rankingItem.nome,
          },
        };

        gerentesRankingTotalPlaceholder.push(rankingPlaceholder);
      });
      setGerentesRankingTotal(gerentesRankingTotalPlaceholder);
      setGerentesRanking(gerentesRankingTotalPlaceholder.slice(0, PAGE_SIZE));
    } else if (selectedIndex === tabNumber.EQUIPE) {
      const response = await api.get(`Ranking/:equipe?equipe=${tab}`);
      const apiResponse = response.data.listRanking;

      setRankingTotal(response.data.vendaTotalFormatado);
      const equipesRankingTotalPlaceholder: IRanking[] = [];

      const rankingSelfPlaceholder = response.data.eu;
      if (rankingSelfPlaceholder) {
        setRankingSelf({
          profile: {
            image: rankingSelfPlaceholder.tx_foto,
            Corretor: rankingSelfPlaceholder.nome,
            'VGV Vendido': rankingSelfPlaceholder.valor.toString(),
            Equipe: {
              link: `/dashboard/ranking/${rankingSelfPlaceholder.equipe}`,
              label: rankingSelfPlaceholder.equipe,
            },
          },
          position: rankingSelfPlaceholder.nu_rank,
        });
      } else{
        setRankingSelf(undefined);
      }
      apiResponse?.forEach((rankingItem: any) => {
        const rankingPlaceholder: IRanking = {
          image: rankingItem.tx_foto,
          Gerente: rankingItem.nome,
          'VGV Vendido': rankingItem.valorFormatado,
          Equipe: {
            link: `/dashboard/ranking/${rankingItem.equipe}`,
            label: rankingItem.equipe,
          },
        };

        equipesRankingTotalPlaceholder.push(rankingPlaceholder);
      });

      setEquipesRankingTotal(equipesRankingTotalPlaceholder);
      setEquipesRanking(equipesRankingTotalPlaceholder.slice(0, PAGE_SIZE));
    }
    setLoaderActive(false);
  };

  const loadMore = (index: number): void => {
    if (index === tabNumber.CORRETORES) {
      setCorretoresRanking(
        corretoresRankingTotal.slice(
          0,
          PAGE_SIZE * (corretoresRanking.length / PAGE_SIZE + 1),
        ),
      );
    } else if (index === tabNumber.GERENTE) {
      setGerentesRanking(
        gerentesRankingTotal.slice(
          0,
          PAGE_SIZE * (gerentesRanking.length / PAGE_SIZE + 1),
        ),
      );
    } else if (index === tabNumber.EQUIPE) {
      setEquipesRanking(
        equipesRankingTotal.slice(
          0,
          PAGE_SIZE * (equipesRanking.length / PAGE_SIZE + 1),
        ),
      );
    }
  };

  useEffect(() => {
    fetchRanking();
  }, [selectedIndex]);

  useEffect(() => {
    if (tab === 'corretores') {
      setSelectedIndex(0);
    } else if (tab === 'gerente') {
      setSelectedIndex(1);
    } else {
      setSelectedIndex(2);
    }
  }, [tab]);

  const navigate = (url : string)=>{
    history.push('/dashboard/ranking/'+url);
  }

  const getRegional = (() => {  
    const regional = user.idRegional;
    if (regional == 1){
      return "São Paulo";
    } else if (regional == 2) {
      return "Rio de Janeiro"
    } else {
      return "Campinas"
    }
  });
  
  return (
    <RankingPageContainer>
      <TegraOpaqueHero
        title="Ranking"
        subtitle={`${new Date().getFullYear()} • ${getRegional()}`}
        description= {`Total em vendas: ${rankingTotal}`}
        subDescription={`Atualizado às ${getCurrentDate()} , sendo consideradas as vendas realizadas do ano vigente e nos seguintes status: "Escritura", "Validado com Pendência" e "Distratada com Produção"`}
      />
      <RankingContentWrapper>
        <TegraTabsContainer>
          <Tabs
            selectedIndex={selectedIndex}
            onSelect={index => setSelectedIndex(index)}
          >
            <RankingTabsWrapper>
              <TabList>
                <Tab onClick={()=> navigate('corretores')}>Corretores</Tab>
                {/*<Tab onClick={()=> navigate('gerente')}>Gerentes</Tab>*/}
              </TabList>
            </RankingTabsWrapper>
            <TabPanel>
              {corretoresRanking.length !== 0 && (
                <RankingTable
                  rankingList={corretoresRanking}
                  rankingSelfRow={rankingSelf}
                />
              )}
              {corretoresRankingTotal.length > corretoresRanking.length && (
                <RankingActionContainer>
                  <TegraButton
                    onClick={() => loadMore(tabNumber.CORRETORES)}
                    isAction
                  >
                    CARREGAR MAIS
                  </TegraButton>
                </RankingActionContainer>
              )}
            </TabPanel>
            <TabPanel>
              {gerentesRanking.length !== 0 && (
                <RankingTable rankingList={gerentesRanking} />
              )}
              {gerentesRankingTotal.length > gerentesRanking.length && (
                <RankingActionContainer>
                  <TegraButton
                    onClick={() => loadMore(tabNumber.GERENTE)}
                    isAction
                  >
                    CARREGAR MAIS
                  </TegraButton>
                </RankingActionContainer>
              )}
            </TabPanel>
            <TabPanel>
              {equipesRanking.length !== 0 && (
                <TegraBannerEquipe gerenteEquipe = {equipesRanking[0].Equipe.label} />
                )}
              {rankingSelf && (
                <RankingSelfBanner
                  image={rankingSelf.profile.image as string}
                  sales={rankingSelf.profile['VGV Vendido'] as string}
                  name={rankingSelf.profile.Corretor as string}
                />
              )}
              {equipesRanking.length !== 0 && (
                <RankingTable rankingList={equipesRanking} />
              )}
              {equipesRankingTotal.length > equipesRanking.length && (
                <RankingActionContainer>
                  <TegraButton
                    onClick={() => loadMore(tabNumber.EQUIPE)}
                    isAction
                  >
                    CARREGAR MAIS
                  </TegraButton>
                </RankingActionContainer>
              )}
            </TabPanel>
          </Tabs>
        </TegraTabsContainer>
      </RankingContentWrapper>
    </RankingPageContainer>
  );
};

export default Ranking;
