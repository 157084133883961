import React from 'react';
import ProductMaterials from '../..';

const IptuCondominio: React.FC = () => {
  return (
    <ProductMaterials
      title="IPTU e Condomínio"
      subtitle=""
      description="Informações sobre impostos e taxas condominiais de cada um de nossos empreendimentos."
      showColumnTitle={false}
      actions={['download']}
      apiUrl="IPTUCondominio"
      columns={[{ label: 'Label', field: 'tx_produto' }]}
      anchorName="Iptu"
      onClickItem={() => localStorage.setItem('SCROLL', '#Iptu')}
    />
  );
};

export default IptuCondominio;
