import React, {
  RefObject,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import { Container as GridContainer, Row, Col } from 'react-grid-system';
import { FiChevronLeft } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import Input from '../../../../components/TegraInput';
import TegraButton from '../../../../components/TegraButton';
import TegraDivider from '../../../../components/TegraDivider';
import {
  Wrapper,
  DontKnowCEPContainer,
  WizardNavigationContainer,
  WizardNavigationGoBack,
  ErrorsContainer,
  LineDivTitleErrors,
  Subtitle,
  ExtraActions,
  GoBackContainer,
  Title,
  DivTegraRio, 
  TegraRedirect,
  OrigemContato,
  DivTextArea,
} from './styles';
import TegraSelect from '../../../../components/TegraSelect';
import TegraInputWithMask from '../../../../components/TegraInputWithMask';
import ajaxLoading from '../../../../assets/ajax-loader.gif';

import { FaEye, FaEyeSlash } from 'react-icons/fa';

import api from '../../../../services/api';
import { ImobiliariaFormData } from '..';
import { request } from 'https';
import { StyledCheckbox } from '../../../../components/TegraCheckbox/styles';
// import { StepProps, withWizard } from 'react-albus';

interface StepProps {
  acessoAdm: boolean;
  updateForm: (data: ImobiliariaFormData) => void;
  changeStep: (step: string) => void;
  id: string;
  formRef: RefObject<FormHandles>;
  formData: ImobiliariaFormData;
  
}

interface Regional {
  id: number;
  nome: string;
  uf: string;
}
interface Dropdown {
  value: number;
  label: string;
  uf?: string;
  cidade?: number | string;
}

interface Zona {
  id: number;
  nome: string;
}

interface Cidade {
  id: number;
  nome: string;
  uf: string;
  regioes: Zona[];

}

const StepOne: React.FC<StepProps> = ({
  acessoAdm,
  formRef,
  updateForm,
  formData,
  changeStep,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [descErrors, setDescErrors] = useState<string>('');

  const defaultValues = useMemo(() => formData, [formData]);

  //Pega o token para caso clique no botão voltar
  const urlCompleta = new URL(window.location.href)
  const tokenAdm =  urlCompleta.searchParams.get('tk') === null? "/signup" : "/signup/?tk=" + urlCompleta.searchParams.get('tk');

  /*
  if (acessoAdm){
    const urlCompleta = new URL(window.location.href)
    setTokenAdm(urlCompleta.searchParams.get('tk') === null? "" :
                           "/?tk=" + urlCompleta.searchParams.get('tk'));
  }
*/

  const [loading, setLoading] = useState<boolean>(true);

  const [cidades, setCidades] = useState<Dropdown[]>([]);

  //const [isCampinas, setIsCampinas] = useState(false);

  const [regionais, setRegionais] = useState<Dropdown[]>([]);

  const [zonas, setZonas] = useState<Dropdown[]>([]);

  const [passwordState, setPasswordState] = useState(false);

  const [maskInscEstadualState, setMaskInscEstadual] = useState("999.999.999.999");

  const [isOutraOrigem, setIsOutraOrigem] = useState(false);
  
  const [outraOrigem, setOutraOrigem] = useState();

  const [passwordValid, setPasswordValid] = useState({
    hasSix: false,
    hasEighteen: false,
    hasUpper: false,
    hasLower: false,
    hasNumber: false,
    hasSpecial: false,
  });

  const [isencao, setIsencao] = useState(false);

  /*
  const [tegraRio, setTegraRio] = useState(false);
  const [buttonTegraRio, setButtonTegraRio] = useState(true);
  const [showCampos, setShowCampos] = useState(acessoAdm);
*/

  const checkPassword = (passwordValue: string) => {
    let passwordValidState = { ...passwordValid },
      hasSpecialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
      hasNumbers = /[0-9]/,
      hasLower = /[a-z]/,
      hasUpper = /[A-Z]/,
      testSpecial = hasSpecialChar.test(passwordValue),
      testNumbers = hasNumbers.test(passwordValue),
      testLower = hasLower.test(passwordValue),
      testUpper = hasUpper.test(passwordValue),
      testSixChar = passwordValue.length >= 6 ? true : false,
      testEighteenChar = passwordValue.length <= 18 ? true : false;

    passwordValidState.hasSix = testSixChar;
    passwordValidState.hasEighteen = testEighteenChar;
    passwordValidState.hasUpper = testUpper;
    passwordValidState.hasLower = testLower;
    passwordValidState.hasNumber = testNumbers;
    passwordValidState.hasSpecial = testSpecial;
    setPasswordValid(passwordValidState);
  };

/*
  const openTegraRio = useCallback(() => {
    //window.open("https://plataformadigital-tegra.sydle.com/plataformaDigital/i/618beb849e20761ea976762a/618bd5119e20761ea9ee84c4/areas+tegra-vendas+cadastro-de-parceiro-tegra-rio-de-janeiro");  
    setButtonTegraRio(false);
    window.setTimeout(function() {
      window.open("https://plataformadigital-tegra.sydle.com/plataformaDigital/i/618beb849e20761ea976762a/618bd5119e20761ea9ee84c4/areas+tegra-vendas+cadastro-de-parceiro-tegra-rio-de-janeiro");
    }, 4000);
  },[buttonTegraRio]);
*/
  function getSenhaAleatoria() {
    var randomCharsMax = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var randomCharsMin = 'abcdefghijklmnopqrstuvwxyz';
    var randomNumber = '0123456789';
    var randomSpecial = '!@#$%&*()-_=+[]{}~^<>?';
    var result = '';
    for ( var i = 0; i < 3; i++ ) {
        result += randomCharsMax.charAt(Math.floor(Math.random() * randomCharsMax.length));
        result += randomCharsMin.charAt(Math.floor(Math.random() * randomCharsMin.length));
        result += randomNumber.charAt(Math.floor(Math.random() * randomNumber.length));
        result += randomSpecial.charAt(Math.floor(Math.random() * randomSpecial.length));
    }
    return result;
}

  const handleStepSubmit = useCallback(async () => {
    let data: ImobiliariaFormData = {} as ImobiliariaFormData;

    if (!formRef.current) {
      return;
    }

    setDescErrors('');
    setErrors([]);

    data = formRef.current.getData() as ImobiliariaFormData;

    if (acessoAdm && (!data.senha)){
      //let senhaAletoria = getSenhaAleatoria();
      data.senha = 'Abc,12345';
      data.confirmacaoSenha = 'Abc,12345';
    }
    
    try {

      const schema = 
        acessoAdm ?
        Yup.object().shape({
        creci: Yup.string().required('CRECI é obrigatório'),
        cep: Yup.string().required('CEP é obrigatório'),
        nome: Yup.string().required('Nome é obrigatório'),
        email: Yup.string()
          .email('Email deve ser um email válido')
          .required('E-mail é obrigatório'),
        telefone: Yup.string(),//.required('Telefone é obrigatório'),
        celular: Yup.string().required('Celular é obrigatório'),
        // inscricao_estadual: Yup.string().required('Inscrição Estadual é obrigatória'),
        data_constituicao: Yup.string().required('Data de Constituição é obrigatória'),
        endereco: Yup.string().required('Endereço é obrigatório'),
        numero: Yup.string().required('Número é obrigatório'),
        complemento: Yup.string(), //.required('Complemento é obrigatório'),
        bairro: Yup.string().required('Bairro é obrigatório'),
        cnpj: Yup.string().required('CNPJ é obrigatório'),
        razao_social: Yup.string().required('Razão Social é obrigatório'),
        regional: Yup.object()
          .nullable()
          .required('Regional é obrigatório')
          .shape({
            value: Yup.string().required('Regional'),
          }),     
        contato: Yup.object()
          .nullable()
          .required('Origem do contato é obrigatório')
          .shape({
            value: Yup.string().required('contato'),
          }),    
        outraOrigem: isOutraOrigem && !outraOrigem ? Yup.string().required('Outra origem é obrigatório') : Yup.string(), 
      })

      :

      Yup.object().shape({
        creci: Yup.string().required('CRECI é obrigatório'),
        cep: Yup.string().required('CEP é obrigatório'),
        nome: Yup.string().required('Nome é obrigatório'),
        email: Yup.string()
          .email('Email deve ser um email válido')
          .required('E-mail é obrigatório'),
          senha: Yup.string().required('Senha é obrigatória'),
        confirmacaoSenha: Yup.string()
          .oneOf(
            [Yup.ref('senha'), undefined],
            'A senha e a confirmação de senha não são iguais',
          )
          .required('Confirmação é obrigatória'),
        telefone: Yup.string(),//.required('Telefone é obrigatório'),
        celular: Yup.string().required('Celular é obrigatório'),
        // inscricao_estadual: Yup.string().required('Inscrição Estadual é obrigatória'),
        data_constituicao: Yup.string().required('Data de Constituição é obrigatória'),
        endereco: Yup.string().required('Endereço é obrigatório'),
        numero: Yup.string().required('Número é obrigatório'),
        complemento: Yup.string(), //.required('Complemento é obrigatório'),
        bairro: Yup.string().required('Bairro é obrigatório'),
        cnpj: Yup.string().required('CNPJ é obrigatório'),
        razao_social: Yup.string().required('Razão Social é obrigatório'),
        regional: Yup.object()
          .nullable()
          .required('Regional é obrigatório')
          .shape({
            value: Yup.string().required('Regional'),
          }), 
        contato: Yup.object()
          .nullable()
          .required('Origem do contato é obrigatório')
          .shape({
            value: Yup.string().required('contato'),
          }),
        outraOrigem: isOutraOrigem && !outraOrigem ? Yup.string().required('Outra origem é obrigatório') : Yup.string(),        
      });


      await schema.validate(data, {
        abortEarly: false,
      });

      const selectedRegional = regionais.find(
        reg => reg.value === Number(data.regional.value),
      );

      if (!selectedRegional) {
        throw new Error('Nenhuma regional selecionada');
      }

      if (sessionStorage.getItem('_corretorSelecionado')) {
        const ger = JSON.parse(
          sessionStorage.getItem('_corretorSelecionado') || '{}',
        );
        data.gerente = {
          value: ger.id_corretorSigavi,
          label: ger.tx_nome,
        };
        data.possui_gerente = true;
      }

      const dataConstituicaoTratada = data.data_constituicao
        ? `${data.data_constituicao
            .split('/')
            .reverse()
            .join('-')}T00:00:00.000Z`
        : '';

      const requestData = {
        // só utilizado caso esteja editando
        id_parceiro: formData?.id_parceiro,
        id_tipoParceiro: 1,
        tx_cnpj: data.cnpj,
        tx_razaoSocial: data.razao_social,
        tx_responsavel: data.nome,
        tx_responsavelEmail: data.email,
        tx_responsavelTelefone: data.telefone,
        id_zona: data.zona?.value,
        tx_zona: data.zona?.label,
        tx_nome: data.nome,
        tx_email: data.email,
        tx_senha: data.senha,
        tx_confirmacaoSenha: data.confirmacaoSenha,
        tx_telefone: data.telefone,
        tx_celular: data.celular,
        tx_inscricaoEstadual: isencao ? 'Isenta' : data.inscricao_estadual,
        dt_dataConstituicao: dataConstituicaoTratada,
        tx_logradouro: data.endereco,
        tx_numero: data.numero,
        tx_complemento: data.complemento,
        tx_bairro: data.bairro,
        tx_cep: data.cep,
        id_regional: data.regional?.value,
        tx_regional: data.regional?.label,
        id_cidade: data.cidade?.value,
        tx_cidade: data.cidade?.label,
        tx_creci: data.creci,
        dt_cadastro: new Date().toISOString(),
        tx_uf: data.regional.uf,

        id_gerente: data.gerente?.value,
        tx_gerente: data.gerente?.label,
        hasGerente: data.possui_gerente,

        isAcessoAdm: acessoAdm,

        tx_origemContato: data.contato?.label,
        tx_outraOrigem: isOutraOrigem ? outraOrigem : null,
      };

  /*    
      if (requestData.tx_regional === 'Tegra Campinas') {
        const findCampinasCidade = cidades.find(
          cidade => cidade.label === 'Campinas',
        );

        if (findCampinasCidade) {
          Object.assign(requestData, {
            tx_cidade: findCampinasCidade.label,
            id_cidade: findCampinasCidade.value,            
          });
        }
        data.cidade = { label: requestData.tx_cidade, value: requestData.id_cidade };        
      }
*/
      setLoading(true);
      api
        .post('Cadastro/part1', requestData)
        .then(res => {
          const { sucesso, idParceiro, token } = res.data;          
          if (sucesso) {
            // aqui precisa salvar no sessionStorage pra continuar se der F5
            requestData.id_parceiro = idParceiro;
            data.id_parceiro = idParceiro;
            data.token = token;
            data.step = sessionStorage.getItem('_corretorSelecionado')
              ? 'stepThree'
              : 'stepTwo';
            updateForm(data);
            sessionStorage.setItem('_cadastro', JSON.stringify(data));
            setLoading(false);
            changeStep(data.step);
          }
        })
        .catch(err => {
          setLoading(false);
          if (err.response) {
            const { erro } = err.response.data;

            const validationErrors: string[] = [];

            validationErrors.push(erro);

            setErrors(validationErrors);
          }
        });
    } catch (err) {
      const validationErrors: string[] = [];

      if (err instanceof Yup.ValidationError) {
        setDescErrors('Um ou mais campos preenchidos consta alguma informação inválida. Por favor, verifique os campos e tente novamente.');
        err.inner.forEach(error => {
          validationErrors.push(error.message);
        });
        setErrors(validationErrors);
      }
    }
  }, [formRef, updateForm, cidades, formData, regionais, changeStep, outraOrigem, isOutraOrigem]);

  const loadCidades = (uf?: string, regional?: number, callback?: () => void): void => {
    api
      .get<Cidade[]>('Cidades', {
        params: { uf, regional },
      })
      .then(res => {
        setCidades(
          res.data.map(cidade => ({
            value: cidade.id,
            label: cidade.nome,
          })),
        );
        if (callback) callback();
      });
  };

  const loadZonas = (
    id_cidade: number | string,
    callback?: () => void,
  ): void => {
    api
      .get<Zona[]>(`Cidades/regioes/:id_cidade`, {
        params: { id_cidade },
      })
      .then(res => {
        setZonas(
          res.data.map(zona => ({
            value: zona.id,
            label: zona.nome,
            cidade: id_cidade,
          })),
        );
        if (callback) callback();
      });
  };


  const onChangeCep = useCallback(
    (value) => {               
      const cep = value.replace(/[^0-9]/g,'');

      if (cep?.length  !== 8){
        return;
      }
  
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json()) 
      .then((data) =>{
        if (!formRef.current) {
          return;
        }
          formRef.current.setFieldValue('bairro', data.bairro);
          formRef.current.setFieldValue('endereco', data.logradouro);
          formRef.current.setFieldValue('complemento', data.complemento);        
      });     
    },
    [formRef],
  );
  
  const onChangeRegional = useCallback(
    ({ value }) => {

      if (formRef && formRef.current) {
        formRef.current.clearField('inscricao_estadual');
      }

      if (value === 2){
        setMaskInscEstadual("999.999.99-9");
      }
      else{
        setMaskInscEstadual("999.999.999.999");
      }

      const findRegional = regionais.find(regional => regional.value === value);
      formRef.current?.setFieldValue('regional', findRegional);
      if (findRegional) {
        setZonas([]);
        if (formRef && formRef.current) {
          formRef.current.clearField('cidade');
          formRef.current.clearField('zona');
        }
        loadCidades(findRegional.uf, findRegional.value);
      }
      /*
      if (value !== 2 || acessoAdm){
        setTegraRio(false);
        setShowCampos(true);
        const findRegional = regionais.find(regional => regional.value === value);

        if (findRegional) {
          setZonas([]);
          if (formRef && formRef.current) {
            formRef.current.clearField('cidade');
            formRef.current.clearField('zona');
          }
          loadCidades(findRegional.uf, findRegional.value);
        }
      }
      else{
        setCidades([]);
        setTegraRio(true);
      }
      */
    },
    [formRef, regionais, cidades, maskInscEstadualState/*, tegraRio*/],
  );

  const onChangeContato = useCallback(
    (e) => {
      console.log('value', e.value);
      if (e.value === "Outros"){
        setIsOutraOrigem(true);
      }
      else{
        setIsOutraOrigem(false);
      }
    },
    [isOutraOrigem],
  );

  const handleChange = useCallback(
    (event:any) => {  
    setOutraOrigem(event.target.value);
    formRef.current?.setFieldValue('contato', {label: 'Outros', value: 'Outros', outraOrigem: event.target.value});
    },[formRef, outraOrigem]
  );

  const onChangeCidade = useCallback(
    ({ value }) => {
      const findCidade = cidades.find(cidade => cidade.value === value);

      if (formRef && formRef.current) {
        formRef.current.clearField('zona');
      }
      if (findCidade && value == 5242) {
        loadZonas(findCidade.value);
      }else{
        setZonas([]);
      }
    },
    [cidades, formRef, zonas],
  );

  useEffect(() => {  
    api.get<Regional[]>('Regionais').then(res => {
      const { data } = res;
      setRegionais(
        data.map(regional => ({
          value: regional.id,
          label: regional.nome,
          uf: regional.uf,
        })),
      );

      
      if (!formData) {
        setLoading(false);
      } else {
        if (formData.regional) {
          loadCidades(formData.regional.uf, Number(formData.regional.value), () => {
            formRef &&
              formRef.current &&
              formRef.current.setFieldValue('cidade', formData.cidade);

            if (formData.cidade) {
              loadZonas(formData.cidade.value, () => {
                setLoading(false);
              });
            }
          });
        } else setLoading(false);
        if (formRef && formRef.current) {
          formRef.current.setFieldValue('regional', formData.regional);
        }
      }
    });
    
  }, [formData, formRef]);

  useEffect(() => {
    if (!formRef || !formRef.current || !formData || !formData.cidade) return;
    const frm = formRef.current.getData() as ImobiliariaFormData;
    if (typeof formData.zona !== "undefined"){
      if (frm && frm.cidade && formData.zona.cidade === frm.cidade.value) {
        formRef.current.setFieldValue('zona', formData.zona);
      }
    }
  }, [formData, formData.zona, formRef, zonas]);


  useEffect(() => {
    if (sessionStorage.getItem('_corretorSelecionado')){
      const ger = JSON.parse(
        sessionStorage.getItem('_corretorSelecionado') || '{}',
      );
      var value = ger.id_empresaSigavi;
      onChangeRegional({value});
    }      
  }, [regionais]);

  return (
    <Wrapper>
      <ExtraActions>
        <GoBackContainer>
          <FiChevronLeft size={10} />
          <Link to={tokenAdm}>Voltar</Link>
        </GoBackContainer>
        <Link to="/" className="jaPossuoCadastro">
          Já possuo cadastro
        </Link>
      </ExtraActions>
      <Title>Cadastre-se</Title>
      <Subtitle>Sou uma imobiliária</Subtitle>
      {errors.length >= 1 && (
        <ErrorsContainer>
            {descErrors !== '' &&(
              <div>
                <p>{descErrors} </p>   
                <LineDivTitleErrors/>    
              </div>
            )}          
          {errors.map(error => (
            <p dangerouslySetInnerHTML={{ __html: error }} />
          ))}
        </ErrorsContainer>
      )}

      <TegraDivider axis="x" color="#e6e6e6" />
      <GridContainer
        style={{
          padding: 0,
          marginLeft: '0px',
          marginRight: '0px',
          maxWidth: '100%',
        }}
      >
        <Row>
          <Col
            sm={12}
            md={(cidades.length > 0 || sessionStorage.getItem('_corretorSelecionado')) ? 6 : 12}
            xl={(cidades.length > 0 || sessionStorage.getItem('_corretorSelecionado')) ? 6 : 12}
          >
            <TegraSelect
              // onChange={handleSelectChange}
              label="Selecionar Regional"
              placeholder=""
              onChange={onChangeRegional}
              noOptionsMessage={() => 'Nenhum selecionado'}
              name="regional"
              isDisabled={sessionStorage.getItem('_corretorSelecionado')?true:false}
              options={regionais}
            />
          </Col>
          {(cidades.length > 0 || sessionStorage.getItem('_corretorSelecionado'))  && (
            <Col sm={12} md={6} xl={6}>
              <TegraSelect
                label="Selecionar Cidade"
                onChange={onChangeCidade}
                placeholder=""
                noOptionsMessage={() => 'Nenhuma selecionada'}
                name="cidade"
                options={cidades}
              />
            </Col>
          )}
        </Row>
        
          {/*!tegraRio && showCampos &&*/(
            <div>
            <TegraDivider axis="x" color="#e6e6e6" />
            <Row>
              <Col sm={12} md={6} xl={6}>
                 <TegraInputWithMask
                    defaultValue={defaultValues.creci}
                    label="CRECI"
                    mask="999999-a"
                    name="creci"
                />
              </Col>    
              <Col sm={12} md={6} xl={6}>
                <TegraInputWithMask
                  defaultValue={defaultValues.cnpj}
                  label="CNPJ"
                  name="cnpj"
                  mask="99.999.999/9999-99"
                />
              </Col>                         
            </Row>
            <Row>
              <Col sm={12} md={6} xl={6}>
                <Input label="Nome" name="nome" defaultValue={defaultValues.nome} />
              </Col>
              <Col sm={12} md={6} xl={6}>
                 <Input
                    label="Razao Social"
                    name="razao_social"
                    defaultValue={defaultValues.razao_social}
                  />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} xl={6}>
                <TegraInputWithMask
                  label="Telefone"
                  type="tel"
                  name="telefone"
                  defaultValue={defaultValues.telefone}
                  placeholder="Opcional"
                  mask="(99) 9999-9999"
                />
              </Col>
              <Col sm={12} md={6} xl={6}>
                <TegraInputWithMask
                  label="Celular"
                  type="tel"
                  name="celular"
                  defaultValue={defaultValues.celular}
                  mask="(99) 9 9999-9999"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} xl={6}>
                {isencao && (
                  <TegraInputWithMask
                    label="Inscrição Estadual"
                    name="inscricao_estadual"
                    defaultValue="Isenta"
                    mask={""}
                    disabled
                  />
                )}
                {!isencao && (
                  <TegraInputWithMask
                  label="Inscrição Estadual"
                  name="inscricao_estadual"
                  defaultValue={defaultValues.inscricao_estadual}
                  mask={maskInscEstadualState}
                />
                )}
                <input className='isenta'
                    style={{ marginTop: '10px', marginRight: '5px' }}
                    type="checkbox"
                    name="isenta"
                    id="isenta"
                    onChange={(e) => {setIsencao(e.target.checked)}}
                  />
                <label htmlFor="isenta">
                  <span>Isenta</span>
                </label>
              </Col>
              <Col sm={12} md={6} xl={6}>
                <Input
                  label="Data da Constituição"
                  name="data_constituicao"
                  type='date'
                  defaultValue={defaultValues.data_constituicao}
                />
              </Col>
              
            </Row>

            <TegraDivider axis="x" color="#e6e6e6" />

            <Row>
              <Col sm={12} md={12} xl={12}>
                <Input
                  label="Email"
                  type="email"
                  name="email"
                  defaultValue={defaultValues.email}
                />
              </Col>
            </Row>      

            {!acessoAdm && (
              <div>
                <Row>
                  <Col sm={12} md={6} xl={6} className="inputPassword">
                    <Input
                      label="Senha"
                      type={passwordState ? 'text' : 'password'}
                      minLength={6}
                      maxLength={18}
                      name="senha"
                      defaultValue={defaultValues.senha}
                      placeholder=""
                      onChange={e => checkPassword(e.target.value)}
                    />
                    {passwordState ? (
                      <FaEyeSlash 
                        onClick={() => setPasswordState(false)}
                        className="inputPassword__icon"
                        style={{fontSize:'1.50em',opacity:'50%', right: '27px'}}
                      />
                    ) : (
                      <FaEye
                        onClick={() => setPasswordState(true)}
                        className="inputPassword__icon"
                        style={{fontSize:'1.50em',opacity:'50%', right: '27px'}}
                      />
                    )}
                  </Col>
                  <Col sm={12} md={6} xl={6}>
                    <Input
                      label="Confirme sua senha"
                      type={passwordState ? 'text' : 'password'}
                      minLength={6}
                      maxLength={18}
                      name="confirmacaoSenha"
                      defaultValue={defaultValues.confirmacaoSenha}
                    />
                    {passwordState ? (
                      <FaEyeSlash
                        onClick={() => setPasswordState(false)}
                        className="inputPassword__icon"
                        style={{fontSize:'1.50em',opacity:'50%', right: '27px'}}
                      />
                    ) : (
                      <FaEye
                        onClick={() => setPasswordState(true)}
                        className="inputPassword__icon"
                        style={{fontSize:'1.50em',opacity:'50%', right: '27px'}}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} xl={6}>
                    <div className="password-orientations">
                      <p className="orientations-header">
                        A senha deve seguir o seguinte padrão:
                      </p>
                      <p className={passwordValid.hasSix ? 'orientation-valid' : ''}>
                        Mínimo de 6 caracteres;
                      </p>
                      <p
                        className={passwordValid.hasEighteen ? 'orientation-valid' : ''}
                      >
                        Máximo de 18 caracteres;
                      </p>
                      <p className={passwordValid.hasUpper ? 'orientation-valid' : ''}>
                        Possuir letras maiusculas;
                      </p>
                      <p className={passwordValid.hasLower ? 'orientation-valid' : ''}>
                        Possuir letras minusculas;
                      </p>
                      <p className={passwordValid.hasNumber ? 'orientation-valid' : ''}>
                        Possuir ao menos um número;
                      </p>
                      <p
                        className={passwordValid.hasSpecial ? 'orientation-valid' : ''}
                      >
                        Possuir ao menos um caracter especial;
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            )}          
            <TegraDivider axis="x" color="#e6e6e6" />
            <Row>
              <Col sm={12} md={6} xl={6}>
                <DontKnowCEPContainer>
                  <a
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                    target="new"
                  >
                    Não sei o meu CEP
                  </a>
                </DontKnowCEPContainer>
                <TegraInputWithMask
                  defaultValue={defaultValues.cep}
                  label="CEP"
                  name="cep"
                  mask="99999-999"  
                  onChange={(e) => onChangeCep(e.target.value)}              
                />
              </Col>
              <Col sm={12} md={6} xl={6}>
                <Input
                  label="Bairro"
                  name="bairro"
                  defaultValue={defaultValues.bairro}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} xl={12}>
                <Input
                  label="Endereço"
                  name="endereco"
                  defaultValue={defaultValues.endereco}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} xl={6}>
                <Input
                  label="Número"
                  name="numero"
                  type="number"
                  defaultValue={defaultValues.numero}
                />
              </Col>
              <Col sm={12} md={6} xl={6}>
                <Input
                  label="Complemento"
                  name="complemento"
                  placeholder="Opcional"
                  defaultValue={defaultValues.complemento}
                />
              </Col>
            </Row>
            </div>
          )}
          {/*tegraRio &&(
            <DivTegraRio>
              {buttonTegraRio && (
                <TegraButton isDisabled={loading} isAction onClick={openTegraRio} style={{margin:'0px 0px'}}>
                  Continue seu cadastro na Plataforma Digital Tegra
                </TegraButton>
              )}
              {!buttonTegraRio && (
                <div>
                  <TegraRedirect>Parceiro(a), você será redirecionado(a) para a Plataforma Digital da Tegra para continuar o seu cadastro.</TegraRedirect>
                  <TegraRedirect>
                    Caso não seja redirecionado automaticamente, 
                      <span> <a href="https://plataformadigital-tegra.sydle.com/plataformaDigital/i/618beb849e20761ea976762a/618bd5119e20761ea9ee84c4/areas+tegra-vendas+cadastro-de-parceiro-tegra-rio-de-janeiro">clique aqui</a>.</span>
                  </TegraRedirect>
                </div>
              )}

            </DivTegraRio>
              )*/}
        <Row>
          {cidades.length > 0 /*&& !isCampinas*/ && zonas.length > 0 &&(
            <Col sm={12} md={12} xl={12}>
              <TegraSelect
                label="Zona"
                placeholder=""
                noOptionsMessage={() => 'Nenhuma selecionada'}
                name="zona"
                options={zonas}
              />
            </Col>
          )}
          <Col sm={12} md={12} xl={12}>
            <TegraSelect
              placeholder="Selecione a origem do contato"
              noOptionsMessage={() => 'Nenhum selecionado'}
              label="Qual a origem do seu contato com o Tegra Parcerias?"
              name="contato"            
              options={[
                { label: 'Contato Órulo', value: 'Contato Órulo' },
                { label: 'Relacionamento com gerente', value: 'Relacionamento com gerente' },
                { label: 'Site Tegra', value: 'Site Tegra' },
                { label: 'Indicação de Parceiro', value: 'Indicação de Parceiro' },
                { label: 'Outros', value: 'Outros' },          
              ]}
              onChange={(e) => onChangeContato(e)}
            />
          </Col>
          {isOutraOrigem && (
            <Col sm={12} md={12} xl={12}>
              <OrigemContato>Outra Origem</OrigemContato>
              <DivTextArea>
                <textarea
                  name="outraOrigem"                  
                  className="textarea"
                  onChange={handleChange}
                ></textarea>
              </DivTextArea>
            </Col>
          )}
        </Row>

        {errors.length >= 1 && (
          <ErrorsContainer>  
            {descErrors !== '' &&(
              <div>
                <p>{descErrors} </p>   
                <LineDivTitleErrors/>    
              </div>
            )}
            {errors.map(error => (
              <p dangerouslySetInnerHTML={{ __html: error }} />
            ))}
          </ErrorsContainer>
        )}
        <WizardNavigationContainer>
          <WizardNavigationGoBack>
            <FiChevronLeft size={10} />
            <Link to={tokenAdm}>Voltar</Link>
          </WizardNavigationGoBack>
          {/*!tegraRio && showCampos &&*/(
            <TegraButton isDisabled={loading} isAction onClick={handleStepSubmit} style={{margin:'0px 0px'}}>
              Próximo
              {loading && <img src={ajaxLoading} alt="Ícone do botão" />}
            </TegraButton>
          )}
        </WizardNavigationContainer>
      </GridContainer>
    </Wrapper>
  );
};

export default StepOne;