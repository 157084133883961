import styled from 'styled-components';
import colors from '../../../styles/colors';

interface LinkGroupWrapperProps {
  columns: boolean;
}
export const LinkGroupWrapper = styled.div<LinkGroupWrapperProps>`
  border-bottom: white 1px solid;
  &:last-of-type {
    border: none;
  }
  padding-bottom: 2rem;
  font-family: Montserrat;
  font-size: 14px;
  display: flex;
  flex-direction: ${props => (props.columns ? 'row' : 'column')};
  flex-wrap: ${props => (props.columns ? 'wrap' : 'no-wrap')};
  @media (min-width: 992px) {
    border-bottom: none;
    border-right: white 1px solid;
    margin-right: 2rem;
    padding-bottom: 0;
  }
`;

export const LinkGroupTitle = styled.div`
  font-weight: bold;
  width: 100%;
  color: ${colors.yellowTitle};
  padding: 2.25rem 0 1.875rem;
  @media (min-width: 992px) {
    padding-top: 0;
  }
`;
